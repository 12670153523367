import { UsersAction, UsersActionTypes } from './users.actions';
import { BranchListItemDto, BusinessDto, UserDto, UserGroupDto } from '@vpfa/rest-api/admin';
import { produce } from 'immer';

export const USERS_FEATURE_KEY = 'users';

/**
 * Interface for the 'Users' data used in
 *  - UsersState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface UsersState {
  createUser: {
    business: {
      entity: BusinessDto;
      loading: boolean;
      loaded: boolean;
      error?: any;
    };
    branches: {
      entities: BranchListItemDto[];
      loading: boolean;
      loaded: boolean;
      error?: any;
    };
    userGroups: {
      entities: UserGroupDto[];
      loading: boolean;
      loaded: boolean;
      error?: any;
    };
    creating: boolean;
    addUserModalOpen: boolean;
  };
  list: {
    entities: UserDto[];
    loaded: boolean;
    loading: boolean;
    error?: any;
  };
  selectedId?: string | number; // which Users record has been selected
  userDetails: {
    entity: UserDto;
    editUser: {
      branches: {
        entities: BranchListItemDto[];
        loading: boolean;
        loaded: boolean;
        error?: any;
      };
      userGroups: {
        entities: UserGroupDto[];
        loading: boolean;
        loaded: boolean;
        error?: any;
      };
    };
    isLoadingUser: boolean;
    editing: boolean;
    resettingPassword: boolean;
    editUserModalOpen: boolean;
  };
  updatingActiveStatus: boolean;
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState;
}

export const initialState: UsersState = {
  createUser: {
    business: {
      entity: null,
      loading: false,
      loaded: false,
    },
    branches: {
      entities: null,
      loading: false,
      loaded: false,
    },
    userGroups: {
      entities: null,
      loading: false,
      loaded: false,
    },
    creating: false,
    addUserModalOpen: false,
  },
  list: {
    entities: [],
    loaded: false,
    loading: false,
  },
  userDetails: {
    entity: null,
    editUser: {
      branches: {
        entities: null,
        loading: false,
        loaded: false,
      },
      userGroups: {
        entities: null,
        loading: false,
        loaded: false,
      },
    },
    isLoadingUser: false,
    resettingPassword: false,
    editing: false,
    editUserModalOpen: false,
  },
  updatingActiveStatus: false,
};

export function reducer(state: UsersState = initialState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersActionTypes.ResetUsersData: {
      return initialState;
    }
    case UsersActionTypes.LoadUsersGlobal:
    case UsersActionTypes.LoadUsersByCountry:
    case UsersActionTypes.LoadUsersByBusiness:
    case UsersActionTypes.LoadUsersByUsersBusiness: {
      return produce<UsersState>(state, newState => {
        newState.list.loading = true;
        newState.userDetails.entity = null;
        newState.userDetails.editUser.branches.entities = null;
        newState.userDetails.editUser.branches.loaded = false;
        newState.userDetails.editUser.userGroups.entities = null;
        newState.userDetails.editUser.userGroups.loaded = false;
      });
    }
    case UsersActionTypes.LoadUsersGlobalSuccess:
    case UsersActionTypes.LoadUsersByCountrySuccess: {
      return produce<UsersState>(state, newState => {
        newState.list.entities = action.payload;
        newState.list.loaded = true;
        newState.list.loading = false;
      });
    }
    case UsersActionTypes.LoadUsersByUsersBusinessSucess:
    case UsersActionTypes.LoadUsersByBusinessSuccess: {
      return produce<UsersState>(state, newState => {
        newState.list.entities = action.payload.users;
        newState.list.loaded = true;
        newState.list.loading = false;
      });
    }
    case UsersActionTypes.LoadUsersByUsersBusinessError:
    case UsersActionTypes.LoadUsersGlobalError:
    case UsersActionTypes.LoadUsersByCountryError:
    case UsersActionTypes.LoadUsersByBusinessError: {
      return produce<UsersState>(state, newState => {
        newState.list.loading = false;
      });
    }
    case UsersActionTypes.LoadUserDetails: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.isLoadingUser = true;
      });
    }
    case UsersActionTypes.LoadUserDetailsError: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.isLoadingUser = false;
      });
    }
    case UsersActionTypes.LoadUserDetailsCompleted: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.entity = action.payload;
        newState.userDetails.isLoadingUser = false;
      });
    }
    case UsersActionTypes.LoadCreateUserBusiness: {
      return produce<UsersState>(state, newState => {
        newState.createUser.business.loading = true;
      });
    }
    case UsersActionTypes.LoadCreateUserBusinessError: {
      return produce<UsersState>(state, newState => {
        newState.createUser.business.loading = false;
        newState.createUser.business.loaded = false;
      });
    }
    case UsersActionTypes.LoadCreateUserBusinessCompleted: {
      return produce<UsersState>(state, newState => {
        newState.createUser.business.entity = action.payload;
        newState.createUser.business.loading = false;
        newState.createUser.business.loaded = true;
      });
    }
    case UsersActionTypes.LoadCreateUserBranches: {
      return produce<UsersState>(state, newState => {
        newState.createUser.branches.loading = true;
      });
    }
    case UsersActionTypes.LoadCreateUserBranchesError: {
      return produce<UsersState>(state, newState => {
        newState.createUser.branches.loading = false;
        newState.createUser.branches.loaded = false;
      });
    }
    case UsersActionTypes.LoadCreateUserBranchesCompleted: {
      return produce<UsersState>(state, newState => {
        newState.createUser.branches.entities = action.payload;
        newState.createUser.branches.loading = false;
        newState.createUser.branches.loaded = true;
      });
    }
    case UsersActionTypes.LoadCreateUserUserGroups: {
      return produce<UsersState>(state, newState => {
        newState.createUser.userGroups.loading = true;
      });
    }
    case UsersActionTypes.LoadCreateUserUserGroupsError: {
      return produce<UsersState>(state, newState => {
        newState.createUser.userGroups.loading = false;
        newState.createUser.userGroups.loaded = false;
      });
    }
    case UsersActionTypes.LoadCreateUserUserGroupsCompleted: {
      return produce<UsersState>(state, newState => {
        newState.createUser.userGroups.entities = action.payload;
        newState.createUser.userGroups.loading = false;
        newState.createUser.userGroups.loaded = true;
      });
    }
    case UsersActionTypes.LoadEditUserBranches: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUser.branches.loading = true;
      });
    }
    case UsersActionTypes.LoadEditUserBranchesError: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUser.branches.loading = false;
        newState.userDetails.editUser.branches.loaded = false;
      });
    }
    case UsersActionTypes.LoadEditUserBranchesCompleted: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUser.branches.entities = action.payload;
        newState.userDetails.editUser.branches.loading = false;
        newState.userDetails.editUser.branches.loaded = true;
      });
    }
    case UsersActionTypes.LoadEditUserUserGroups: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUser.userGroups.loading = true;
      });
    }
    case UsersActionTypes.LoadEditUserUserGroupsError: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUser.userGroups.loading = false;
        newState.userDetails.editUser.userGroups.loaded = false;
      });
    }
    case UsersActionTypes.LoadEditUserUserGroupsCompleted: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUser.userGroups.entities = action.payload;
        newState.userDetails.editUser.userGroups.loading = false;
        newState.userDetails.editUser.userGroups.loaded = true;
      });
    }
    case UsersActionTypes.AddUser: {
      return produce<UsersState>(state, newState => {
        newState.createUser.creating = true;
      });
    }
    case UsersActionTypes.AddUserError: {
      return produce<UsersState>(state, newState => {
        newState.createUser.creating = false;
      });
    }
    case UsersActionTypes.AddUserCompleted: {
      return produce<UsersState>(state, newState => {
        newState.createUser.creating = false;
        newState.createUser.addUserModalOpen = false;
      });
    }
    case UsersActionTypes.OpenAddUserModal: {
      return produce<UsersState>(state, newState => {
        newState.createUser.addUserModalOpen = true;
      });
    }
    case UsersActionTypes.CloseAddUserModal: {
      return produce<UsersState>(state, newState => {
        newState.createUser.addUserModalOpen = false;
      });
    }
    case UsersActionTypes.EditUser: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editing = true;
      });
    }
    case UsersActionTypes.EditUserError:
    case UsersActionTypes.EditUserCompleted: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editing = false;
        newState.userDetails.editUserModalOpen = false;
      });
    }
    case UsersActionTypes.OpenEditUserModal: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUserModalOpen = true;
      });
    }
    case UsersActionTypes.CloseEditUserModal: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.editUserModalOpen = false;
      });
    }
    case UsersActionTypes.UpdateUserActiveStatus: {
      return produce<UsersState>(state, newState => {
        newState.updatingActiveStatus = true;
      });
    }
    case UsersActionTypes.UpdateUserActiveStatusError: {
      return produce<UsersState>(state, newState => {
        newState.updatingActiveStatus = false;
      });
    }
    case UsersActionTypes.UpdateUserActiveStatusCompleted: {
      return produce<UsersState>(state, newState => {
        if (state.userDetails.entity?.subjectId === action.payload.subjectId) {
          newState.userDetails.entity.isActive = action.payload.isActive;
        }

        const listEntityId = state.list.entities.findIndex(entity => entity.subjectId === action.payload.subjectId);

        if (listEntityId > -1) {
          newState.list.entities[listEntityId].isActive = action.payload.isActive;
        }
        newState.updatingActiveStatus = false;
      });
    }
    case UsersActionTypes.ResetUserPassword:
    case UsersActionTypes.ResetUserPasswordByAdmin: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.resettingPassword = true;
      });
    }
    case UsersActionTypes.ResetUserPasswordCompleted:
    case UsersActionTypes.ResetUserPasswordByAdminCompleted:
    case UsersActionTypes.ResetUserPasswordError:
    case UsersActionTypes.ResetUserPasswordByAdminError: {
      return produce<UsersState>(state, newState => {
        newState.userDetails.resettingPassword = false;
      });
    }
  }
  return state;
}
