import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IdentificationMethod, VehicleDto } from '@vpfa/rest-api/valuation';
import { plateNoLengthValidator, vinLengthValidator, vinValidator } from '@vpfa/shared/validators';
import { UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { emptyKbaTranslationKey, SelectOption } from '@vpfa/ui-kit';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { isNil } from 'lodash';
import { LocaleFacade } from '@vpfa/locale';

@Component({
  selector: 'vpfa-vehicle-basic-info',
  templateUrl: './vehicle-basic-info.component.html',
  styleUrls: ['./vehicle-basic-info.component.scss'],
})
export class VehicleBasicInfoComponent {
  @ViewChild('vinElement') vinElement;
  @ViewChild('natcodeElement') natcodeElement;
  @ViewChild('kbaElement') kbaElement;
  @ViewChild('vrmElement') vrmElement;
  // INFO: This is correct spelling because of toLowerCase() in getTemplateBasedOnIdentificationMethod() method
  @ViewChild('matriculationnumberElement') matriculationnumberElement;

  private _vinNo: string;
  private _matriculationNumber: string;
  private _plateNo: string;
  private _vehicleData: VehicleDto;

  possibleKBAs: SelectOption[] = [];
  vinControl = new UntypedFormControl(null, [vinValidator, vinLengthValidator]);
  plateNoControl: UntypedFormControl;
  matriculationNumberControl: UntypedFormControl;

  featureIdentifiersOrder$ = this.localeFacade.featureIdentifiersOrderConfiguration$;

  @Input() set vehicleData(vehicle: VehicleDto) {
    // VIN
    this.vinControl.reset(vehicle.vin);
    this._vinNo = vehicle.vin;
    if (vehicle.vin) {
      this.vinControl.setValidators([vinValidator, vinLengthValidator, Validators.required]);
    } else {
      this.vinControl.setValidators([vinValidator, vinLengthValidator]);
    }
    this._vehicleData = vehicle;

    // KBA
    this.possibleKBAs = this.generateKbaOptions(vehicle);

    // Matriculation number
    if (this.matriculationNumberControl) {
      this.matriculationNumberControl.reset(vehicle.matriculationNumber);
    } else {
      this.matriculationNumberControl = this.fb.control(vehicle.matriculationNumber);
      this.matriculationNumberControl.setValidators(
        this.matriculationNumberValidators(null, vehicle.matriculationNumber),
      );
    }
    this._matriculationNumber = vehicle.matriculationNumber;
  }

  @Input() set countryMatriculationNumberRegexPattern(pattern: string) {
    if (pattern) {
      if (!this.matriculationNumberControl) {
        this.matriculationNumberControl = this.fb.control(
          null,
          this.matriculationNumberValidators(pattern, this.matriculationNumber),
        );
      } else {
        this.matriculationNumberControl.setValidators(
          this.matriculationNumberValidators(pattern, this.matriculationNumber),
        );
      }
    }
  }

  @Input() set plateNo(plateNo: string) {
    if (this.plateNoControl) {
      this.plateNoControl.reset(plateNo);
    } else {
      this.plateNoControl = this.fb.control(plateNo);
      this.plateNoControl.setValidators(this.plateNoValidators(null, plateNo));
    }
    this._plateNo = plateNo;
  }

  @Input() set countryVrmRegexPattern(pattern: string) {
    if (pattern) {
      if (!this.plateNoControl) {
        this.plateNoControl = this.fb.control(null, this.plateNoValidators(pattern, this.plateNo));
      } else {
        this.plateNoControl.setValidators(this.plateNoValidators(pattern, this.plateNo));
      }
    }
  }

  @Input() isKbaEditable = false;
  @Input() allowKbaClear = true;
  @Input() isProcessingKba = false;
  @Input() displayHorizontal = true;
  @Input() isVinEditable = false;
  @Input() isPlateNoEditable = false;
  @Input() isMatriculationNumberEditable = false;
  @Input() noVinWarning = false;
  @Input() noPlateWarning = false;
  @Input() isLoadingCaseData = true;
  @Input() isUpdatingPlateNo = false;
  @Input() set isUpdatingPlateNoError(isError: boolean) {
    if (isError) {
      this.plateNoControl.setValue(this.plateNo);
    }
  }
  @Input() isUpdatingVin = false;
  @Input() set isUpdatingVinError(isError: boolean) {
    if (isError) {
      this.vinControl.setValue(this.vinNo);
    }
  }
  @Input() isUpdatingMatriculationNumber = false;
  @Input() set isUpdatingMatriculationNumberError(isError: boolean) {
    if (isError) {
      this.matriculationNumberControl.setValue(this.matriculationNumber);
    }
  }
  @Input() showCheckedSymbolOnVin = false;
  @Input() showBlueBackgroundOnVin = false;
  @Output() vinChange = new EventEmitter();
  @Output() kbaChange = new EventEmitter<string>();
  @Output() plateNoChange = new EventEmitter<string>();
  @Output() matriculationNumberChange = new EventEmitter<string>();

  get vehicleData() {
    return this._vehicleData;
  }

  get vinNo() {
    return this._vinNo;
  }

  get matriculationNumber() {
    return this._matriculationNumber;
  }

  get plateNo(): string {
    return this._plateNo;
  }

  get vin(): string {
    return this.vinControl.value;
  }

  set vin(vin: string) {
    this.vinChange.emit(vin);
  }

  get plate(): string {
    return this.plateNoControl.value;
  }

  set plate(plate: string) {
    this.plateNoChange.emit(plate);
  }

  get matriculationNumberValue(): string {
    return this.matriculationNumberControl.value;
  }

  set matriculationNumberValue(matriculationNumber: string) {
    this.matriculationNumberChange.emit(matriculationNumber);
  }

  constructor(
    private notification: BasicNotificationsService,
    private localeFacade: LocaleFacade,
    private fb: UntypedFormBuilder,
  ) {}

  onEmptyKbaList() {
    this.notification.warning('vehicleBasicInfo.noAvailableKBAs');
  }

  getTemplateBasedOnIdentificationMethod(identifier: IdentificationMethod): string {
    return this[`${IdentificationMethod[identifier].toLowerCase()}Element`];
  }

  private generateKbaOptions(vehicle: VehicleDto): SelectOption[] {
    const result = [];
    if (vehicle.possibleKBAs) {
      if (!isNil(vehicle.kba) && this.allowKbaClear) {
        result.push({ value: null, name: emptyKbaTranslationKey });
      }
      vehicle.possibleKBAs.forEach(el => result.push({ value: el, name: el }));
    }
    return result;
  }

  private plateNoValidators(pattern: string, plateNo: string): ValidatorFn[] {
    let validators = [];

    if (pattern) {
      validators.push(Validators.pattern(new RegExp(pattern)));
    } else {
      validators.push(plateNoLengthValidator);
    }

    if (plateNo) {
      validators.push(Validators.required);
    }

    return validators;
  }

  private matriculationNumberValidators(pattern: string, matriculationNumber: string): ValidatorFn[] {
    let validators = [];

    if (pattern) {
      validators.push(Validators.pattern(new RegExp(pattern)));
    } else {
      validators.push(plateNoLengthValidator);
    }

    if (matriculationNumber) {
      validators.push(Validators.required);
    }

    return validators;
  }
}
