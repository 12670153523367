import { Component, ContentChild, forwardRef, HostBinding, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { SuffixPrefixValueComponent } from '@vpfa/ui-kit/suffix-prefix-value';
import {
  NumberFieldComponent,
  PasswordFieldComponent,
  PhoneNumberFieldComponent,
  SelectComponent,
  TextFieldComponent,
  TextListFieldComponent,
} from '@vpfa/ui-kit';
import { TextareaComponent } from '@vpfa/ui-kit/form-controls';

@Component({
  selector: 'vpfa-setting-box',
  templateUrl: './setting-box.component.html',
  styleUrls: ['./setting-box.component.scss'],
})
export class SettingBoxComponent {
  @ContentChild(forwardRef(() => TextFieldComponent)) textFieldRef: TextFieldComponent;
  @ContentChild(forwardRef(() => SelectComponent)) selectFieldRef: SelectComponent;
  @ContentChild(forwardRef(() => NumberFieldComponent)) numberFieldRef: NumberFieldComponent;
  @ContentChild(forwardRef(() => TextListFieldComponent)) textListFieldRef: TextListFieldComponent;
  @ContentChild(forwardRef(() => SuffixPrefixValueComponent)) suffixPrefixFieldRef: SuffixPrefixValueComponent;
  @ContentChild(forwardRef(() => PhoneNumberFieldComponent)) phoneNumberFieldRef: PhoneNumberFieldComponent;
  @ContentChild(forwardRef(() => TextareaComponent)) textareaFieldRef: TextareaComponent;
  @ContentChild(forwardRef(() => PasswordFieldComponent)) passwordFieldRef: PasswordFieldComponent;
  @ContentChild(forwardRef(() => NgControl)) ngControl: NgControl;

  @Input() label: string;
  @Input() divider = true;
  @Input() inline = false;
  @Input() disabled = false;
  @Input() hideRequired = false;
  @Input() forceRequired = false;
  @HostBinding('class.inline-box') get isInline() {
    return this.inline;
  }

  get textField() {
    if (this.textareaFieldRef) {
      return true;
    }
    return false;
  }

  get isRequired() {
    let fc: AbstractControl;
    if (this.hideRequired) {
      return false;
    }
    if (this.textFieldRef) {
      fc = this.textFieldRef.parentFormGroup.get(this.textFieldRef.fcName);
    } else if (this.selectFieldRef) {
      fc = this.selectFieldRef.fc;
    } else if (this.numberFieldRef) {
      fc = this.numberFieldRef.parentFormGroup.get(this.numberFieldRef.fcName);
    } else if (this.suffixPrefixFieldRef) {
      fc = this.suffixPrefixFieldRef.formGroup.get(this.suffixPrefixFieldRef.valueControlName);
    } else if (this.phoneNumberFieldRef) {
      fc = this.phoneNumberFieldRef.parentFormGroup.get(this.phoneNumberFieldRef.fcName);
    } else if (this.textareaFieldRef) {
      fc = this.textareaFieldRef.parentFormGroup.get(this.textareaFieldRef.fcName);
    } else if (this.passwordFieldRef) {
      fc = this.passwordFieldRef.parentFormGroup.get(this.passwordFieldRef.fcName);
    } else {
      fc = this.ngControl?.control;
    }
    if (fc?.validator) {
      return fc.validator({} as AbstractControl)?.required;
    } else {
      return false;
    }
  }
}
