import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BranchService, BranchViewService, TwinnerService, TwinnerViewService } from '@vpfa/rest-api/admin';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isNil } from 'lodash';

import {
  BranchesActionTypes,
  BranchUrlUpdate,
  BranchUrlUpdated,
  fromBranchesActions,
  ImportCustomersFromCsv,
  ImportCustomersFromCsvSuccess,
  LoadBranchConfiguration,
  LoadBranchConfigurationError,
  LoadBranchConfigurationSuccess,
  LoadBranchDetails,
  LoadBranchesByBusiness,
  LoadBranchGdvConfiguration,
  LoadBranchGdvConfigurationError,
  LoadBranchGdvConfigurationSuccess,
  LoadOfferEmailTemplateByBranch,
  LoadOfferEmailTemplateByUserContext,
  LoadQuotesEmailTemplateByBranch,
  LoadQuotesEmailTemplateByUserContext,
  LoadResellersEmailTemplate,
  LoadTwinnerCredentials,
  LoadTwinnerCredentialsError,
  LoadTwinnerCredentialsSuccess,
  OfferEmailTemplateUpdated,
  QuotesEmailTemplateUpdated,
  ResellersEmailTemplateUpdated,
  UpdateBranchGdvConfiguration,
  UpdateBranchGdvConfigurationError,
  UpdateBranchGdvConfigurationSuccess,
  UpdateBranchStockDays,
  UpdateBranchStockDaysError,
  UpdateBranchTolerances,
  UpdateBranchTolerancesError,
  UpdateOfferEmailTemplate,
  UpdateQuotesEmailTemplate,
  UpdateResellersEmailTemplate,
  UpdateTwinnerPassword,
  UpdateTwinnerPasswordError,
  UpdateTwinnerPasswordSuccess,
  UpdateTwinnerUsername,
  UpdateTwinnerUsernameError,
  UpdateTwinnerUsernameSuccess,
  LoadBranchesForDealerNetwork,
  UpdateBranchContact,
  UpdateBranchContactSuccess,
} from './branches.actions';
import {
  BranchConfigurationService,
  BranchConfigurationViewService,
  CustomerService,
  OfferEmailTemplateService,
  OfferEmailTemplateViewService,
  QuoteEmailTemplateService,
  QuoteEmailTemplateViewService,
  UpdateBranchLiveRetailConfigurationCommand,
} from '@vpfa/rest-api/valuation';
import { ResellerEmailTemplateService, ResellerEmailTemplateViewService } from '@vpfa/rest-api/reseller-notification';
import { Store } from '@ngrx/store';
import { branchesQuery } from './branches.selectors';
import {
  InsuranceClaimingConfigurationService,
  InsuranceClaimingConfigurationViewService,
} from '@vpfa/rest-api/vehicle-insurance-claiming';

@Injectable()
export class BranchesEffects {
  loadBranchesByBusiness$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadBranchesByBusiness>(BranchesActionTypes.LoadBranchesByBusiness),
      switchMap(action =>
        this.branchViewService.getBranchListForBusiness(action.payload).pipe(
          map(response => new fromBranchesActions.BranchesLoaded(response)),
          catchError(err => of(new fromBranchesActions.BranchesLoadError())),
        ),
      ),
    ),
  );

  updateBranchContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBranchContact>(BranchesActionTypes.UpdateBranchContact),
      switchMap(action =>
        this.branchService.updateBranchContact(action.payload).pipe(
          map(() => new fromBranchesActions.UpdateBranchContactSuccess(action.payload.aggregateRootId)),
          catchError(() => of(new fromBranchesActions.UpdateBranchContactError())),
        ),
      ),
    ),
  );

  updateBranchContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBranchContactSuccess>(BranchesActionTypes.UpdateBranchContactSuccess),
      tap(() => {
        this.notification.success('userProfile.accountDetails.branchAddress.branchContactUpdateSuccessMessage');
      }),
      map(x => new LoadBranchDetails(x.branchId)),
    ),
  );

  loadBranchesForDealerNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadBranchesForDealerNetwork>(BranchesActionTypes.LoadBranchesForDealerNetwork),
      switchMap(action =>
        this.branchViewService.getBranchesListForDealerNetwork(action.payload).pipe(
          map(response => new fromBranchesActions.LoadBranchesForDealerNetworkCompleted(response)),
          catchError(err => of(new fromBranchesActions.LoadBranchesForDealerNetworkError())),
        ),
      ),
    ),
  );

  loadBranchDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadBranchDetails>(BranchesActionTypes.LoadBranchDetails),
      switchMap(action =>
        this.branchViewService.get(action.payload).pipe(
          map(response => new fromBranchesActions.BranchDetailsLoaded(response)),
          catchError(err => of(new fromBranchesActions.BranchDetailsLoadError())),
        ),
      ),
    ),
  );

  branchUrlUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BranchUrlUpdate>(BranchesActionTypes.BranchUrlUpdate),
      switchMap(action =>
        this.branchService.updateUrl(action.payload).pipe(
          map(response => new fromBranchesActions.BranchUrlUpdated(response.aggregateId)),
          catchError(err => of(new fromBranchesActions.BranchUrlUpdateError())),
        ),
      ),
    ),
  );

  branchUrlUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BranchUrlUpdated>(BranchesActionTypes.BranchUrlUpdated),
        tap(() => this.notification.success('admin.branches.branchUrlUpdated')),
      ),
    { dispatch: false },
  );

  branchUrlUpdatedLoadBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BranchUrlUpdated>(BranchesActionTypes.BranchUrlUpdated),
      map(action => new fromBranchesActions.LoadBranchDetails(action.payload)),
    ),
  );

  branchImportCustomerFromCsv = createEffect(() =>
    this.actions$.pipe(
      ofType<ImportCustomersFromCsv>(BranchesActionTypes.ImportCustomersFromCsv),
      switchMap(action => {
        const { businessId, branchId, file } = action.payload;
        return this.customerService.importCustomers(businessId, branchId, file).pipe(
          map(response => new fromBranchesActions.ImportCustomersFromCsvSuccess(response)),
          catchError(_ => of(new fromBranchesActions.ImportCustomersFromCsvError())),
        );
      }),
    ),
  );

  branchImportCustomerFromCsvSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ImportCustomersFromCsvSuccess>(BranchesActionTypes.ImportCustomersFromCsvSuccess),
        tap(action => {
          let successRecords = action.payload.noOfRows;
          const totalRecords = action.payload.noOfRows;
          const invalidRows = action.payload.invalidRowLineNumbers;
          if (!isNil(invalidRows)) {
            successRecords = successRecords - invalidRows.length;
          }
          if (totalRecords === successRecords) {
            if (successRecords === 1) {
              this.notification.success(
                'admin.branches.customerImport.result.success',
                'admin.branches.customerImport.result.successTextSingular',
              );
            } else if (successRecords > 1) {
              this.notification.success(
                'admin.branches.customerImport.result.success',
                'admin.branches.customerImport.result.successTextPlural',
                {
                  successRecords: successRecords,
                  totalRecords: totalRecords,
                },
              );
            }
          } else {
            if (successRecords === 0) {
              this.notification.error(
                'admin.branches.customerImport.result.failure',
                'admin.branches.customerImport.result.failureText',
                {
                  totalRecords: totalRecords,
                },
              );
            } else {
              this.notification.warning(
                'admin.branches.customerImport.result.warning',
                'admin.branches.customerImport.result.successSummaryText',
                {
                  successRecords: successRecords,
                  totalRecords: totalRecords,
                  invalidRowLineNumbers: invalidRows,
                },
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );

  updateResellersEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateResellersEmailTemplate>(BranchesActionTypes.UpdateResellersEmailTemplate),
      switchMap(action =>
        this.resellerEmailTemplateService.updateResellerEmailTemplate(action.payload).pipe(
          map(response => new fromBranchesActions.ResellersEmailTemplateUpdated(response)),
          catchError(err => of(new fromBranchesActions.ResellersEmailTemplateUpdateError())),
        ),
      ),
    ),
  );

  resellersEmailTemplateUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResellersEmailTemplateUpdated>(BranchesActionTypes.ResellersEmailTemplateUpdated),
      map(({ payload }) => new fromBranchesActions.LoadResellersEmailTemplate(payload.aggregateId)),
      tap(() =>
        this.notification.success('admin.branches.resellersEmailTemplate.resellersEmailTemplateUpdatedSuccessfully'),
      ),
    ),
  );

  loadResellersEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadResellersEmailTemplate>(BranchesActionTypes.LoadResellersEmailTemplate),
      switchMap(action =>
        this.resellerEmailTemplateViewService.getEmailTemplate(action.payload).pipe(
          map(response => new fromBranchesActions.ResellersEmailTemplateLoaded(response)),
          catchError(err => of(new fromBranchesActions.ResellersEmailTemplateLoadError())),
        ),
      ),
    ),
  );

  updateQuotesEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateQuotesEmailTemplate>(BranchesActionTypes.UpdateQuotesEmailTemplate),
      switchMap(action =>
        this.quoteEmailTemplateService.updateQuoteEmailTemplate(action.payload.command).pipe(
          map(() => new fromBranchesActions.QuotesEmailTemplateUpdated(action.payload.branchId)),
          catchError(() => of(new fromBranchesActions.QuotesEmailTemplateUpdateError())),
        ),
      ),
    ),
  );

  quotesEmailTemplateUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<QuotesEmailTemplateUpdated>(BranchesActionTypes.QuotesEmailTemplateUpdated),
      map(({ payload }) => new fromBranchesActions.LoadQuotesEmailTemplateByBranch(payload)),
      tap(() => this.notification.success('admin.branches.quotesEmailTemplate.quotesEmailTemplateUpdatedSuccessfully')),
    ),
  );

  loadQuotesEmailTemplateByBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadQuotesEmailTemplateByBranch>(BranchesActionTypes.LoadQuotesEmailTemplateByBranch),
      switchMap(action =>
        this.quoteEmailTemplateViewService.getBranchQuoteEmailTemplate(action.payload).pipe(
          map(response => new fromBranchesActions.QuotesEmailTemplateByBranchLoaded(response)),
          catchError(() => of(new fromBranchesActions.QuotesEmailTemplateByBranchLoadError())),
        ),
      ),
    ),
  );

  loadQuotesEmailTemplateByUserContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadQuotesEmailTemplateByUserContext>(BranchesActionTypes.LoadQuotesEmailTemplateByUserContext),
      switchMap(() =>
        this.quoteEmailTemplateViewService.get().pipe(
          map(response => new fromBranchesActions.QuotesEmailTemplateByUserContextLoaded(response)),
          catchError(() => of(new fromBranchesActions.QuotesEmailTemplateByUserContextLoadError())),
        ),
      ),
    ),
  );

  loadOfferEmailTemplateByBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadOfferEmailTemplateByBranch>(BranchesActionTypes.LoadOfferEmailTemplateByBranch),
      switchMap(action =>
        this.offerEmailTemplateViewService.getBranchOfferEmailTemaplte(action.payload).pipe(
          map(response => new fromBranchesActions.OfferEmailTemplateByBranchLoaded(response)),
          catchError(() => of(new fromBranchesActions.OfferEmailTemplateByBranchLoadError())),
        ),
      ),
    ),
  );

  loadOfferEmailTemplateByUserContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadOfferEmailTemplateByUserContext>(BranchesActionTypes.LoadOfferEmailTemplateByUserContext),
      switchMap(() =>
        this.offerEmailTemplateViewService.get().pipe(
          map(response => new fromBranchesActions.OfferEmailTemplateByUserContextLoaded(response)),
          catchError(() => of(new fromBranchesActions.OfferEmailTemplateByUserContextLoadError())),
        ),
      ),
    ),
  );

  updateOfferEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateOfferEmailTemplate>(BranchesActionTypes.UpdateOfferEmailTemplate),
      switchMap(action =>
        this.offerEmailTemplateService.update(action.payload.command).pipe(
          map(() => new fromBranchesActions.OfferEmailTemplateUpdated(action.payload.branchId)),
          catchError(() => of(new fromBranchesActions.OfferEmailTemplateUpdateError())),
        ),
      ),
    ),
  );

  offerEmailTemplateUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OfferEmailTemplateUpdated>(BranchesActionTypes.OfferEmailTemplateUpdated),
      map(({ payload }) => new fromBranchesActions.LoadOfferEmailTemplateByBranch(payload)),
      tap(() => this.notification.success('admin.branches.offerEmailTemplate.offerEmailTemplateUpdatedSuccessfully')),
    ),
  );

  loadBranchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadBranchConfiguration>(BranchesActionTypes.LoadBranchConfiguration),
      switchMap(action =>
        this.branchConfigurationViewService.getBranchConfiguration(action.payload).pipe(
          map(response => new LoadBranchConfigurationSuccess(response)),
          catchError(() => of(new LoadBranchConfigurationError())),
        ),
      ),
    ),
  );

  updateBranchTolerances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBranchTolerances>(BranchesActionTypes.UpdateBranchTolerances),
      withLatestFrom(this.store$.select(branchesQuery.getBranchConfigurationId)),
      map(([action, aggregateRootId]) => {
        return { ...action.payload, aggregateRootId };
      }),
      switchMap((command: UpdateBranchLiveRetailConfigurationCommand) =>
        this.branchConfigurationService.updateLiveRetailConfiguration(command).pipe(
          map(response => new LoadBranchConfiguration(response.aggregateId)),
          catchError(() => of(new UpdateBranchTolerancesError())),
        ),
      ),
    ),
  );

  updateBranchStockDays$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBranchStockDays>(BranchesActionTypes.UpdateBranchStockDaysNumber),
      withLatestFrom(this.store$.select(branchesQuery.getBranchConfigurationId)),
      map(([action, aggregateRootId]) => {
        return { ...action.payload, aggregateRootId };
      }),
      switchMap(action =>
        this.branchConfigurationService.updateBranchSlowMovingKPINumberOfDays(action).pipe(
          map(response => new LoadBranchConfiguration(response.aggregateId)),
          catchError(() => of(new UpdateBranchStockDaysError())),
        ),
      ),
    ),
  );

  loadTwinnerCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadTwinnerCredentials>(BranchesActionTypes.LoadTwinnerCredentials),
      switchMap(action =>
        this.twinnerViewService.getCredentials(action.payload.id).pipe(
          map(response => new LoadTwinnerCredentialsSuccess(response)),
          catchError(() => of(new LoadTwinnerCredentialsError())),
        ),
      ),
    ),
  );

  updateTwinnerUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTwinnerUsername>(BranchesActionTypes.UpdateTwinnerUsername),
      switchMap(action =>
        this.twinnerService.setUsername(action.payload).pipe(
          map(() => new UpdateTwinnerUsernameSuccess(action.payload.branchId)),
          catchError(() => of(new UpdateTwinnerUsernameError())),
        ),
      ),
    ),
  );

  updateTwinnerUsernameSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTwinnerUsernameSuccess>(BranchesActionTypes.UpdateTwinnerUsernameSuccess),
      tap(() => {
        this.notification.success('admin.branches.twinner.twinnerUsernameUpdatedSuccessfully');
      }),
      map(
        action => new LoadTwinnerCredentials({ id: action.payload, updatingUsername: true, updatingPassword: false }),
      ),
    ),
  );

  updateTwinnerPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTwinnerPassword>(BranchesActionTypes.UpdateTwinnerPassword),
      switchMap(action =>
        this.twinnerService.setPassword(action.payload).pipe(
          map(() => new UpdateTwinnerPasswordSuccess(action.payload.branchId)),
          catchError(() => of(new UpdateTwinnerPasswordError())),
        ),
      ),
    ),
  );

  updateTwinnerPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTwinnerPasswordSuccess>(BranchesActionTypes.UpdateTwinnerPasswordSuccess),
      tap(() => {
        this.notification.success('admin.branches.twinner.twinnerPasswordUpdatedSuccessfully');
      }),
      map(
        action => new LoadTwinnerCredentials({ id: action.payload, updatingUsername: false, updatingPassword: true }),
      ),
    ),
  );

  loadBranchGdvConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadBranchGdvConfiguration>(BranchesActionTypes.LoadBranchGdvConfiguration),
      switchMap(action =>
        this.insuranceClaimingConfigurationViewService.getBranchConfiguration(action.payload).pipe(
          map(response => new LoadBranchGdvConfigurationSuccess(response)),
          catchError(() => of(new LoadBranchGdvConfigurationError())),
        ),
      ),
    ),
  );

  updateBranchGdvConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBranchGdvConfiguration>(BranchesActionTypes.UpdateBranchGdvConfiguration),
      switchMap(action =>
        this.insuranceClaimingConfigurationService.updateGdvInsuranceClaimingBranchConfiguration(action.payload).pipe(
          map(() => new UpdateBranchGdvConfigurationSuccess(action.payload.branchId)),
          catchError(() => of(new UpdateBranchGdvConfigurationError())),
        ),
      ),
    ),
  );

  updateBranchGdvConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBranchGdvConfigurationSuccess>(BranchesActionTypes.UpdateBranchGdvConfigurationSuccess),
      tap(() => {
        this.notification.success('admin.gdv.gdvConfigUpdatedSuccessfully');
      }),
      map(action => new LoadBranchGdvConfiguration(action.payload)),
    ),
  );

  constructor(
    private store$: Store,
    private resellerEmailTemplateService: ResellerEmailTemplateService,
    private resellerEmailTemplateViewService: ResellerEmailTemplateViewService,
    private quoteEmailTemplateService: QuoteEmailTemplateService,
    private quoteEmailTemplateViewService: QuoteEmailTemplateViewService,
    private offerEmailTemplateService: OfferEmailTemplateService,
    private offerEmailTemplateViewService: OfferEmailTemplateViewService,
    private actions$: Actions,
    private branchViewService: BranchViewService,
    private branchService: BranchService,
    private notification: BasicNotificationsService,
    private customerService: CustomerService,
    private branchConfigurationViewService: BranchConfigurationViewService,
    private branchConfigurationService: BranchConfigurationService,
    private twinnerViewService: TwinnerViewService,
    private twinnerService: TwinnerService,
    private insuranceClaimingConfigurationService: InsuranceClaimingConfigurationService,
    private insuranceClaimingConfigurationViewService: InsuranceClaimingConfigurationViewService,
  ) {}
}
