import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LanguageService, LocaleFacade, SelectContentLanguage, SelectUILanguage } from '@vpfa/locale';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { delayUntil } from '@vpfa/utils';
import { LanguageEnum } from '@vpfa/shared/translate';
import { EMPTY } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { ProfileFacade } from '@vpfa/profile/data';
import { isEmpty } from 'lodash';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private languageService: LanguageService,
    private localeFacade: LocaleFacade,
    private profileFacade: ProfileFacade
  ) {}

  
  $whenOnPublicSiteDoNotLoadLocaleFromUserContext$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
    delayUntil(this.localeFacade.localeEffectsInitialized$),
    withLatestFrom(
      this.profileFacade.userContext$,
      this.localeFacade.selectedUiLanguage$,
      this.localeFacade.selectedContentLanguage$
    ),
    concatMap(([nav, userContext, selectedUiLanguage, selectedContentLanguage]) => {
      if (
        !(nav.payload.routerState.url.includes('/public') || nav.payload.routerState.url.includes('/resellers')) &&
        !isEmpty(userContext)
      ) {
        if (userContext.uiLanguage === selectedUiLanguage && userContext.contentLanguage === selectedContentLanguage) {
          return EMPTY;
        } else {
          return [
            new SelectUILanguage(userContext.uiLanguage as LanguageEnum),
            new SelectContentLanguage(userContext.contentLanguage as LanguageEnum),
          ];
        }
      } else return EMPTY;
    })
  ));
}
