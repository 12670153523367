import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomerCreated, CustomersActionTypes } from '@vpfa/dealer/customers/data';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  AddCaseDocument,
  CancelUpdateCaseRequestValuation,
  CaseDocumentAdded,
  CaseDocumentAddError,
  CaseDocumentDeleted,
  CaseDocumentDeleteError,
  CaseDocumentDownloaded,
  CaseDocumentDownloadError,
  CaseDocumentListLoaded,
  CaseDocumentListLoadError,
  CaseDocumentUploadReset,
  CaseLightDamageEstimatorLoadCompleted,
  CaseLightDamageEstimatorLoadError,
  CaseLightDamageEstimatorUpdateCompleted,
  CaseLightDamageEstimatorUpdateError,
  CaseLoadCompleted,
  CaseLoadEmissionInfo,
  CaseLoadEmissionInfoCompleted,
  CaseLoadEmissionInfoError,
  CaseLoadEquipments,
  CaseLoadEquipmentsCompleted,
  CaseLoadEquipmentsError,
  CaseLoadEquipmentsValid,
  CaseLoadEquipmentsValidCompleted,
  CaseLoadEquipmentsValidError,
  CaseLoadError,
  CaseLoadFuelSubtypes,
  CaseLoadFuelSubtypesCompleted,
  CaseLoadFuelSubtypesError,
  CaseLoadLightDamageEstimator,
  CaseLoadReturnReport,
  CaseLoadReturnReportCompleted,
  CaseLoadReturnReportError,
  CaseLoadRoadTax,
  CaseLoadRoadTaxCompleted,
  CaseLoadRoadTaxError,
  CaseLoadVinEreData,
  CaseLoadVinEreDataCompleted,
  CaseLoadVinEreDataError,
  CaseNotesUpdateCompleted,
  CaseNotesUpdateError,
  CaseQRCodeLoad,
  CaseQRCodeLoaded,
  CaseQRCodeLoadError,
  CaseReloadVinEreData,
  CasesActionTypes,
  CaseUpdateCancelReservation,
  CaseUpdateCancelReservationCompleted,
  CaseUpdateCancelReservationError,
  CaseUpdateCaseResellerSiteConfiguration,
  CaseUpdateCaseResellerSiteConfigurationCompleted,
  CaseUpdateCaseResellerSiteConfigurationError,
  CaseUpdateCloseReservationModal,
  CaseUpdateDueToInStock,
  CaseUpdateDueToInStockError,
  CaseUpdateDueToInStockSuccess,
  CaseUpdateLightDamageEstimator,
  CaseUpdateLightDamagePartsAdjustment,
  CaseUpdatePlateNo,
  CaseUpdatePlateNoCompleted,
  CaseUpdatePlateNoError,
  CaseUpdatePricingDetailsError,
  CaseUpdatePricingSuccess,
  CaseUpdatePurchaseVehicle,
  CaseUpdatePurchaseVehicleCompleted,
  CaseUpdatePurchaseVehicleError,
  CaseUpdateRegistrationDate,
  CaseUpdateRegistrationDateCompleted,
  CaseUpdateRegistrationDateError,
  CaseUpdateRequestValuationCompleted,
  CaseUpdateRequestValuationError,
  CaseUpdateReserveVehicle,
  CaseUpdateReserveVehicleCompleted,
  CaseUpdateReserveVehicleError,
  CaseUpdateReturnReport,
  CaseUpdateReturnReportCompleted,
  CaseUpdateReturnReportError,
  CaseUpdateReturnToStock,
  CaseUpdateReturnToStockError,
  CaseUpdateReturnToStockSuccess,
  CaseUpdateSaveMileageAdjustment,
  CaseUpdateSaveMileageAdjustmentCompleted,
  CaseUpdateSaveMileageAdjustmentError,
  CaseUpdateSavePublicSiteConfiguration,
  CaseUpdateSavePublicSiteConfigurationCompleted,
  CaseUpdateSavePublicSiteConfigurationError,
  CaseUpdateSaveQuote,
  CaseUpdateSaveQuoteCompleted,
  CaseUpdateSaveQuoteError,
  CaseUpdateSellVehicle,
  CaseUpdateSellVehicleCompleted,
  CaseUpdateSellVehicleError,
  CaseUpdateSoldPricingDetails,
  CaseUpdateStockOffer,
  CaseUpdateStockOfferCompleted,
  CaseUpdateStockOfferError,
  CaseUpdateStockPricingDetails,
  CaseUpdateTechnicalData,
  CaseUpdateTechnicalDataCompleted,
  CaseUpdateTechnicalDataError,
  CaseUpdateTwinnerImages,
  CaseUpdateTwinnerImagesAfterValuation,
  CaseUpdateTwinnerImagesAfterValuationCompleted,
  CaseUpdateTwinnerImagesAfterValuationError,
  CaseUpdateTwinnerImagesCompleted,
  CaseUpdateTwinnerImagesError,
  CaseUpdateUpdateCaseOwners,
  CaseUpdateUpdateCaseOwnersCompleted,
  CaseUpdateUpdateCaseOwnersError,
  CaseUpdateUpdateCasePreviousOwners,
  CaseUpdateUpdateCasePreviousOwnersCompleted,
  CaseUpdateUpdateCasePreviousOwnersError,
  CaseUpdateUpdateCaseProspectCustomers,
  CaseUpdateUpdateCaseProspectCustomersCompleted,
  CaseUpdateUpdateCaseProspectCustomersError,
  CaseUpdateValuationDate,
  CaseUpdateValuationDateCompleted,
  CaseUpdateValuationDateError,
  CaseUpdateValuationUniqueData,
  CaseUpdateValuationUniqueDataCompleted,
  CaseUpdateValuationUniqueDataError,
  CaseUpdateVehicleKba,
  CaseUpdateVehicleKbaCompleted,
  CaseUpdateVehicleKbaError,
  CaseUpdateVin,
  CaseUpdateVinCompleted,
  CaseUpdateVinError,
  DeleteCaseDocument,
  DoNotUpdateTwinnerImages,
  DownloadCaseDocument,
  EmissionStickerImageLoadCompleted,
  EmissionStickerImageLoadError,
  LoadCase,
  LoadCaseBlackBoxPriceDetails,
  LoadCaseBlackBoxPriceDetailsCompleted,
  LoadCaseBlackBoxPriceDetailsError,
  LoadCaseDocumentList,
  LoadEmissionStickerImage,
  ResendCaseLatestOffer,
  ResendCaseLatestOfferCompleted,
  ResendCaseLatestOfferError,
  ResendCaseLatestQuote,
  ResendCaseLatestQuoteCompleted,
  ResendCaseLatestQuoteError,
  SetHasCaseDocumentFormProcessingError,
  SetIsProcessingCaseDocumentForm,
  UpdateCaseNotes,
  UpdateCaseRequestValuation,
  UpdateIdentifiedVehicleBuildabilityData,
  UpdateIdentifiedVehicleBuildabilityDataCompleted,
  UpdateIdentifiedVehicleBuildabilityDataError,
  UpdateYoutubeUrl,
  UpdateYoutubeUrlError,
  UpdateYoutubeUrlSuccess,
  CaseUpdateMatriculationNumber,
  CaseUpdateMatriculationNumberCompleted,
  CaseUpdateMatriculationNumberError,
  CaseUpdateValuationUniqueDataValidateBroadcast,
  CaseUpdateValuationUniqueDataValidateBroadcastShowWarning,
  CaseUpdateValuationUniqueDataUserCancelled,
  RegisterNewVehicle,
  RegisterNewVehicleSuccess,
  RegisterNewVehicleError,
  CaseCloseRegisterNewModal,
} from './cases.actions';
import {
  BasicNotificationsService,
  FileNotAcceptedReason,
  FilesNotificationsService,
} from '@vpfa/shared/notifications';

import {
  CaseFilesService,
  CaseFilesViewService,
  CaseService,
  CaseStatus,
  CaseViewService,
  CommandHandlerResponse,
  CreateStockOfferCommandHandlerResponse,
  EmissionStickerViewService,
  ErrorCode,
  IdentificationMethod,
  IdentifiedVehicleService,
  QRCodeViewService,
  StockStatus,
  UpdateCaseRegistrationDateCommand,
  VinEreDataStatus,
} from '@vpfa/rest-api/valuation';
import { Router } from '@angular/router';
import { CasesFacade } from './cases.facade';
import { FileSaverService } from 'ngx-filesaver';
import { mapFileToDataUrl } from '@vpfa/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AdjustmentsFacade } from './adjustments/adjustments.facade';
// TODO: relative due to circular dependency
import { LoadGalleryImageOrderList } from '../../../../../gallery-manager/data/src/lib/+state/gallery-manager.actions';
// TODO: relative due to circular dependency
import { fromBroadcastActions } from '../../../../../broadcasts/data/src/lib/+state/broadcast.actions';
import { CaseBroadcastSettingsViewService } from '@vpfa/rest-api/ad-broadcast';
import { ModalService } from '@vpfa/modals';

@Injectable()
export class CasesEffects {
  updateCaseValuation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCaseRequestValuation>(CasesActionTypes.UpdateCaseRequestValuation),
      withLatestFrom(this.casesFacade.isOldVehicle$),
      switchMap(([action, isOldVehicle]: [UpdateCaseRequestValuation, boolean]) => {
        if (isOldVehicle === true) {
          return of(new CancelUpdateCaseRequestValuation({ caseId: action.payload.caseId }));
        }
        return this.caseService
          .updateValuation({ aggregateRootId: action.payload.caseId, force: action.payload.force })
          .pipe(
            map(() => new CaseUpdateRequestValuationCompleted({ caseId: action.payload.caseId })),
            catchError(() => of(new CaseUpdateRequestValuationError({ caseId: action.payload.caseId }))),
          );
      }),
    ),
  );

  afterValuationRequestCompletedLoadCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateRequestValuationCompleted | CaseUpdateRequestValuationError | CancelUpdateCaseRequestValuation>(
        CasesActionTypes.CaseUpdateRequestValuationCompleted,
        CasesActionTypes.CaseUpdateRequestValuationError,
        CasesActionTypes.CancelUpdateCaseRequestValuation,
      ),
      map(action => new LoadCase(action.payload.caseId)),
    ),
  );

  loadCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCase>(CasesActionTypes.LoadCase),
      switchMap(action =>
        this.caseViewService.getCase(action.payload).pipe(
          map(res => new CaseLoadCompleted(res)),
          catchError(err => of(new CaseLoadError(err))),
          takeUntil(this.adjustmentsFacade.adjustmentsInProgress$.pipe(filter(value => value > 0))),
        ),
      ),
    ),
  );

  loadVinEreDataOnCaseIdentifiedByVinLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadCompleted>(CasesActionTypes.CaseLoadCompleted),
      // INFO: Load VIN Ere Data only for just created cases (VinEreDataStatus.NotStarted) that were identified by VIN
      filter(
        action =>
          action?.payload?.identificationMethod === IdentificationMethod.VIN &&
          action?.payload?.vinEreDataStatus === VinEreDataStatus.NotStarted,
      ),
      map(action => new CaseLoadVinEreData(action?.payload?.id)),
    ),
  );

  caseForbidden$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseLoadError>(CasesActionTypes.CaseLoadError),
        withLatestFrom(this.casesFacade.activeCaseData$),
        tap(([action, activeCase]) => {
          if (action.payload && action.payload.status === 403) {
            if (activeCase) {
              if (activeCase.caseStatus === CaseStatus.Valuated) {
                this.router.navigate(['valuations', 'not-found']);
              } else {
                this.router.navigate(['stock', 'not-found']);
              }
            } else {
              this.router.navigate(['']);
            }
          }
        }),
      ),
    { dispatch: false },
  );

  caseUpdateSaveMileageAdjustment = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateSaveMileageAdjustment>(CasesActionTypes.CaseUpdateSaveMileageAdjustment),
      switchMap(action =>
        this.caseService.updateVehicleMileage(action.payload).pipe(
          map(res => new CaseUpdateSaveMileageAdjustmentCompleted(res)),
          catchError(err => of(new CaseUpdateSaveMileageAdjustmentError())),
        ),
      ),
    ),
  );

  afterMileageAdjustmentChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSaveMileageAdjustmentCompleted>(CasesActionTypes.CaseUpdateSaveMileageAdjustmentCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateValuationDate = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateValuationDate>(CasesActionTypes.CaseUpdateValuationDate),
      switchMap(action =>
        this.caseService.updateValuationDate(action.payload).pipe(
          map(res => new CaseUpdateValuationDateCompleted(res)),
          catchError(err => of(new CaseUpdateValuationDateError())),
        ),
      ),
    ),
  );

  caseUpdateValuationDateCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateValuationDateCompleted>(CasesActionTypes.CaseUpdateValuationDateCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
        tap(() => {
          this.notifications.success('caseValuationDetails.updateValuationDateSuccess');
        }),
      ),
    { dispatch: false },
  );

  caseUpdateRegistrationDate = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateRegistrationDate>(CasesActionTypes.CaseUpdateRegistrationDate),
      switchMap(action =>
        this.caseService.updateRegistrationDate(action.payload as UpdateCaseRegistrationDateCommand).pipe(
          map(res => new CaseUpdateRegistrationDateCompleted(res)),
          catchError(err => of(new CaseUpdateRegistrationDateError())),
        ),
      ),
    ),
  );

  afterRegDateChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateRegistrationDateCompleted>(CasesActionTypes.CaseUpdateRegistrationDateCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateVin = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateVin>(CasesActionTypes.CaseUpdateVin),
      switchMap(action =>
        this.identifiedVehicle.updateVehicleVin(action.payload.vehicle).pipe(
          map(res => new CaseUpdateVinCompleted({ response: res, caseId: action.payload.caseId })),
          catchError(err => of(new CaseUpdateVinError())),
        ),
      ),
    ),
  );

  caseUpdateVinCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateVinCompleted>(CasesActionTypes.CaseUpdateVinCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateMatriculationNumber = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateMatriculationNumber>(CasesActionTypes.CaseUpdateMatriculationNumber),
      switchMap(action =>
        this.identifiedVehicle.updateVehicleMatriculationNumber(action.payload).pipe(
          map(res => new CaseUpdateMatriculationNumberCompleted(res)),
          catchError(() => of(new CaseUpdateMatriculationNumberError())),
        ),
      ),
    ),
  );

  caseUpdateMatriculationNumberCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateMatriculationNumberCompleted>(CasesActionTypes.CaseUpdateMatriculationNumberCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateSaveQuote = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateSaveQuote>(CasesActionTypes.CaseUpdateSaveQuote),
      switchMap(action =>
        this.caseService.createQuote(action.payload).pipe(
          map(res => new CaseUpdateSaveQuoteCompleted(res)),
          catchError((err: HttpErrorResponse) => {
            const apiErrorData: CommandHandlerResponse = err.error;
            if (!isNil(apiErrorData?.vpError) && apiErrorData?.vpError?.code === ErrorCode.SendingEmailFailed) {
              this.notifications.warning(`vpError.${apiErrorData.vpError.codeName}`);
              return of(new CaseUpdateSaveQuoteCompleted(apiErrorData));
            } else {
              return of(new CaseUpdateSaveQuoteError(err));
            }
          }),
        ),
      ),
    ),
  );

  caseUpdateSaveQuoteComplete = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSaveQuoteCompleted>(CasesActionTypes.CaseUpdateSaveQuoteCompleted),
        tap(() => {
          this.notifications.success('caseValuationDetails.createQuoteSuccess');
        }),
      ),
    { dispatch: false },
  );

  caseUpdateSaveQuoteError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSaveQuoteError>(CasesActionTypes.CaseUpdateSaveQuoteError),
        tap((error: CaseUpdateSaveQuoteError) => {
          const payload: HttpErrorResponse = error.payload;
          this.notifications.error('caseValuationDetails.createQuoteError');
          if (!isNil(payload?.error?.vpError)) {
            this.notifications.vpError(payload);
          }
        }),
      ),
    { dispatch: false },
  );

  caseUpdateStockOffer = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateStockOffer>(CasesActionTypes.CaseUpdateStockOffer),
      switchMap(action =>
        this.caseService.createStockOffer(action.payload).pipe(
          map(res => new CaseUpdateStockOfferCompleted(res)),
          catchError((err: HttpErrorResponse) => {
            const apiErrorData: CreateStockOfferCommandHandlerResponse = err.error;
            if (!isNil(apiErrorData?.vpError) && apiErrorData?.vpError?.code === ErrorCode.SendingEmailFailed) {
              this.notifications.warning(`vpError.${apiErrorData.vpError.codeName}`);
              return of(new CaseUpdateStockOfferCompleted(apiErrorData));
            } else {
              return of(new CaseUpdateStockOfferError(err));
            }
          }),
        ),
      ),
    ),
  );

  caseUpdateStockOfferComplete = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateStockOfferCompleted>(CasesActionTypes.CaseUpdateStockOfferCompleted),
        tap(() => {
          this.notifications.success('createStockOfferModal.createSuccess');
        }),
      ),
    { dispatch: false },
  );

  caseUpdateStockOfferError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateStockOfferError>(CasesActionTypes.CaseUpdateStockOfferError),
        tap((error: CaseUpdateStockOfferError) => {
          const payload: HttpErrorResponse = error.payload;
          this.notifications.error('createStockOfferModal.createError');
          if (!isNil(payload.error.vpError)) {
            this.notifications.vpError(payload);
          }
        }),
      ),
    { dispatch: false },
  );

  caseUpdatePurchaseVehicle = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdatePurchaseVehicle>(CasesActionTypes.CaseUpdatePurchaseVehicle),
      switchMap(action =>
        this.caseService.purchaseVehicle(action.payload).pipe(
          map(res => {
            this.router.navigate(['/', 'stock', action.payload.aggregateRootId]);
            return new CaseUpdatePurchaseVehicleCompleted({
              status: action.payload.status,
              caseId: action.payload.aggregateRootId,
              vin: action.payload.vin,
            });
          }),
          catchError(err => of(new CaseUpdatePurchaseVehicleError())),
        ),
      ),
    ),
  );

  caseUpdatePurchaseVehicleCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdatePurchaseVehicleCompleted>(CasesActionTypes.CaseUpdatePurchaseVehicleCompleted),
      tap(() => {
        this.notifications.success('caseValuationDetails.purchaseVehicleSuccess');
      }),
      map(action => action.payload),
      map(({ vin, status, caseId }) => {
        if (vin && (status === StockStatus.InStock || status === StockStatus.DueInStock)) {
          return new CaseUpdateTwinnerImagesAfterValuation({ aggregateRootId: caseId });
        } else {
          return new DoNotUpdateTwinnerImages();
        }
      }),
    ),
  );

  caseUpdateSellVehicle = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateSellVehicle>(CasesActionTypes.CaseUpdateSellVehicle),
      switchMap(action =>
        this.caseService.sellVehicle(action.payload).pipe(
          map(res => new CaseUpdateSellVehicleCompleted(action.payload.aggregateRootId)),
          catchError(err => of(new CaseUpdateSellVehicleError(err))),
        ),
      ),
    ),
  );

  caseUpdateSellVehicleCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSellVehicleCompleted>(CasesActionTypes.CaseUpdateSellVehicleCompleted),
        tap(() => {
          this.notifications.success('caseValuationDetails.sellVehicleSuccess');
        }),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateSellVehicleError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSellVehicleError>(CasesActionTypes.CaseUpdateSellVehicleError),
        tap(() => this.notifications.error('caseValuationDetails.sellVehicleError')),
      ),
    { dispatch: false },
  );

  caseUpdateUpdateCaseOwners = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateUpdateCaseOwners>(CasesActionTypes.CaseUpdateUpdateCaseOwners),
      switchMap(action =>
        this.caseService.updateOwners(action.payload).pipe(
          map(
            res =>
              new CaseUpdateUpdateCaseOwnersCompleted({
                response: res,
                listLength: action.payload.customerOwnerIds.length,
              }),
          ),
          catchError(err => of(new CaseUpdateUpdateCaseOwnersError())),
        ),
      ),
    ),
  );

  caseUpdateUpdateCaseOwnersCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateUpdateCaseOwnersCompleted>(CasesActionTypes.CaseUpdateUpdateCaseOwnersCompleted),
        withLatestFrom(
          this.adjustmentsFacade.adjustmentsInProgress$,
          this.casesFacade.activeCaseId$,
          this.casesFacade.ownersLength$,
          (action, progress, caseId, ownersLength) => ({ action, progress, caseId, ownersLength }),
        ),
        tap(({ action, ownersLength }) => {
          if (action.payload.listLength > ownersLength) {
            this.notifications.success('caseValuationDetails.ownerAdded');
          } else if (action.payload.listLength === ownersLength) {
            this.notifications.success('customers.addRemoveCustomerList.promotedToMainBuyerSuccessMessage');
          } else {
            this.notifications.success('caseValuationDetails.ownerRemoved');
          }
        }),
        filter(({ progress }) => progress === 0),
        map(({ caseId }) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateUpdateCasePreviousOwners = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateUpdateCasePreviousOwners>(CasesActionTypes.CaseUpdateUpdateCasePreviousOwners),
      switchMap(action =>
        this.caseService.updatePreviousOwners(action.payload).pipe(
          map(
            res =>
              new CaseUpdateUpdateCasePreviousOwnersCompleted({
                response: res,
                listLength: action.payload.customerPreviousOwnerIds.length,
              }),
          ),
          catchError(err => of(new CaseUpdateUpdateCasePreviousOwnersError())),
        ),
      ),
    ),
  );

  caseUpdateUpdateCasePreviousOwnersCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateUpdateCasePreviousOwnersCompleted>(
          CasesActionTypes.CaseUpdateUpdateCasePreviousOwnersCompleted,
        ),
        withLatestFrom(
          this.adjustmentsFacade.adjustmentsInProgress$,
          this.casesFacade.activeCaseId$,
          this.casesFacade.previousOwnersLength$,
          (action, progress, caseId, previousOwnersLength) => ({ action, progress, caseId, previousOwnersLength }),
        ),
        tap(({ action, previousOwnersLength }) => {
          if (action.payload.listLength > previousOwnersLength) {
            this.notifications.success('caseValuationDetails.previousOwnerAdded');
          } else if (action.payload.listLength === previousOwnersLength) {
            this.notifications.success('customers.addRemoveCustomerList.promotedToPreviousMainBuyerSuccessMessage');
          } else {
            this.notifications.success('caseValuationDetails.previousOwnerRemoved');
          }
        }),
        filter(({ progress }) => progress === 0),
        map(({ caseId }) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateUpdateCaseProspectCustomers = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateUpdateCaseProspectCustomers>(CasesActionTypes.CaseUpdateUpdateCaseProspectCustomers),
      switchMap(action =>
        this.caseService.updateProspectCustomers(action.payload).pipe(
          map(
            res =>
              new CaseUpdateUpdateCaseProspectCustomersCompleted({
                response: res,
                listLength: action.payload.prospectCustomerIds.length,
              }),
          ),
          catchError(err => of(new CaseUpdateUpdateCaseProspectCustomersError())),
        ),
      ),
    ),
  );

  registerNewVehicle = createEffect(() =>
    this.actions$.pipe(
      ofType<RegisterNewVehicle>(CasesActionTypes.RegisterNewVehicle),
      switchMap(action =>
        this.caseService.updateRegistrationDate(action.payload).pipe(
          map(res => new RegisterNewVehicleSuccess(action.payload.aggregateRootId)),
          catchError(err => of(new RegisterNewVehicleError())),
        ),
      ),
    ),
  );

  registerNewVehicleSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType<RegisterNewVehicleSuccess>(CasesActionTypes.RegisterNewVehicleSuccess),
      tap(() => this.notifications.success('caseValuationDetails.vehicleRegisteredSuccessfully')),
      tap(() => this.casesFacade.closeRegisterNewVehicleModal()),
      map(action => new LoadCase(action.caseId)),
    ),
  );

  caseUpdateUpdateCaseProspectCustomersCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateUpdateCaseProspectCustomersCompleted>(
          CasesActionTypes.CaseUpdateUpdateCaseProspectCustomersCompleted,
        ),
        withLatestFrom(
          this.adjustmentsFacade.adjustmentsInProgress$,
          this.casesFacade.activeCaseId$,
          this.casesFacade.prospectCustomersLength$,
          (action, progress, caseId, prospectCustomersLength) => ({
            action,
            progress,
            caseId,
            prospectCustomersLength,
          }),
        ),
        tap(({ action, prospectCustomersLength }) => {
          if (action.payload.listLength > prospectCustomersLength) {
            this.notifications.success('caseValuationDetails.prospectCustomerAdded');
          } else {
            this.notifications.success('caseValuationDetails.prospectCustomerRemoved');
          }
        }),
        filter(({ progress }) => progress === 0),
        map(({ caseId }) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  loadCaseVinEreData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadVinEreData>(CasesActionTypes.CaseLoadVinEreData),
      switchMap(action =>
        this.caseService.loadVinEreData({ aggregateRootId: action.caseId }).pipe(
          map(res => {
            if (res.vinEreDataStatus === VinEreDataStatus.Failed && action.failCount === 0) {
              return new CaseReloadVinEreData(action.caseId, action.failCount + 1);
            }
            return new CaseLoadVinEreDataCompleted(res);
          }),
          catchError(() => of(new CaseLoadVinEreDataError())),
        ),
      ),
    ),
  );

  reloadCaseVinEreData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseReloadVinEreData>(CasesActionTypes.CaseReloadVinEreData),
      map(action => new CaseLoadVinEreData(action.caseId, action.failCount)),
    ),
  );

  loadCaseDocumentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCaseDocumentList>(CasesActionTypes.LoadCaseDocumentList),
      switchMap(action =>
        this.caseFilesViewService.getCaseDocumentList(action.payload).pipe(
          map(res => new CaseDocumentListLoaded(res)),
          catchError(err => of(new CaseDocumentListLoadError())),
        ),
      ),
    ),
  );

  addCaseDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCaseDocument>(CasesActionTypes.AddCaseDocument),
      switchMap(({ payload, isUpdate }) =>
        this.caseFilesService.addDocument(payload.aggregateRootId, payload.file, payload.name).pipe(
          map(res => new CaseDocumentAdded(res, isUpdate, payload.name)),
          catchError(err => of(new CaseDocumentAddError({ error: err, name: payload.name }))),
          takeUntil(this.casesFacade.isCaseDocumentUploadNotProcessingAndNotCompleted$.pipe(filter(Boolean))),
        ),
      ),
    ),
  );

  onDocumentAddSetFormProcessingInFilesToTrue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCaseDocument>(CasesActionTypes.AddCaseDocument),
      map(({ payload }) => new SetIsProcessingCaseDocumentForm(true)),
    ),
  );

  afterCaseDocumentAdded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseDocumentAdded>(CasesActionTypes.CaseDocumentAdded),
      tap(({ fileName }) => {
        this.filesNotifications.success(fileName);
      }),
      map(({ payload }) => new LoadCaseDocumentList(payload.aggregateId)),
    ),
  );

  onDocumentAddedSetFormProcessingInFilesToFalse$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseDocumentAdded>(CasesActionTypes.CaseDocumentAdded),
      withLatestFrom(this.casesFacade.documentUploadIsCompleted$),
      filter(([, completed]) => completed),
      concatMap(() => [new SetIsProcessingCaseDocumentForm(false), new CaseDocumentUploadReset()]),
    ),
  );

  onDocumentUploadErrorSetHasFormProcessingErrorInFilesToTrue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseDocumentAddError>(CasesActionTypes.CaseDocumentAddError),
      tap(({ payload }) => {
        if (payload && payload.error && payload.error.error && payload.error.error.vpError) {
          switch (payload.error.error.vpError.code) {
            case ErrorCode.InvalidFileContentType:
              this.filesNotifications.error(payload.name, FileNotAcceptedReason.FileExtensionDoesNotMatchFileContent);
              break;
            case ErrorCode.FileTooBig:
              this.filesNotifications.error(payload.name, FileNotAcceptedReason.ExceedsDocumentFileSizeLimit);
              break;
            case ErrorCode.CaseFilesInTotalTooBig:
              this.filesNotifications.error(payload.name, FileNotAcceptedReason.ExceedsTotalSizeLimitForCaseDocuments);
              break;
            default:
              this.filesNotifications.error(payload.name, FileNotAcceptedReason.FileUploadError);
              break;
          }
        }
      }),
      map(() => new SetHasCaseDocumentFormProcessingError(true)),
    ),
  );

  onDocumentAddedSethasFormProcessingErrorInFilesToFalse$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseDocumentAdded>(CasesActionTypes.CaseDocumentAdded),
      map(({ payload }) => new SetHasCaseDocumentFormProcessingError(false)),
    ),
  );

  deleteCaseDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCaseDocument>(CasesActionTypes.DeleteCaseDocument),
      switchMap(({ payload }) =>
        this.caseFilesService.deleteDocuments(payload.aggregateRootId, payload.fileKey).pipe(
          map(res => new CaseDocumentDeleted(res)),
          catchError(err => of(new CaseDocumentDeleteError())),
        ),
      ),
    ),
  );

  downloadCaseDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadCaseDocument>(CasesActionTypes.DownloadCaseDocument),
      switchMap(({ payload }) =>
        this.caseFilesViewService.getCaseDocument(payload.caseId, payload.fileKey).pipe(
          map(res => new CaseDocumentDownloaded({ fileName: payload.fileName, data: res as unknown as Blob })),
          catchError(err => of(new CaseDocumentDownloadError())),
        ),
      ),
    ),
  );

  downloadCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseDocumentDownloaded>(CasesActionTypes.CaseDocumentDownloaded),
        tap(({ payload }) => {
          this.fileSaverService.save(payload.data, payload.fileName);
        }),
      ),
    { dispatch: false },
  );

  afterCaseDocumentDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseDocumentDeleted>(CasesActionTypes.CaseDocumentDeleted),
      tap(() => {
        this.notifications.success('addCaseDocument.documentRemovedSuccessfully');
      }),
      map(({ payload }) => new LoadCaseDocumentList(payload.aggregateId)),
    ),
  );

  afterNewAccountCreatedAssignAsOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CustomerCreated>(CustomersActionTypes.CustomerCreated),
      filter(action => Boolean(action.afterSuccess && action.afterSuccess['VALUATION__ADD_CUSTOMER_AS_OWNER'])),
      withLatestFrom(this.casesFacade.activeCaseData$),
      map(([action, activeCase]) => {
        return new CaseUpdateUpdateCaseOwners({
          aggregateRootId: activeCase.id,
          customerOwnerIds: [...activeCase.owners.map(el => el.customerId), action.payload],
        });
      }),
    ),
  );

  loadLightDamageEstimator$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadLightDamageEstimator>(CasesActionTypes.CaseLoadLightDamageEstimator),
      switchMap(action =>
        this.caseViewService.getEstimator(action.payload).pipe(
          map(res => new CaseLightDamageEstimatorLoadCompleted(res)),
          catchError(err => of(new CaseLightDamageEstimatorLoadError())),
        ),
      ),
    ),
  );

  updateLightDamageEstimator$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateLightDamageEstimator>(CasesActionTypes.CaseUpdateLightDamageEstimator),
      switchMap((action: CaseUpdateLightDamageEstimator) => {
        return this.caseService.updateCaseLightDamageParts(action.payload).pipe(
          map(() => new CaseLightDamageEstimatorUpdateCompleted(action.payload)),
          catchError(err => of(new CaseLightDamageEstimatorUpdateError(err))),
        );
      }),
    ),
  );

  updateLightDamageEstimatorCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLightDamageEstimatorUpdateCompleted>(CasesActionTypes.CaseLightDamageEstimatorUpdateCompleted),
      tap(() => {
        this.notifications.success('damageEstimatorModal.estimationUpdateSuccess');
      }),
      map(action => {
        const total: number =
          action.payload.lightDamageParts && action.payload.lightDamageParts.length
            ? action.payload.lightDamageParts
                .filter(part => part.isIncludedInValuation)
                .map(part => part.cost)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
            : null;
        return new CaseUpdateLightDamagePartsAdjustment({
          aggregateId: action.payload.aggregateRootId,
          deductionAmount: total,
        });
      }),
    ),
  );

  updateLightDamagePartsAdjustment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateLightDamagePartsAdjustment>(CasesActionTypes.CaseUpdateLightDamagePartsAdjustment),
      map(action => new CaseLoadLightDamageEstimator(action.payload.aggregateId)),
    ),
  );

  afterUpdateLightDamageEstimatorCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseLightDamageEstimatorUpdateCompleted>(CasesActionTypes.CaseLightDamageEstimatorUpdateCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  updateLightDamageEstimatorError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseLightDamageEstimatorUpdateError>(CasesActionTypes.CaseLightDamageEstimatorUpdateError),
        tap(httpError => {
          const err = httpError.payload.error as CommandHandlerResponse;
          this.notifications.error('damageEstimatorModal.estimationUpdateError');
        }),
      ),
    { dispatch: false },
  );

  validateBroadcastBeforeCaseUpdateValuationUniqueData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateValuationUniqueDataValidateBroadcast>(
        CasesActionTypes.CaseUpdateValuationUniqueDataValidateBroadcast,
      ),
      withLatestFrom(this.casesFacade.activeCaseData$),
      switchMap(([action, caseData]) => {
        // prepare action for later use (passthrough if there are no warnings)
        const continueNormalAction = new CaseUpdateValuationUniqueData({
          uniqueData: action.payload.uniqueData,
          updatedFromBroadcast: action.payload.updatedFromBroadcast,
          broadcastSettings: action.payload.broadcastSettings,
        });

        // only case in stock can have broadcasting
        if (caseData.caseStatus !== CaseStatus.InStock) {
          return of(continueNormalAction);
        }

        if (!action.payload.changedBranch) {
          return of(continueNormalAction);
        }

        return this.caseBroadcastSettingsViewService.getCaseBroadcastSettingsByCaseId(caseData.id).pipe(
          map(broadcastSettings => {
            const isBroadcastingEnabled = broadcastSettings.adPortals.some(x => x.isEnabled === true);

            if (!isBroadcastingEnabled) {
              return continueNormalAction;
            }

            return new CaseUpdateValuationUniqueDataValidateBroadcastShowWarning({
              uniqueData: action.payload.uniqueData,
              updatedFromBroadcast: action.payload.updatedFromBroadcast,
              broadcastSettings: action.payload.broadcastSettings,
            });
          }),
          catchError(err => of(new CaseUpdateValuationUniqueDataError())),
        );
      }),
    ),
  );

  caseUpdateValuationUniqueDataValidateBroadcastShowWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateValuationUniqueDataValidateBroadcastShowWarning>(
        CasesActionTypes.CaseUpdateValuationUniqueDataValidateBroadcastShowWarning,
      ),
      switchMap(action =>
        this.modalsService.confirm(['uniqueData.broadcastingWarningDueToChangedBranch']).pipe(
          map(userConfirmed => {
            if (userConfirmed) {
              return new CaseUpdateValuationUniqueData({
                uniqueData: action.payload.uniqueData,
                updatedFromBroadcast: action.payload.updatedFromBroadcast,
                broadcastSettings: action.payload.broadcastSettings,
              });
            }

            return new CaseUpdateValuationUniqueDataUserCancelled();
          }),
        ),
      ),
    ),
  );

  caseUpdateValuationUniqueData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateValuationUniqueData>(CasesActionTypes.CaseUpdateValuationUniqueData),
      switchMap(action => {
        return this.caseService.updateUniqueData(action.payload.uniqueData).pipe(
          map(
            () =>
              new CaseUpdateValuationUniqueDataCompleted({
                caseId: action.payload.uniqueData.aggregateRootId,
                updatedFromBroadcast: action.payload.updatedFromBroadcast,
                broadcastSettings: action.payload.broadcastSettings,
              }),
          ),
          catchError(err => of(new CaseUpdateValuationUniqueDataError())),
        );
      }),
    ),
  );

  caseUpdateValuationUniqueDataCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateValuationUniqueDataCompleted>(CasesActionTypes.CaseUpdateValuationUniqueDataCompleted),
      tap(action => {
        if (!action.payload.updatedFromBroadcast) {
          this.notifications.success('uniqueData.updateSuccess');
        }
      }),
      map(action => {
        if (action.payload.updatedFromBroadcast) {
          return new fromBroadcastActions.UpdateAdvertConfiguration({
            broadcastSettings: action.payload.broadcastSettings,
          });
        } else {
          return new UpdateCaseRequestValuation({ caseId: action.payload.caseId, force: true });
        }
      }),
    ),
  );

  caseUpdateVehicleKba$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateVehicleKba>(CasesActionTypes.CaseUpdateVehicleKba),
      switchMap(action => {
        return this.identifiedVehicle.updateVehicleKBA(action.payload.vehicle).pipe(
          map(
            response =>
              new CaseUpdateVehicleKbaCompleted({
                response,
                caseId: action.payload.caseId,
                newKba: action.payload.vehicle.kba,
              }),
          ),
          catchError(err => of(new CaseUpdateVehicleKbaError())),
        );
      }),
    ),
  );

  caseUpdateVehicleKbaCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateVehicleKbaCompleted>(CasesActionTypes.CaseUpdateVehicleKbaCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateVehicleTechnicalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateTechnicalData>(CasesActionTypes.CaseUpdateTechnicalData),
      switchMap(action => {
        return this.identifiedVehicle.updateVehicleTechnicalData(action.payload.technicalData).pipe(
          map(() => new CaseUpdateTechnicalDataCompleted(action.payload)),
          catchError(err => of(new CaseUpdateTechnicalDataError())),
        );
      }),
    ),
  );

  caseUpdateVehicleTechnicalDataCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateTechnicalDataCompleted>(CasesActionTypes.CaseUpdateTechnicalDataCompleted),
      tap(action => {
        if (!action.payload.updatedFromBroadcast) {
          this.notifications.success('vehicleFullTechnicalData.updateSuccessful');
        }
      }),
      map(action => {
        if (action.payload.updatedFromBroadcast && action.payload.uniqueData?.aggregateRootId) {
          return new CaseUpdateValuationUniqueData({
            uniqueData: action.payload.uniqueData,
            updatedFromBroadcast: action.payload.updatedFromBroadcast,
            broadcastSettings: action.payload.broadcastSettings,
          });
        } else if (action.payload.updatedFromBroadcast) {
          return new fromBroadcastActions.UpdateAdvertConfiguration({
            broadcastSettings: action.payload.broadcastSettings,
          });
        } else {
          return new UpdateCaseRequestValuation({ caseId: action.payload.caseId, force: true });
        }
      }),
    ),
  );

  savePublicSiteConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateSavePublicSiteConfiguration>(CasesActionTypes.CaseUpdateSavePublicSiteConfiguration),
      switchMap(action => {
        return this.caseService.updatePublicSiteConfiguration(action.payload.command).pipe(
          map(
            () =>
              new CaseUpdateSavePublicSiteConfigurationCompleted({
                command: action.payload.command,
                initialPublishedValue: action.payload.initialPublishedValue,
              }),
          ),
          catchError(err => of(new CaseUpdateSavePublicSiteConfigurationError(err))),
        );
      }),
    ),
  );

  savePublicSiteConfigurationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSavePublicSiteConfigurationError>(CasesActionTypes.CaseUpdateSavePublicSiteConfigurationError),
        tap(httpError => {
          const err = httpError.payload.error as CommandHandlerResponse;
          this.notifications.error('publicSiteOptionModal.savePublicSiteError');
        }),
      ),
    { dispatch: false },
  );

  savePublicSiteConfigurationCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateSavePublicSiteConfigurationCompleted>(
          CasesActionTypes.CaseUpdateSavePublicSiteConfigurationCompleted,
        ),
        tap(action => {
          if (action.payload.command.enabled === action.payload.initialPublishedValue) {
            this.notifications.success('publicSiteOptionModal.offerDetailsSaved');
          } else if (action.payload.command.enabled) {
            this.notifications.success('publicSiteOptionModal.offerPublishedSuccessfully');
          } else {
            this.notifications.success('publicSiteOptionModal.offerUnpublishedSuccessfully');
          }
        }),
      ),
    { dispatch: false },
  );

  updateCaseResellerSiteConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateCaseResellerSiteConfiguration>(CasesActionTypes.CaseUpdateCaseResellerSiteConfiguration),
      switchMap(action => {
        return this.caseService.updateResellerSiteConfiguration(action.payload.command).pipe(
          map(
            response =>
              new CaseUpdateCaseResellerSiteConfigurationCompleted({
                response,
                request: action.payload.command,
                initialPublishedValue: action.payload.initialPublishedValue,
              }),
          ),
          catchError(err => of(new CaseUpdateCaseResellerSiteConfigurationError())),
        );
      }),
    ),
  );

  updateCaseResellerSiteConfigurationCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateCaseResellerSiteConfigurationCompleted>(
          CasesActionTypes.CaseUpdateCaseResellerSiteConfigurationCompleted,
        ),
        tap(action => {
          if (action.payload.request.isEnabled === action.payload.initialPublishedValue) {
            this.notifications.success('publishOfferModal.offerDetailsSaved');
          } else if (action.payload.request.isEnabled) {
            this.notifications.success('publishOfferModal.offerPublishedSuccessfully');
          } else {
            this.notifications.success('publishOfferModal.offerUnpublishedSuccessfully');
          }
        }),
      ),
    { dispatch: false },
  );

  caseQRCodeLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseQRCodeLoad>(CasesActionTypes.CaseQRCodeLoad),
      switchMap(action =>
        this.qrCodeService.getPublicCaseQRCode(action.payload.caseId).pipe(
          map(response => new CaseQRCodeLoaded(URL.createObjectURL(response))),
          catchError(err => of(new CaseQRCodeLoadError())),
        ),
      ),
    ),
  );

  reserveVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateReserveVehicle>(CasesActionTypes.CaseUpdateReserveVehicle),
      withLatestFrom(this.casesFacade.activeCaseId$),
      switchMap(([action, aggregateRootId]) => {
        return this.caseService.reserveVehicle({ aggregateRootId, ...action.payload }).pipe(
          map(response => new CaseUpdateReserveVehicleCompleted(response.aggregateId)),
          catchError(err => of(new CaseUpdateReserveVehicleError())),
        );
      }),
    ),
  );

  reserveVehicleError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateReserveVehicleError>(CasesActionTypes.CaseUpdateReserveVehicleError),
        tap(_ => {
          this.notifications.error('reserveVehicleModal.reserveVehicleError');
        }),
      ),
    { dispatch: false },
  );

  reserveVehicleCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateReserveVehicleCompleted>(CasesActionTypes.CaseUpdateReserveVehicleCompleted),
      tap(() => {
        this.notifications.success('reserveVehicleModal.reserveVehicleSuccess');
      }),
      concatMap(action => [
        new UpdateCaseRequestValuation({ caseId: action.payload, force: false }),
        new CaseUpdateCloseReservationModal(),
      ]),
    ),
  );

  cancelReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateCancelReservation>(CasesActionTypes.CaseUpdateCancelReservation),
      withLatestFrom(this.casesFacade.activeCaseId$),
      switchMap(([_, aggregateRootId]) => {
        return this.caseService.cancelReservation({ aggregateRootId }).pipe(
          map(response => new CaseUpdateCancelReservationCompleted(response.aggregateId)),
          catchError(err => of(new CaseUpdateCancelReservationError())),
        );
      }),
    ),
  );

  cancelReservationCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateCancelReservationCompleted>(CasesActionTypes.CaseUpdateCancelReservationCompleted),
      tap(() => {
        this.notifications.success('reservationViewModal.cancelReservationSuccess');
      }),
      concatMap(action => [
        new UpdateCaseRequestValuation({ caseId: action.payload, force: false }),
        new CaseUpdateCloseReservationModal(),
      ]),
    ),
  );

  cancelReservationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateCancelReservationError>(CasesActionTypes.CaseUpdateCancelReservationError),
        tap(_ => {
          this.notifications.error('reservationViewModal.cancelReservationError');
        }),
      ),
    { dispatch: false },
  );

  caseUpdateReturnVehicleToStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateReturnToStock>(CasesActionTypes.CaseUpdateReturnToStock),
      switchMap(action =>
        this.caseService.returnToStock(action.payload).pipe(
          map(res => new CaseUpdateReturnToStockSuccess(res.aggregateId)),
          catchError(error => of(new CaseUpdateReturnToStockError())),
        ),
      ),
    ),
  );

  caseUpdateReturnVehicleToStockSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateReturnToStockSuccess>(CasesActionTypes.CaseUpdateReturnToStockSuccess),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdateDueToInStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateDueToInStock>(CasesActionTypes.CaseUpdateDueToInStock),
      switchMap(action =>
        this.caseService.moveFromDueToInStock(action.payload).pipe(
          map(res => new CaseUpdateDueToInStockSuccess(res.aggregateId)),
          catchError(error => of(new CaseUpdateDueToInStockError())),
        ),
      ),
    ),
  );

  caseUpdateDueToInStockSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateDueToInStockSuccess>(CasesActionTypes.CaseUpdateDueToInStockSuccess),
        tap(() => {
          this.notifications.success('moveDueToInStock.dueToInStockSuccess');
        }),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  updateCaseNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCaseNotes>(CasesActionTypes.UpdateCaseNotes),
      switchMap(action =>
        this.caseService.updateCaseNotes(action.payload).pipe(
          map(res => new CaseNotesUpdateCompleted(res)),
          catchError(error => of(new CaseNotesUpdateError())),
        ),
      ),
    ),
  );

  caseNotesUpdatedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseNotesUpdateCompleted>(CasesActionTypes.CaseNotesUpdateCompleted),
        tap(() => {
          this.notifications.success('updateCaseNotes.caseNotesUpdatedSuccessfully');
        }),
      ),
    { dispatch: false },
  );

  loadEmissionStickerImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadEmissionStickerImage>(CasesActionTypes.LoadEmissionStickerImage),
      withLatestFrom(this.casesFacade.activeCaseCO2EfficiencyClass$),
      switchMap(([, efficiencyClass]) =>
        this.emissionStickerViewService.getEmissionSticker(efficiencyClass).pipe(
          switchMap(res =>
            mapFileToDataUrl([res as any, '']).pipe(
              map(
                ({ file }) => new EmissionStickerImageLoadCompleted({ item: file, efficiencyClass: +efficiencyClass }),
              ),
            ),
          ),
          catchError(() => of(new EmissionStickerImageLoadError())),
        ),
      ),
    ),
  );

  updateStockPricingDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateStockPricingDetails>(CasesActionTypes.CaseUpdateStockPricingDetails),
      map(action => action.payload),
      switchMap(command =>
        this.caseService.updateStockPrices(command).pipe(
          map(response => new CaseUpdatePricingSuccess(response.aggregateId)),
          catchError(_ => of(new CaseUpdatePricingDetailsError())),
        ),
      ),
    ),
  );

  updateSoldPricingDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateSoldPricingDetails>(CasesActionTypes.CaseUpdateSoldPricingDetails),
      map(action => action.payload),
      switchMap(command =>
        this.caseService.updateSoldPrices(command).pipe(
          map(response => new CaseUpdatePricingSuccess(response.aggregateId)),
          catchError(_ => of(new CaseUpdatePricingDetailsError())),
        ),
      ),
    ),
  );

  caseUpdatePricingDetailsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdatePricingSuccess>(CasesActionTypes.CaseUpdatePricingSuccess),
        tap(() => {
          this.notifications.success('pricingDetails.updateSuccess');
        }),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  caseUpdatePlateNo = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdatePlateNo>(CasesActionTypes.CaseUpdatePlateNo),
      switchMap(action =>
        this.caseService.updateCasePlateNumber(action.payload).pipe(
          map(res => new CaseUpdatePlateNoCompleted(res)),
          catchError((errResp: HttpErrorResponse) => of(new CaseUpdatePlateNoError())),
        ),
      ),
    ),
  );

  caseUpdatePlateNoCompleted = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdatePlateNoCompleted>(CasesActionTypes.CaseUpdatePlateNoCompleted),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  resendCaseLatestQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResendCaseLatestQuote>(CasesActionTypes.ResendCaseLatestQuote),
      switchMap(action =>
        this.caseService.resendLatestQuote(action.payload).pipe(
          map(res => new ResendCaseLatestQuoteCompleted(res)),
          catchError(err => of(new ResendCaseLatestQuoteError())),
        ),
      ),
    ),
  );

  resentCaseLatestQuoteCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ResendCaseLatestQuoteCompleted>(CasesActionTypes.ResendCaseLatestQuoteCompleted),
        tap(() => {
          this.notifications.success('quoteHistory.emailResentSuccessfully');
        }),
      ),
    { dispatch: false },
  );

  resendCaseLatestOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResendCaseLatestOffer>(CasesActionTypes.ResendCaseLatestOffer),
      switchMap(action =>
        this.caseService.resendLatestStockOffer(action.payload).pipe(
          map((res: any) => new ResendCaseLatestOfferCompleted(res)),
          catchError(err => of(new ResendCaseLatestOfferError())),
        ),
      ),
    ),
  );

  resentCaseLatestOfferCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ResendCaseLatestOfferCompleted>(CasesActionTypes.ResendCaseLatestOfferCompleted),
        tap(() => {
          this.notifications.success('offerHistory.emailResentSuccessfully');
        }),
      ),
    { dispatch: false },
  );

  caseLoadFuelSubtypes = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadFuelSubtypes>(CasesActionTypes.CaseLoadFuelSubtypes),
      switchMap(action =>
        this.caseViewService.getFuelSubtypes(action.payload).pipe(
          map(res => new CaseLoadFuelSubtypesCompleted(res)),
          catchError(err => of(new CaseLoadFuelSubtypesError())),
        ),
      ),
    ),
  );

  caseLoadEmissionInfo = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadEmissionInfo>(CasesActionTypes.CaseLoadEmissionInfo),
      switchMap(action =>
        this.caseViewService.getEmissionInfo(action.payload).pipe(
          map(res => new CaseLoadEmissionInfoCompleted(res)),
          catchError(err => of(new CaseLoadEmissionInfoError())),
        ),
      ),
    ),
  );

  caseLoadRoadTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadRoadTax>(CasesActionTypes.CaseLoadRoadTax),
      switchMap(action =>
        this.caseViewService.getRoadTaxData(action.payload).pipe(
          map(res => new CaseLoadRoadTaxCompleted(res)),
          catchError(err => of(new CaseLoadRoadTaxError())),
        ),
      ),
    ),
  );

  caseLoadReturnReport = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadReturnReport>(CasesActionTypes.CaseLoadReturnReport),
      switchMap(action =>
        this.caseViewService.getReturnReport(action.payload).pipe(
          map(res => new CaseLoadReturnReportCompleted(res)),
          catchError(() => of(new CaseLoadReturnReportError())),
        ),
      ),
    ),
  );

  caseUpdateReturnReport = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateReturnReport>(CasesActionTypes.CaseUpdateReturnReport),
      switchMap(action =>
        this.caseService.updateReturnReport(action.payload).pipe(
          map(res => new CaseUpdateReturnReportCompleted(res.aggregateId)),
          catchError(() => of(new CaseUpdateReturnReportError())),
        ),
      ),
    ),
  );

  caseUpdateReturnReportCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateReturnReportCompleted>(CasesActionTypes.CaseUpdateReturnReportCompleted),
      tap(() => {
        this.notifications.success('caseValuationDetails.returnReportUpdated');
      }),
      map(action => new CaseLoadReturnReport(action.payload)),
    ),
  );

  updateYoutubeUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateYoutubeUrl>(CasesActionTypes.UpdateYoutubeUrl),
      switchMap(action =>
        this.caseService.updateYoutubeUrl(action.payload).pipe(
          map(() => new UpdateYoutubeUrlSuccess(action.payload.youtubeUrl)),
          catchError(() => of(new UpdateYoutubeUrlError())),
        ),
      ),
    ),
  );

  updateYoutubeUrlSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateYoutubeUrlSuccess>(CasesActionTypes.UpdateYoutubeUrlSuccess),
        tap(() => {
          this.notifications.success('caseValuationDetails.updateYoutubeUrlSuccess');
        }),
      ),
    { dispatch: false },
  );

  caseLoadEquipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadEquipments>(CasesActionTypes.CaseLoadEquipments),
      switchMap(action =>
        this.caseViewService.getCaseEquipments(action.payload).pipe(
          map(response => new CaseLoadEquipmentsCompleted(response)),
          catchError(() => of(new CaseLoadEquipmentsError())),
        ),
      ),
    ),
  );

  loadEquipmentsValidAfterEquipmentListLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadEquipmentsCompleted>(CasesActionTypes.CaseLoadEquipmentsCompleted),
      map(() => new CaseLoadEquipmentsValid()),
    ),
  );

  caseLoadEquipmentsValid$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseLoadEquipmentsValid>(CasesActionTypes.CaseLoadEquipmentsValid),
      withLatestFrom(this.casesFacade.activeCaseId$),
      switchMap(([_, caseId]) =>
        this.caseViewService.isCaseEquipmentSetValid(caseId).pipe(
          map(res => new CaseLoadEquipmentsValidCompleted(res)),
          catchError(err => of(new CaseLoadEquipmentsValidError())),
        ),
      ),
    ),
  );

  caseUpdateTwinnerImagesAfterValuation = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateTwinnerImagesAfterValuation>(CasesActionTypes.CaseUpdateTwinnerImagesAfterValuation),
      switchMap(action =>
        this.caseFilesService.updateTwinnerImages(action.payload).pipe(
          map(() => new CaseUpdateTwinnerImagesAfterValuationCompleted()),
          catchError(() => of(new CaseUpdateTwinnerImagesAfterValuationError())),
        ),
      ),
    ),
  );

  caseUpdateTwinnerImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateTwinnerImages>(CasesActionTypes.CaseUpdateTwinnerImages),
      switchMap(action =>
        this.caseFilesService.updateTwinnerImages(action.payload).pipe(
          map(res => new CaseUpdateTwinnerImagesCompleted(res)),
          catchError(err => of(new CaseUpdateTwinnerImagesError())),
        ),
      ),
    ),
  );

  caseUpdateTwinnerImagesCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType<CaseUpdateTwinnerImagesCompleted>(CasesActionTypes.CaseUpdateTwinnerImagesCompleted),
      map(action => {
        return new LoadGalleryImageOrderList(action.payload.aggregateId);
      }),
    ),
  );

  caseUpdateTwinnerImagesError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CaseUpdateTwinnerImagesCompleted>(CasesActionTypes.CaseUpdateTwinnerImagesCompleted),
        tap(action => {
          if (action.payload.fileKeys.length) {
            this.notifications.warning(
              'twinner.fileUploadError',
              `${this.translateService.instant('twinner.galleryLimitsAreExceeded')}`,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  loadCaseBlackBoxPriceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCaseBlackBoxPriceDetails>(CasesActionTypes.LoadCaseBlackBoxPriceDetails),
      withLatestFrom(this.casesFacade.activeCaseId$),
      switchMap(([_, caseId]) =>
        this.caseViewService.getCasePriceDetails(caseId).pipe(
          map(res => new LoadCaseBlackBoxPriceDetailsCompleted(res)),
          catchError(err => of(new LoadCaseBlackBoxPriceDetailsError())),
        ),
      ),
    ),
  );

  updateIdentifiedVehicleBuildabilityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateIdentifiedVehicleBuildabilityData>(CasesActionTypes.UpdateIdentifiedVehicleBuildabilityData),
      switchMap(action =>
        this.identifiedVehicle
          .setBuidibilityData({
            aggregateRootId: action.payload.identifiedVehicleId,
          })
          .pipe(
            map(() => new UpdateIdentifiedVehicleBuildabilityDataCompleted(action.payload.caseId)),
            catchError(() => of(new UpdateIdentifiedVehicleBuildabilityDataError())),
          ),
      ),
    ),
  );

  updateIdentifiedVehicleBuildabilityDataCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateIdentifiedVehicleBuildabilityDataCompleted>(
          CasesActionTypes.UpdateIdentifiedVehicleBuildabilityDataCompleted,
        ),
        withLatestFrom(this.casesFacade.activeCaseId$),
        map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private caseService: CaseService,
    private caseFilesViewService: CaseFilesViewService,
    private casesFacade: CasesFacade,
    private adjustmentsFacade: AdjustmentsFacade,
    private caseViewService: CaseViewService,
    private notifications: BasicNotificationsService,
    private filesNotifications: FilesNotificationsService,
    private identifiedVehicle: IdentifiedVehicleService,
    private router: Router,
    private qrCodeService: QRCodeViewService,
    private caseFilesService: CaseFilesService,
    private fileSaverService: FileSaverService,
    private translateService: TranslateService,
    private emissionStickerViewService: EmissionStickerViewService,
    private caseBroadcastSettingsViewService: CaseBroadcastSettingsViewService,
    private modalsService: ModalService,
  ) {}
}
