import { Component, Input, Optional } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TemplateBaseModal } from '../../utils/template-base-modal';
import { OpenRenameModalCommand } from '@vpfa/print-templates/data';
import { LocaleFacade } from '@vpfa/locale';
import { CountriesFacade } from '@vpfa/admin/countries/data';

const EMPTY_TEMPLATE = { name: null, description: null };

@Component({
  selector: 'vpfa-rename-template-modal',
  templateUrl: './rename-template-modal.component.html',
  styleUrls: ['./rename-template-modal.component.scss'],
})
export class RenameTemplateModalComponent extends TemplateBaseModal {
  @Input() modalTitle = 'admin.printTemplates.modal.renameTemplate';
  @Input() set template(template: OpenRenameModalCommand) {
    this._template = template ? template : EMPTY_TEMPLATE;
    this.resetForm();
  }

  private _template = EMPTY_TEMPLATE;

  constructor(
    fb: UntypedFormBuilder,
    translateService: TranslateService,
    localeFacade: LocaleFacade,
    @Optional() countriesFacade: CountriesFacade,
  ) {
    super(fb, translateService, localeFacade, countriesFacade);
  }

  resetForm() {
    const { name, ...rest } = this._template;

    /**
     * `{name: {"DEDE": "test"}, description: "test2"}` => `{"DEDE": "test", description: "test2"}`
     */
    this.templateForm.reset({
      ...rest,
      ...name,
    });
  }
}
