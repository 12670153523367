import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BranchConfigurationState,
  BranchDetailsState,
  BRANCHES_FEATURE_KEY,
  BranchesState,
  BranchListState,
  GdvConfigurationState,
  OfferEmailTemplateState,
  QuotesEmailTemplateState,
  ResellersEmailsTemplateState,
  TwinnerCredentialsState,
} from './branches.reducer';
import { TwinnerCredentialsDto } from '@vpfa/rest-api/admin';

const getBranchesState = createFeatureSelector<BranchesState>(BRANCHES_FEATURE_KEY);
const getListState = createSelector(getBranchesState, (state: BranchesState) => state.list);
const getBranchesForDealerNetworkState = createSelector(
  getBranchesState,
  (state: BranchesState) => state.branchesForDealerNetwork
);
const getConfigurationState = createSelector(getBranchesState, (state: BranchesState) => state.configuration);
const getDetailsState = createSelector(getBranchesState, (state: BranchesState) => state.details);
const getResellersEmailsTemplateState = createSelector(
  getBranchesState,
  (state: BranchesState) => state.resellersEmailsTemplate
);
const getQuotesEmailTemplateState = createSelector(
  getBranchesState,
  (state: BranchesState) => state.quotesEmailTemplate
);
const getOfferEmailTemplateState = createSelector(getBranchesState, (state: BranchesState) => state.offerEmailTemplate);
const getTwinnerState = createSelector(getBranchesState, (state: BranchesState) => state.twinner);
const getGdvState = createSelector(getBranchesState, (state: BranchesState) => state.gdv);

const getListLoading = createSelector(getListState, (state: BranchListState) => state.loading);
const getAllBranches = createSelector(getListState, (state: BranchListState) => state.items);

const getBranchesForDealerNetworkLoading = createSelector(
  getBranchesForDealerNetworkState,
  (state: BranchListState) => state.loading
);
const getBranchesForDealerNetwork = createSelector(
  getBranchesForDealerNetworkState,
  (state: BranchListState) => state.items
);

const getBranchDetails = createSelector(getDetailsState, (state: BranchDetailsState) => {
  return state.branch;
});
const getBranchDetailsLoading = createSelector(getDetailsState, (state: BranchDetailsState) => {
  return state.loading;
});

const getBranchUrl = createSelector(getDetailsState, (state: BranchDetailsState) => {
  return state.branch ? state.branch.branchUrlPart : null;
});

const isImportingCustomers = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.isImportingCustomers
);

const getResellersEmailTemplateDetails = createSelector(
  getResellersEmailsTemplateState,
  (state: ResellersEmailsTemplateState) => state.details
);

const getIsResellersEmailTemplateDetailsLoading = createSelector(
  getResellersEmailsTemplateState,
  (state: ResellersEmailsTemplateState) => state.loading
);

const getIsResellersEmailTemplateProcessing = createSelector(
  getResellersEmailsTemplateState,
  (state: ResellersEmailsTemplateState) => state.processing
);

const getQuotesEmailTemplateDetails = createSelector(
  getQuotesEmailTemplateState,
  (state: QuotesEmailTemplateState) => state.details
);

const getIsLoadingQuotesEmailTemplateDetails = createSelector(
  getQuotesEmailTemplateState,
  (state: QuotesEmailTemplateState) => state.loading
);

const getIsProcessingQuotesEmailTemplateDetailsForm = createSelector(
  getQuotesEmailTemplateState,
  (state: QuotesEmailTemplateState) => state.processing
);

const getOfferEmailTemplateDetails = createSelector(
  getOfferEmailTemplateState,
  (state: OfferEmailTemplateState) => state.details
);

const getIsLoadingOfferEmailTemplateDetails = createSelector(
  getOfferEmailTemplateState,
  (state: OfferEmailTemplateState) => state.loading
);

const getIsProcessingOfferEmailTemplateDetailsForm = createSelector(
  getOfferEmailTemplateState,
  (state: OfferEmailTemplateState) => state.processing
);

const getLiveRetailConfiguration = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.configuration.liveRetailConfiguration
);
const getNumberOfDaysSlowMovingKPI = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.configuration.numberOfDaysSlowMovingKPI
);
const isBranchConfigurationLoading = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.loading
);
const isBranchTolerancesUpdating = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.updatingTolerances
);
const isBranchStockDaysUpdating = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.updatingStockDays
);
const isBranchURLUpdating = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.updatingStockDays
);
const getBranchConfigurationId = createSelector(
  getConfigurationState,
  (state: BranchConfigurationState) => state.configuration.branchId
);

const getTwinnerCredentials = createSelector(getTwinnerState, (state: TwinnerCredentialsState) => state.credentials);

const getTwinnerUsername = createSelector(getTwinnerCredentials, (state: TwinnerCredentialsDto) => state.username);

const getIsTwinnerPasswordSet = createSelector(
  getTwinnerCredentials,
  (state: TwinnerCredentialsDto) => state.isPasswordSet
);

const isTwinnerLoading = createSelector(getTwinnerState, (state: TwinnerCredentialsState) => state.loading);

const isUpdatingUsername = createSelector(getTwinnerState, (state: TwinnerCredentialsState) => state.updatingUsername);

const isUpdatingPassword = createSelector(getTwinnerState, (state: TwinnerCredentialsState) => state.updatingPassword);

const isBranchGdvConfigurationLoading = createSelector(getGdvState, (state: GdvConfigurationState) => state.loading);

const isBranchGdvConfigurationProcessing = createSelector(
  getGdvState,
  (state: GdvConfigurationState) => state.processing
);

const getBranchGdvConfiguration = createSelector(getGdvState, (state: GdvConfigurationState) => state.config);

export const branchesQuery = {
  getListLoading,
  getAllBranches,
  getBranchesForDealerNetworkLoading,
  getBranchesForDealerNetwork,
  getBranchDetails,
  getBranchDetailsLoading,
  getBranchUrl,
  isImportingCustomers,
  getResellersEmailTemplateDetails,
  getIsResellersEmailTemplateDetailsLoading,
  getIsResellersEmailTemplateProcessing,
  getQuotesEmailTemplateDetails,
  getIsLoadingQuotesEmailTemplateDetails,
  getIsProcessingQuotesEmailTemplateDetailsForm,
  getOfferEmailTemplateDetails,
  getIsLoadingOfferEmailTemplateDetails,
  getIsProcessingOfferEmailTemplateDetailsForm,
  getLiveRetailConfiguration,
  getNumberOfDaysSlowMovingKPI,
  isBranchConfigurationLoading,
  isBranchTolerancesUpdating,
  getBranchConfigurationId,
  isBranchStockDaysUpdating,
  isBranchURLUpdating,
  getTwinnerCredentials,
  getTwinnerUsername,
  getIsTwinnerPasswordSet,
  isTwinnerLoading,
  isUpdatingUsername,
  isUpdatingPassword,
  isBranchGdvConfigurationLoading,
  isBranchGdvConfigurationProcessing,
  getBranchGdvConfiguration,
};
