import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomerService, CustomerViewService } from '@vpfa/rest-api/valuation';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { some } from 'lodash';
import { of } from 'rxjs';
import { catchError, combineAll, debounceTime, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  CreateCustomer,
  CustomerCreated,
  CustomerCreateError,
  CustomerDeleted,
  CustomersActionTypes,
  CustomerUpdated,
  CustomerUpdateError,
  DeleteCustomer,
  DeleteSelectedCustomersFromList,
  fromCustomersActions,
  LoadCustomerDetails,
  LoadCustomerFormsOfAddress,
  LoadCustomerLatestQuoteList,
  LoadCustomerList,
  LoadHaveRelatedCase,
  LoadHaveRelatedCaseForSelectedCustomers,
  LoadPagedCustomerList,
  UpdateCustomer,
  SelectedCustomersDeleted,
  CustomersDeleted,
  LoadSelectedCustomerDetailsForSellModal,
} from './customers.actions';
import { CustomersFacade } from './customers.facade';
import { mapCustomerListResponse } from '../mappers/map-customer-list-item';
import { TranslateService } from '@ngx-translate/core';
import { LocaleFacade } from '@vpfa/locale';
import { Features } from '@vpfa/rest-api/admin';
import { CustomersListService } from '../services/customers-list.service';

@Injectable()
export class CustomersEffects {
  $useListsV2 = this.localeFacade.isFeatureConfigurationEnabled(Features.ShowListsV2);

  loadCustomerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCustomerList>(CustomersActionTypes.LoadCustomerList),
      switchMap(() =>
        this.customerViewService.getList().pipe(
          map(res => new fromCustomersActions.CustomerListLoaded(res)),
          catchError(err => of(new fromCustomersActions.CustomerListLoadError(err))),
        ),
      ),
    ),
  );

  loadPagedCustomerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadPagedCustomerList>(CustomersActionTypes.LoadPagedCustomerList),
      debounceTime(500),
      switchMap(({ payload }) =>
        this.customerViewService.getListAsync(payload.filters, payload.order, payload.pageIndex, payload.pageSize).pipe(
          map(response => mapCustomerListResponse(response, this.translateService.instant('common.noValue'))),
          map(res => new fromCustomersActions.PagedCustomerListLoaded(res)),
          catchError(err => of(new fromCustomersActions.PagedCustomerListLoadError(err))),
        ),
      ),
    ),
  );

  loadCustomerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCustomerDetails>(CustomersActionTypes.LoadCustomerDetails),
      switchMap(action =>
        this.customerViewService.getCustomer(action.payload).pipe(
          map(res => new fromCustomersActions.CustomerDetailsLoaded(res)),
          catchError(err => of(new fromCustomersActions.CustomerDetailsLoadError(err))),
        ),
      ),
    ),
  );

  loadSelectedCustomerDetailsForSellModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadSelectedCustomerDetailsForSellModal>(CustomersActionTypes.LoadSelectedCustomerDetailsForSellModal),
      mergeMap(action =>
        this.customerViewService.getCustomer(action.payload).pipe(
          map(res => new fromCustomersActions.SelectedCustomerDetailsForSellModalLoaded(res)),
          catchError(() => of(new fromCustomersActions.SelectedCustomerDetailsForSellModalLoadError())),
        ),
      ),
    ),
  );

  loadFormsOfAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCustomerFormsOfAddress>(CustomersActionTypes.LoadCustomerFormsOfAddress),
      switchMap(action =>
        this.customerViewService.listFormOfAddresses(action.payload).pipe(
          map(res => new fromCustomersActions.CustomerFormsOfAddressLoaded(res)),
          catchError(() => of(new fromCustomersActions.CustomerFormsOfAddressLoadError())),
        ),
      ),
    ),
  );

  loadLatestCustomerQuoteList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadCustomerLatestQuoteList>(CustomersActionTypes.LoadCustomerLatestQuoteList),
      switchMap(action =>
        this.customerViewService.getRecentOffers(action.payload).pipe(
          map(res => new fromCustomersActions.CustomerLatestQuoteListLoaded(res)),
          catchError(err => of(new fromCustomersActions.CustomerLatestQuoteListLoadError(err))),
        ),
      ),
    ),
  );

  createCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCustomer>(CustomersActionTypes.CreateCustomer),
      switchMap(action =>
        this.customerService.createCustomer(action.payload).pipe(
          map(res => {
            return new fromCustomersActions.CustomerCreated(res.aggregateId, action.meta.afterSuccess);
          }),
          catchError(() => of(new fromCustomersActions.CustomerCreateError())),
        ),
      ),
    ),
  );

  customerCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CustomerCreated>(CustomersActionTypes.CustomerCreated),
        tap(() => this.notifications.success('customerCreate.customerCreated')),
        withLatestFrom(this.$useListsV2),
        tap(([, useListsV2]) => {
          if (useListsV2) {
            this.customerListService.loadFirstPage();
          } else {
            this.customersFacade.loadAll();
          }
        }),
      ),
    { dispatch: false },
  );

  customerCreateError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CustomerCreateError>(CustomersActionTypes.CustomerCreateError),
        tap(() => {
          // TODO: show error from interceptor?
          this.notifications.error('customerCreate.couldNotCreate');
        }),
        withLatestFrom(this.$useListsV2),
        tap(([, useListsV2]) => {
          if (useListsV2) {
            this.customerListService.loadFirstPage();
          } else {
            this.customersFacade.loadAll();
          }
        }),
      ),
    { dispatch: false },
  );

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCustomer>(CustomersActionTypes.UpdateCustomer),
      switchMap(action =>
        this.customerService.updateCustomer(action.payload).pipe(
          map(() => new fromCustomersActions.CustomerUpdated(action.payload?.aggregateRootId, action.payload)),
          catchError(() => of(new fromCustomersActions.CustomerUpdateError())),
        ),
      ),
    ),
  );

  customerUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CustomerUpdated>(CustomersActionTypes.CustomerUpdated),
      tap(() => this.notifications.success('customerUpdate.customerUpdated')),
      tap(() => {
        this.customersFacade.closeUpdateCustomerModal();
      }),
      map(action => new fromCustomersActions.LoadCustomerDetails(action.id)),
    ),
  );

  customerUpdateError = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CustomerUpdateError>(CustomersActionTypes.CustomerUpdateError),
        tap(() => {
          // TODO: show error from interceptor?
          this.notifications.error('customerUpdate.couldNotUpdate');
        }),
      ),
    { dispatch: false },
  );

  loadHaveRelatedCaseForSelectCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadHaveRelatedCaseForSelectedCustomers>(CustomersActionTypes.LoadHaveRelatedCaseForSelectCustomers),
      withLatestFrom(this.customersFacade.selectedCustomersId$),
      map(([, selectedCustomers]) => new fromCustomersActions.LoadHaveRelatedCase(selectedCustomers)),
      catchError(err => of(new fromCustomersActions.HaveRelatedCaseError(err))),
    ),
  );

  loadHaveRelatedCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadHaveRelatedCase>(CustomersActionTypes.LoadHaveRelatedCase),
      switchMap(action =>
        of(...action.payload.map(id => this.customerViewService.isOwnerWithReferences(id))).pipe(combineAll()),
      ),
      map(res => some(res, caseHas => caseHas === true)),
      map(hasRelatedCase => new fromCustomersActions.HaveRelatedCaseLoaded(hasRelatedCase)),
      catchError(err => of(new fromCustomersActions.HaveRelatedCaseError(err))),
    ),
  );

  deleteSelectedCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSelectedCustomersFromList>(CustomersActionTypes.DeleteSelectedCustomersFromList),
      withLatestFrom(this.customersFacade.selectedCustomersId$),
      switchMap(([, selectedCustomers]) =>
        of(...selectedCustomers.map(id => this.customerService.deleteCustomer(id))).pipe(combineAll()),
      ),
      map(() => new fromCustomersActions.SelectedCustomersDeleted()),
      catchError(() => of(new fromCustomersActions.SelectedCustomersDeleteError())),
    ),
  );

  selectedCustomersDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SelectedCustomersDeleted>(CustomersActionTypes.SelectedCustomersDeleted),
        tap(() => this.notifications.success('customerUpdate.customersDeleted')),
        withLatestFrom(this.$useListsV2),
        tap(([, useListsV2]) => {
          if (useListsV2) {
            this.customerListService.loadFirstPage();
          } else {
            this.customersFacade.loadAll();
          }
        }),
      ),
    { dispatch: false },
  );

  deleteCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCustomer>(CustomersActionTypes.DeleteCustomer),
      switchMap(action =>
        this.customerService.deleteCustomer(action.payload).pipe(
          map(() => new fromCustomersActions.CustomerDeleted()),
          catchError(() => of(new fromCustomersActions.CustomerDeleteError())),
        ),
      ),
    ),
  );

  deletedCustomers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CustomersDeleted>(CustomersActionTypes.CustomersDeleted),
        tap(action =>
          action.payload.isMultiple
            ? this.notifications.success('customerUpdate.customersDeleted')
            : this.notifications.success('customerUpdate.customerDeleted'),
        ),
        tap(() => {
          this.router.navigate(['/customers']);
        }),
        withLatestFrom(this.$useListsV2),
        tap(([, useListsV2]) => {
          if (useListsV2) {
            this.customerListService.loadFirstPage();
          } else {
            this.customersFacade.loadAll();
          }
        }),
      ),
    { dispatch: false },
  );

  customerDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CustomerDeleted>(CustomersActionTypes.CustomerDeleted),
        tap(() => this.notifications.success('customerUpdate.customerDeleted')),
        tap(() => this.router.navigate(['/customers'])),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private customerService: CustomerService,
    private customerViewService: CustomerViewService,
    private customersFacade: CustomersFacade,
    private notifications: BasicNotificationsService,
    private translateService: TranslateService,
    private localeFacade: LocaleFacade,
    private customerListService: CustomersListService,
  ) {}
}
