import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '../../interfaces';
import { SelectComponent } from '../select/select.component';

export const emptyKbaTranslationKey = 'vehicleBasicInfo.emptyKba';

@Component({
  selector: 'vpfa-short-label-inline-editable-select',
  templateUrl: './short-label-inline-editable-select.component.html',
  styleUrls: ['./short-label-inline-editable-select.component.scss'],
})
export class ShortLabelInlineEditableSelectComponent implements OnInit {
  @ViewChild('kbaOptions') selectElement: SelectComponent;
  @Input() allowClear = true;

  /**
   *  If set to true and options list is empty, prevents from editing and emits emptyListError
   */
  @Input() allowEmptyOptions = false;
  @Input() set initOption(value: string) {
    this._pristineValue = value;
    this.resetControl(value);
  }
  @Input()
  public set options(value: SelectOption[]) {
    this._options = value;

    if (value?.length > 0) {
      this.hasOptions = value.filter(x => x.name != emptyKbaTranslationKey)?.length > 1;
    }
  }
  private _options: SelectOption[] = [];
  public get options(): SelectOption[] {
    return this._options;
  }

  @Input() label;
  @Input() displayValue = null;
  @Input() isLoading = false;
  @Input() isProcessing = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() emptyListError = new EventEmitter();

  hasOptions = true;
  isInEditMode = false;
  formGroup = new UntypedFormGroup({ control: new UntypedFormControl(null) });

  control = this.formGroup.get('control');
  private _pristineValue: string;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  onSelected(value: SelectOption) {
    if (!this.isInEditMode) return;

    if (value) {
      this.valueChange.emit(value.value);
    } else {
      this.valueChange.emit(null);
    }
    this.isInEditMode = false;
    this.resetControl(this._pristineValue);
  }

  onEditModeToggle() {
    this.isInEditMode = !this.isInEditMode;

    if (this.isInEditMode && this.allowEmptyOptions === false && this.options.length === 0) {
      this.isInEditMode = false;
      this.emptyListError.emit();
      return;
    }

    if (this.isInEditMode) {
      this.changeDetectorRef.detectChanges();
      this.selectElement.openSelect();
    }
  }

  onOptionsClosed() {
    this.isInEditMode = false;
  }

  resetControl(value: string) {
    if (value != null) {
      this.control.reset({ value, name: value });
    } else {
      this.control.reset(null);
    }
  }

  ngOnInit() {}
}
