import { ValueFormatterParams } from 'ag-grid-community';
import {
  DataTableColDef,
  DATA_TABLE_COMPONENTS,
  DataTableActionListItem,
  DataTableValueGetterParams,
  compareMultiValue,
  ActionsColumnName,
} from '@vpfa/ui-kit/data-table';
import { SelectOption } from '@vpfa/ui-kit';
import { TranslateService } from '@ngx-translate/core';
import { PdfTemplateListItem } from '@vpfa/shared/interfaces';

export const getTemplateColumnsDefinitions = (
  translate: TranslateService,
  languages: SelectOption<string>[],
  actionGetter: (params: DataTableValueGetterParams<PdfTemplateListItem>) => DataTableActionListItem[],
): DataTableColDef<PdfTemplateListItem>[] => {
  let first = true;

  let nameCols = [];

  if (languages === null) {
    nameCols = [
      {
        colId: 'Name',
        headerName: 'admin.printTemplates.list.name',
        field: 'name',
        maxWidth: 570,
        cellRenderer: DATA_TABLE_COMPONENTS.MULTI_VALUE_RENDERER,
        cellRendererParams: {
          horizontal: true,
        },
        valueGetter: params => {
          return [params.data.name, params.data.descritpion];
        },
      },
    ];
  } else {
    // make the default language the first one.
    languages.sort((a, b) => Number(b.additional?.isDefaultLanguage) - Number(a.additional?.isDefaultLanguage));

    nameCols = languages.map(lang => {
      return {
        headerName: `${translate.instant('admin.printTemplates.list.name')} ${lang.name}`,
        maxWidth: 570,
        valueGetter: params => {
          const langCode = lang.additional.countryCode + lang.value;

          if (first) {
            first = false;
            return [params.data.name[langCode], params.data.descritpion];
          }

          return [params.data.name[langCode]];
        },
        cellRenderer: DATA_TABLE_COMPONENTS.MULTI_VALUE_RENDERER,
        cellRendererParams: {
          horizontal: true,
        },
        comparator: compareMultiValue(true),
      };
    });
  }
  return [
    ...nameCols,
    {
      colId: 'CreatedAt',
      headerName: 'admin.printTemplates.list.created',
      maxWidth: 160,
      field: 'createdAt',
      cellRenderer: DATA_TABLE_COMPONENTS.DATE_RENDERER,
    },
    {
      colId: 'UpdatedAt',
      headerName: 'admin.printTemplates.list.modified',
      maxWidth: 160,
      field: 'updatedAt',
      cellRenderer: DATA_TABLE_COMPONENTS.DATE_RENDERER,
    },
    {
      colId: 'Status',
      headerName: 'admin.printTemplates.list.status',
      maxWidth: 160,
      field: 'isBaseTemplate',
      cellClass: ['cell-with-status'],
      valueFormatter: (params: ValueFormatterParams): string => {
        return `admin.printTemplates.status.${params.value ? 'base' : 'custom'}`;
      },
      cellRenderer: DATA_TABLE_COMPONENTS.TRANSLATE_RENDERER,
    },
    {
      colId: 'Modules',
      headerName: 'admin.printTemplates.list.module(s)',
      maxWidth: 280,
      field: 'pdfTemplateModules',
      cellClass: ['cell-with-status'],
      valueFormatter: (params: ValueFormatterParams): string => {
        let formattedModules = '';
        for (let i = 0; i < params.value.length; i++) {
          formattedModules += `admin.printTemplates.list.module${params.value[i]}`;
          if (i !== params.value.length - 1) {
            formattedModules += `,`;
          }
        }
        return formattedModules;
      },
      cellRenderer: DATA_TABLE_COMPONENTS.TRANSLATE_RENDERER,
    },
    {
      colId: 'Visible',
      headerName: 'admin.printTemplates.list.visible',
      field: 'visible',
      maxWidth: 100,
      sortable: false,
      cellStyle: params => {
        if (params.value === null) {
          return { display: 'none' };
        }
      },
      cellRenderer: DATA_TABLE_COMPONENTS.SWITCH_RENDERER,
    },
    {
      colId: ActionsColumnName,
      headerName: null,
      maxWidth: 40,
      sortable: false,
      valueGetter: actionGetter,
      cellClass: 'cell-with-actions',
      cellRenderer: DATA_TABLE_COMPONENTS.ACTIONS_RENDERER,
    },
  ];
};
