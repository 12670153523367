import { EquipmentDto } from '@vpfa/rest-api/valuation';
import { isDebugEnabled } from './debug/debug.utils';
import { getEquipmentDebugText } from './debug/equipment-debug.util';

export function calculateShortDesc(equipment: EquipmentDto): string {
  const maxSize = 40;
  const desc = equipment.shortDescription?.trim() || equipment.description;

  const soaCode = isDebugEnabled('formulaWizard') ? getEquipmentDebugText(equipment) : '';

  if (!desc) {
    return '';
  }

  // Resolve OEM Code
  let foundOemCode : boolean = (equipment.oemCode && equipment.oemCode.length > 0) ? true:false;
  let oemCodeLength = 0;

  if (foundOemCode) {
    oemCodeLength = equipment.oemCode.length + 3;
  }

  if (desc?.length + oemCodeLength <= maxSize) {
    if (foundOemCode)
      return "[" + equipment.oemCode + "] " + soaCode + desc;
    else
      return soaCode + desc;
  }

  if (foundOemCode)
    return "[" + equipment.oemCode + "] " + `${desc.slice(0, maxSize)}` + `...`;
  else
    return `${desc.slice(0, maxSize)}...`;

}

export function recalculateShortDesc(equipment: EquipmentDto): EquipmentDto {
  return {
    ...equipment,
    shortDescription: calculateShortDesc(equipment),
  };
}
