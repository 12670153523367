import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CURRENCY_DISPLAY_FORMAT, SelectOption } from '@vpfa/ui-kit';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CaseTaxDto,
  CustomerCaseListItemDto,
  OfferEmailTemplateDto,
  QuoteEmailTemplateDto,
} from '@vpfa/rest-api/valuation';
import { isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EMAIL_MAX_LENGTH, emailValidator, F_PRICE_MAX, TEXT_LINE_MAX_LENGTH } from '@vpfa/shared/validators';
import { AddRemoveCustomerListItem } from '@vpfa/dealer/customers/shared';
import { PdfTemplateListItem } from '@vpfa/shared/interfaces';

export interface QuoteOptions {
  finalPrice: number;
}

export interface CreateQuoteOffer {
  aggregateRootId: string;
  finalPrice: number;
  emailData?: EmailData;
  pdfTemplateId: string;
  customerId?: string;
}

export interface EmailData {
  subject: string;
  body: string;
  addresseeFirstName: string;
  addresseeLastName: string;
  addresseeEmails: Array<string>;
}

@Component({
  selector: 'vpfa-create-quote-modal',
  templateUrl: './create-quote-modal.component.html',
  styleUrls: ['./create-quote-modal.component.scss'],
})
export class CreateQuoteModalComponent implements OnInit, OnDestroy {
  readonly FINAL_PRICE_MIN_VALUE = 1;
  readonly textAreaMaxLength = 10000;

  @Input() translationLabelParent = 'createQuoteModal';
  @Input() caseId: string;
  @Input() title: string = this.translationLabelParent + '.createQuote';
  @Input() customerLabel: string = this.translationLabelParent + '.owner';
  @Input() downloadLabel: string = 'createQuoteModal.downloadQuote';
  @Input() activeCaseDataTaxes: CaseTaxDto;

  @Input() set emailDetails(details: QuoteEmailTemplateDto | OfferEmailTemplateDto) {
    if (details) {
      this._emailDetails = details;
      this.resetFormGroups();
    }
  }

  @Input() isEmailDetailsLoading: boolean;

  @Input() set caseCustomers(customers: CustomerCaseListItemDto[]) {
    if (customers) {
      this.caseCustomersOptions = customers.map(
        (customer: CustomerCaseListItemDto) =>
          <AddRemoveCustomerListItem>{
            company: customer.company,
            id: customer.customerId,
            emails: customer.emails,
            firstName: customer.firstName,
            lastName: customer.lastName,
            phoneNumbers: customer.phoneNumbers,
            address: customer.address,
            city: customer.city,
            street: customer.street,
          }
      );
    }
  }

  @Input() set quoteOptions(options: QuoteOptions) {
    if (options) {
      this._finalPrice = options.finalPrice;
      this.quoteForm.patchValue(options);
    }
  }

  @Input() set templates(templates: PdfTemplateListItem[]) {
    if (templates) {
      this.availableTemplates = templates.map(template => {
        return <SelectOption>{
          name: template.name,
          value: template.id,
        };
      });
      this.initialTemplate = this.availableTemplates[0];
    }
  }

  @Input() isVisible: boolean;
  @Input() isTemplatesLoading: boolean;

  @Input() set isProcessing(creating: boolean) {
    if (!isNil(creating)) {
      this._isProcessing = creating;
      if (!this._isProcessing) {
        if (!this.templateForm.get('download').value) {
          this.pdfViewerAvailable.emit();
        } else {
          this.downloadPdfAction.emit();
        }
        this.closeAction.emit();
      }
    }
  }

  get isProcessing(): boolean {
    return this._isProcessing;
  }

  @Output() submitAction = new EventEmitter<CreateQuoteOffer>();
  @Output() downloadPdfAction = new EventEmitter();
  @Output() pdfViewerAvailable = new EventEmitter();
  @Output() closeAction = new EventEmitter();

  quoteForm: UntypedFormGroup;
  addCustomerForm: UntypedFormGroup;
  templateForm: UntypedFormGroup;
  templateControl: UntypedFormControl;
  initialTemplate: SelectOption;
  availableTemplates: SelectOption[];
  caseCustomersOptions: AddRemoveCustomerListItem[];
  toggleAddForm = false;
  CURRENCY_DISPLAY_FORMAT = CURRENCY_DISPLAY_FORMAT;
  finalPriceErrorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
  firstNameErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'required',
      errorMsg: this.translateService.instant('createQuoteModal.addCustomerByEmail.firstNameRequired'),
    },
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: TEXT_LINE_MAX_LENGTH }),
    },
  ];
  lastNameErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'required',
      errorMsg: this.translateService.instant('createQuoteModal.addCustomerByEmail.lastNameRequired'),
    },
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: TEXT_LINE_MAX_LENGTH }),
    },
  ];
  emailErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'required',
      errorMsg: this.translateService.instant('createQuoteModal.addCustomerByEmail.emailRequired'),
    },
    {
      error: 'email',
      errorMsg: this.translateService.instant('createQuoteModal.addCustomerByEmail.emailFormatIncorrect'),
    },
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: EMAIL_MAX_LENGTH }),
    },
  ];
  subjectErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: TEXT_LINE_MAX_LENGTH }),
    },
  ];

  private _isProcessing = false;
  private _emailDetails: QuoteEmailTemplateDto | OfferEmailTemplateDto;
  private _finalPrice: number;
  private _onDestroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private translateService: TranslateService) {
    this.quoteForm = this.fb.group({
      finalPrice: [
        null,
        [Validators.required, Validators.min(this.FINAL_PRICE_MIN_VALUE), Validators.max(F_PRICE_MAX)],
      ],
      subject: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
      body: [null, Validators.maxLength(this.textAreaMaxLength)],
      owner: [null],
    });

    this.addCustomerForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.maxLength(TEXT_LINE_MAX_LENGTH)]],
      lastName: [null, [Validators.required, Validators.maxLength(TEXT_LINE_MAX_LENGTH)]],
      emails: [null, [Validators.required, Validators.maxLength(EMAIL_MAX_LENGTH), emailValidator]],
    });

    this.templateForm = this.fb.group({
      download: [false],
      selectedTemplate: [null, Validators.required],
    });

    this.templateControl = this.templateForm.get('selectedTemplate') as UntypedFormControl;
  }

  ngOnInit() {
    this.addCustomerForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(value => this.addCustomerForm.setValue(value, { onlySelf: true, emitEvent: false }));

    this.finalPriceErrorMessages = [
      {
        error: 'required',
        errorMsg: this.translateService.instant(this.translationLabelParent + '.finalPriceIsRequired'),
      },
      {
        error: 'min',
        errorMsg: this.translateService.instant('common.forms.minimumValue', { minValue: this.FINAL_PRICE_MIN_VALUE }),
      },
      {
        error: 'max',
        errorMsg: this.translateService.instant('common.forms.maximumValue', { maxValue: F_PRICE_MAX }),
      },
    ];
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  close() {
    this.closeAction.emit();
  }

  afterClose() {
    this.initialTemplate = { ...this.availableTemplates[0] };
    this.resetFormGroups();
  }

  resetFormGroups() {
    if (this._emailDetails) {
      this.quoteForm.reset({
        finalPrice: this._finalPrice,
        subject: this._emailDetails.subject,
        body: this._emailDetails.body,
        owner: null,
      });
    }
    this.addCustomerForm.reset({
      firstName: null,
      lastName: null,
      emails: null,
    });
    this.templateForm.reset({
      download: false,
    });

    this.toggleAddForm = false;
  }

  isOwnerSelected() {
    return !isNil(this.quoteForm.get('owner').value);
  }

  onToggleAddForm(toggle: boolean) {
    this.toggleAddForm = toggle;
  }

  saveQuote() {
    if (this.quoteForm.valid) {
      this.submitAction.emit(this.prepareQuote());
    }
  }

  private prepareQuote(): CreateQuoteOffer {
    return <CreateQuoteOffer>{
      aggregateRootId: this.caseId,
      finalPrice: this.quoteForm.get('finalPrice').value,
      emailData: this.prepareQuoteEmailData(),
      pdfTemplateId: this.getPdfTemplateId(),
      customerId:
        ((this.quoteForm.get('owner')?.value as SelectOption)?.additional as AddRemoveCustomerListItem)?.id ?? null,
    };
  }

  private prepareQuoteEmailData(): CreateQuoteOffer['emailData'] {
    if (
      !this.isOwnerSelected() &&
      (isNil(this.addCustomerForm.get('firstName').value) ||
        isNil(this.addCustomerForm.get('lastName').value) ||
        isNil(this.addCustomerForm.get('emails').value))
    ) {
      return null;
    } else {
      return {
        subject: this.quoteForm.get('subject').value,
        body: this.quoteForm.get('body').value,
        addresseeFirstName: this.getAddresseeFirstName(),
        addresseeLastName: this.getAddresseeLastName(),
        addresseeEmails: this.getAddresseeEmails(),
      };
    }
  }

  private getPdfTemplateId() {
    if (!isNil(this.templateControl.value)) {
      return this.templateControl.value.value;
    }
  }

  get ownerFromForm(): AddRemoveCustomerListItem {
    return this.isOwnerSelected()
      ? ((this.quoteForm?.get('owner')?.value as SelectOption)?.additional as AddRemoveCustomerListItem)
      : null;
  }

  private getAddresseeFirstName() {
    return this.ownerFromForm?.firstName ?? this.addCustomerForm?.get('firstName')?.value;
  }

  private getAddresseeLastName() {
    return this.ownerFromForm?.lastName ?? this.addCustomerForm?.get('lastName')?.value;
  }

  private getAddresseeEmails() {
    return this.ownerFromForm?.emails ?? [this.addCustomerForm?.get('emails')?.value];
  }
}
