import { isInUrlsList } from '@vpfa/utils';

export function isInBlackList(url: string): boolean {
  return isInUrlsList(url, blackList);
}

const blackList: string[] = [
  'api/LogoView/business/:id',
  'api/LogoView/country/:id',
  'api/Post',
  'api/CaseView/:caseId/images/medium/:id',
  'api/CaseFilesView/:caseId/Images/:id',
  'api/CaseFilesView/:caseId/public/:id',
  'api/CountryView/Login/:url',
  'api/Case/Quote',
  'api/CaseView/Public/:caseId',
  'api/CaseView/:branchId/Public/List',
  'api/CaseView/Reseller/:caseId',
  'api/CaseView/:branchId/Reseller/List',
  'api/BusinessView/public/:businessId',
  'api/BranchView/public/:groupId/:branchId',
  'api/Auth',
  'api/EnvironmentConfigView',
  'api/RetailMarket/radarUrl/:caseId',
  'api/CaseFiles/AddDocument',
  'api/CaseFiles/UpdateTwinnerImages',
  'api/CaseView/TwinnerUrl/:caseId',
  'api/Media/:mediaId',
  'api/CaseBroadcastSettings/refreshBroadcastsStatus',
  'api/MappingView/mappingKeyNames/:id',
  'api/MappingView/mappingValueNames/:id',
  'api/CaseView/:caseId/images/:size/:viewCode',
  'api/Category',
  'api/BroadcastConnectionConfigurationView/:id/logo',
  'api/BulkValuation',
  'api/Case/StockOffer',
  'api/SubscriptionView/businessSubscription/:id',
  'api/TableauReportView'
];
