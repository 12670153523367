import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxButtonComponent } from './checkbox-button/checkbox-button.component';
import { CheckboxButtonGroupComponent } from './checkbox-button-group/checkbox-button-group.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { UiKitModule } from '@vpfa/ui-kit';
import { UiKitSettingBoxModule } from '@vpfa/ui-kit/setting-box';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { CheckboxButtonOldComponent } from './old/checkbox-button/checkbox-button-old.component';
import { CheckboxButtonGroupOldComponent } from './old/checkbox-button-group/checkbox-button-group-old.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FlexModule,
    NzPopconfirmModule,
    UiKitModule,
    UiKitSettingBoxModule,
    SharedMobileViewModule,
    NzIconModule,
  ],
  declarations: [
    CheckboxButtonComponent,
    CheckboxButtonGroupComponent,
    CheckboxButtonOldComponent,
    CheckboxButtonGroupOldComponent,
    CheckboxComponent,
  ],
  exports: [
    CheckboxButtonComponent,
    CheckboxButtonGroupComponent,
    CheckboxButtonOldComponent,
    CheckboxButtonGroupOldComponent,
    CheckboxComponent,
  ],
})
export class UiKitCheckboxButtonModule {}
