import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { ProfileFacade } from '@vpfa/profile/data';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DealerAppGuard  {
  constructor(private store: Store<any>, private profileFacade: ProfileFacade, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.profileFacade.userContext$.pipe(
      filter(x => !isNil(x)),
      map(userContext => {
        const userBranchAmount = userContext.branchIds.length;
        switch (userBranchAmount) {
          case 0:
            this.router.navigate(['error/noBranchAssigned']);
            break;
          case 1:
            return true;
        }
        return false;
      })
    );
  }
}
