<vpfa-simple-modal
  [isVisible]="isVisible"
  [isLoading]="isLoading"
  [showBottomClose]="true"
  [confirmClose]="!form.pristine"
  (closeModal)="onClose()"
  modalName="Register New Vehicle"
>
  <h1>{{ 'vehicleOverviewData.registerVehicle' | translate }}</h1>
  <form [formGroup]="form" (ngSubmit)="saveChanges()" class="d-flex flex-column">
    <vpfa-setting-box [divider]="false">
      <ng-container label>
        <vpfa-label [text]="'vehicleOverviewData.firstRegDate' | translate"></vpfa-label>
      </ng-container>
      <ng-container content>
        <vpfa-iso-date-picker
          id="registerNewVehicleRegistrationDate"
          [ngClass]="{ 'with-value': form.get('registrationDate').value }"
          [allowClear]="false"
          [format]="'short' | localeDateFormat"
          [formControl]="form.get('registrationDate')"
          [disabledDate]="disabledDate"
        ></vpfa-iso-date-picker>
      </ng-container>
    </vpfa-setting-box>

    <vpfa-setting-box [divider]="false">
      <ng-container label>
        <vpfa-label [text]="'vehicleOverviewData.mileage' | translate"></vpfa-label>
      </ng-container>
      <ng-container content>
        <vpfa-number-field
          id="registerNewVehicleMileage"
          [errorMessages]="priceErrorMsgList"
          fcName="mileage"
          [parentFormGroup]="form"
          fieldWidth="100%"
          [maxFractionNumber]="0"
        >
        </vpfa-number-field>
      </ng-container>
    </vpfa-setting-box>

    <vpfa-action-button id="registerNewVehicleSubmitButton" buttonType="submit" [disabled]="form.invalid">
      {{ 'common.save' | translate }}
    </vpfa-action-button>
  </form>
</vpfa-simple-modal>
