<div fxFlex fxLayout="row" class="container">
  <div class="label">{{ label }}</div>

  <vpfa-inline-loader [isLoading]="isProcessing || isLoading">
    <vpfa-select
      #kbaOptions
      *ngIf="isInEditMode"
      [allowClear]="allowClear"
      [parentFormGroup]="formGroup"
      [fc]="formGroup.get('control')"
      [options]="options"
      [translateLabel]="true"
      (optionsClosed)="onOptionsClosed()"
      (selected)="onSelected($event)"
    >
    </vpfa-select>
    <div class="value" *ngIf="!isInEditMode && !isLoading">{{ displayValue | lT }}</div>
    <span
      *ngIf="!(isInEditMode || isProcessing || isLoading) && hasOptions"
      (click)="onEditModeToggle()"
      class="edit-icon"
      nzTheme="twotone"
      nz-icon
      nzType="edit"
    ></span>
  </vpfa-inline-loader>
</div>
