import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { JwtHelperModule } from './jwt-helper.module';
import { TokenEffects } from './+state/effects/token.effects';
import { LoginEffects } from './+state/effects/login.effects';
import { AuthEffects } from './+state/effects/auth.effects';
import { LogoutEffects } from './+state/effects/logout.effects';
import { AUTH_FEATURE_KEY, authReducer } from './+state/auth.reducer';
import { AuthFacade } from './+state/auth.facade';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { ManageStorageService } from './utils/manage-storage.service';
import { LocaleEffects } from './+state/effects/locale.effects';
import { TemporaryEffects } from './+state/effects/temporary.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
    EffectsModule.forFeature([TokenEffects, LoginEffects, AuthEffects, LogoutEffects, LocaleEffects, TemporaryEffects]),
    JwtHelperModule
  ],
  providers: [
    AuthFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ManageStorageService
  ]
})
export class AuthModule {}
