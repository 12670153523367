import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DataTableMultiValueParams } from '../../models/data-table-multi-value-params';
import { isArray } from 'lodash';
import { _ } from 'ag-grid-community';

@Component({
  selector: 'vpfa-multi-value-renderer',
  templateUrl: './multi-value-renderer.component.html',
  styleUrls: ['./multi-value-renderer.component.scss'],
})
export class MultiValueRendererComponent implements ICellRendererAngularComp {
  params: DataTableMultiValueParams;
  values: string[];
  title: string;
  constructor() {}

  agInit(params: DataTableMultiValueParams): void {
    this.params = params;
    if (isArray(params.value)) {
      this.values = params.value;

      this.title = this.values.filter(x => x !== null && x !== undefined && x !== '').join(' | ');
    }
  }

  refresh(params: DataTableMultiValueParams): boolean {
    return false;
  }
}

export const compareMultiValue = (accentedCompare: boolean) => (valueA: string[], valueB: string[]) =>
  _.defaultComparator(valueA.join(' '), valueB.join(' '), accentedCompare);
