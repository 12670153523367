import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CASES_FEATURE_KEY, CasesState, LightDamageEstimatorState } from './cases.reducer';
import { isNil } from 'lodash';
import {
  CaseStatus,
  CaseSummaryDto,
  CustomerCaseListItemDto,
  IdentificationMethod,
  LiveRetailInsightsDto,
  VinEreDataStatus,
} from '@vpfa/rest-api/valuation';
import { CaseHistoricalPrices } from './cases.facade';
import { AmountAdjustment, ValuationAdjustmentType } from '@vpfa/shared/interfaces';
import { PROVENANCE_CHECK_FEATURE_KEY } from './provenance-check/provenance-check.reducer';
import { getCurrentDate, isDateAfterToday, isDateBeforeToday } from '@vpfa/utils';
import { localeQuery } from '@vpfa/locale';

// Lookup the 'Cases' feature state managed by NgRx
export const getCasesState = createFeatureSelector<CasesState>(CASES_FEATURE_KEY);
export const getActiveCaseData = createSelector(getCasesState, s => s.activeCaseData);

const getIsCaseValuationForecasted = createSelector(
  getActiveCaseData, localeQuery.getTimezone,
  (caseData, timezone) => {
    const forecastDate = isDateBeforeToday(caseData.valuationForDate, timezone)
      ? getCurrentDate(timezone)
      : caseData.valuationForDate;
    return isDateAfterToday(forecastDate, timezone);
  }
);

const getLoaded = createSelector(getCasesState, (state: CasesState) => state.loaded);
const getLoading = createSelector(getCasesState, (state: CasesState) => state.loading);
const getError = createSelector(getCasesState, (state: CasesState) => state.error);

const getCaseQrCode = createSelector(getCasesState, (state: CasesState) => state.activeCaseQrCode);

const getAllCases = createSelector(getCasesState, getLoaded, (state: CasesState, isLoaded) => {
  return isLoaded ? state.list : [];
});

const getDocumentsLoaded = createSelector(getCasesState, (state: CasesState) => state.documents.loaded);
const getDocumentsLoading = createSelector(getCasesState, (state: CasesState) => state.documents.loading);
const getAllDocuments = createSelector(getCasesState, getDocumentsLoaded, (state: CasesState, isLoaded) => {
  return isLoaded ? state.documents.items : [];
});

const getDocumentsTotalSize = createSelector(getCasesState, getDocumentsLoaded, (state: CasesState, isLoaded) => {
  return isLoaded ? state.documents.totalSize : null;
});

const getCaseDocumentsTotalSize = createSelector(getCasesState, getDocumentsLoaded, (state: CasesState, isLoaded) => {
  return isLoaded ? state.documents.totalSize : null;
});

const getIsCaseDocumentProcessingForm = createSelector(
  getCasesState,
  (state: CasesState) => state.documents.isProcessingForm
);

const getIsCaseDocumentUploadNotProcessingAndNotCompleted = createSelector(
  getCasesState,
  (state: CasesState) => !state.documents.upload.completed && !state.documents.upload.processing
);

const getHasCaseDocumentFormProcessingError = createSelector(
  getCasesState,
  (state: CasesState) => state.documents.hasFormProcessingError
);

const getAllStockItems = createSelector(getCasesState, getLoaded, (state: CasesState, isLoaded) => {
  return isLoaded ? state.list.filter(el => [CaseStatus.InStock, CaseStatus.DueInStock].includes(el.caseStatus)) : [];
});

const getAllValuationItems = createSelector(getCasesState, getLoaded, (state: CasesState, isLoaded) => {
  return isLoaded ? state.list.filter(el => [CaseStatus.Valuated].includes(el.caseStatus)) : [];
});

const getSelectedId = createSelector(getCasesState, (state: CasesState) => state.selectedId);

const getSelectedCases = createSelector(getAllCases, getSelectedId, (cases, id) => {
  const result = cases.find(it => it['id'] === id);
  return result ? Object.assign({}, result) : undefined;
});

const getActiveCaseId = createSelector(getCasesState, s => s.activeCaseId);
const getActiveCaseNotes = createSelector(getCasesState, s => s.activeCaseNotes);

const getActiveCaseStatus = createSelector(getActiveCaseData, s => s.caseStatus);
const getActiveCaseNotesData = createSelector(getActiveCaseData, acd => (acd ? acd.notes : null));
const getDateOfAcceptance = createSelector(getActiveCaseData, acd => (acd ? acd.dateOfAcceptance : null));
const getYoutubeUrl = createSelector(getActiveCaseData, acd => acd.youtubeUrl);

const getUniqueData = createSelector(getActiveCaseData, s => s.uniqueData);
const getValuationUniqueData = createSelector(getUniqueData, s => s.valuationUniqueData);
const getStockUniqueData = createSelector(getUniqueData, s => s.stockUniqueData);
const getSoldUniqueData = createSelector(getUniqueData, s => s.soldUniqueData);

const getUniqueDataManufacturerColor = createSelector(getValuationUniqueData, s => s.manufacturerColor);
const getUniqueDataManufacturerOriginalColor = createSelector(getValuationUniqueData, s => s.manufacturerOriginalColor);

const getActiveCaseDataAdjustments = createSelector(getActiveCaseData, activeCaseData => activeCaseData.adjustments);
const getIsUpdatingValuationUniqueData = createSelector(getCasesState, s => s.isUpdatingValuationUniqueData);
const getIsUniqueDataModalOpen = createSelector(getCasesState, s => s.isUniqueDataModalOpen);

const getIsUpdatingTechnicalData = createSelector(getCasesState, s => s.isUpdatingTechnicalData);
const getIsTechnicalDataModalOpen = createSelector(getCasesState, s => s.isTechnicalDataModalOpen);

const getActiveCaseManufacturerColorList = createSelector(
  getCasesState,
  s => s.activeCaseData.vehicle.manufacturerColors || []
);

const getIsPurchaseProcessing = createSelector(getCasesState, s => s.purchase.processing);
const getPurchaseModalOpen = createSelector(getCasesState, s => s.purchase.open);

const getIsResellerSiteConfigurationModalOpen = createSelector(getCasesState, s => s.publishToResellers.open);
const getHasPublishToResellerProcessingError = createSelector(
  getCasesState,
  s => s.publishToResellers.hasProcessingError
);
const getIsPublishToResellerProcessingCompleted = createSelector(
  getCasesState,
  s => s.publishToResellers.isProcessingCompleted
);
const getIsPublishToResellerProcessing = createSelector(getCasesState, s => s.publishToResellers.isProcessing);

const getIsUpdatingVin = createSelector(getCasesState, s => s.isUpdatingVin);
const getIsUpdatingVinError = createSelector(getCasesState, s => s.isUpdatingVinError);

const getIsUpdatingMatriculationNumber = createSelector(getCasesState, s => s.isUpdatingMatriculationNumber);
const getIsUpdatingMatriculationNumberError = createSelector(getCasesState, s => s.isUpdatingMatriculationNumberError);

const getIsUpdatingKba = createSelector(getCasesState, s => s.isUpdatingKba);

const getIsUpdatingMileage = createSelector(getCasesState, s => s.isUpdatingMileage);

const getIsUpdatingRegDate = createSelector(getCasesState, s => s.isUpdatingRegDate);

const getIsUpdatingValuationDate = createSelector(getCasesState, s => s.isUpdatingValuationDate);

const getIsUpdateValuationDataModalOpen = createSelector(getCasesState, s => s.isUpdateValuationDateModalOpen);

const getIsReservingVehicle = createSelector(getCasesState, s => s.isReservingVehicle);

const getIsProcessingSellVehicle = createSelector(getCasesState, s => s.sellModal.processing);
const getIsSellModalOpen = createSelector(getCasesState, s => s.sellModal.open);

const getActiveCaseVehicle = createSelector(getCasesState, s => s.activeCaseData.vehicle);

const getTechnicalData = createSelector(getActiveCaseVehicle, s => s.technicalData);

const getActiveCaseVehicleEquipment = createSelector(getCasesState, s => s.activeCaseData?.vehicle.equipments);

const getLightDamageEstimatorState = createSelector(getCasesState, (state: CasesState) => {
  return state.lightDamageEstimator;
});

const getLightDamageEstimatorAreas = createSelector(
  getLightDamageEstimatorState,
  (state: LightDamageEstimatorState) => {
    return state.areas;
  }
);

const getLightDamageEstimatorLoading = createSelector(
  getLightDamageEstimatorState,
  (state: LightDamageEstimatorState) => {
    return state.loading;
  }
);

const getLightDamageEstimatorSaving = createSelector(
  getLightDamageEstimatorState,
  (state: LightDamageEstimatorState) => {
    return state.saving;
  }
);

const getLightDamageEstimatorSaved = createSelector(
  getLightDamageEstimatorState,
  (state: LightDamageEstimatorState) => {
    return state.saved;
  }
);

const getLightDamageEstimatorTotal = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) => {
  return getAdjustmentValue(activeCaseData, ValuationAdjustmentType.LightDamagePartsAdjustment);
});

const getRepairEstimatorTotal = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) => {
  return getAdjustmentValue(activeCaseData, ValuationAdjustmentType.RepairEstimateCalculationAdjustment);
});

const getPublicSiteOption = createSelector(getActiveCaseData, activeCase => {
  if (!activeCase) {
    return null;
  }

  return activeCase.publicSiteConfiguration;
});

const getValuationVersionAtPurchase = createSelector(
  getActiveCaseData,
  (activeCaseData: CaseSummaryDto) => activeCaseData.valuationVersionAtPurchasedTime
);

const getActiveCaseDataPurchasedTime = createSelector(
  getActiveCaseData,
  (activeCaseData: CaseSummaryDto) => activeCaseData.purchasedTime
);

const getActiveCaseDataTaxes = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) =>
  activeCaseData ? activeCaseData.taxes : null
);

const getActiveCaseDataTaxedPrice = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) =>
  activeCaseData ? activeCaseData.taxedPrice : null
);

const isUpdatingPublicSite = createSelector(getCasesState, state => state.isUpdatingPublicSite);
const getIsProcessingForm = createSelector(getCasesState, state => state.isProcessingForm);
const getHasFormProcessingError = createSelector(getCasesState, state => state.hasFormProcessingError);

const getReservation = createSelector(getCasesState, s => (s.activeCaseData ? s.activeCaseData.reservation : null));
const getIsCancellingReservation = createSelector(getCasesState, s => s.isCancellingReservation);
const isReserveModalOpen = createSelector(getCasesState, state => state.isReserveModalOpen);
const isReservationViewModalOpen = createSelector(getCasesState, state => state.isReservationViewModalOpen);

const getRetailMarketDetails = createSelector(getCasesState, s =>
  mapLiveRetailDetails(s.retailMarketDetails, s.activeCaseData?.isOldVehicle, s.activeCaseData?.isOutOfValuationRange, s.activeCaseData?.isNewVehicle)
);
const getRetailMarketLoading = createSelector(getCasesState, s => !s.activeCaseData.isOldVehicle && s.loading);
const getRetailMarketDetailsLiveRetailPrice = createSelector(getRetailMarketDetails, s => s?.liveRetailPrice);

const isReturnToStockModalOpen = createSelector(getCasesState, state => state.isReturnToStockModalOpen);
const isReturningVehicleToStock = createSelector(getCasesState, s => s.isReturningVehicleToStock);

const getIsMoveToStockModalOpen = createSelector(getCasesState, state => state.moveToStock?.open);
const getIsProcessingMoveToStock = createSelector(getCasesState, state => state.moveToStock?.processing);

const getIsStockOfferModalOpen = createSelector(getCasesState, state => state.stockOfferModal?.open);
const getIsProcessingStockOffer = createSelector(getCasesState, state => state.stockOfferModal?.processing);
const getStockOfferUsedCustomerId = createSelector(getCasesState, state => state.stockOfferModal?.usedCustomerId);

const getIsQuoteModalOpen = createSelector(getCasesState, state => state.quoteModal?.open);
const getIsRegisterNewModalOpen = createSelector(getCasesState, state => state.registerNewModal?.open);
const getRegisterNewModalProcessing = createSelector(getCasesState, state => state.registerNewModal?.processing);
const getIsProcessingQuote = createSelector(getCasesState, state => state.quoteModal?.processing);

const getIsNewVehicle = createSelector(getActiveCaseData, activeCase => (activeCase ? activeCase.isNewVehicle : false));
const getIsOldVehicle = createSelector(getActiveCaseData, activeCase => (activeCase ? activeCase.isOldVehicle : false));

const getCO2EmissionStickersList = createSelector(getCasesState, s =>
  s.emissionStickers.loaded ? s.emissionStickers.items : {}
);

const getIsLoadingCO2EmissionStickers = createSelector(getCasesState, s => s.emissionStickers.loading);

const getActiveCaseCO2EmissionEfficiencyClass = createSelector(
  getCasesState,
  s =>
    s?.activeCaseData?.vehicle?.technicalData?.cO2EfficiencyClassCustom?.id ??
    s?.activeCaseData?.vehicle?.technicalData?.cO2EfficiencyClass?.value?.id
);

const getActiveCaseCO2EmissionEfficiencyClassImage = createSelector(getCasesState, s => {
  if (s.activeCaseData) {
    const efficiencyClass =
      s.activeCaseData.vehicle.technicalData.cO2EfficiencyClassCustom ||
      s.activeCaseData.vehicle.technicalData.cO2EfficiencyClass?.value;

    return s.emissionStickers.items[Number(efficiencyClass?.id)];
  } else {
    return null;
  }
});

const getHistoricalPrices = createSelector(getActiveCaseData, activeCase => {
  return activeCase
    ? <CaseHistoricalPrices>{
        askingPriceHistory: activeCase.askingPriceHistory,
        effectiveTradeInPriceHistory: activeCase.effectiveTradeInPriceHistory,
        soldPriceHistory: activeCase.soldPriceHistory,
      }
    : null;
});

const getIsUpdatingPricingDetails = createSelector(getCasesState, s => s.isUpdatingPricingDetails);
const getIsPricingDetailsModalOpen = createSelector(getCasesState, s => s.isPricingDetailsModalOpen);

const getIsUpdatingPlateNo = createSelector(getCasesState, s => s.isUpdatingPlateNo);
const getIsUpdatingPlateNoError = createSelector(getCasesState, s => s.isUpdatingPlateNoError);

const getIsResendingCaseLatestQuote = createSelector(getCasesState, s => s.resendCaseLatestQuote.isProcessing);

const getIsResendingCaseLatestOffer = createSelector(getCasesState, s => s.resendCaseLatestOffer.isProcessing);

const getDocumentsUploadCurrentIndex = createSelector(getCasesState, s => s.documents.upload.currentIndex);
const getDocumentsUploadTotalCount = createSelector(getCasesState, s => s.documents.upload.totalCount);
const getDocumentsUploadStepsCompleted = createSelector(getCasesState, s => s.documents.upload.currentIndex);
const getDocumentsUploadIsCompleted = createSelector(getCasesState, s => s.documents.upload.completed);

const getDocumentsUploadProcessing = createSelector(getCasesState, s => s.documents.upload.processing);

const getIsLoadingFuelSubtypes = createSelector(getCasesState, s => s.fuelSubtypes.isLoading);
const getIsProcessingFuelSubtypes = createSelector(getCasesState, s => s.fuelSubtypes.isProcessing);
const getFuelSubtypes = createSelector(getCasesState, s =>
  s.fuelSubtypes.list?.slice().sort((a, b) => a.name?.localeCompare(b.name))
);

const getIsLoadingEmissionInfo = createSelector(getCasesState, s => s.emissionInfo.isLoading);
const getEmissionInfo = createSelector(getCasesState, s => s.emissionInfo.item);

const getRoadTaxState = createSelector(getCasesState, s => s.roadTax);
const getRoadTax = createSelector(getRoadTaxState, s => s.item);

const getReturnReportState = createSelector(getCasesState, s => s.returnReport);
const getReturnReport = createSelector(getReturnReportState, s => s.report);
const getIsReturnReportProcessing = createSelector(getReturnReportState, s => s.processing);
const getIsReturnReportModalOpen = createSelector(getReturnReportState, s => s.open);

const getIsValuating = createSelector(getCasesState, caseState => caseState.isValuating);
const getValuationAttemptFailed = createSelector(getCasesState, caseState => caseState.valuationAttemptFailed);
const getIsOutOfValuationRange = createSelector(getActiveCaseData, caseData =>
  caseData ? caseData.isOutOfValuationRange : null
);

const getIsValuationNotAvailable = createSelector(
  getCasesState,
  caseState =>
    caseState.valuationAttemptFailed ||
    caseState.activeCaseData?.isOldVehicle ||
    caseState.activeCaseData?.isOutOfValuationRange ||
    caseState.activeCaseData?.isNewVehicle
);

const getIsUpdatingGalleryFromTwinner = createSelector(getCasesState, s => s.isUpdatingGalleryFromTwinner);
const getIsUpdatedGalleryFromTwinner = createSelector(getCasesState, s => s.isUpdatedGalleryFromTwinner);

const getIsEquipmentsValid = createSelector(getCasesState, s => s.activeCaseEquipmentsValid);

const getProvenanceCheck = createSelector(getCasesState, s => {
  return s[PROVENANCE_CHECK_FEATURE_KEY];
});

const getOwners = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) =>
  activeCaseData ? activeCaseData.owners : null
);
const getOwnersLength = createSelector(getOwners, (owners: Array<CustomerCaseListItemDto>) =>
  owners ? owners.length : 0
);

const getIsProcessingCaseOwnersList = createSelector(getCasesState, state => {
  return state.isProcessingCaseOwnersList;
});

const getPreviousOwners = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) =>
  activeCaseData ? activeCaseData.previousOwners : null
);
const getPreviousOwnersLength = createSelector(getPreviousOwners, (previousOwners: Array<CustomerCaseListItemDto>) =>
  previousOwners ? previousOwners.length : 0
);

const getIsProcessingPreviousCaseOwnersList = createSelector(getCasesState, state => {
  return state.isProcessingPreviousCaseOwnersList;
});

const getIsProcessingProspectCaseOwnersList = createSelector(getCasesState, state => {
  return state.isProcessingProspectCaseOwnersList;
});

const getProspectCustomers = createSelector(getActiveCaseData, (activeCaseData: CaseSummaryDto) =>
  activeCaseData ? activeCaseData.prospectCustomers : null
);
const getProspectCustomersLength = createSelector(
  getProspectCustomers,
  (prospectCustomers: Array<CustomerCaseListItemDto>) => (prospectCustomers ? prospectCustomers.length : 0)
);

const getCaseVinEreDataLoading = createSelector(
  getActiveCaseData,
  caseState => caseState?.vinEreDataStatus === VinEreDataStatus.Pending
);

const getIsAllVinEquipmentsSelected = createSelector(getActiveCaseData, s => {
  if (s.identificationMethod === IdentificationMethod.VIN) {
    const equipmentsEnabledByVin = s.vehicle?.equipments?.filter(equipment => equipment.isEnabledByVin);
    return equipmentsEnabledByVin?.length ? equipmentsEnabledByVin.every(equipment => equipment.isEnabled) : false;
  } else {
    return false;
  }
});

const getActiveCaseBlackBoxPricingDetailsLoading = createSelector(
  getCasesState,
  (state: CasesState) => state.activeCaseBlackBoxPricingDetails.loading
);
const getActiveCaseBlackBoxPricingDetailsData = createSelector(
  getCasesState,
  (state: CasesState) => state.activeCaseBlackBoxPricingDetails.data
);

const getIsUpdatingBuildabilityData = createSelector(
  getCasesState,
  (state: CasesState) => state.isUpdatingBuildabilityData
);

const getCanUserMakeValuation = createSelector(
  getCasesState,
  (state: CasesState) => state.activeCaseData.canUserMakeValuation
);

const getIsCaseIdentifiedByVin = createSelector(
  getActiveCaseData,
  (activeCaseData: CaseSummaryDto) => activeCaseData?.identificationMethod === IdentificationMethod.VIN
);

const getActiveCaseVehicleEngineFuel = createSelector(getCasesState, x => x.activeCaseVehicleEngineFuel);

const getIsEquipmentEnabledBySoaCode = (soaCode: string) =>
  createSelector(getCasesState, (state: CasesState) => {
    return state.activeCaseData?.vehicle?.equipments?.find(x => x.soaCode === soaCode)?.isEnabled;
  });

const getIsPackageEquipmentEnabledBySoaCode = (packageSoaCode: string, contentSoaCode: string) =>
  createSelector(getCasesState, (state: CasesState) => {
    return state.activeCaseData?.vehicle?.equipments
      ?.find(x => x.soaCode === packageSoaCode)
      ?.content?.find(y => y.soaCode === contentSoaCode)?.isEnabled;
  });

export const casesQuery = {
  getLoaded,
  getLoading,
  getError,
  getAllCases,
  getAllValuationItems,
  getAllStockItems,
  getSelectedCases,
  getIsUpdatingVin,
  getIsUpdatingVinError,
  getIsUpdatingMatriculationNumber,
  getIsUpdatingMatriculationNumberError,
  getIsUpdatingKba,
  getIsUpdatingMileage,
  getIsUpdatingRegDate,
  getIsUpdatingValuationDate,
  getIsUpdateValuationDataModalOpen,
  getActiveCaseId,
  getActiveCaseNotes,
  getActiveCaseData,
  getIsCaseValuationForecasted,
  getActiveCaseStatus,
  getValuationUniqueData,
  getStockUniqueData,
  getSoldUniqueData,
  getUniqueDataManufacturerColor,
  getUniqueDataManufacturerOriginalColor,
  getIsPurchaseProcessing,
  getPurchaseModalOpen,
  getActiveCaseVehicleEquipment,
  getIsReservingVehicle,
  getIsProcessingSellVehicle,
  getIsSellModalOpen,
  getLightDamageEstimatorAreas,
  getLightDamageEstimatorLoading,
  getLightDamageEstimatorSaving,
  getLightDamageEstimatorSaved,
  getLightDamageEstimatorTotal,
  getRepairEstimatorTotal,
  getPublicSiteOption,
  isUpdatingPublicSite,
  getIsProcessingForm,
  getHasFormProcessingError,
  getReservation,
  getCaseQrCode,
  isReserveModalOpen,
  isReservationViewModalOpen,
  getIsCancellingReservation,
  getIsUpdatingValuationUniqueData,
  getIsUniqueDataModalOpen,
  getIsUpdatingTechnicalData,
  getIsTechnicalDataModalOpen,
  getDocumentsLoaded,
  getDocumentsLoading,
  getAllDocuments,
  getCaseDocumentsTotalSize,
  getIsCaseDocumentProcessingForm,
  getHasCaseDocumentFormProcessingError,
  getRetailMarketDetails,
  getRetailMarketDetailsLiveRetailPrice,
  isReturnToStockModalOpen,
  getActiveCaseDataTaxes,
  isReturningVehicleToStock,
  getActiveCaseDataTaxedPrice,
  getActiveCaseDataPurchasedTime,
  getValuationVersionAtPurchase,
  getActiveCaseNotesData,
  getDateOfAcceptance,
  getYoutubeUrl,
  getDocumentsTotalSize,
  getIsNewVehicle,
  getIsOldVehicle,
  getIsOutOfValuationRange,
  getIsResellerSiteConfigurationModalOpen,
  getHasPublishToResellerProcessingError,
  getIsPublishToResellerProcessing,
  getIsPublishToResellerProcessingCompleted,
  getCO2EmissionStickersList,
  getActiveCaseCO2EmissionEfficiencyClass,
  getActiveCaseCO2EmissionEfficiencyClassImage,
  getIsLoadingCO2EmissionStickers,
  getActiveCaseManufacturerColorList,
  getHistoricalPrices,
  getIsMoveToStockModalOpen,
  getIsProcessingMoveToStock,
  getIsStockOfferModalOpen,
  getIsProcessingStockOffer,
  getStockOfferUsedCustomerId,
  getIsQuoteModalOpen,
  getIsRegisterNewModalOpen,
  getIsProcessingQuote,
  getRetailMarketLoading,
  getIsUpdatingPricingDetails,
  getIsPricingDetailsModalOpen,
  getIsUpdatingPlateNo,
  getIsUpdatingPlateNoError,
  getIsResendingCaseLatestQuote,
  getIsResendingCaseLatestOffer,
  getDocumentsUploadCurrentIndex,
  getDocumentsUploadTotalCount,
  getDocumentsUploadIsCompleted,
  getDocumentsUploadStepsCompleted,
  getDocumentsUploadProcessing,
  getIsLoadingFuelSubtypes,
  getIsProcessingFuelSubtypes,
  getFuelSubtypes,
  getIsLoadingEmissionInfo,
  getEmissionInfo,
  getRoadTax,
  getIsValuating,
  getValuationAttemptFailed,
  getIsValuationNotAvailable,
  getTechnicalData,
  getReturnReport,
  getIsReturnReportProcessing,
  getIsReturnReportModalOpen,
  getIsUpdatingGalleryFromTwinner,
  getIsUpdatedGalleryFromTwinner,
  getIsEquipmentsValid,
  getIsProcessingCaseOwnersList,
  getIsProcessingPreviousCaseOwnersList,
  getIsProcessingProspectCaseOwnersList,
  getProvenanceCheck,
  getIsCaseDocumentUploadNotProcessingAndNotCompleted,
  getOwners,
  getOwnersLength,
  getPreviousOwners,
  getPreviousOwnersLength,
  getProspectCustomers,
  getProspectCustomersLength,
  getCaseVinEreDataLoading,
  getIsAllVinEquipmentsSelected,
  getActiveCaseBlackBoxPricingDetailsLoading,
  getActiveCaseBlackBoxPricingDetailsData,
  getIsUpdatingBuildabilityData,
  getCanUserMakeValuation,
  getIsCaseIdentifiedByVin,
  getIsEquipmentEnabledBySoaCode,
  getIsPackageEquipmentEnabledBySoaCode,
  getActiveCaseVehicleEngineFuel,
  getRegisterNewModalProcessing
};

function getAdjustmentValue(
  activeCase: CaseSummaryDto,
  type: ValuationAdjustmentType,
  defaultValue: number = null
): number {
  if (!activeCase || !activeCase.adjustments) {
    return defaultValue;
  } else {
    const adjustment: AmountAdjustment = <AmountAdjustment>activeCase.adjustments.find(adj => adj.type === type);
    return adjustment && !isNil(adjustment.deductionAmount) ? adjustment.deductionAmount : defaultValue;
  }
}

function mapLiveRetailDetails(
  liveRetailDetails: LiveRetailInsightsDto,
  isOldVehicle: boolean,
  isOutOfValuationRange: boolean,
  isNewVehicle: boolean
) {

  // New vehicles have no live retail
  if (isNewVehicle) return null;

  if (!isOldVehicle && !isOutOfValuationRange) {
    // Sometimes desirability is null, sometimes is '-'. It should always be mapped to null
    if (
      !isNil(liveRetailDetails) &&
      (isNil(liveRetailDetails?.desirability) || liveRetailDetails.desirability === '-')
    ) {
      return {
        ...liveRetailDetails,
        desirability: null,
      };
    }
    return liveRetailDetails;
  }
  // For old and out of valuation range vehicles we don't display live retail data
  return null;
}
