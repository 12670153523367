import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { UsersPartialState } from './users.reducer';
import { usersQuery } from './users.selectors';
import * as usersActions from './users.actions';
import { CreateUserCommand, UpdateUserCommand } from '@vpfa/rest-api/admin';
import { ResetUserPasswordCommand } from '../models/reset-user-password-command';

@Injectable()
export class UsersFacade {
  businessId: string;

  createUserCreating$ = this.store.pipe(select(usersQuery.getCreateUserCreating));
  createUserAddUserModalOpen$ = this.store.pipe(select(usersQuery.getCreateUserAddUserModalOpen));
  createUserBusinessName$ = this.store.pipe(select(usersQuery.getCreateUserBusinessName));
  createUserBranchesSelectOptions$ = this.store.pipe(select(usersQuery.getCreateUserBranchesSelectOptions));
  createUserUserGroupsSelectOptions$ = this.store.pipe(select(usersQuery.getCreateUserUserGroupsSelectOptions));
  createUserDefaultUserGroupSelectOption$ = this.store.pipe(
    select(usersQuery.getCreateUserDefaultUserGroupSelectOption)
  );
  usersLoaded$ = this.store.pipe(select(usersQuery.getUsersLoaded));
  usersLoading$ = this.store.pipe(select(usersQuery.getUsersLoading));
  allUsers$ = this.store.pipe(select(usersQuery.getAllUsers));
  userDetails$ = this.store.pipe(select(usersQuery.getUserDetailsEntity));
  userDetailsLoading$ = this.store.pipe(select(usersQuery.getUserDetailsLoading));
  userUpdatingActiveStatus$ = this.store.pipe(select(usersQuery.getUpdatingActiveStatus));
  userDetailsEditing$ = this.store.pipe(select(usersQuery.getUserDetailsEditing));
  userDetailsEditUserModalOpen$ = this.store.pipe(select(usersQuery.getUserDetailsEditUserModalOpen));
  userDetailsEditUserBranchesSelectOptions$ = this.store.pipe(
    select(usersQuery.getUserDetailsEditUserBranchesSelectOptions)
  );
  userDetailsEditUserUserGroupsSelectOptions$ = this.store.pipe(
    select(usersQuery.getUserDetailsEditUserUserGroupsSelectOptions)
  );

  isResettingPassword$ = this.store.pipe(select(usersQuery.isResettingPassword));

  constructor(private store: Store<UsersPartialState>) {}

  resetUsersData() {
    this.store.dispatch(new usersActions.ResetUsersData());
  }

  loadUsersGlobal() {
    this.store.dispatch(new usersActions.LoadUsersGlobal());
  }

  loadUsersByCountry(countryId: string) {
    this.store.dispatch(new usersActions.LoadUsersByCountry(countryId));
  }

  loadUsersByBusiness(businessId: string) {
    this.store.dispatch(new usersActions.LoadUsersByBusiness(businessId));
  }

  loadUsersByUsersBusiness() {
    this.store.dispatch(new usersActions.LoadUsersByUsersBusiness());
  }

  loadUserData(id: string): void {
    this.store.dispatch(new usersActions.LoadUserDetails(id));
  }

  addUser(businessId: string, user: CreateUserCommand) {
    this.store.dispatch(new usersActions.AddUser({ businessId: businessId, createUserCommand: user }));
  }

  openAddUserModal() {
    this.store.dispatch(new usersActions.OpenAddUserModal());
  }

  closeAddUserModal() {
    this.store.dispatch(new usersActions.CloseAddUserModal());
  }

  editUser(user: UpdateUserCommand) {
    this.store.dispatch(new usersActions.EditUser(user));
  }

  openEditUserModal() {
    this.store.dispatch(new usersActions.OpenEditUserModal());
  }

  closeEditUserModal() {
    this.store.dispatch(new usersActions.CloseEditUserModal());
  }

  updateUserActiveStatus(userId: string, isActive: boolean) {
    this.store.dispatch(
      new usersActions.UpdateUserActiveStatus({
        isActive,
        subjectId: userId,
      })
    );
  }

  resetUserPassword(command: ResetUserPasswordCommand) {
    this.store.dispatch(new usersActions.ResetUserPassword(command));
  }

  resetUserPasswordByAdmin(subjectId: string) {
    this.store.dispatch(new usersActions.ResetUserPasswordByAdmin({ subjectId }));
  }
}
