import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CountryViewService } from '@vpfa/rest-api/admin';
import {
  AuthLocaleActionTypes,
  LoadCountry,
  LoadCountryError,
  LoadCountryLogoAndHelp,
  LoadCountryLogoAndHelpError,
  LoadCountryLogoAndHelpSuccess,
  LoadCountrySuccess,
} from '../actions/locale.actions';
import { LanguageEnum } from '@vpfa/shared/translate';
import { LocaleFacade } from '@vpfa/locale';
import { LogoFacade } from '@vpfa/logo/data';
import { isNil } from 'lodash';

@Injectable()
export class LocaleEffects {
  constructor(
    private actions$: Actions,
    private countryViewService: CountryViewService,
    private localeFacade: LocaleFacade,
    private logoFacade: LogoFacade
  ) {}

  
  loadCountry$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountry>(AuthLocaleActionTypes.LoadCountry),
    switchMap(action =>
      this.countryViewService.getUrlCountry(action.payload.countryUrl).pipe(
        map(res => new LoadCountrySuccess({ data: res })),
        catchError(() => of(new LoadCountryError()))
      )
    )
  ));

  
  loadCountryLogoAndHelp$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryLogoAndHelp>(AuthLocaleActionTypes.LoadCountryLogoAndHelp),
    switchMap(action =>
      this.countryViewService.getUrlCountry(action.payload.countryUrl).pipe(
        map(res => new LoadCountryLogoAndHelpSuccess({ data: res })),
        catchError(() => of(new LoadCountryLogoAndHelpError()))
      )
    )
  ));

  
  loadCountryLogoAndHelpSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryLogoAndHelpSuccess>(AuthLocaleActionTypes.LoadCountryLogoAndHelp),
    filter(action => !isNil(action?.payload?.data?.countryId)),
    tap(action => this.logoFacade.loadLogoByCountry(action.payload.data.countryId))
  ), { dispatch: false });

  
  loadCountrySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountrySuccess>(AuthLocaleActionTypes.LoadCountrySuccess),
    tap(action => {
      // TODO: LanguageService is also observing language changes, so maybe this is not required?
      this.localeFacade.selectUILanguage(action.payload.data.defaultLanguage as LanguageEnum);
      this.localeFacade.selectContentLocale(action.payload.data.defaultContentLanguage as LanguageEnum);
      this.logoFacade.loadLogoByCountry(action.payload.data.countryId);
    })
  ), { dispatch: false });

  
  loadCountryError$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryError>(AuthLocaleActionTypes.LoadCountryError),
    tap(() => {
      this.localeFacade.selectUILanguage(LanguageEnum.EN);
      this.localeFacade.selectContentLocale(LanguageEnum.EN);
    })
  ), { dispatch: false });
}
