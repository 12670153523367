import { Component, EventEmitter } from '@angular/core';
import { DynamicComponentStack } from '@vpfa/shared/dynamic-components-stack';

@Component({
  selector: 'vpfa-mobile-copyright',
  templateUrl: './mobile-copyright.component.html',
  styleUrls: ['./mobile-copyright.component.scss'],
})
export class MobileCopyrightComponent implements DynamicComponentStack {
  close = new EventEmitter();
  
  onClose(){
    this.close.emit();
  }
}
