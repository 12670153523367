import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_FEATURE_KEY, AppState } from './app.reducer';

// Lookup the 'App' feature state managed by NgRx
const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

const getLoaded = createSelector(getAppState, (state: AppState) => state.loaded);
const getError = createSelector(getAppState, (state: AppState) => state.error);

const getIsDealerApp = createSelector(getAppState, (state: AppState) => state.isDealerApp);
const getIsAdminApp = createSelector(getAppState, (state: AppState) => state.isAdminApp);
const getIsNavigationInProgress = createSelector(getAppState, (state: AppState) => state.isNavigationInProgress);

export const appQuery = {
  getLoaded,
  getError,
  getIsDealerApp,
  getIsAdminApp,
  getIsNavigationInProgress,
};
