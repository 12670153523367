import { Component, Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { GroupLabels, SelectOption } from '@vpfa/ui-kit';
import { checkObjectTypeByKeys, WithOptional } from '@vpfa/utils';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  BranchSelectListItemDto,
  CaseSummaryDto,
  CustomerCaseListItemDto,
  CustomerListItemDto,
} from '@vpfa/rest-api/valuation';
import { sortBy } from 'lodash';
import { getCustomerSorting } from '../../utils/customers-utils';
import { NzFilterOptionType, NzSelectItemInterface } from 'ng-zorro-antd/select';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { NzModalService } from 'ng-zorro-antd/modal';

export type AddRemoveCustomerListItem = WithOptional<
  CustomerListItemDto,
  | 'branchId'
  | 'country'
  | 'city'
  | 'department'
  | 'notes'
  | 'position'
  | 'postcode'
  | 'street'
  | 'address'
  | 'vatRegistered'
  | 'formOfAddress'
> & { isMain?: boolean };

const getOptionText = (el: AddRemoveCustomerListItem) => ` ${el.lastName} ${el.firstName || ''}`;

@Directive()
export class BaseAddRemoveCustomerComponent implements OnDestroy {
  @Input() idPrefix = '';
  @Input() isLoadingData: boolean;
  @Input() isLoadingCaseOwners: boolean;
  @Input() disabled: boolean;
  @Input() showEditCustomer = false;
  @Input() placeholderCode = 'customers.addRemoveCustomerList.selectOwner';
  @Input() selectLabel = 'selectOwner';
  @Input() set prospectCustomers(
    value: Array<AddRemoveCustomerListItem & Pick<CustomerCaseListItemDto, 'customerId'>>,
  ) {
    value = value.map(c => ({ id: c.customerId, ...c }));
    this.prospectCustomersList$.next(sortBy(value, getCustomerSorting));
  }
  @Input() showGroups = false;
  @Input() set initiallySelectedCustomers(setValue: CaseSummaryDto['owners']) {
    if (!setValue) {
      return;
    }

    let mappedValue: AddRemoveCustomerListItem[] = setValue.map(
      (el: CustomerCaseListItemDto) =>
        <AddRemoveCustomerListItem>{
          company: el.company,
          id: el.customerId,
          emails: el.emails,
          firstName: el.firstName,
          lastName: el.lastName,
          phoneNumbers: el.phoneNumbers,
          address: el.address,
          city: el.city,
          street: el.street,
          isMain: el.isMain,
        },
    );
    this.selectedCustomersList$.next(sortBy(mappedValue, getCustomerSorting));
  }

  @Input() set customerList(setValue: AddRemoveCustomerListItem[]) {
    if (setValue) {
      this.customerList$.next(setValue);
    }
  }

  @Input() set branchList(branchList: BranchSelectListItemDto[]) {
    if (branchList) {
      let mappedBranches = branchList.map(branch => this.mapBranchToOptions(branch));
      this.branchesListOptions$.next(mappedBranches);
    }
  }
  @Input() useInfiniteScroll: boolean;

  @Output() loadNextPage = new EventEmitter();
  @Output() onSearch = new EventEmitter<any>();
  @Output() listChange = new EventEmitter<AddRemoveCustomerListItem[]>();

  onDestroy$ = new Subject<void>();
  selectedCustomersList$ = new BehaviorSubject<AddRemoveCustomerListItem[]>([]);
  customerList$ = new BehaviorSubject<AddRemoveCustomerListItem[]>([]);
  prospectCustomersList$ = new BehaviorSubject<AddRemoveCustomerListItem[]>([]);
  branchesListOptions$ = new BehaviorSubject<SelectOption[]>([]);
  selectedBranch$ = new BehaviorSubject<BranchSelectListItemDto>(null);

  customerListOptions$: Observable<SelectOption[]> = combineLatest([
    this.selectedCustomersList$,
    this.customerList$,
  ]).pipe(
    map(([buyerList, customerList]) =>
      customerList
        .filter(el => !buyerList.some(buyer => buyer.id === el.id))
        .map(customer => ({ name: getOptionText(customer), value: customer.id, additional: customer })),
    ),
    takeUntil(this.onDestroy$),
  );

  groupedCustomerListOptions$: Observable<SelectOption[]> = combineLatest([
    this.selectedCustomersList$,
    this.prospectCustomersList$,
    this.customerListOptions$,
    this.branchesListOptions$,
  ]).pipe(
    map(([selectedCustomers, prospects, customers, branches]) => {
      const customersGroupWithoutProspects: SelectOption[] = customers
        .filter(customer => !prospects.some(prospect => prospect.id === customer.value))
        .map(customer => ({ ...customer, groupLabel: GroupLabels.customers }));
      const prospectsGroupWithoutSelectedCustomers: SelectOption[] = prospects
        .filter(prospect => !selectedCustomers.some(buyer => buyer.id === prospect.id))
        .map(prospect => ({
          name: getOptionText(prospect),
          value: prospect.id,
          additional: prospect,
          groupLabel: GroupLabels.prospects,
        }));
      return [...branches, ...prospectsGroupWithoutSelectedCustomers, ...customersGroupWithoutProspects];
    }),
    takeUntil(this.onDestroy$),
  );

  constructor(protected customerFacade: CustomersFacade) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onLoadNextPage() {
    this.loadNextPage.emit();
  }

  customerSearchFilter: NzFilterOptionType = (input: string, option: NzSelectItemInterface) => {
    return option.nzValue?.name?.toLowerCase().includes(input.toLowerCase(), 0);
  };

  removeCustomer(item: AddRemoveCustomerListItem) {
    this.selectedCustomersList$.next([...this.selectedCustomersList$.value.filter(el => el.id !== item.id)]);
    this.listChange.emit(this.selectedCustomersList$.getValue());
  }

  getStreetAndAddress(item: AddRemoveCustomerListItem) {
    let items = [];
    if (item.street) items.push(item.street);
    if (item.address) items.push(item.address);

    return items.length ? items.join(', ') : null;
  }

  private mapBranchToOptions(branch: BranchSelectListItemDto): SelectOption {
    return <SelectOption>{
      name: branch.branchName,
      groupLabel: GroupLabels.branches,
      value: branch.branchId,
      additional: branch,
    };
  }

  isCustomer(object: AddRemoveCustomerListItem | BranchSelectListItemDto) {
    return checkObjectTypeByKeys<AddRemoveCustomerListItem>(object, [
      'lastName',
      'firstName',
      'street',
      'address',
      'city',
    ]);
  }

  isBranch(object: AddRemoveCustomerListItem | BranchSelectListItemDto) {
    return checkObjectTypeByKeys<BranchSelectListItemDto>(object, ['branchId', 'branchName']);
  }
}
