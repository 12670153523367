import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UnauthorizedUrl } from '../+state/actions/auth.actions';
import { isInBlackList } from './auth-interceptor-blacklist';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private store: Store<any>,
    private router: Router,
    private notification: BasicNotificationsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (req.url.startsWith(environment.apiGateway) && !this.isRequestInBlackList(req)) {
          this.handleError(error);
        }
        return throwError(error);
      }) as any
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.notification.error(`vpError.Unauthorized`);
      this.store.dispatch(new UnauthorizedUrl(this.router.url));
    }
  }

  private isRequestInBlackList(req: HttpRequest<any>): boolean{
    return isInBlackList(req.url.split(`${environment.apiGateway}/`)[1])
  }
}
