<footer fxLayout="column" ngClass.lt-mv="mobile">
  <div
    class="container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayout.lt-mv="column"
    fxLayoutAlign.lt-mv="center center"
  >
    <a [href]="groupSiteUrl" class="img-link">
      <img src="/assets/autovista-group-logo.svg" alt="" />
    </a>
    <div fxLayout="column" fxLayoutAlign="center center" class="no-print" *ngIf="isGitInformationVisible">
        <div>
          <a [href]="frontendCommitUrl" target="_blank" [title]="FRONTEND_BRANCH + '@' + FRONTEND_COMMITHASH"
            >Frontend</a
          >
        </div>
        <div
          *ngIf="BACKEND_COMMITHASH"
          nz-tooltip
          [nzTooltipOverlayClassName]="'tooltip'"
          [nzTooltipTrigger]="'click'"
          [nzTooltipPlacement]="'top'"
          [nzTooltipTitle]="backend"
        >
          <a>Backend</a>
        </div>
    </div>
    <div *ngIf="showKernelData$ | async">
      <span>{{ 'common.dataVersion' | translate }}: {{ (valuationKernel$ | async)?.kernelVersion | lT }}</span>
      <span *ngIf="(valuationKernel$ | async)?.kernelLastUpdateDate as valuationDate"
        >, {{ valuationDate | dateLocale: 'shortDate':null:null:true | lT }}</span
      >
    </div>
    <div fxLayout="column" fxLayoutAlign.lt-mv="center center" fxLayoutAlign="center end" (click)="onCopyrightClick()">
      <p class="copyright">© {{ currentYear }} Autovista Group</p>
      <a>{{ 'copyright.title' | translate }}</a>
    </div>
  </div>
</footer>

<vpfa-simple-modal
  modalName="Copyright"
  *vpfaResponsiveIf="'web'"
  [showBottomClose]="true"
  [isVisible]="isVisible"
  (closeModal)="closeCopyright()"
>
  <div>
    <h1>{{ 'copyright.title' | translate }}</h1>
    <p [innerHTML]="'copyright.content' | translate"></p>
  </div>
</vpfa-simple-modal>

<ng-template #backend>
  <div *ngFor="let item of BACKEND_COMMITHASH" class="be-services">
    <a [href]="backendCommitUrl + item.commitId" target="_blank" [title]="item.branch + '@' + item.commitId">
      {{ item.service }}
    </a>
  </div>
</ng-template>
