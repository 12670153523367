import { Component, ViewChild, TemplateRef } from '@angular/core';

@Component({
  template: `
    <ng-template #nzDatePickerSuffixTpl>
      <i class="zmdi zmdi-calendar-alt" style="margin-right:2px;"></i>
    </ng-template>
  `,
})
export class GlobalTemplatesComponent {
  @ViewChild('nzDatePickerSuffixTpl', { static: true })
  nzDatePickerSuffixTpl!: TemplateRef<void>;
}
