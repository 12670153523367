import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { CustomerDto, UpdateCustomerCommand } from '@vpfa/rest-api/valuation';
import { CustomerFormModel } from '@vpfa/dealer/customers/shared';
import { UserContext } from '@vpfa/rest-api/admin';
import { ProfileFacade } from '@vpfa/profile/data';
import { merge, Subject } from 'rxjs';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { UsersFacade } from '@vpfa/admin/users-data';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';
import { DynamicComponentStack } from '@vpfa/shared/dynamic-components-stack';

@Component({
  selector: 'vpfa-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
})
export class CustomerEditComponent implements DynamicComponentStack, OnInit, OnDestroy {
  @Input() show: boolean;
  @Input() set customer(customerModel: CustomerDto) {
    if (!customerModel) {
      this.customerFormModel = undefined;
      this.id = undefined;
      return;
    }

    const { id, changedOn, ...formModel } = customerModel;
    this.customerFormModel = formModel;
    this.id = id;
  }
  @Output() onClose = new EventEmitter<void>();

  isLoading$ = merge(this.customersFacade.updateCustomerModalProcessing$, this.customersFacade.detailsLoading$);

  customerFormsOfAddress$ = this.customersFacade.customerFormsOfAddress$;
  customerFormsOfAddressLoading$ = this.customersFacade.customerFormsOfAddressLoading$;
  allUsers$ = this.usersFacade.allUsers$;
  allUsersLoading$ = this.usersFacade.usersLoading$;

  customerFormModel: CustomerFormModel;
  private id: string;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private customersFacade: CustomersFacade,
    private profileFacade: ProfileFacade,
    private usersFacade: UsersFacade,
    private scrollService: ScrollToTopService,
  ) {}

  // for mobile view
  close = new EventEmitter<any>();

  ngOnInit() {
    this.profileFacade.userContext$.pipe(takeUntil(this._onDestroy$)).subscribe((value: UserContext) => {
      this.customersFacade.loadFormsOfAddress(value.countryCode);
      this.usersFacade.loadUsersByUsersBusiness();
    });

    // Close in mobile view after customer updated
    this.customersFacade.updateCustomerModalOpen$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => prev === true && curr === false),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        this.close.emit();
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  closeModal() {
    this.onClose.emit();
    this.close.emit();
  }

  updateCustomer(customer: CustomerFormModel) {
    const command = this.convertFormToCommand(customer);
    this.customersFacade.updateCustomer(command);
  }

  private convertFormToCommand(customerForm: CustomerFormModel): UpdateCustomerCommand {
    return {
      ...customerForm,
      vatRegistered: Boolean(customerForm.vatRegistered),
      aggregateRootId: this.id,
    };
  }
}
