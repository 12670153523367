import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule, MediaMarshaller } from '@ngbracket/ngx-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocaleModule } from '@vpfa/locale';
import { BasicNotificationsService, SharedNotificationsModule } from '@vpfa/shared/notifications';
import { CookieService } from 'ngx-cookie-service';
import { en_GB, NZ_DATE_LOCALE, NZ_I18N, NzI18nModule } from 'ng-zorro-antd/i18n';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApp from './+state/app.reducer';
import { AppEffects } from './+state/app.effects';
import { AppFacade } from './+state/app.facade';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@env/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthModule } from '@vpfa/auth';
import { AppRoutingModule } from './app-routing.module';
import { RestApiAdminModule } from '@vpfa/rest-api/admin';
import { RestApiValuationModule } from '@vpfa/rest-api/valuation';
import { RestApiIdentificationModule } from '@vpfa/rest-api/identification';
import { ManageStorageService } from 'libs/auth/src/lib/utils/manage-storage.service';
import { DealerDictionariesModule } from '@vpfa/dealer/dictionaries';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { BreadcrumbModule } from 'angular-crumbs';
import { ApiExceptionInterceptor } from './interceptors/api-exception.interceptor';
import { ProfileDataModule } from '@vpfa/profile/data';
import { MixPanelInterceptor } from './interceptors/mix-panel.interceptor';
import { RestApiResellerNotificationModule } from '@vpfa/rest-api/reseller-notification';
import { LogoDataModule } from '@vpfa/logo/data';
import { RestApiAdBroadcastModule } from '@vpfa/rest-api/ad-broadcast';
import { RestApiGenericTransformModule } from '@vpfa/rest-api/generic-transform';
import {
  DeploymentVersionService,
  EnvironmentsDataModule,
  environmentsInitializer,
  EnvironmentsService,
} from '@vpfa/environments/data';
import { PermissionsDataModule } from '@vpfa/permissions/data';
import { RestApiVehicleInsuranceClaimingModule } from '@vpfa/rest-api/vehicle-insurance-claiming';
import { SharedComponentsModule } from '@vpfa/shared/components';
import { SharedUserProfileDataModule } from '@vpfa/shared/user-profile/data';
import { GlobalTemplatesComponent } from './components/global-templates.component';
import { nzConfigProvider } from './utils/nz-config-factory';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { ModalsModule } from '@vpfa/modals';
import { LiveRetailInterceptor } from '@vpfa/dealer/radar';
import { SharedFooterModule } from '@vpfa/shared/footer';
import { DealerCustomersDataModule } from '@vpfa/dealer/customers/data';
import { RestApiVersionsModule } from '@vpfa/rest-api/versions';
import { OAuthDataModule } from '@vpfa/oauth/data';
import {
  sanitizeConfirmAdvertizedImagesAction,
  sanitizeConfirmAdvertizedImagesState,
} from '@vpfa/dealer/confirm-advertised-images';
import { CacheInterceptor, ChunkErrorHandler } from '@vpfa/utils';
import { ApiUnavailableInterceptor } from './interceptors/api-unavailable.interceptor';
import { UsersDataModule } from '@vpfa/admin/users-data';
import { SorcIntegrationInterceptor, SorcIntegrationModule } from '@vpfa/sorc-integration';
import { SharedImagesDataModule } from '@vpfa/shared/images/data';
import { enGB } from 'date-fns/locale';

@NgModule({
  declarations: [AppComponent, GlobalTemplatesComponent],
  imports: [
    ModalsModule,
    NzI18nModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule.withConfig({
      printWithBreakpoints: ['gt-mv'],
    }),
    SharedMobileViewModule,
    SharedNotificationsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictStateSerializability: true,
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    LocaleModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          stateSanitizer: state => {
            state = sanitizeConfirmAdvertizedImagesState(state);
            return state;
          },
          actionSanitizer: (action, id) => {
            action = sanitizeConfirmAdvertizedImagesAction(action, id);
            return action;
          },
          connectInZone: true,
        })
      : [],
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forFeature(fromApp.APP_FEATURE_KEY, fromApp.reducer),
    EffectsModule.forRoot([AppEffects]),
    DealerDictionariesModule,
    AuthModule,
    PermissionsDataModule,
    RestApiAdminModule,
    RestApiValuationModule,
    LogoDataModule,
    ProfileDataModule,
    UsersDataModule,
    RestApiIdentificationModule,
    BreadcrumbModule,
    RestApiResellerNotificationModule,
    RestApiAdBroadcastModule,
    RestApiGenericTransformModule,
    EnvironmentsDataModule,
    RestApiVehicleInsuranceClaimingModule,
    SharedComponentsModule,
    SharedUserProfileDataModule,
    SharedLoadersModule,
    SharedFooterModule,
    DealerCustomersDataModule,
    RestApiVersionsModule,
    OAuthDataModule,
    SorcIntegrationModule,
    SharedImagesDataModule,
  ],
  providers: [
    // Fix for German date formatting: 'dd.MM.y' format: https://ng.ant.design/docs/i18n/en#how-to-format-a-date-using-date-fns
    { provide: NZ_DATE_LOCALE, useValue: enGB },
    {
      provide: NZ_I18N,
      useValue: en_GB,
    },
    BasicNotificationsService,
    AppFacade,
    CookieService,
    ManageStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiExceptionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUnavailableInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MixPanelInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: environmentsInitializer,
      multi: true,
      deps: [EnvironmentsService, DeploymentVersionService],
    },
    environment.production ? { provide: ErrorHandler, useClass: ChunkErrorHandler } : [],
    { provide: HTTP_INTERCEPTORS, useClass: LiveRetailInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SorcIntegrationInterceptor, multi: true },
    nzConfigProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private lastValue;

  public constructor(marshaller: MediaMarshaller) {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201

    marshaller['subject'].subscribe(() => {
      if (
        marshaller['activatedBreakpoints'].length &&
        marshaller['activatedBreakpoints'].filter(breakpoint => breakpoint.alias === 'print').length === 0
      ) {
        this.lastValue = [...marshaller['activatedBreakpoints']];
      } else {
        //marshaller['activatedBreakpoints'] = [...this.lastValue]; // this one also breaks the printing

        marshaller['hook'].collectActivations = () => {};

        marshaller['hook']['deactivations'] = [...this.lastValue];
      }
    });
  }
}
