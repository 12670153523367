import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { IToken } from '../../models/token';
import { ManageStorageService } from '../../utils/manage-storage.service';

import {
  AuthActionTypes,
  InitAuthEffects,
  LoadTokenFromCacheClearToken,
  LoadTokenFromCacheFailure,
  LoadTokenFromCacheSetToken,
  LoadTokenFromCacheSuccess,
} from '../actions/auth.actions';
import { ClearToken } from '../actions/token.actions';
import { AuthState } from '../auth.reducer';
import { authQuery } from '../auth.selectors';

enum LoadTokenError {
  TOKEN_IS_NOT_SET_IN_LOCAL_STORAGE = 'tokenIsNotSetInLocalStorage',
  TOKEN_IS_EXPIRED = 'tokenIsExpired',
  TOKEN_FORMAT_IS_INCORRECT = 'tokenFormatIsIncorrect',
}

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private router: Router,
    private manageStorageService: ManageStorageService
  ) {}

  
  initAuthEffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.InitAuthEffects),
    mapTo(this.manageStorageService.getToken()),
    switchMap(token => {
      if (isNil(token)) {
        return throwError(LoadTokenError.TOKEN_IS_NOT_SET_IN_LOCAL_STORAGE);
      } else {
        return of(token);
      }
    }),
    map(cachedTokenString => {
      try {
        return JSON.parse(cachedTokenString);
      } catch (error) {
        throw LoadTokenError.TOKEN_IS_NOT_SET_IN_LOCAL_STORAGE;
      }
    }),
    map((cachedToken: IToken) => {
      if (isNil(cachedToken.token) || isNil(cachedToken.decodedToken.exp)) {
        throw LoadTokenError.TOKEN_FORMAT_IS_INCORRECT;
      }

      return cachedToken;
    }),
    map((cachedToken: IToken) => {
      if (new Date() > new Date(cachedToken.decodedToken.exp * 1000)) {
        throw LoadTokenError.TOKEN_IS_EXPIRED;
      }

      return cachedToken;
    }),
    concatMap((cashedToken: IToken) => [new LoadTokenFromCacheSetToken(cashedToken), new LoadTokenFromCacheSuccess()]),
    // we can use it because it should be once
    //
    // when we use catchError then observable is change to new one
    //  and older is broke
    catchError((error: LoadTokenError | string) => {
      const failedActions: Action[] = [];
      if (Object.keys(LoadTokenError).indexOf(error) !== -1) {
        failedActions.push(new LoadTokenFromCacheClearToken());
      }

      switch (error) {
        case LoadTokenError.TOKEN_FORMAT_IS_INCORRECT:
          failedActions.push(new LoadTokenFromCacheClearToken());
          break;
      }

      failedActions.push(new LoadTokenFromCacheFailure());

      return of('').pipe(concatMap(() => failedActions));
    })
  ));

  
  unauthorizedUrl$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.UnauthorizedUrl),
    tap(unauthorizedUrl => {
      this.router.navigate(['auth/login']);
    }),
    map(() => {
      return new ClearToken();
    })
  ));

  
  init$: Observable<Action> = createEffect(() => defer(() => {
    return this.store.pipe(
      select(authQuery.getLoadedFromCacheState),
      filter(isLoaded => !isLoaded),
      mapTo(new InitAuthEffects())
    );
  }));
}
