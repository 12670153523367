import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBranches from './+state/branches.reducer';
import { BranchesEffects } from './+state/branches.effects';
import { BranchesFacade } from './+state/branches.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBranches.BRANCHES_FEATURE_KEY, fromBranches.reducer),
    EffectsModule.forFeature([BranchesEffects]),
  ],
  providers: [BranchesFacade]
})
export class AdminBranchesDataModule {}
