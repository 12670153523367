import { CustomerDto, CustomerListItemDto, OwnerVehicleRecentOfferDto } from '@vpfa/rest-api/valuation';
import { produce } from 'immer';
import { CustomersAction, CustomersActionTypes } from './customers.actions';

export const CUSTOMERS_FEATURE_KEY = 'customers';

/* tslint:disable:no-empty-interface */
export interface Entity extends CustomerListItemDto {}

export interface CreateCustomerModalState {
  open: boolean;
  processing: boolean;
}

export interface UpdateCustomerModalState {
  open: boolean;
  processing: boolean;
}

export interface CreateCaseCustomersState {
  createCaseOwnerModalOpen: boolean;
  createCasePreviousOwnerModalOpen: boolean;
  createCaseProspectCustomerModalOpen: boolean;
}

export interface CustomerDetailsState {
  customer: CustomerDto;
  loading: boolean;
  processing: boolean;
  error?: any;
}

export interface SelectedCustomerDetailsForSellModalState {
  customers: CustomerDto[];
  requestCounter: number;
  loaded: boolean;
}

export interface CustomerFormsOfAddressState {
  list: string[];
  loading: boolean;
}

export interface CustomerListState {
  list: Entity[];
  isLoaded: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  error?: any;
  selectedIds: string[];
}

export interface CustomerHasRelatedCase {
  haveRelatedCase: boolean;
  loading: boolean;
  error?: any;
}

export interface CustomerLatestQuoteListState {
  list: Array<OwnerVehicleRecentOfferDto>;
  loading: boolean;
  error?: any;
}

export interface CustomersState {
  createCustomerModal: CreateCustomerModalState;
  updateCustomerModel: UpdateCustomerModalState;
  createCaseCustomers: CreateCaseCustomersState;
  list: CustomerListState;
  pagedList: CustomerListState & { total: number };
  details: CustomerDetailsState;
  selectedForSellModalDetails: SelectedCustomerDetailsForSellModalState;
  formsOfAddress: CustomerFormsOfAddressState;
  haveRelatedCase: CustomerHasRelatedCase;
  latestQuotes: CustomerLatestQuoteListState;
}

export interface CustomersPartialState {
  readonly [CUSTOMERS_FEATURE_KEY]: CustomersState;
}

export const initialState: CustomersState = {
  createCustomerModal: {
    open: false,
    processing: false,
  },
  updateCustomerModel: {
    open: false,
    processing: false,
  },
  createCaseCustomers: {
    createCaseOwnerModalOpen: false,
    createCasePreviousOwnerModalOpen: false,
    createCaseProspectCustomerModalOpen: false,
  },
  list: {
    list: [],
    isLoaded: false,
    isLoading: false,
    isProcessing: false,
    selectedIds: [],
  },
  pagedList: {
    list: [],
    total: null,
    isLoaded: false,
    isLoading: false,
    isProcessing: false,
    selectedIds: [],
  },
  details: {
    customer: null,
    loading: false,
    processing: false,
  },
  selectedForSellModalDetails: {
    customers: [],
    requestCounter: 0,
    loaded: false,
  },
  formsOfAddress: {
    list: null,
    loading: false,
  },
  haveRelatedCase: {
    haveRelatedCase: null,
    loading: false,
  },
  latestQuotes: { error: null, list: [], loading: false },
};

export function reducer(state = initialState, action: CustomersAction): CustomersState {
  switch (action.type) {
    case CustomersActionTypes.CreateCustomer:
      return produce<CustomersState>(state, newState => {
        newState.createCustomerModal.processing = true;
      });
    case CustomersActionTypes.CustomerCreated:
      return produce<CustomersState>(state, newState => {
        newState.createCustomerModal.processing = false;
        newState.createCustomerModal.open = false;
        newState.createCaseCustomers.createCaseOwnerModalOpen = false;
        newState.createCaseCustomers.createCasePreviousOwnerModalOpen = false;
        newState.createCaseCustomers.createCaseProspectCustomerModalOpen = false;
      });
    case CustomersActionTypes.CustomerCreateError:
      return produce<CustomersState>(state, newState => {
        newState.createCustomerModal.processing = false;
      });
    case CustomersActionTypes.OpenCreateCustomerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCustomerModal.open = true;
      });
    case CustomersActionTypes.CloseCreateCustomerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCustomerModal.open = false;
      });

    case CustomersActionTypes.UpdateCustomer:
      return produce<CustomersState>(state, newState => {
        newState.updateCustomerModel.processing = true;
      });
    case CustomersActionTypes.CustomerUpdated:
      return produce<CustomersState>(state, newState => {
        if (!!state?.pagedList?.list) {
          // update customer on the list to avoid reloading
          newState.pagedList.list = state.pagedList.list.map(x => {
            if (x.id === action.id) {
              return {
                ...x,
                ...action.command,
              };
            }

            return x;
          });
        }

        newState.updateCustomerModel.processing = false;
        newState.updateCustomerModel.open = false;
      });
    case CustomersActionTypes.CustomerUpdateError:
      return produce<CustomersState>(state, newState => {
        newState.updateCustomerModel.processing = false;
      });
    case CustomersActionTypes.OpenUpdateCustomerModal:
      return produce<CustomersState>(state, newState => {
        newState.updateCustomerModel.open = true;
      });
    case CustomersActionTypes.CloseUpdateCustomerModal:
      return produce<CustomersState>(state, newState => {
        newState.updateCustomerModel.open = false;
      });

    case CustomersActionTypes.OpenCreateCaseOwnerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCaseCustomers.createCaseOwnerModalOpen = true;
      });
    case CustomersActionTypes.CloseCreateCaseOwnerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCaseCustomers.createCaseOwnerModalOpen = false;
      });
    case CustomersActionTypes.OpenCreateCasePreviousOwnerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCaseCustomers.createCasePreviousOwnerModalOpen = true;
      });
    case CustomersActionTypes.CloseCreateCasePreviousOwnerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCaseCustomers.createCasePreviousOwnerModalOpen = false;
      });
    case CustomersActionTypes.OpenCreateCaseProspectCustomerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCaseCustomers.createCaseProspectCustomerModalOpen = true;
      });
    case CustomersActionTypes.CloseCreateCaseProspectCustomerModal:
      return produce<CustomersState>(state, newState => {
        newState.createCaseCustomers.createCaseProspectCustomerModalOpen = false;
      });

    case CustomersActionTypes.LoadPagedCustomerList:
      return produce<CustomersState>(state, newState => {
        newState.pagedList.isLoading = true;
      });
    case CustomersActionTypes.PagedCustomerListLoaded:
      return produce<CustomersState>(state, newState => {
        if (!action.payload?.page) {
          newState.pagedList.list = action.payload.data;
        } else {
          newState.pagedList.list = state.pagedList.list.concat(action.payload.data);
        }

        newState.pagedList.total = action.payload.total;
        newState.pagedList.isLoaded = true;
        newState.pagedList.isLoading = false;
      });
    case CustomersActionTypes.PagedCustomerListLoadError:
      return produce<CustomersState>(state, newState => {
        newState.pagedList.isLoading = false;
      });

    case CustomersActionTypes.LoadCustomerList:
      return produce<CustomersState>(state, newState => {
        newState.list.isLoading = true;
      });
    case CustomersActionTypes.CustomerListLoaded:
      return produce<CustomersState>(state, newState => {
        newState.list.list = action.payload;
        newState.list.isLoaded = true;
        newState.list.isLoading = false;
      });
    case CustomersActionTypes.CustomerListLoadError:
      return produce<CustomersState>(state, newState => {
        newState.list.isLoading = false;
      });

    case CustomersActionTypes.CustomerListChangeSelections:
      return produce<CustomersState>(state, newState => {
        newState.list.selectedIds = action.payload;
        newState.pagedList.selectedIds = action.payload;
      });

    case CustomersActionTypes.LoadCustomerDetails:
      return produce<CustomersState>(state, newState => {
        newState.details.loading = true;
        newState.details.error = null;
      });

    case CustomersActionTypes.CustomerDetailsLoaded:
      return produce<CustomersState>(state, newState => {
        newState.details.customer = action.payload;
        newState.details.loading = false;
        newState.details.error = null;
      });
    case CustomersActionTypes.CustomerDetailsLoadError:
      return produce<CustomersState>(state, newState => {
        newState.details.error = action.payload;
        newState.details.loading = false;
      });

    case CustomersActionTypes.LoadSelectedCustomerDetailsForSellModal:
      return produce<CustomersState>(state, newState => {
        newState.selectedForSellModalDetails.loaded = false;
        newState.selectedForSellModalDetails.requestCounter++;
      });
    case CustomersActionTypes.SelectedCustomerDetailsForSellModalLoaded:
      return produce<CustomersState>(state, newState => {
        newState.selectedForSellModalDetails.customers.push(action.payload);
        newState.selectedForSellModalDetails.requestCounter--;

        if (newState.selectedForSellModalDetails.requestCounter === 0) {
          newState.selectedForSellModalDetails.loaded = true;
        }
      });
    case CustomersActionTypes.SelectedCustomerDetailsForSellModalLoadError:
      return produce<CustomersState>(state, newState => {
        newState.selectedForSellModalDetails.requestCounter--;

        if (newState.selectedForSellModalDetails.requestCounter === 0) {
          newState.selectedForSellModalDetails.loaded = true;
        }
      });

    case CustomersActionTypes.LoadHaveRelatedCase: {
      return produce<CustomersState>(state, newState => {
        newState.haveRelatedCase.loading = true;
        newState.haveRelatedCase.haveRelatedCase = null;
      });
    }
    case CustomersActionTypes.HaveRelatedCaseLoaded: {
      return produce<CustomersState>(state, newState => {
        newState.haveRelatedCase.loading = false;
        newState.haveRelatedCase.haveRelatedCase = action.payload;
      });
    }
    case CustomersActionTypes.HaveRelatedCaseError: {
      return produce<CustomersState>(state, newState => {
        newState.haveRelatedCase.loading = false;
      });
    }

    case CustomersActionTypes.DeleteSelectedCustomersFromList: {
      return produce<CustomersState>(state, newState => {
        newState.list.isProcessing = true;
      });
    }
    case CustomersActionTypes.SelectedCustomersDeleted:
    case CustomersActionTypes.SelectedCustomersDeleteError: {
      return produce<CustomersState>(state, newState => {
        newState.list.isProcessing = false;
      });
    }

    case CustomersActionTypes.DeleteCustomer: {
      return produce<CustomersState>(state, newState => {
        newState.details.processing = true;
      });
    }
    case CustomersActionTypes.CustomerDeleted:
    case CustomersActionTypes.CustomerDeleteError: {
      return produce<CustomersState>(state, newState => {
        newState.details.processing = false;
      });
    }

    case CustomersActionTypes.LoadCustomerFormsOfAddress: {
      return produce<CustomersState>(state, newState => {
        newState.formsOfAddress.loading = true;
      });
    }
    case CustomersActionTypes.CustomerFormsOfAddressLoaded: {
      return produce<CustomersState>(state, newState => {
        newState.formsOfAddress.loading = false;
        newState.formsOfAddress.list = action.payload;
      });
    }
    case CustomersActionTypes.CustomerFormsOfAddressLoadError: {
      return produce<CustomersState>(state, newState => {
        newState.formsOfAddress.loading = false;
      });
    }

    case CustomersActionTypes.LoadCustomerLatestQuoteList: {
      return produce<CustomersState>(state, newState => {
        newState.latestQuotes.loading = true;
      });
    }

    case CustomersActionTypes.CustomerLatestQuoteListLoaded: {
      return produce<CustomersState>(state, newState => {
        newState.latestQuotes.loading = false;
        newState.latestQuotes.list = action.payload;
      });
    }
    case CustomersActionTypes.CustomerLatestQuoteListLoadError: {
      return produce<CustomersState>(state, newState => {
        newState.latestQuotes.loading = false;
        newState.latestQuotes.error = action.payload;
      });
    }

    default:
      return state;
  }
}
