import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadUserContext } from '@vpfa/profile/data';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  AddContactUsEnquiryType,
  AddContactUsEnquiryTypeSuccess,
  AddCountryAlternativeContentLanguage,
  AddCountryAlternativeLanguage,
  CountriesActionTypes,
  CountryAlternativeContentLanguageAdded,
  CountryAlternativeContentLanguageError,
  CountryAlternativeContentLanguageRemoved,
  CountryAlternativeLanguageAdded,
  CountryAlternativeLanguageError,
  CountryAlternativeLanguageRemoved,
  CountryCurrencyError,
  CountryCurrencyUpdated,
  CountryCustomersListInitialSetOfFieldsLoad,
  CountryCustomersListInitialSetOfFieldsUpdate,
  CountryCustomersListInitialSetOfFieldsUpdated,
  CountryDefaultContentLanguageError,
  CountryDefaultContentLanguageUpdated,
  CountryDefaultLanguageError,
  CountryDefaultLanguageUpdated,
  CountryDetailsLoaded,
  CountryLocaleError,
  CountryLocaleUpdated,
  CountryStockListInitialSetOfFieldsLoad,
  CountryStockListInitialSetOfFieldsUpdate,
  CountryStockListInitialSetOfFieldsUpdated,
  CountryTimezoneError,
  CountryTimezoneUpdated,
  CountryValuationListInitialSetOfFieldsLoad,
  CountryValuationListInitialSetOfFieldsUpdate,
  CountryValuationListInitialSetOfFieldsUpdated,
  CreateFtpExport,
  CreateFtpExportSuccess,
  DeleteContactUsEnquiryType,
  DeleteContactUsEnquiryTypeSuccess,
  EditFtpExport,
  EditFtpExportSuccess,
  fromCountriesActions,
  LoadBroadcastCertificate,
  LoadBroadcastCertificateError,
  LoadBroadcastCertificateSuccess,
  LoadBroadcastCountryMappingList,
  LoadBroadcastDetails,
  LoadBroadcastList,
  LoadBroadcastLogo,
  LoadBroadcastLogoError,
  LoadBroadcastLogoSuccess,
  LoadCountries,
  LoadCountryDetails,
  LoadCountryGdv,
  LoadCountryTaxType,
  LoadExperianCountrySettings,
  LoadVinThreshold,
  LogoLoad,
  LogoUpload,
  LogoUploaded,
  RemoveCountryAlternativeContentLanguage,
  RemoveCountryAlternativeLanguage,
  UpdateBroadcastCertificate,
  UpdateBroadcastCertificateError,
  UpdateBroadcastCertificateSuccess,
  UpdateBroadcastHostname,
  UpdateBroadcastHostnameError,
  UpdateBroadcastIsActive,
  UpdateBroadcastIsActiveError,
  UpdateBroadcastIsActiveSuccess,
  UpdateBroadcastLogo,
  UpdateBroadcastLogoError,
  UpdateBroadcastLogoSuccess,
  UpdateBroadcastName,
  UpdateBroadcastPassword,
  UpdateBroadcastPasswordError,
  UpdateBroadcastPasswordSuccess,
  UpdateBroadcastUsername,
  UpdateBroadcastUsernameError,
  UpdateContactUsCountryCode,
  UpdateContactUsCountryCodeSuccess,
  UpdateContactUsDefaultClassification,
  UpdateContactUsDefaultClassificationSuccess,
  UpdateContactUsDefaultPriority,
  UpdateContactUsDefaultPrioritySuccess,
  UpdateContactUsDefaultType,
  UpdateContactUsDefaultTypeSuccess,
  UpdateContactUsEnquiryType,
  UpdateContactUsEnquiryTypeSuccess,
  UpdateContactUsHeaderTexts,
  UpdateContactUsHeaderTextsSuccess,
  UpdateCountryCurrency,
  UpdateCountryDefaultContentLanguage,
  UpdateCountryDefaultLanguage,
  UpdateCountryGdvPassword,
  UpdateCountryGdvPasswordSuccess,
  UpdateCountryGdvUsername,
  UpdateCountryGdvUsernameSuccess,
  UpdateCountryHotlineEmail,
  UpdateCountryHotlineEmailSuccess,
  UpdateCountryHotlinePhone,
  UpdateCountryHotlinePhoneSuccess,
  UpdateCountryLocale,
  UpdateCountryTaxType,
  UpdateCountryTaxTypeSuccess,
  UpdateCountryTimezone,
  UpdateCountryUrl,
  UpdateCountryUrlSuccess,
  UpdateExperianHostUrl,
  UpdateExperianHostUrlSuccess,
  UpdateExperianTermsAndConditionsUrl,
  UpdateExperianTermsAndConditionsUrlSuccess,
  UpdateUCNumberSourceType,
  UpdateUCNumberSourceTypeSuccess,
  UpdateVinThreshold,
  UpdateVinThresholdSuccess,
} from './countries.actions';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import {
  CountryService,
  CountryViewService,
  ExperianService,
  ExperianViewService,
  LogoService,
  LogoViewService,
  TableConfigurationViewService,
  TableService,
} from '@vpfa/rest-api/admin';
import { ExperianTermsUrlLoaded, LocaleFacade } from '@vpfa/locale';
import { CountryConfigurationService, CountryConfigurationViewService } from '@vpfa/rest-api/valuation';
import { mapFileToDataUrl } from '@vpfa/utils';
import { LanguageEnum } from '@vpfa/shared/translate';
import {
  BroadcastConnectionConfigurationService,
  BroadcastConnectionConfigurationViewService,
} from '@vpfa/rest-api/ad-broadcast';
import {
  InsuranceClaimingConfigurationService,
  InsuranceClaimingConfigurationViewService,
} from '@vpfa/rest-api/vehicle-insurance-claiming';
import { CountriesFacade } from './countries.facade';

@Injectable()
export class CountriesEffects {
   loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountries>(CountriesActionTypes.LoadCountries),
    switchMap(() =>
      this.countryViewService.getList().pipe(
        map(response => new fromCountriesActions.CountriesLoaded(response)),
        catchError(() => of(new fromCountriesActions.CountriesLoadError()))
      )
    )
  ));

   loadCountryDetails$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryDetails>(CountriesActionTypes.LoadCountryDetails),
    switchMap(action =>
      this.countryViewService.get(action.payload).pipe(
        map(res => new fromCountriesActions.CountryDetailsLoaded(res)),
        catchError(() => of(new fromCountriesActions.CountryDetailsLoadError()))
      )
    )
  ));

   updateCountryDefaultLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryDefaultLanguage>(CountriesActionTypes.UpdateCountryDefaultLanguage),
    switchMap(action => {
      return this.countryService.updateDefaultLanguage(action.payload).pipe(
        map(
          () => new fromCountriesActions.CountryDefaultLanguageUpdated(action.payload.defaultLanguage as LanguageEnum)
        ),
        catchError(err => of(new fromCountriesActions.CountryDefaultLanguageError()))
      );
    })
  ));

   updateCountryDefaultContentLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryDefaultContentLanguage>(CountriesActionTypes.UpdateCountryDefaultContentLanguage),
    switchMap(action =>
      this.countryService.updateDefaultContentLanguage(action.payload).pipe(
        map(
          () =>
            new fromCountriesActions.CountryDefaultContentLanguageUpdated(
              action.payload.defaultContentLanguage as LanguageEnum
            )
        ),
        catchError(err => of(new fromCountriesActions.CountryDefaultContentLanguageError()))
      )
    )
  ));

   CheckCountryDefaultLanguageFile$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryDefaultLanguage>(CountriesActionTypes.UpdateCountryDefaultLanguage),
    withLatestFrom(this.countriesFacade.countryCode$),
    tap(([action, countryCode]) => {
      this.localeFacade.checkTranslateFile(action.payload.defaultLanguage as LanguageEnum, countryCode);
    })
  ), { dispatch: false });

   countryValuationListInitialSetOfFieldsUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<CountryValuationListInitialSetOfFieldsUpdate>(
      CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdate
    ),
    switchMap(action =>
      this.tableService.updateTableConfiguration(action.payload.countryId, action.payload.command).pipe(
        map(
          () =>
            new fromCountriesActions.CountryValuationListInitialSetOfFieldsUpdated({
              countryId: action.payload.countryId,
              tableName: action.payload.command.tableName,
            })
        ),
        catchError(() => of(new fromCountriesActions.CountryValuationListInitialSetOfFieldsUpdateError()))
      )
    )
  ));

   countryValuationListInitialSetOfFieldsUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<CountryValuationListInitialSetOfFieldsUpdated>(
      CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdated
    ),
    tap(() => this.notification.success('admin.valuationsListConfig.updateSuccess')),
    map(
      action =>
        new fromCountriesActions.CountryValuationListInitialSetOfFieldsLoad({
          countryId: action.payload.countryId,
          tableName: action.payload.tableName,
        })
    )
  ));

   countryValuationListInitialSetOfFieldsLoad$ = createEffect(() => this.actions$.pipe(
    ofType<CountryValuationListInitialSetOfFieldsLoad>(CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoad),
    switchMap(action =>
      this.tableViewService.getCountryTableConfiguration(action.payload.tableName, action.payload.countryId).pipe(
        map(response => new fromCountriesActions.CountryValuationListInitialSetOfFieldsLoaded(response)),
        catchError(() => of(new fromCountriesActions.CountryValuationListInitialSetOfFieldsLoadError()))
      )
    )
  ));

   countryStockListInitialSetOfFieldsUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<CountryStockListInitialSetOfFieldsUpdate>(CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdate),
    switchMap(action =>
      this.tableService.updateTableConfiguration(action.payload.countryId, action.payload.command).pipe(
        map(
          () =>
            new fromCountriesActions.CountryStockListInitialSetOfFieldsUpdated({
              countryId: action.payload.countryId,
              tableName: action.payload.command.tableName,
            })
        ),
        catchError(() => of(new fromCountriesActions.CountryStockListInitialSetOfFieldsUpdateError()))
      )
    )
  ));

   countryStockListInitialSetOfFieldsUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<CountryStockListInitialSetOfFieldsUpdated>(CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdated),
    tap(() => this.notification.success('admin.stockListConfig.updateSuccess')),
    map(
      action =>
        new fromCountriesActions.CountryStockListInitialSetOfFieldsLoad({
          countryId: action.payload.countryId,
          tableName: action.payload.tableName,
        })
    )
  ));

   countryStockListInitialSetOfFieldsLoad$ = createEffect(() => this.actions$.pipe(
    ofType<CountryStockListInitialSetOfFieldsLoad>(CountriesActionTypes.CountryStockListInitialSetOfFieldsLoad),
    switchMap(action =>
      this.tableViewService.getCountryTableConfiguration(action.payload.tableName, action.payload.countryId).pipe(
        map(response => new fromCountriesActions.CountryStockListInitialSetOfFieldsLoaded(response)),
        catchError(() => of(new fromCountriesActions.CountryStockListInitialSetOfFieldsLoadError()))
      )
    )
  ));

   countryCustomersListInitialSetOfFieldsUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<CountryCustomersListInitialSetOfFieldsUpdate>(
      CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdate
    ),
    switchMap(action =>
      this.tableService.updateTableConfiguration(action.payload.countryId, action.payload.command).pipe(
        map(
          () =>
            new fromCountriesActions.CountryCustomersListInitialSetOfFieldsUpdated({
              countryId: action.payload.countryId,
              tableName: action.payload.command.tableName,
            })
        ),
        catchError(() => of(new fromCountriesActions.CountryCustomersListInitialSetOfFieldsUpdateError()))
      )
    )
  ));

   countryCustomersListInitialSetOfFieldsUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<CountryCustomersListInitialSetOfFieldsUpdated>(
      CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdated
    ),
    tap(() => this.notification.success('admin.customersListConfig.updateSuccess')),
    map(
      action =>
        new fromCountriesActions.CountryCustomersListInitialSetOfFieldsLoad({
          countryId: action.payload.countryId,
          tableName: action.payload.tableName,
        })
    )
  ));

   countryCustomersListInitialSetOfFieldsLoad$ = createEffect(() => this.actions$.pipe(
    ofType<CountryCustomersListInitialSetOfFieldsLoad>(CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoad),
    switchMap(action =>
      this.tableViewService.getCountryTableConfiguration(action.payload.tableName, action.payload.countryId).pipe(
        map(response => new fromCountriesActions.CountryCustomersListInitialSetOfFieldsLoaded(response)),
        catchError(() => of(new fromCountriesActions.CountryCustomersListInitialSetOfFieldsLoadError()))
      )
    )
  ));

   addCountryAlternativeLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<AddCountryAlternativeLanguage>(CountriesActionTypes.AddCountryAlternativeLanguage),
    switchMap(action =>
      this.countryService.addAlternativeLanguage(action.payload).pipe(
        map(
          () =>
            new fromCountriesActions.CountryAlternativeLanguageAdded(action.payload.alternativeLanguage as LanguageEnum)
        ),
        catchError(err => of(new fromCountriesActions.CountryAlternativeLanguageError()))
      )
    )
  ));

   CheckCountryAlternativeLanguageFile$ = createEffect(() => this.actions$.pipe(
    ofType<AddCountryAlternativeLanguage>(CountriesActionTypes.AddCountryAlternativeLanguage),
    withLatestFrom(this.countriesFacade.countryCode$),
    tap(([action, countryCode]) => {
      this.localeFacade.checkTranslateFile(action.payload.alternativeLanguage as LanguageEnum, countryCode);
    })
  ), { dispatch: false });

   removeCountryAlternativeLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveCountryAlternativeLanguage>(CountriesActionTypes.RemoveCountryAlternativeLanguage),
    switchMap(action =>
      this.countryService.deleteAlternativeLanguage(action.payload.countryId, action.payload.alternativeLanguage).pipe(
        map(() => new fromCountriesActions.CountryAlternativeLanguageRemoved(action.payload.alternativeLanguage)),
        catchError(err => of(new fromCountriesActions.CountryAlternativeLanguageError()))
      )
    )
  ));

   addCountryAlternativeContentLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<AddCountryAlternativeContentLanguage>(CountriesActionTypes.AddCountryAlternativeContentLanguage),
    switchMap(action =>
      this.countryService.addAlternativeContentLanguage(action.payload).pipe(
        map(
          () =>
            new fromCountriesActions.CountryAlternativeContentLanguageAdded(
              action.payload.alternativeContentLanguage as LanguageEnum
            )
        ),
        catchError(err => of(new fromCountriesActions.CountryAlternativeContentLanguageError()))
      )
    )
  ));

   removeCountryAlternativeContentLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<RemoveCountryAlternativeContentLanguage>(CountriesActionTypes.RemoveCountryAlternativeContentLanguage),
    switchMap(action =>
      this.countryService
        .deleteAlternativeContentLanguage(action.payload.countryId, action.payload.alternativeContentLanguage)
        .pipe(
          map(
            () =>
              new fromCountriesActions.CountryAlternativeContentLanguageRemoved(
                action.payload.alternativeContentLanguage
              )
          ),
          catchError(err => of(new fromCountriesActions.CountryAlternativeContentLanguageError()))
        )
    )
  ));

   countryLanguageUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryDefaultLanguageError>(CountriesActionTypes.CountryDefaultLanguageError),
    tap(() => {
      this.notifications.error('admin.country.languageUpdateError');
    })
  ), { dispatch: false });

   countryContentLanguageUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryDefaultContentLanguageError>(CountriesActionTypes.CountryDefaultContentLanguageError),
    tap(() => {
      this.notifications.error('admin.country.contentLanguageUpdateError');
    })
  ), { dispatch: false });

   countryLanguageUpdateSuccess = createEffect(() => this.actions$.pipe(
    ofType<CountryDefaultLanguageUpdated>(CountriesActionTypes.CountryDefaultLanguageUpdated),
    tap(() => {
      this.notifications.success('admin.country.languageUpdated');
    })
  ), { dispatch: false });

   countryContentLanguageUpdateSuccess = createEffect(() => this.actions$.pipe(
    ofType<CountryDefaultContentLanguageUpdated>(CountriesActionTypes.CountryDefaultContentLanguageUpdated),
    tap(() => {
      this.notifications.success('admin.country.contentLanguageUpdated');
    })
  ), { dispatch: false });

   countryAlternativeLanguageUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryAlternativeLanguageError>(CountriesActionTypes.CountryAlternativeLanguageError),
    tap(() => {
      this.notifications.error('admin.country.alternativeLanguageUpdateError');
    })
  ), { dispatch: false });

   countryAlternativeContentLanguageUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryAlternativeContentLanguageError>(CountriesActionTypes.CountryAlternativeContentLanguageError),
    tap(() => {
      this.notifications.error('admin.country.alternativeContentLanguageUpdateError');
    })
  ), { dispatch: false });

   countryAlternativeLanguageUpdateSuccess = createEffect(() => this.actions$.pipe(
    ofType<CountryAlternativeLanguageAdded | CountryAlternativeLanguageRemoved>(
      CountriesActionTypes.CountryAlternativeLanguageAdded,
      CountriesActionTypes.CountryAlternativeLanguageRemoved
    ),
    tap(() => {
      this.notifications.success('admin.country.alternativeLanguageUpdated');
    })
  ), { dispatch: false });

   countryAlternativeContentLanguageUpdateSuccess = createEffect(() => this.actions$.pipe(
    ofType<CountryAlternativeContentLanguageAdded | CountryAlternativeContentLanguageRemoved>(
      CountriesActionTypes.CountryAlternativeContentLanguageAdded,
      CountriesActionTypes.CountryAlternativeContentLanguageRemoved
    ),
    tap(() => {
      this.notifications.success('admin.country.alternativeContentLanguageUpdated');
    })
  ), { dispatch: false });

   afterCountrySettingsChangedReloadUserContext = createEffect(() => this.actions$.pipe(
    ofType<CountryAlternativeContentLanguageAdded | CountryAlternativeContentLanguageRemoved>(
      CountriesActionTypes.CountryAlternativeLanguageAdded,
      CountriesActionTypes.CountryAlternativeLanguageRemoved,
      CountriesActionTypes.CountryDefaultLanguageUpdated,
      CountriesActionTypes.CountryAlternativeContentLanguageAdded,
      CountriesActionTypes.CountryDefaultContentLanguageUpdated,
      CountriesActionTypes.CountryAlternativeContentLanguageRemoved
    ),
    mapTo(new LoadUserContext())
  ));

   updateCountryLocale$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryLocale>(CountriesActionTypes.UpdateCountryLocale),
    switchMap(action =>
      this.countryService.updateLocale(action.payload).pipe(
        map(() => new fromCountriesActions.CountryLocaleUpdated(action.payload.locale)),
        catchError(err => of(new fromCountriesActions.CountryLocaleError()))
      )
    )
  ));

   countryLocaleUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<CountryLocaleUpdated>(CountriesActionTypes.CountryLocaleUpdated),
    tap(action => {
      this.notifications.success('admin.country.localeUpdated');
    })
  ), { dispatch: false });

   countryLocaleUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryLocaleError>(CountriesActionTypes.CountryLocaleError),
    tap(() => {
      this.notifications.error('admin.country.localeUpdateError');
    })
  ), { dispatch: false });

   updateCountryTimezone$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryTimezone>(CountriesActionTypes.UpdateCountryTimezone),
    switchMap(action =>
      this.countryService.updateTimezone(action.payload).pipe(
        map(() => new fromCountriesActions.CountryTimezoneUpdated(action.payload.timezone)),
        catchError(err => of(new fromCountriesActions.CountryTimezoneError()))
      )
    )
  ));

   countryTimezoneUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<CountryTimezoneUpdated>(CountriesActionTypes.CountryTimezoneUpdated),
    tap(action => {
      this.notifications.success('admin.country.timezoneUpdated');
    })
  ), { dispatch: false });

   countryTimezoneUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryTimezoneError>(CountriesActionTypes.CountryTimezoneError),
    tap(() => {
      this.notifications.error('admin.country.timezoneUpdateError');
    })
  ), { dispatch: false });

   updateCountryCurrency$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryCurrency>(CountriesActionTypes.UpdateCountryCurrency),
    switchMap(action =>
      this.countryService.updateCurrency(action.payload).pipe(
        map(() => new fromCountriesActions.CountryCurrencyUpdated(action.payload.currency)),
        catchError(err => of(new fromCountriesActions.CountryCurrencyError()))
      )
    )
  ));

   countryCurrencyUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<CountryCurrencyUpdated>(CountriesActionTypes.CountryCurrencyUpdated),
    tap(() => {
      this.notifications.success('admin.country.currencyUpdated');
      this.localeFacade.loadUserCountry();
    })
  ), { dispatch: false });

   countryCurrencyUpdateError = createEffect(() => this.actions$.pipe(
    ofType<CountryCurrencyError>(CountriesActionTypes.CountryCurrencyError),
    tap(() => {
      this.notifications.error('admin.country.currencyUpdateError');
    })
  ), { dispatch: false });

   loadCountryTaxType = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryTaxType>(CountriesActionTypes.LoadCountryTaxType),
    switchMap(action =>
      this.countryConfigurationViewService.getCountryTax(action.payload).pipe(
        map(res => new fromCountriesActions.LoadCountryTaxTypeSuccess(res)),
        catchError(err => of(new fromCountriesActions.LoadCountryTaxTypeError()))
      )
    )
  ));

   updateCountryTaxType = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryTaxType>(CountriesActionTypes.UpdateCountryTaxType),
    switchMap(action =>
      this.countryConfigurationService.updateCountryTaxType(action.payload).pipe(
        map(_ => new fromCountriesActions.UpdateCountryTaxTypeSuccess()),
        catchError(err => of(new fromCountriesActions.UpdateCountryTaxTypeError()))
      )
    )
  ));

   updateCountryUCNumberSourceType = createEffect(() => this.actions$.pipe(
    ofType<UpdateUCNumberSourceType>(CountriesActionTypes.UpdateUCNumberSourceType),
    switchMap(action =>
      this.countryService.updateUcNumberSource(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateUCNumberSourceTypeSuccess(res)),
        catchError(err => of(new fromCountriesActions.UpdateUCNumberSourceTypeError()))
      )
    )
  ));

   updateCountryUCNumberSourceTypeSuccess = createEffect(() => this.actions$.pipe(
    ofType<UpdateUCNumberSourceTypeSuccess>(CountriesActionTypes.UpdateUCNumberSourceTypeSuccess),
    tap(() => {
      this.notifications.success('admin.ucNumberSource.countryUCNumberSourceUpdateSuccess');
    }),
    map(({ payload }) => new fromCountriesActions.LoadCountryDetails(payload.aggregateId))
  ));

   updateCountryTaxTypeSuccess = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryTaxTypeSuccess>(CountriesActionTypes.UpdateCountryTaxTypeSuccess),
    tap(() => {
      this.notifications.success('admin.taxType.updateSuccess');
    })
  ), { dispatch: false });

   countryLogoLoad = createEffect(() => this.actions$.pipe(
    ofType<LogoLoad>(CountriesActionTypes.LogoLoad),
    switchMap(action =>
      this.logoViewService.getCountryLogo(action.payload).pipe(
        switchMap(logoBlob =>
          mapFileToDataUrl([logoBlob as any, '']).pipe(
            map(parsedLogo => new fromCountriesActions.LogoLoaded(parsedLogo.file))
          )
        ),
        catchError(() => of(new fromCountriesActions.LogoLoadError()))
      )
    )
  ));

   countryLogoUpload$ = createEffect(() => this.actions$.pipe(
    ofType<LogoUpload>(CountriesActionTypes.LogoUpload),
    switchMap(action =>
      this.logoService.updateCountryLogo(action.payload.aggregateRootId, action.payload.file).pipe(
        map(() => new fromCountriesActions.LogoUploaded(action.payload.aggregateRootId)),
        catchError(() => of(new fromCountriesActions.LogoUploadError()))
      )
    )
  ));

   countryLogoUploaded$ = createEffect(() => this.actions$.pipe(
    ofType<LogoUploaded>(CountriesActionTypes.LogoUploaded),
    tap(() => {
      this.notification.success('admin.country.countryLogoUpdated');
    }),
    map(action => new fromCountriesActions.LogoLoad(action.payload))
  ));

   updateCountryUrl = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryUrl>(CountriesActionTypes.UpdateCountryUrl),
    switchMap(action =>
      this.countryService.updateCountryUrl(action.payload).pipe(
        map(
          ({ countryNamesWithSameUrl }) =>
            new fromCountriesActions.UpdateCountryUrlSuccess({
              countryId: action.payload.aggregateRootId,
              countryNamesWithSameUrl,
            })
        ),
        catchError(() => of(new fromCountriesActions.UpdateCountryUrlError()))
      )
    )
  ));

   updateCountryUrlSuccess = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryUrlSuccess>(CountriesActionTypes.UpdateCountryUrlSuccess),
    map(action => action.payload),
    tap(({ countryNamesWithSameUrl }) => {
      if (countryNamesWithSameUrl && countryNamesWithSameUrl.length) {
        this.notifications.warning(`admin.country.urlAlreadyUsed`);
      } else {
        this.notifications.success('admin.country.countryUrlUpdated');
      }
    }),
    map(({ countryId }) => new LoadCountryDetails(countryId))
  ));

   updateCountryHotlinePhone = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryHotlinePhone>(CountriesActionTypes.UpdateCountryHotlinePhone),
    switchMap(action =>
      this.countryService.updateCountryHotlinePhone(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateCountryHotlinePhoneSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateCountryHotlinePhoneError()))
      )
    )
  ));

   updateCountryHotlinePhoneSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryHotlinePhoneSuccess>(CountriesActionTypes.UpdateCountryHotlinePhoneSuccess),
    tap(() => {
      this.notifications.success('admin.country.countryHotlinePhoneUpdated');
    }),
    map(action => new LoadCountryDetails(action.payload.aggregateId))
  ));

   updateCountryHotlineEmail$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryHotlineEmail>(CountriesActionTypes.UpdateCountryHotlineEmail),
    switchMap(action =>
      this.countryService.updateCountryHotlineEmail(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateCountryHotlineEmailSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateCountryHotlineEmailError()))
      )
    )
  ));

   updateCountryHotlineEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryHotlineEmailSuccess>(CountriesActionTypes.UpdateCountryHotlineEmailSuccess),
    tap(() => {
      this.notifications.success('admin.country.countryHotlineEmailUpdated');
    }),
    map(action => new LoadCountryDetails(action.payload.aggregateId))
  ));

   loadBroadcastList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBroadcastList>(CountriesActionTypes.LoadBroadcastList),
    switchMap(action =>
      this.broadcastConnectionConfigurationViewService
        .listCountryBroadcastConnectionConfigurations(action.payload)
        .pipe(
          map(res => new fromCountriesActions.LoadBroadcastListSuccess(res)),
          catchError(err => of(new fromCountriesActions.LoadBroadcastListError()))
        )
    )
  ));

   loadBroadcastCountryMappingList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBroadcastCountryMappingList>(CountriesActionTypes.LoadBroadcastCountryMappingList),
    switchMap(action =>
      this.broadcastConnectionConfigurationViewService
        .listCountryBroadcastConnectionConfigurationsMappings(action.payload)
        .pipe(
          map(res => new fromCountriesActions.LoadBroadcastCountryMappingListSuccess(res)),
          catchError(err => of(new fromCountriesActions.LoadBroadcastCountryMappingListError()))
        )
    )
  ));

   loadBroadcastDetails$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBroadcastDetails>(CountriesActionTypes.LoadBroadcastDetails),
    switchMap(action =>
      this.broadcastConnectionConfigurationViewService
        .getCountryBroadcastConnectionConfiguration(action.payload.broadcastId, action.payload.countryId)
        .pipe(
          map(res => new fromCountriesActions.LoadBroadcastDetailsSuccess(res)),
          catchError(err => of(new fromCountriesActions.LoadBroadcastDetailsError()))
        )
    )
  ));

   updateBroadcastHostname$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastHostname>(CountriesActionTypes.UpdateBroadcastHostname),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .updateCountryBroadcastConnectionConfigurationHostname(action.payload)
        .pipe(
          map(res => new fromCountriesActions.UpdateBroadcastHostnameSuccess(res)),
          catchError(err => of(new UpdateBroadcastHostnameError()))
        )
    )
  ));

   updateBroadcastIsActive$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastIsActive>(CountriesActionTypes.UpdateBroadcastIsActive),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .updateCountryBroadcastConnectionConfigurationIsActive(action.payload)
        .pipe(
          map(res => new UpdateBroadcastIsActiveSuccess(res)),
          catchError(err => of(new UpdateBroadcastIsActiveError()))
        )
    )
  ));

   updateBroadcastName$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastName>(CountriesActionTypes.UpdateBroadcastName),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .updateCountryBroadcastConnectionConfigurationName(action.payload)
        .pipe(
          map(res => new fromCountriesActions.UpdateBroadcastNameSuccess(res)),
          catchError(err => of(new fromCountriesActions.UpdateBroadcastNameError()))
        )
    )
  ));

   updateBroadcastUsername$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastUsername>(CountriesActionTypes.UpdateBroadcastUsername),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .updateCountryBroadcastConnectionConfigurationUsername(action.payload)
        .pipe(
          map(res => new fromCountriesActions.UpdateBroadcastUsernameSuccess(res)),
          catchError(err => of(new UpdateBroadcastUsernameError()))
        )
    )
  ));

   updateBroadcastPassword$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastPassword>(CountriesActionTypes.UpdateBroadcastPassword),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .updateCountryBroadcastConnectionConfigurationPassword(action.payload)
        .pipe(
          map(
            res =>
              new UpdateBroadcastPasswordSuccess({
                countryId: action.payload.countryId,
                broadcastId: action.payload.aggregateRootId,
              })
          ),
          catchError(err => of(new UpdateBroadcastPasswordError()))
        )
    )
  ));

   updateBroadcastPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastPasswordSuccess>(CountriesActionTypes.UpdateBroadcastPasswordSuccess),
    map(action => new LoadBroadcastDetails(action.payload))
  ));

   updateBroadcastLogo$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastLogo>(CountriesActionTypes.UpdateBroadcastLogo),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .uploadCountryBroadcastConnectionConfigurationLogo(action.payload.aggregateRootId, action.payload.file)
        .pipe(
          map(res => new UpdateBroadcastLogoSuccess(res)),
          catchError(err => of(new UpdateBroadcastLogoError()))
        )
    )
  ));

   updateBroadcastLogoSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastLogoSuccess>(CountriesActionTypes.UpdateBroadcastLogoSuccess),
    tap(() => {
      this.notification.success('admin.broadcastingForm.logoAdded');
    }),
    map(({ payload }) => new LoadBroadcastLogo(payload.aggregateId))
  ));

   loadBroadcastLogo$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBroadcastLogo>(CountriesActionTypes.LoadBroadcastLogo),
    switchMap(action =>
      this.broadcastConnectionConfigurationViewService
        .getCountryBroadcastConnectionConfigurationLogo(action.payload)
        .pipe(
          switchMap(logoBlob =>
            mapFileToDataUrl([logoBlob as any, '']).pipe(
              map(parsedLogo => new LoadBroadcastLogoSuccess(parsedLogo.file))
            )
          ),
          catchError(err => of(new LoadBroadcastLogoError()))
        )
    )
  ));

   updateBroadcastCertificate$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastCertificate>(CountriesActionTypes.UpdateBroadcastCertificate),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .uploadCountryBroadcastConnectionConfigurationCertificate(
          action.payload.aggregateRootId,
          action.payload.countryId,
          action.payload.file
        )
        .pipe(
          map(res => new UpdateBroadcastCertificateSuccess(res)),
          catchError(err => of(new UpdateBroadcastCertificateError()))
        )
    )
  ));

   updateBroadcastCertificateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateBroadcastCertificateSuccess>(CountriesActionTypes.UpdateBroadcastCertificateSuccess),
    tap(() => {
      this.notification.success('admin.broadcastingForm.certificateAdded');
    }),
    map(({ payload }) => new LoadBroadcastCertificate(payload.aggregateId))
  ));

   loadBroadcastCertificate$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBroadcastCertificate>(CountriesActionTypes.LoadBroadcastCertificate),
    switchMap(action =>
      this.broadcastConnectionConfigurationViewService
        .getCountryBroadcastConnectionConfigurationCertificate(action.payload)
        .pipe(
          switchMap(certBlob =>
            mapFileToDataUrl([certBlob as any, '']).pipe(
              map(parsedCert => new LoadBroadcastCertificateSuccess(parsedCert.file))
            )
          ),
          catchError(err => of(new LoadBroadcastCertificateError()))
        )
    )
  ));

   createFtpExport$ = createEffect(() => this.actions$.pipe(
    ofType<CreateFtpExport>(CountriesActionTypes.CreateFtpExport),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .createCountryBroadcastConnectionFtpConfiguration(action.payload.ftpConfig)
        .pipe(
          map(
            res =>
              new fromCountriesActions.CreateFtpExportSuccess({
                res,
                file: action.payload.file,
              })
          ),
          catchError(() => of(new fromCountriesActions.CreateFtpExportError()))
        )
    )
  ));

   createFtpExportSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<CreateFtpExportSuccess>(CountriesActionTypes.CreateFtpExportSuccess),
    tap(() => {
      this.notifications.success('admin.ftp.ftpExportCreated');
    }),
    map(
      action =>
        new fromCountriesActions.UpdateBroadcastLogo({
          aggregateRootId: action.payload.res.aggregateId,
          file: action.payload.file,
        })
    )
  ));

   editFtpExport$ = createEffect(() => this.actions$.pipe(
    ofType<EditFtpExport>(CountriesActionTypes.EditFtpExport),
    switchMap(action =>
      this.broadcastConnectionConfigurationService
        .updateCountryBroadcastConnectionFtpConfiguration(action.payload.ftpConfig)
        .pipe(
          map(
            res =>
              new fromCountriesActions.EditFtpExportSuccess({
                res,
                file: action.payload.file,
              })
          ),
          catchError(() => of(new fromCountriesActions.CreateFtpExportError()))
        )
    )
  ));

   editFtpExportSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<EditFtpExportSuccess>(CountriesActionTypes.EditFtpExportSuccess),
    tap(() => {
      this.notifications.success('admin.ftp.ftpExportUpdated');
    }),
    map(
      action =>
        new fromCountriesActions.UpdateBroadcastLogo({
          aggregateRootId: action.payload.res.aggregateId,
          file: action.payload.file,
        })
    )
  ));

   updateContactUsCountryCode$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsCountryCode>(CountriesActionTypes.UpdateContactUsCountryCode),
    switchMap(action =>
      this.countryService.updateContactUsSalesforceCountryCode(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateContactUsCountryCodeSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateContactUsCountryCodeError()))
      )
    )
  ));

   updateContactUsCountryCodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsCountryCodeSuccess>(CountriesActionTypes.UpdateContactUsCountryCodeSuccess),
    tap(() => {
      this.notifications.success('admin.contactUs.countryCodeUpdated');
    })
  ), { dispatch: false });

   updateContactUsDefaultPriority$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsDefaultPriority>(CountriesActionTypes.UpdateContactUsDefaultPriority),
    switchMap(action =>
      this.countryService.updateContactUsSalesforceDefaultPriority(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateContactUsDefaultPrioritySuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateContactUsDefaultPriorityError()))
      )
    )
  ));

   updateContactUsDefaultPrioritySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsDefaultPrioritySuccess>(CountriesActionTypes.UpdateContactUsDefaultPrioritySuccess),
    tap(() => {
      this.notifications.success('admin.contactUs.defaultPriorityUpdated');
    })
  ), { dispatch: false });

   updateContactUsDefaultType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsDefaultType>(CountriesActionTypes.UpdateContactUsDefaultType),
    switchMap(action =>
      this.countryService.updateContactUsSalesforceDefaultType(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateContactUsDefaultTypeSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateContactUsDefaultTypeError()))
      )
    )
  ));

   UpdateContactUsDefaultTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsDefaultTypeSuccess>(CountriesActionTypes.UpdateContactUsDefaultTypeSuccess),
    tap(() => {
      this.notifications.success('admin.contactUs.defaultTypeUpdated');
    })
  ), { dispatch: false });

   updateContactUsDefaultClassification$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsDefaultClassification>(CountriesActionTypes.UpdateContactUsDefaultClassification),
    switchMap(action =>
      this.countryService.updateContactUsSalesforceDefaultClassification(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateContactUsDefaultClassificationSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateContactUsDefaultClassificationError()))
      )
    )
  ));

   updateContactUsDefaultClassificationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsDefaultClassificationSuccess>(
      CountriesActionTypes.UpdateContactUsDefaultClassificationSuccess
    ),
    tap(() => {
      this.notifications.success('admin.contactUs.defaultClassificationUpdated');
    })
  ), { dispatch: false });

   updateContactUsHeaderTexts$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsHeaderTexts>(CountriesActionTypes.UpdateContactUsHeaderTexts),
    switchMap(action =>
      this.countryService.updateContactUsHeaderTexts(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateContactUsHeaderTextsSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateContactUsHeaderTextsError()))
      )
    )
  ));

   updateContactUsHeaderTextsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsHeaderTextsSuccess>(CountriesActionTypes.UpdateContactUsHeaderTextsSuccess),
    tap(() => {
      this.notifications.success('admin.contactUs.headerTextsUpdated');
    })
  ), { dispatch: false });

   updateContactUsEnquiryType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsEnquiryType>(CountriesActionTypes.UpdateContactUsEnquiryType),
    switchMap(action =>
      this.countryService.updateContactUsSalesforceEnquiryType(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateContactUsEnquiryTypeSuccess(res)),
        catchError(() => of(new fromCountriesActions.UpdateContactUsEnquiryTypeError()))
      )
    )
  ));

   updateContactUsEnquiryTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateContactUsEnquiryTypeSuccess>(CountriesActionTypes.UpdateContactUsEnquiryTypeSuccess),
    map(action => {
      this.notifications.success('admin.contactUs.enquiryTypeUpdated');
      return new fromCountriesActions.LoadCountryDetails(action.payload.aggregateId);
    })
  ));

   addContactUsEnquiryType$ = createEffect(() => this.actions$.pipe(
    ofType<AddContactUsEnquiryType>(CountriesActionTypes.AddContactUsEnquiryType),
    switchMap(action =>
      this.countryService.addContactUsSalesforceEnquiryType(action.payload).pipe(
        map(res => new fromCountriesActions.AddContactUsEnquiryTypeSuccess(res)),
        catchError(() => of(new fromCountriesActions.AddContactUsEnquiryTypeError()))
      )
    )
  ));

   addContactUsEnquiryTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AddContactUsEnquiryTypeSuccess>(CountriesActionTypes.AddContactUsEnquiryTypeSuccess),
    map(action => {
      this.notifications.success('admin.contactUs.enquiryTypeAdded');
      return new fromCountriesActions.LoadCountryDetails(action.payload.aggregateId);
    })
  ));

   deleteContactUsEnquiryType$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteContactUsEnquiryType>(CountriesActionTypes.DeleteContactUsEnquiryType),
    switchMap(action =>
      this.countryService
        .deleteContactUsSalesforceEnquiryType(action.payload.countryId, action.payload.enquiryTypeId)
        .pipe(
          map(res => new fromCountriesActions.DeleteContactUsEnquiryTypeSuccess(res)),
          catchError(() => of(new fromCountriesActions.DeleteContactUsEnquiryTypeError()))
        )
    )
  ));

   deleteContactUsEnquiryTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteContactUsEnquiryTypeSuccess>(CountriesActionTypes.DeleteContactUsEnquiryTypeSuccess),
    map(action => {
      this.notifications.success('admin.contactUs.enquiryTypeDeleted');
      return new fromCountriesActions.LoadCountryDetails(action.payload.aggregateId);
    })
  ));

   loadVinThreshold$ = createEffect(() => this.actions$.pipe(
    ofType<LoadVinThreshold>(CountriesActionTypes.LoadVinThreshold),
    switchMap(action =>
      this.countryViewService.getCountryVinThreshold(action.payload).pipe(
        map(res => new fromCountriesActions.LoadVinThresholdSuccess(res)),
        catchError(() => of(new fromCountriesActions.LoadVinThresholdError()))
      )
    )
  ));

   updateVinThreshold$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateVinThreshold>(CountriesActionTypes.UpdateVinThreshold),
    switchMap(action =>
      this.countryService.updateCountryVinThreshold(action.payload).pipe(
        map(res => new fromCountriesActions.UpdateVinThresholdSuccess(action.payload.aggregateRootId)),
        catchError(() => of(new fromCountriesActions.UpdateVinThresholdError()))
      )
    )
  ));

   updateVinThresholdSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateVinThresholdSuccess>(CountriesActionTypes.UpdateVinThresholdSuccess),
    tap(() => {
      this.notifications.success('admin.country.vinThresholdUpdated');
    }),
    map(action => new LoadVinThreshold(action.payload))
  ));

   loadExperianCountrySettings$ = createEffect(() => this.actions$.pipe(
    ofType<LoadExperianCountrySettings>(CountriesActionTypes.LoadExperianCountrySettings),
    switchMap(action =>
      this.experianViewService.getExperianCountrySettings(action.payload).pipe(
        map(res => new fromCountriesActions.LoadExperianCountrySettingsSuccess(res)),
        catchError(() => of(new fromCountriesActions.LoadExperianCountrySettingsError()))
      )
    )
  ));

   updateExperianHostUrl$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianHostUrl>(CountriesActionTypes.UpdateExperianHostUrl),
    switchMap(action =>
      this.experianService.setHostUrl(action.payload.countryId, action.payload.command).pipe(
        map(() => new fromCountriesActions.UpdateExperianHostUrlSuccess(action.payload.countryId)),
        catchError(() => of(new fromCountriesActions.UpdateExperianHostUrlError()))
      )
    )
  ));

   UpdateExperianHostUrlSuccess = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianHostUrlSuccess>(CountriesActionTypes.UpdateExperianHostUrlSuccess),
    tap(() => this.notifications.success('admin.country.experianHostUpdated')),
    map(action => new LoadExperianCountrySettings(action.payload))
  ));

   updateExperianTermsAndConditionsUrl$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianTermsAndConditionsUrl>(CountriesActionTypes.UpdateExperianTermsAndConditionsUrl),
    switchMap(action =>
      this.experianService.setTermsAndConditionsUrl(action.payload.countryId, action.payload.command).pipe(
        map(() => new fromCountriesActions.UpdateExperianTermsAndConditionsUrlSuccess(action.payload.countryId)),
        catchError(() => of(new fromCountriesActions.UpdateExperianTermsAndConditionsUrlError()))
      )
    )
  ));

   UpdateExperianTermsAndConditionsUrlSuccess = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianTermsAndConditionsUrlSuccess>(CountriesActionTypes.UpdateExperianTermsAndConditionsUrlSuccess),
    tap(() => this.notifications.success('admin.country.experianTermsUpdated')),
    map(action => new LoadExperianCountrySettings(action.payload))
  ));

   UpdateExperianTermsAndConditionsUrlInLocale = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianTermsAndConditionsUrlSuccess>(CountriesActionTypes.UpdateExperianTermsAndConditionsUrlSuccess),
    map(action => new ExperianTermsUrlLoaded(action.payload))
  ));

   loadCountryGdv$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryGdv>(CountriesActionTypes.LoadCountryGdv),
    switchMap(action =>
      this.insuranceClaimingConfigurationViewService.getGdvGatewayConfigurationByCountryId(action.payload).pipe(
        map(response => new fromCountriesActions.LoadCountryGdvSuccess(response)),
        catchError(err => of(new fromCountriesActions.LoadCountryGdvError()))
      )
    )
  ));

   updateCountryGdvUsername$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryGdvUsername>(CountriesActionTypes.UpdateCountryGdvUsername),
    switchMap(action =>
      this.insuranceClaimingConfigurationService.updateGdvGatewayConfigurationUsername(action.payload.command).pipe(
        map(response => new fromCountriesActions.UpdateCountryGdvUsernameSuccess(action.payload.command.username)),
        catchError(err => of(new fromCountriesActions.UpdateCountryGdvUsernameError()))
      )
    )
  ));

   updateCountryGdvUsernameSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryGdvUsernameSuccess>(CountriesActionTypes.UpdateCountryGdvUsernameSuccess),
    tap(() => this.notification.success('admin.country.gdv.usernameUpdatedSuccessfully'))
  ), { dispatch: false });

   updateCountryGdvPassword$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryGdvPassword>(CountriesActionTypes.UpdateCountryGdvPassword),
    switchMap(action =>
      this.insuranceClaimingConfigurationService.updateGdvGatewayConfigurationPassword(action.payload.command).pipe(
        map(response => new fromCountriesActions.UpdateCountryGdvPasswordSuccess(action.payload.countryId)),
        catchError(err => of(new fromCountriesActions.UpdateCountryGdvPasswordError()))
      )
    )
  ));

   updateCountryGdvPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateCountryGdvPasswordSuccess>(CountriesActionTypes.UpdateCountryGdvPasswordSuccess),
    tap(() => this.notification.success('admin.country.gdv.passwordUpdatedSuccessfully')),
    map(action => new LoadCountryGdv(action.payload))
  ));

   loadFeatureAfterCountryDetailsLoaded$ = createEffect(() => this.actions$.pipe(
    ofType<CountryDetailsLoaded>(CountriesActionTypes.CountryDetailsLoaded),
    tap(action => {
      this.localeFacade.loadFeatureConfigByCountry(action.payload.countryCode);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private countryViewService: CountryViewService,
    private countryService: CountryService,
    private notifications: BasicNotificationsService,
    private localeFacade: LocaleFacade,
    private countryConfigurationViewService: CountryConfigurationViewService,
    private countryConfigurationService: CountryConfigurationService,
    private tableService: TableService,
    private tableViewService: TableConfigurationViewService,
    private notification: BasicNotificationsService,
    private logoService: LogoService,
    private logoViewService: LogoViewService,
    private broadcastConnectionConfigurationService: BroadcastConnectionConfigurationService,
    private broadcastConnectionConfigurationViewService: BroadcastConnectionConfigurationViewService,
    private experianService: ExperianService,
    private experianViewService: ExperianViewService,
    private insuranceClaimingConfigurationService: InsuranceClaimingConfigurationService,
    private insuranceClaimingConfigurationViewService: InsuranceClaimingConfigurationViewService,
    private countriesFacade: CountriesFacade
  ) {}
}
