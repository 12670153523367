import { Injector, ComponentFactoryResolver } from '@angular/core';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { GlobalTemplatesComponent } from '../components/global-templates.component';

const nzConfigFactory = (injector: Injector, resolver: ComponentFactoryResolver): NzConfig => {
  const factory = resolver.resolveComponentFactory(GlobalTemplatesComponent);
  const { nzDatePickerSuffixTpl } = factory.create(injector).instance;
  return {
    datePicker: {
      nzSuffixIcon: nzDatePickerSuffixTpl,
    },
  };
};

export const nzConfigProvider = {
  provide: NZ_CONFIG,
  useFactory: nzConfigFactory,
  deps: [Injector, ComponentFactoryResolver],
};
