import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { authQuery } from '../auth.selectors';
import { LoginPayload } from '../../models/login-payload';
import { ManageStorageService } from '../../utils/manage-storage.service';
import {
  AuthLoginActionTypes,
  fromLoginActions,
  Login,
  LoginCancel,
  LoginError,
  LoginFailure,
  LoginFinished,
  LoginSessionAlreadyExistForTheUser,
  LoginSetTokenFromDto,
  LoginSuccess,
  LoginVerifyAssignedBranchesAmount,
  RequestPasswordReset,
} from '../actions/login.actions';
import { AuthService, UserContext, UserContextService } from '@vpfa/rest-api/admin';
import { ErrorCode, VPError } from '@vpfa/rest-api/valuation';
import { TemporaryPassword } from '../actions/temporary.actions';
import { isNil } from 'lodash';
import { ClearToken } from '../actions/token.actions';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { MixpanelEvent, MixpanelService, readVpErrorData } from '@vpfa/utils';
import { LATEST_NEWS_SEEN } from '../../../../../shared/latest-news/details/src/lib/utils/latest-news-seen-const';
import { ModalService } from '@vpfa/modals';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
    private storageService: ManageStorageService,
    private userContextService: UserContextService,
    private notifications: BasicNotificationsService,
    private mixpanel: MixpanelService,
    private modalService: ModalService
  ) {}

  
  login: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthLoginActionTypes.Login),
    map((action: Login) => action.payload),
    switchMap(payload => this.loginByAPI(payload))
  ));

  
  loginSuccess: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<LoginSuccess>(AuthLoginActionTypes.LoginSuccess),
    withLatestFrom(this.store.pipe(select(authQuery.getRedirectToUrlAfterLogin))),
    switchMap(([action, redirectToUrlAfterLogin]) => {
      const defaultPath = '/';
      localStorage.removeItem(LATEST_NEWS_SEEN);
      const userContext = action.payload as UserContext;
      const isAnyAdmin = userContext.isCountryAdmin || userContext.isBusinessAdmin || userContext.isSystemAdmin;
      // check '' for chrome and '/' for firefox and edge
      if (
        isAnyAdmin &&
        userContext.branchIds.length === 0 &&
        (isNil(redirectToUrlAfterLogin) ||
          redirectToUrlAfterLogin == '' ||
          redirectToUrlAfterLogin == '/' ||
          redirectToUrlAfterLogin?.includes('auth/login'))
      ) {
        return this.router.navigate(['admin']);
      } else {
        // Sometimes when multiple request are returning HTTP Unauthorized, slowest one may happen on login page, and
        // login page will be saved as unauthorizedUrl. This will prevent from redirecting to login page after login.
        if (redirectToUrlAfterLogin?.includes('auth/login')) {
          redirectToUrlAfterLogin = defaultPath;
        }
        return this.router.navigateByUrl(redirectToUrlAfterLogin ?? defaultPath);
      }
    }),
    map(() => {
      return new LoginFinished();
    })
  ));

  
  loginVerifyAssignedBranchesAmount$ = createEffect(() => this.actions$.pipe(
    ofType<LoginVerifyAssignedBranchesAmount>(AuthLoginActionTypes.LoginVerifyAssignedBranchesAmount),
    switchMap(() =>
      this.userContextService.getUserContext().pipe(
        map(userContext => {
          const branchIds = userContext.branchIds;
          if (!isNil(branchIds) && branchIds.length > 1) {
            this.mixpanel.track(MixpanelEvent.LoginError);
            return new LoginFailure('loginPage.loginError');
          }
          return new LoginSuccess(userContext);
        })
      )
    )
  ));

   requestPasswordReset$ = createEffect(() => this.actions$.pipe(
    ofType<RequestPasswordReset>(AuthLoginActionTypes.RequestPasswordReset),
    switchMap(action =>
      this.authService.resetPassword(action.payload.email, action.payload.recaptchaToken).pipe(
        map(res => new fromLoginActions.PasswordResetRequestSuccess(res)),
        catchError(err => of(new fromLoginActions.PasswordResetRequestError()))
      )
    )
  ));

   loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType<LoginFailure>(AuthLoginActionTypes.LoginFailure),
    map(() => new ClearToken())
  ));

   loginError$ = createEffect(() => this.actions$.pipe(
    ofType<LoginError>(AuthLoginActionTypes.LoginError),
    tap(action =>
      this.notifications.error(
        `vpError.${action.payload.codeName ?? 'UnknownServerError'}`,
        null,
        null,
        action.payload.id
      )
    )
  ), { dispatch: false });

   retryLoginWithCancelOfExistingSessions$ = createEffect(() => this.actions$.pipe(
    ofType<LoginSessionAlreadyExistForTheUser>(AuthLoginActionTypes.LoginSessionAlreadyExistForTheUser),
    switchMap(action =>
      this.modalService.confirm(['loginPage.loggedOnToAnotherDevice']).pipe(map(confirmed => ({ action, confirmed })))
    ),
    map(({ action, confirmed }) => {
      if (confirmed) {
        const modifiedCommand = { ...action.payload.command };
        modifiedCommand.cancelExistingSessions = true;
        const newPayload = {
          ...action.payload,
          command: modifiedCommand,
        };

        return new Login(newPayload);
      } else {
        return new LoginCancel();
      }
    })
  ));

  private loginByAPI(payload: LoginPayload) {
    this.storageService.setRememberMe(payload.rememberMe);
    return this.authService.login(payload.command).pipe(
      switchMap(response => [new LoginSetTokenFromDto(response?.tokenDto), new LoginVerifyAssignedBranchesAmount()]),
      catchError((httpError: HttpErrorResponse) => {
        const vpError: Pick<VPError, 'id' | 'codeName'> = readVpErrorData(httpError);

        switch (vpError?.codeName) {
          case ErrorCode.TemporaryPasswordMustBeChanged:
            return of(new TemporaryPassword(payload.command));
          case ErrorCode.IncorrectCredentials:
            this.mixpanel.track(MixpanelEvent.LoginError);
            return of(new LoginFailure(`vpError.IncorrectCredentials`));
          case ErrorCode.LoginSessionAlreadyExistForTheUser:
            return of(new LoginSessionAlreadyExistForTheUser(payload));
          default:
            this.mixpanel.track(MixpanelEvent.LoginError);
            return of(new LoginError(vpError));
        }
      })
    );
  }
}
