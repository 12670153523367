<span
  #labelText
  class="label-text"
  [class.label-text-ellipsis]="ellipsis"
  nz-tooltip
  [nzTooltipOverlayClassName]="'tooltip'"
  [nzTooltipTitle]="isEllipsisActive ? text : null"
>
  {{ text }}
  <ng-content #name></ng-content>
</span>
