import { CustomerDto, CustomerListItemDto } from '@vpfa/rest-api/valuation';
import { DynamicComponentsStackService } from '@vpfa/shared/dynamic-components-stack';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { first, switchMap } from 'rxjs';
import { CustomerEditComponent } from '../components/customer-edit/customer-edit.component';
import { AddRemoveCustomerListItem } from '../components/add-remove-customer-list/base-add-remove-customer.component';
import { NzModalService } from 'ng-zorro-antd/modal';

export function displayCustomer(customer: CustomerDto) {
  const title = customer.formOfAddress ? customer.formOfAddress : '';
  const firstName = customer.firstName ? customer.firstName : '';
  const lastName = customer.lastName ? customer.lastName : '';
  return `${title} ${firstName} ${lastName}`;
}

export const getCustomerSorting = (customer: AddRemoveCustomerListItem | CustomerListItemDto) => {
  const lastName = customer.lastName?.toLowerCase();
  const firstName = customer.firstName?.toLowerCase();
  const street = customer.street?.toLowerCase();
  const city = customer.city?.toLowerCase();

  // SSM-3950 & SSM-3952 -> main owner should be first
  if (isCustomerCaseListItemDto(customer)) {
    const isMain = (customer as any).isMain === true ? 0 : 1;

    return [isMain, lastName, firstName, street, city];
  }

  return [lastName, firstName, street, city];
};

function isCustomerCaseListItemDto(input: unknown) {
  return (input as AddRemoveCustomerListItem).isMain !== undefined;
}

export function openEditCustomerMobile(
  dynamicComponentsStackService: DynamicComponentsStackService,
  customersFacade: CustomersFacade,
  customerId: string,
) {
  const componentRef = dynamicComponentsStackService.add(CustomerEditComponent);
  componentRef.setInput('show', true);
  customersFacade.openUpdateCustomerModal(); // action emission is necessary for correct state

  // load customer details and hydrate modal with data
  customersFacade
    .loadCustomerDetails(customerId)
    .pipe(
      first(),
      switchMap(() => customersFacade.customerDetails$.pipe(first())),
    )
    .subscribe(customer => {
      componentRef.setInput('customer', customer);
    });
}

