import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleFullTechnicalDataComponent } from './components/vehicle-full-technical-data/vehicle-full-technical-data.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UiKitModule } from '@vpfa/ui-kit';
import { UiKitMobileModule } from '@vpfa/ui-kit-mobile';
import { FullTechSpecAndEquipmentListModalComponent } from './components/full-tech-spec-and-equipment-list/full-tech-spec-and-equipment-list-modal/full-tech-spec-and-equipment-list-modal.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { VehicleSerialEquipmentListComponent } from './components/vehicle-equipment-list/vehicle-serial-equipment-list.component';
import { VehicleSpecialEquipmentListComponent } from './components/vehicle-special-equipment-list/vehicle-special-equipment-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { VehicleBasicInfoComponent } from './components/vehicle-basic-info/vehicle-basic-info.component';
import { ValuationUpdateModalComponent } from './components/valuation-update-modal/valuation-update-modal.component';
import { UiKitShortLabelValueModule } from '@vpfa/ui-kit/short-label-value';
import { UtilsModule } from '@vpfa/utils';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EngineCapacityCellRendererComponent } from './cell-renderers/engine-capacity-cell-renderer/engine-capacity-cell-renderer.component';
import { EngineCapacityPipe } from './pipes/engine-capacity/engine-capacity.pipe';
import { FullTechnicalDataVehicleInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-vehicle-information/full-technical-data-vehicle-information.component';
import { FullTechnicalDataEngineInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-engine-information/full-technical-data-engine-information.component';
import { FullTechnicalDataCommonInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-common-information/full-technical-data-common-information.component';
import { FullTechnicalDataWeightsInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-weights-information/full-technical-data-weights-information.component';
import { FullTechnicalDataDimensionsInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-dimensions-information/full-technical-data-dimensions-information.component';
import { FullTechnicalDataNedcInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-nedc-information/full-technical-data-nedc-information.component';
import { FullTechnicalDataWltpInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-wltp-information/full-technical-data-wltp-information.component';
import { FullTechnicalDataInsuranceInformationComponent } from './components/vehicle-full-technical-data/full-technical-data-insurance-information/full-technical-data-insurance-information.component';
import { VehicleListFullTechnicalDataComponent } from './components/vehicle-list-full-technical-data/vehicle-list-full-technical-data.component';
import { UiKitArrowLinkModule } from '@vpfa/ui-kit/arrow-link';
import { UiKitActionDropdownModule } from '@vpfa/ui-kit/action-dropdown';
import { UiKitDataTableModule } from '@vpfa/ui-kit/data-table';
import { ForecastModalComponent } from './components/forecast-modal/forecast-modal.component';
import { ForecastComponent } from './containers/forecast/forecast.component';
import { UiKitPriceWithIconModule } from '@vpfa/ui-kit/price-with-icon';
import { PromoTextRendererComponent } from './cell-renderers/promo-text-renderer/promo-text-renderer.component';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { VehicleTechnicalDataSingleFieldComponent } from './components/vehicle-technical-data-single-field/vehicle-technical-data-single-field.component';
import { VehicleTechnicalDataIntervalNumberFieldComponent } from './components/vehicle-technical-data-interval-number-field/vehicle-technical-data-interval-number-field.component';
import { VehicleTechnicalDataNumberFieldComponent } from './components/vehicle-technical-data-number-field/vehicle-technical-data-number-field.component';
import { VehicleTechnicalDataSelectFieldComponent } from './components/vehicle-technical-data-select-field/vehicle-technical-data-select-field.component';
import { MobileVehicleListCellComponent } from './components/vehicle-list/mobile/cell/mobile-vehicle-list-cell.component';
import { MobileVehicleListComponent } from './components/vehicle-list/mobile/mobile-vehicle-list.component';
import { WebVehicleListComponent } from './components/vehicle-list/web/web-vehicle-list.component';
import { VehicleListFullTechnicalDataCellComponent } from './components/vehicle-list-full-technical-data/vehicle-list-full-technical-data-cell/vehicle-list-full-technical-data-cell.component';
import { UiKitFullScreenGalleryModule } from '@vpfa/ui-kit/full-screen-gallery';
import { FullTechSpecAndEquipmentListMobileComponent } from './components/full-tech-spec-and-equipment-list/full-tech-spec-and-equipment-list-mobile/full-tech-spec-and-equipment-list-mobile.component';
import { MobileEquipmentListItemComponent } from './components/equipment-list-item/mobile-equipment-list-item/mobile-equipment-list-item.component';
import { WebEquipmentListItemComponent } from './components/equipment-list-item/web-equipment-list-item/web-equipment-list-item.component';
import { UiKitModalsModule } from '@vpfa/ui-kit/modals';
import { UiKitSettingBoxModule } from '@vpfa/ui-kit/setting-box';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { UiKitSkewLineModule } from '@vpfa/ui-kit/skew-line';
import { ModalsModule } from '@vpfa/modals';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { UiKitIsoDatePickerModule } from '@vpfa/ui-kit/iso-date-picker';
import { CreateQuoteModalComponent } from './components/create-quote-modal/create-quote-modal.component';
import { RegisterNewModalComponent } from './components/register-new-modal/register-new-modal.component';
import { AddCustomerByEmailComponent } from './components/create-quote-modal/add-customer-by-email/add-customer-by-email.component';
import { DealerCustomersSharedModule } from '@vpfa/dealer/customers/shared';
import { UiKitFormControlsModule } from '@vpfa/ui-kit/form-controls';
import { UiKitSwitchModule } from '@vpfa/ui-kit/switch';
import { TaxInformationComponent } from './components/tax-information/tax-information.component';
import { UiKitCheckboxButtonModule } from '@vpfa/ui-kit/checkbox-button';
import { PackageContentItemComponent } from './components/equipment-list-item/package-content-item/package-content-item.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    UiKitModule,
    NzTabsModule,
    NzListModule,
    NzSelectModule,
    TranslateModule,
    SharedPipesModule,
    NzPopconfirmModule,
    NzCarouselModule,
    NzDividerModule,
    NzRadioModule,
    UiKitShortLabelValueModule,
    UtilsModule,
    NzSpinModule,
    NzIconModule,
    NzPopoverModule,
    FormsModule,
    ReactiveFormsModule,
    UiKitArrowLinkModule,
    NzButtonModule,
    UiKitActionDropdownModule,
    UiKitDataTableModule,
    UiKitPriceWithIconModule,
    SharedMobileViewModule,
    UiKitFullScreenGalleryModule,
    UiKitModalsModule,
    UiKitSettingBoxModule,
    SharedLoadersModule,
    UiKitSkewLineModule,
    ModalsModule,
    NzMenuModule,
    UiKitMobileModule,
    UiKitIsoDatePickerModule,
    DealerCustomersSharedModule,
    UiKitFormControlsModule,
    UiKitSwitchModule,
    UiKitCheckboxButtonModule,
  ],
  declarations: [
    VehicleFullTechnicalDataComponent,
    FullTechSpecAndEquipmentListModalComponent,
    VehicleSerialEquipmentListComponent,
    VehicleSpecialEquipmentListComponent,
    VehicleBasicInfoComponent,
    ValuationUpdateModalComponent,
    VehicleListComponent,
    EngineCapacityPipe,
    EngineCapacityCellRendererComponent,
    FullTechnicalDataVehicleInformationComponent,
    FullTechnicalDataEngineInformationComponent,
    FullTechnicalDataCommonInformationComponent,
    FullTechnicalDataWeightsInformationComponent,
    FullTechnicalDataDimensionsInformationComponent,
    FullTechnicalDataNedcInformationComponent,
    FullTechnicalDataWltpInformationComponent,
    FullTechnicalDataInsuranceInformationComponent,
    VehicleListFullTechnicalDataComponent,
    ForecastModalComponent,
    ForecastComponent,
    PromoTextRendererComponent,
    VehicleTechnicalDataSingleFieldComponent,
    VehicleTechnicalDataIntervalNumberFieldComponent,
    VehicleTechnicalDataNumberFieldComponent,
    VehicleTechnicalDataSelectFieldComponent,
    MobileVehicleListCellComponent,
    MobileVehicleListComponent,
    WebVehicleListComponent,
    VehicleListFullTechnicalDataCellComponent,
    FullTechSpecAndEquipmentListMobileComponent,
    MobileEquipmentListItemComponent,
    WebEquipmentListItemComponent,
    CreateQuoteModalComponent,
    RegisterNewModalComponent,    
    AddCustomerByEmailComponent,
    TaxInformationComponent,
    PackageContentItemComponent
  ],
  exports: [
    VehicleFullTechnicalDataComponent,
    FullTechSpecAndEquipmentListModalComponent,
    VehicleBasicInfoComponent,
    ValuationUpdateModalComponent,
    VehicleListComponent,
    EngineCapacityPipe,
    EngineCapacityCellRendererComponent,
    ForecastComponent,
    PromoTextRendererComponent,
    VehicleListFullTechnicalDataComponent,
    FullTechSpecAndEquipmentListMobileComponent,
    VehicleTechnicalDataSingleFieldComponent,
    WebEquipmentListItemComponent,
    CreateQuoteModalComponent,
    RegisterNewModalComponent,
    TaxInformationComponent,
    PackageContentItemComponent
  ],
})
export class SharedVehicleModule {}
