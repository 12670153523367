import { Action } from '@ngrx/store';
import {
  BranchListItemDto,
  BusinessDto,
  CreateUserCommand,
  UpdateUserCommand,
  UserDto,
  UserGroupDto,
  UpdateUserActiveStatusCommand,
  CommandHandlerResponse,
} from '@vpfa/rest-api/admin';
import { ResetUserPasswordCommand } from '../models/reset-user-password-command';

export enum UsersActionTypes {
  LoadUsersGlobal = '[Users] Load Users Global',
  LoadUsersGlobalSuccess = '[Users] Load Users Global Success',
  LoadUsersGlobalError = '[Users] Load Users Global Error',

  LoadUsersByCountry = '[Users] Load Users By Country',
  LoadUsersByCountrySuccess = '[Users] Load Users By Country Success',
  LoadUsersByCountryError = '[Users] Load Users By Country Error',

  LoadUsersByBusiness = '[Users] Load Users By Business',
  LoadUsersByBusinessSuccess = '[Users] Load Users By Business Success',
  LoadUsersByBusinessError = '[Users] Load Users By Business Error',

  LoadUsersByUsersBusiness = '[Users] Load Users By Users Business',
  LoadUsersByUsersBusinessSucess = '[Users] Load Users By Users Business Sucess',
  LoadUsersByUsersBusinessError = '[Users] Load Users By Users Business Error',

  LoadUserDetails = '[Users] Load User Details',
  LoadUserDetailsError = '[Users] Load User Details Error',
  LoadUserDetailsCompleted = '[Users] Load User Details Completed',

  LoadCreateUserBusiness = '[Users] Load Create User Business',
  LoadCreateUserBusinessError = '[Users] Load Create User Business Error',
  LoadCreateUserBusinessCompleted = '[Users] Load Create User Business Completed',

  LoadCreateUserBranches = '[Users] Load Create User Branches',
  LoadCreateUserBranchesError = '[Users] Load Create User Branches Error',
  LoadCreateUserBranchesCompleted = '[Users] Load Create User Branches Completed',

  LoadCreateUserUserGroups = '[Users] Load Create User User Groups',
  LoadCreateUserUserGroupsError = '[Users] Load Create User User Groups Error',
  LoadCreateUserUserGroupsCompleted = '[Users] Load Create User User Groups Completed',

  LoadEditUserBranches = '[Users] Load Edit User Branches',
  LoadEditUserBranchesError = '[Users] Load Edit User Branches Error',
  LoadEditUserBranchesCompleted = '[Users] Load Edit User Branches Completed',

  LoadEditUserUserGroups = '[Users] Load Edit User User Groups',
  LoadEditUserUserGroupsError = '[Users] Load Edit User User Groups Error',
  LoadEditUserUserGroupsCompleted = '[Users] Load Edit User User Groups Completed',

  AddUser = '[Users] Add User',
  AddUserError = '[Users] Add User Error',
  AddUserCompleted = '[Users] Add User Completed',
  OpenAddUserModal = '[Users] Open Add User Modal',
  CloseAddUserModal = '[Users] Close Add User Modal',

  EditUser = '[Users] Edit User',
  EditUserError = '[Users] Edit User Error',
  EditUserCompleted = '[Users] Edit User Completed',
  OpenEditUserModal = '[Users] Open Edit User Modal',
  CloseEditUserModal = '[Users] Close Edit User Modal',

  UpdateUserActiveStatus = '[Users] Update User Active Status',
  UpdateUserActiveStatusError = '[Users] Update User Active Status Error',
  UpdateUserActiveStatusCompleted = '[Users] Update User Active Status Completed',

  ResetUserPassword = '[Users] Reset User Password',
  ResetUserPasswordError = '[Users] Reset User Password Error',
  ResetUserPasswordCompleted = '[Users] Reset User Password Completed',

  ResetUserPasswordByAdmin = '[Users] Reset User Password By Admin',
  ResetUserPasswordByAdminError = '[Users] Reset User Password By Admin Error',
  ResetUserPasswordByAdminCompleted = '[Users] Reset User Password By Admin Completed',

  ResetUsersData = '[Users] Reset Users Data',
}

export class LoadUsersGlobal implements Action {
  readonly type = UsersActionTypes.LoadUsersGlobal;
}

export class LoadUsersGlobalSuccess implements Action {
  readonly type = UsersActionTypes.LoadUsersGlobalSuccess;
  constructor(public payload: UserDto[]) {}
}

export class LoadUsersGlobalError implements Action {
  readonly type = UsersActionTypes.LoadUsersGlobalError;
}

export class LoadUsersByCountry implements Action {
  readonly type = UsersActionTypes.LoadUsersByCountry;
  constructor(public payload: string) {}
}

export class LoadUsersByCountrySuccess implements Action {
  readonly type = UsersActionTypes.LoadUsersByCountrySuccess;
  constructor(public payload: UserDto[]) {}
}

export class LoadUsersByCountryError implements Action {
  readonly type = UsersActionTypes.LoadUsersByCountryError;
}

export class LoadUsersByBusiness implements Action {
  readonly type = UsersActionTypes.LoadUsersByBusiness;
  constructor(public payload: string) {}
}

export class LoadUsersByBusinessSuccess implements Action {
  readonly type = UsersActionTypes.LoadUsersByBusinessSuccess;
  constructor(public payload: { businessId: string; users: UserDto[] }) {}
}

export class LoadUsersByBusinessError implements Action {
  readonly type = UsersActionTypes.LoadUsersByBusinessError;
}

export class LoadUsersByUsersBusiness implements Action {
  readonly type = UsersActionTypes.LoadUsersByUsersBusiness;
}

export class LoadUsersByUsersBusinessSucess implements Action {
  readonly type = UsersActionTypes.LoadUsersByUsersBusinessSucess;
  constructor(public payload: { users: UserDto[] }) {}
}

export class LoadUsersByUsersBusinessError implements Action {
  readonly type = UsersActionTypes.LoadUsersByUsersBusinessError;
}

export class LoadUserDetails implements Action {
  readonly type = UsersActionTypes.LoadUserDetails;
  constructor(public payload: string) {}
}

export class LoadUserDetailsError implements Action {
  readonly type = UsersActionTypes.LoadUserDetailsError;
}

export class LoadUserDetailsCompleted implements Action {
  readonly type = UsersActionTypes.LoadUserDetailsCompleted;
  constructor(public payload: UserDto) {}
}

export class LoadCreateUserBusiness implements Action {
  readonly type = UsersActionTypes.LoadCreateUserBusiness;
  constructor(public payload: string) {}
}

export class LoadCreateUserBusinessError implements Action {
  readonly type = UsersActionTypes.LoadCreateUserBusinessError;
}

export class LoadCreateUserBusinessCompleted implements Action {
  readonly type = UsersActionTypes.LoadCreateUserBusinessCompleted;
  constructor(public payload: BusinessDto) {}
}

export class LoadCreateUserBranches implements Action {
  readonly type = UsersActionTypes.LoadCreateUserBranches;
  constructor(public payload: string) {}
}

export class LoadCreateUserBranchesError implements Action {
  readonly type = UsersActionTypes.LoadCreateUserBranchesError;
}

export class LoadCreateUserBranchesCompleted implements Action {
  readonly type = UsersActionTypes.LoadCreateUserBranchesCompleted;
  constructor(public payload: BranchListItemDto[]) {}
}

export class LoadCreateUserUserGroups implements Action {
  readonly type = UsersActionTypes.LoadCreateUserUserGroups;
  constructor(public payload: string) {}
}

export class LoadCreateUserUserGroupsError implements Action {
  readonly type = UsersActionTypes.LoadCreateUserUserGroupsError;
}

export class LoadCreateUserUserGroupsCompleted implements Action {
  readonly type = UsersActionTypes.LoadCreateUserUserGroupsCompleted;
  constructor(public payload: UserGroupDto[]) {}
}

export class LoadEditUserBranches implements Action {
  readonly type = UsersActionTypes.LoadEditUserBranches;
  constructor(public payload: string) {}
}

export class LoadEditUserBranchesError implements Action {
  readonly type = UsersActionTypes.LoadEditUserBranchesError;
}

export class LoadEditUserBranchesCompleted implements Action {
  readonly type = UsersActionTypes.LoadEditUserBranchesCompleted;
  constructor(public payload: BranchListItemDto[]) {}
}

export class LoadEditUserUserGroups implements Action {
  readonly type = UsersActionTypes.LoadEditUserUserGroups;
  constructor(public payload: string) {}
}

export class LoadEditUserUserGroupsError implements Action {
  readonly type = UsersActionTypes.LoadEditUserUserGroupsError;
}

export class LoadEditUserUserGroupsCompleted implements Action {
  readonly type = UsersActionTypes.LoadEditUserUserGroupsCompleted;
  constructor(public payload: UserGroupDto[]) {}
}

export class AddUser implements Action {
  readonly type = UsersActionTypes.AddUser;
  constructor(public payload: { businessId: string; createUserCommand: CreateUserCommand }) {}
}

export class AddUserError implements Action {
  readonly type = UsersActionTypes.AddUserError;
}

export class AddUserCompleted implements Action {
  readonly type = UsersActionTypes.AddUserCompleted;
  constructor(public payload: string) {}
}

export class OpenAddUserModal implements Action {
  readonly type = UsersActionTypes.OpenAddUserModal;
}

export class CloseAddUserModal implements Action {
  readonly type = UsersActionTypes.CloseAddUserModal;
}

export class EditUser implements Action {
  readonly type = UsersActionTypes.EditUser;
  constructor(public payload: UpdateUserCommand) {}
}

export class EditUserError implements Action {
  readonly type = UsersActionTypes.EditUserError;
}

export class EditUserCompleted implements Action {
  readonly type = UsersActionTypes.EditUserCompleted;
  constructor(public payload: string) {}
}

export class OpenEditUserModal implements Action {
  readonly type = UsersActionTypes.OpenEditUserModal;
}

export class CloseEditUserModal implements Action {
  readonly type = UsersActionTypes.CloseEditUserModal;
}

export class UpdateUserActiveStatus implements Action {
  readonly type = UsersActionTypes.UpdateUserActiveStatus;
  constructor(public payload: UpdateUserActiveStatusCommand) {}
}

export class UpdateUserActiveStatusError implements Action {
  readonly type = UsersActionTypes.UpdateUserActiveStatusError;
}

export class UpdateUserActiveStatusCompleted implements Action {
  readonly type = UsersActionTypes.UpdateUserActiveStatusCompleted;
  constructor(public payload: UpdateUserActiveStatusCommand) {}
}

export class ResetUserPassword implements Action {
  readonly type = UsersActionTypes.ResetUserPassword;
  constructor(public payload: ResetUserPasswordCommand) {}
}
export class ResetUserPasswordError implements Action {
  readonly type = UsersActionTypes.ResetUserPasswordError;
}
export class ResetUserPasswordCompleted implements Action {
  readonly type = UsersActionTypes.ResetUserPasswordCompleted;
}

export class ResetUserPasswordByAdmin implements Action {
  readonly type = UsersActionTypes.ResetUserPasswordByAdmin;
  constructor(public payload: { subjectId: string }) {}
}
export class ResetUserPasswordByAdminError implements Action {
  readonly type = UsersActionTypes.ResetUserPasswordByAdminError;
}
export class ResetUserPasswordByAdminCompleted implements Action {
  readonly type = UsersActionTypes.ResetUserPasswordByAdminCompleted;
}

export class ResetUsersData implements Action {
  readonly type = UsersActionTypes.ResetUsersData;
}

export type UsersAction =
  | ResetUsersData
  | LoadUsersGlobal
  | LoadUsersGlobalSuccess
  | LoadUsersGlobalError
  | LoadUsersByCountry
  | LoadUsersByCountrySuccess
  | LoadUsersByCountryError
  | LoadUsersByBusiness
  | LoadUsersByBusinessSuccess
  | LoadUsersByBusinessError
  | LoadUsersByUsersBusiness
  | LoadUsersByUsersBusinessError
  | LoadUsersByUsersBusinessSucess
  | LoadUserDetails
  | LoadUserDetailsError
  | LoadUserDetailsCompleted
  | LoadCreateUserBusiness
  | LoadCreateUserBusinessError
  | LoadCreateUserBusinessCompleted
  | LoadCreateUserBranches
  | LoadCreateUserBranchesError
  | LoadCreateUserBranchesCompleted
  | LoadCreateUserUserGroups
  | LoadCreateUserUserGroupsError
  | LoadCreateUserUserGroupsCompleted
  | LoadEditUserBranches
  | LoadEditUserBranchesError
  | LoadEditUserBranchesCompleted
  | LoadEditUserUserGroups
  | LoadEditUserUserGroupsError
  | LoadEditUserUserGroupsCompleted
  | AddUser
  | AddUserError
  | AddUserCompleted
  | OpenAddUserModal
  | CloseAddUserModal
  | EditUser
  | EditUserError
  | EditUserCompleted
  | OpenEditUserModal
  | CloseEditUserModal
  | UpdateUserActiveStatus
  | UpdateUserActiveStatusError
  | UpdateUserActiveStatusCompleted
  | ResetUserPassword
  | ResetUserPasswordError
  | ResetUserPasswordCompleted
  | ResetUserPasswordByAdmin
  | ResetUserPasswordByAdminError
  | ResetUserPasswordByAdminCompleted;

export const fromUsersActions = {
  ResetUsersData,
  LoadUsersGlobal,
  LoadUsersGlobalSuccess,
  LoadUsersGlobalError,
  LoadUsersByCountry,
  LoadUsersByCountrySuccess,
  LoadUsersByCountryError,
  LoadUsersByBusiness,
  LoadUsersByBusinessSuccess,
  LoadUsersByBusinessError,
  LoadUsersByUsersBusiness,
  LoadUsersByUsersBusinessError,
  LoadUsersByUsersBusinessSucess,
  LoadUserDetails,
  LoadUserDetailsError,
  LoadUserDetailsCompleted,
  LoadCreateUserBusiness,
  LoadCreateUserBusinessError,
  LoadCreateUserBusinessCompleted,
  LoadCreateUserBranches,
  LoadCreateUserBranchesError,
  LoadCreateUserBranchesCompleted,
  LoadCreateUserUserGroups,
  LoadCreateUserUserGroupsError,
  LoadCreateUserUserGroupsCompleted,
  LoadEditUserBranches,
  LoadEditUserBranchesError,
  LoadEditUserBranchesCompleted,
  LoadEditUserUserGroups,
  LoadEditUserUserGroupsError,
  LoadEditUserUserGroupsCompleted,
  AddUser,
  AddUserError,
  AddUserCompleted,
  OpenAddUserModal,
  CloseAddUserModal,
  EditUser,
  EditUserError,
  EditUserCompleted,
  OpenEditUserModal,
  CloseEditUserModal,
  UpdateUserActiveStatus,
  UpdateUserActiveStatusError,
  UpdateUserActiveStatusCompleted,
  ResetUserPassword,
  ResetUserPasswordError,
  ResetUserPasswordCompleted,
  ResetUserPasswordByAdmin,
  ResetUserPasswordByAdminError,
  ResetUserPasswordByAdminCompleted,
};
