import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { filter, map, switchMapTo, take } from 'rxjs/operators';
import { FixJwtInterceptor } from './interceptors/fix-jwt.interceptor';
import { authQuery } from './+state/auth.selectors';
import { AuthState } from './+state/auth.reducer';

const restServerPaths = [environment.apiGateway].map(path => path.slice(path.indexOf('//') + 2));

export function jwtOptionsFactory(store: Store<AuthState>) {
  const loadedFromCache$ = store.pipe(
    select(authQuery.getLoadedFromCacheState),
    filter(isLoaded => isLoaded)
  );

  const token$ = store.pipe(select(authQuery.getAuthToken));

  const getTokenPromise = () =>
    loadedFromCache$
      .pipe(
        switchMapTo(token$),
        map(token => {
          if (isNil(token)) {
            return null;
          }
          return token.token;
        }),
        take(1)
      )
      .toPromise();

  return {
    tokenGetter: () => {
      return getTokenPromise();
    },
    allowedDomains: restServerPaths.map(path =>
      path.indexOf('/') === -1 ? path : path.slice(0, path.indexOf('/'))
    ),
    disallowedRoutes: [
      new RegExp(`https?:\/\/${restServerPaths[0]}\/api\/Authenticate\/ResetPassword`),
      new RegExp(`https?:\/\/${restServerPaths[0]}\/api\/Auth$`),
      new RegExp(`https?:\/\/${restServerPaths[0]}\/api\/EnvironmentConfigView`),
      /\.\/.*/,
    ],
  };
}

@NgModule({
  imports: [],
  providers: [
    JwtHelperService,
    {
      provide: JWT_OPTIONS,
      useFactory: jwtOptionsFactory,
      deps: [Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FixJwtInterceptor,
      multi: true,
    },
  ],
})
export class JwtHelperModule {}
