<div *ngIf="isFilterOn" ngClass.lt-mv="no-hor-scroll">
  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'customers.addRemoveCustomerList.filter' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-text-field fieldWidth="100%" [parentFormGroup]="filteredGroup" fcName="filter"></vpfa-text-field>
    </ng-container>
  </vpfa-setting-box>
</div>

<div fxLayout="column">
  <vpfa-mobile-backend-pagination-infinite-list
    [isLoading]="loadingData"
    [elementTemplate]="checkboxTemplate"
    [totalNumberOfElements]="totalNumber"
    [list]="data"
    [endElement]="pageSize"
    [scrollStep]="pageSize"
    (loadNextPage)="onLoadNextPage()"
  ></vpfa-mobile-backend-pagination-infinite-list>

  <div class="sticky-btn" ngClass.lt-mv="no-hor-scroll">
    <vpfa-action-button (click)="onSave()" [disabled]="saveButtonDisabled()">
      {{ saveSelectionButtonTitle | translate: { count: selectedCount } }}
    </vpfa-action-button>

    <vpfa-action-button
      [outline]="true"
      [border]="false"
      (nzOnConfirm)="onCancel()"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nz-popconfirm
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      nzPopconfirmPlacement="top"
      [nzCondition]="!pendingChanges()"
    >
      {{ 'common.cancel' | translate }}
    </vpfa-action-button>
  </div>
</div>

<ng-template #checkboxTemplate let-element>
  <div style="margin-bottom: 10px">
    <vpfa-checkbox-button
      [id]="element?.id"
      [checked]="element?.checked"
      [contentTemplate]="contentTemplate"
      [contentTemplateContext]="element?.contentContext"
      (onChecked)="onCustomerChecked($event)"
      (onUnchecked)="onCustomerUnchecked($event)"
    >
      <button class="ssm-icon-btn" (click)="onEdit(element?.id); $event.stopPropagation();">
        <span nz-icon nzType="edit" nzTheme="fill"></span>
      </button>
    </vpfa-checkbox-button>
  </div>
</ng-template>
