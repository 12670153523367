import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { isInBlackList } from './api-exception-black-list';
import { ErrorCode } from '@vpfa/rest-api/valuation';
import { ManageStorageService } from '@vpfa/auth';
import { readVpErrorData } from '@vpfa/utils';

@Injectable()
export class ApiExceptionInterceptor implements HttpInterceptor {
  private notifications: BasicNotificationsService;
  constructor(private injector: Injector, private manageStorageService: ManageStorageService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          request.url.startsWith(environment.apiGateway) &&
          !isInBlackList(request.url.split(`${environment.apiGateway}/`)[1])
        ) {
          this.handleError(err);
        }

        return throwError(err);
      })
    );
  }

  private handleError(err: HttpErrorResponse) {
    if (!this.notifications) this.notifications = this.injector.get(BasicNotificationsService);
    switch (err.status) {
      case 403:
        if (this.manageStorageService.hasToken()) {
          this.notifications.error('forbidden');
        }
        break;
      case 400:
      case 409:
      case 500:
        if (this.isErrorHandled(err) || this.isVPErrorHandled(err)) {
          break;
        }
        const errorCode = `vpError.${
          err.error.vpError
            ? err.error.vpError.codeName
            : err.error.codeName
            ? err.error.codeName
            : 'UnknownServerError'
        }`;
        this.notifications.error(errorCode, null, null, this.getErrorIdBasedOnErrorCode(err));
        break;
      case 404:
        if (this.isErrorHandled(err)) {
          break;
        }

        this.isFileNotFoundError(err).then(isFileNotFound => {
          if (isFileNotFound) {
            this.notifications.error('vpError.FileNotFound');
          } else {
            this.notifications.error('common.pageNotFound');
          }
        });
        break;
      case 499:
      case 504:
        this.notifications.error('vpError.TimeoutException', null, null, this.getErrorIdBasedOnErrorCode(err));
        break;
    }
  }

  private async isFileNotFoundError(err: HttpErrorResponse) {
    const fileNotFoundErrorCodes: ErrorCode[] = [ErrorCode.FileNotFound];

    // when requesting a file, json is stored as blob
    if (err.error instanceof Blob === false) {
      return false;
    }

    const content = JSON.parse(await (err.error as Blob).text());

    return fileNotFoundErrorCodes.includes(content.code ?? content.Code);
  }

  private isErrorHandled(err: HttpErrorResponse): boolean {
    //not-found errors
    const handledErrorCodes: ErrorCode[] = [
      ErrorCode.CaseNotFound,
      ErrorCode.CustomerNotFound,
      ErrorCode.VrmNotFound,
      ErrorCode.ValuationKernelNotFound,
      ErrorCode.AmsSubjectNotFound,
    ];
    return handledErrorCodes.includes(ErrorCode[readVpErrorData(err)?.codeName]);
  }

  private isVPErrorHandled(err: HttpErrorResponse): boolean {
    // VP errors
    const handledVPErrorCodes: ErrorCode[] = [
      ErrorCode.NotAbleToProvideValuationForVehicleOutOfValuationRange,
      ErrorCode.CannotMakeValuationForOldVehicle,
    ];
    return handledVPErrorCodes.includes(ErrorCode[readVpErrorData(err)?.codeName]);
  }

  private getErrorIdBasedOnErrorCode(err: HttpErrorResponse): string {
    return readVpErrorData(err)?.id;
  }
}
