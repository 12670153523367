import { Action } from '@ngrx/store';
import { Entity } from './countries.reducer';
import {
  AddContactUsSalesforceEnquiryTypeCommand,
  AddCountryAlternativeContentLanguageCommand,
  AddCountryAlternativeLanguageCommand,
  CommandHandlerResponse as AdminCommandHandlerResponseSuccess,
  CountryDto,
  CountryVinThresholdDto,
  ExperianCountrySettingsDto,
  SetExperianHostUrlCommand,
  TableConfigurationDto,
  UpdateContactUsDefaultClassificationCommand,
  UpdateContactUsDefaultPriorityCommand,
  UpdateContactUsDefaultTypeCommand,
  UpdateContactUsHeaderTextsCommand,
  UpdateContactUsSalesforceCountryCodeCommand,
  UpdateContactUsSalesforceEnquiryTypeCommand,
  UpdateCountryCurrencyCommand,
  UpdateCountryDefaultContentLanguageCommand,
  UpdateCountryDefaultLanguageCommand,
  UpdateCountryHotlineEmailCommand,
  UpdateCountryHotlinePhoneCommand,
  UpdateCountryLocaleCommand,
  UpdateCountryTimezoneCommand,
  UpdateCountryUcNumberSourceCommand,
  UpdateCountryUrlCommand,
  UpdateCountryVinThresholdCommand,
  UpdateExperianTermsAndConditionsUrlCommand,
  UpdateTableConfigurationCommand,
} from '@vpfa/rest-api/admin';
import { CountryTaxDto, UpdateCountryTaxTypeCommand } from '@vpfa/rest-api/valuation';

import {
  CommandHandlerResponse,
  CommandHandlerResponse as BroadcastCommandHandlerResponseSuccess,
  CreateCountryBroadcastConnectionFtpConfigurationCommand,
  UpdateCountryBroadcastConnectionConfigurationHostnameCommand,
  UpdateCountryBroadcastConnectionConfigurationIsActiveCommand,
  UpdateCountryBroadcastConnectionConfigurationNameCommand,
  UpdateCountryBroadcastConnectionConfigurationPasswordCommand,
  UpdateCountryBroadcastConnectionConfigurationUsernameCommand,
  UpdateCountryBroadcastConnectionFtpConfigurationCommand,
} from '@vpfa/rest-api/ad-broadcast';
import {
  GdvInsuranceClaimingConfigurationDto,
  UpdateGdvInsuranceClaimingConfigurationPasswordCommand,
  UpdateGdvInsuranceClaimingConfigurationUsernameCommand,
} from '@vpfa/rest-api/vehicle-insurance-claiming';
import { LanguageEnum } from '@vpfa/shared/translate';
import { CountryBroadcastConnectionConfigurationMappingsListItemDto } from '@vpfa/rest-api/ad-broadcast';

export interface DeleteCountryAlternativeLanguageCommand {
  countryId: string;
  alternativeLanguage: LanguageEnum;
}

export interface DeleteCountryAlternativeContentLanguageCommand {
  countryId: string;
  alternativeContentLanguage: LanguageEnum;
}

export interface LogoUploadCommand {
  aggregateRootId: string;
  file: File;
}

export enum CountriesActionTypes {
  LoadCountries = '[Countries] Load Countries',
  CountriesLoaded = '[Countries] Countries Loaded',
  CountriesLoadError = '[Countries] Countries Load Error',
  LoadCountryDetails = '[Countries] Country Details :: Load Country Details',
  CountryDetailsLoaded = '[Countries] Country Details :: Country Details Loaded',
  CountryDetailsLoadError = '[Countries] Country Details :: Country Details Load Error',
  UpdateCountryDefaultLanguage = '[Countries] Country Details :: Update Country Default Language',
  CountryDefaultLanguageUpdated = '[Countries] Country Details :: Country Default Language Updated',
  CountryDefaultLanguageError = '[Countries] Country Details :: Country Default Language Error',
  UpdateCountryDefaultContentLanguage = '[Countries] Country Details :: Update Country Default Content Language',
  CountryDefaultContentLanguageUpdated = '[Countries] Country Details ::  Country Default Content Language Updated',
  CountryDefaultContentLanguageError = '[Countries] Country Details :: Country Default Content Language Error',
  AddCountryAlternativeLanguage = '[Countries] Country Details :: Add Country Alternative Language',
  RemoveCountryAlternativeLanguage = '[Countries] Country Details :: Remove Country Alternative Language',
  CountryAlternativeLanguageAdded = '[Countries] Country Details :: Country Alternative Language Added',
  CountryAlternativeLanguageRemoved = '[Countries] Country Details :: Country Alternative Language Removed',
  CountryAlternativeLanguageError = '[Countries] Country Details :: Country Alternative Language Error',
  AddCountryAlternativeContentLanguage = '[Countries] Country Details :: Update Country Alternative Content Language',
  RemoveCountryAlternativeContentLanguage = '[Countries] Country Details :: Remove Country Alternative Content Language',
  CountryAlternativeContentLanguageAdded = '[Countries] Country Details ::  Country Default Alternative Content Language Added',
  CountryAlternativeContentLanguageRemoved = '[Countries] Country Details ::  Country Default Alternative Content Language Removed',
  CountryAlternativeContentLanguageError = '[Countries] Country Details :: Country Default Alternative Content Language Error',
  UpdateCountryLocale = '[Countries] Country Details :: Update Country Locale',
  CountryLocaleUpdated = '[Countries] Country Details :: Country Locale Updated',
  CountryLocaleError = '[Countries] Country Details :: Country Locale Error',
  UpdateCountryCurrency = '[Countries] Country Details :: Update Country Currency',
  CountryCurrencyUpdated = '[Countries] Country Details :: Country Currency Updated',
  CountryCurrencyError = '[Countries] Country Details :: Country Currency Error',
  CountryValuationListInitialSetOfFieldsUpdate = '[Countries] Country Details :: Valuation List Initial Set Of Fields Update',
  CountryValuationListInitialSetOfFieldsUpdated = '[Countries] Country Details :: Valuation List Initial Set Of Fields Updated',
  CountryValuationListInitialSetOfFieldsUpdateError = '[Countries] Country Details :: Valuation List Initial Set Of Fields Update Error',
  CountryValuationListInitialSetOfFieldsLoad = '[Countries] Country Details :: Valuation List Initial Set Of Fields Load',
  CountryValuationListInitialSetOfFieldsLoaded = '[Countries] Country Details :: Valuation List Initial Set Of Fields Loaded',
  CountryValuationListInitialSetOfFieldsLoadError = '[Countries] Country Details :: Valuation List Initial Set Of Fields Load Error',
  CountryStockListInitialSetOfFieldsUpdate = '[Countries] Country Details :: Stock List Initial Set Of Fields Update',
  CountryStockListInitialSetOfFieldsUpdated = '[Countries] Country Details :: Stock List Initial Set Of Fields Updated',
  CountryStockListInitialSetOfFieldsUpdateError = '[Countries] Country Details :: Stock List Initial Set Of Fields Update Error',
  CountryStockListInitialSetOfFieldsLoad = '[Countries] Country Details :: Stock List Initial Set Of Fields Load',
  CountryStockListInitialSetOfFieldsLoaded = '[Countries] Country Details :: Stock List Initial Set Of Fields Loaded',
  CountryStockListInitialSetOfFieldsLoadError = '[Countries] Country Details :: Stock List Initial Set Of Fields Load Error',
  CountryCustomersListInitialSetOfFieldsUpdate = '[Countries] Country Details :: Customers List Initial Set Of Fields Update',
  CountryCustomersListInitialSetOfFieldsUpdated = '[Countries] Country Details :: Customers List Initial Set Of Fields Updated',
  CountryCustomersListInitialSetOfFieldsUpdateError = '[Countries] Country Details :: Customers List Initial Set Of Fields Update Error',
  CountryCustomersListInitialSetOfFieldsLoad = '[Countries] Country Details :: Customers List Initial Set Of Fields Load',
  CountryCustomersListInitialSetOfFieldsLoaded = '[Countries] Country Details :: Customers List Initial Set Of Fields Loaded',
  CountryCustomersListInitialSetOfFieldsLoadError = '[Countries] Country Details :: Customers List Initial Set Of Fields Load Error',
  UpdateCountryTimezone = '[Countries] Country Details :: Update Country Timezone',
  CountryTimezoneUpdated = '[Countries] Country Details :: Country Timezone Updated',
  CountryTimezoneError = '[Countries] Country Details :: Country Timezone Error',
  LoadCountryTaxType = '[Countries] Country General :: Load Country Tax Type',
  LoadCountryTaxTypeError = '[Countries] Country General :: Load Country Tax Type Error',
  LoadCountryTaxTypeSuccess = '[Countries] Country General :: Load Country Tax Type Success',

  LoadBroadcastList = '[Countries] Load Broadcast List',
  LoadBroadcastListError = '[Countries] Load Broadcast List Error',
  LoadBroadcastListSuccess = '[Countries] Load Broadcast List Success',

  LoadBroadcastCountryMappingList = '[Countries] Load Broadcast Country Mapping List',
  LoadBroadcastCountryMappingListError = '[Countries] Load Broadcast Country Mapping List Error',
  LoadBroadcastCountryMappingListSuccess = '[Countries] Load Broadcast Country Mapping List Success',

  LoadBroadcastDetails = '[Countries] Load Broadcast Details',
  LoadBroadcastDetailsError = '[Countries] Load Broadcast Details Error',
  LoadBroadcastDetailsSuccess = '[Countries] Load Broadcast Details Success',

  UpdateBroadcastHostname = '[Countries] Update Broadcast Hostname',
  UpdateBroadcastHostnameError = '[Countries] Update Broadcast Hostname Error',
  UpdateBroadcastHostnameSuccess = '[Countries] Update Broadcast Hostname Success',

  UpdateBroadcastIsActive = '[Countries] Update Broadcast IsActive',
  UpdateBroadcastIsActiveError = '[Countries] Update Broadcast IsActive Error',
  UpdateBroadcastIsActiveSuccess = '[Countries] Update Broadcast IsActive Success',

  UpdateBroadcastName = '[Countries] Update Broadcast Name',
  UpdateBroadcastNameError = '[Countries] Update Broadcast Name Error',
  UpdateBroadcastNameSuccess = '[Countries] Update Broadcast Name Success',

  UpdateBroadcastPassword = '[Countries] Update Broadcast Password',
  UpdateBroadcastPasswordError = '[Countries] Update Broadcast Password Error',
  UpdateBroadcastPasswordSuccess = '[Countries] Update Broadcast Password Success',

  UpdateBroadcastUsername = '[Countries] Update Broadcast Username',
  UpdateBroadcastUsernameError = '[Countries] Update Broadcast Username Error',
  UpdateBroadcastUsernameSuccess = '[Countries] Update Broadcast Username Success',

  UpdateBroadcastLogo = '[Countries] Update Broadcast Logo',
  UpdateBroadcastLogoError = '[Countries] Update Broadcast Logo Error',
  UpdateBroadcastLogoSuccess = '[Countries] Update Broadcast Logo Success',

  LoadBroadcastLogo = '[Countries] Load Broadcast Logo',
  LoadBroadcastLogoError = '[Countries] Load Broadcast Logo Error',
  LoadBroadcastLogoSuccess = '[Countries] Load Broadcast Logo Success',

  UpdateBroadcastCertificate = '[Countries] Update Broadcast Certificate',
  UpdateBroadcastCertificateError = '[Countries] Update Broadcast Certificate Error',
  UpdateBroadcastCertificateSuccess = '[Countries] Update Broadcast Certificate Success',

  LoadBroadcastCertificate = '[Countries] Load Broadcast Certificate',
  LoadBroadcastCertificateError = '[Countries] Load Broadcast Certificate Error',
  LoadBroadcastCertificateSuccess = '[Countries] Load Broadcast Certificate Success',

  CreateFtpExport = '[Countries] Create Ftp Export',
  CreateFtpExportError = '[Countries] Create Ftp Export Error',
  CreateFtpExportSuccess = '[Countries] Create Ftp Export Success',

  EditFtpExport = '[Countries] Edit Ftp Export',
  EditFtpExportError = '[Countries] Edit Ftp Export Error',
  EditFtpExportSuccess = '[Countries] Edit Ftp Export Success',

  UpdateCountryTaxType = '[Countries] Country General :: Update Country Tax Type',
  UpdateCountryTaxTypeError = '[Countries] Country General :: Update Country Tax Type Error',
  UpdateCountryTaxTypeSuccess = '[Countries] Country General :: Update Country Tax Type Success',
  UpdateUCNumberSourceType = '[Countries] Country General :: Update UC Number Source Type',
  UpdateUCNumberSourceTypeError = '[Countries] Country General :: Update UC Number Source Type Error',
  UpdateUCNumberSourceTypeSuccess = '[Countries] Country General :: Update UC Number Source Success',
  LogoUpload = '[Countries] Country General :: Logo Upload ',
  LogoUploaded = '[Countries] Country General :: Logo Uploaded',
  LogoUploadError = '[Countries] Country General :: Logo Upload Error',
  LogoLoad = '[Countries] Country General :: Logo Load ',
  LogoLoaded = '[Countries] Country General :: Logo Loaded',
  LogoLoadError = '[Countries] Country General :: Logo Load Error',
  UpdateCountryUrl = '[Countries] Country General :: Update Country Url',
  UpdateCountryUrlError = '[Countries] Country General :: Update Country Url Error',
  UpdateCountryUrlSuccess = '[Countries] Country General :: Update Country Url Success',
  UpdateCountryHotlinePhone = '[Countries] Country General :: Update Country Hotline Phone',
  UpdateCountryHotlinePhoneError = '[Countries] Country General :: Update Country Hotline Phone Error',
  UpdateCountryHotlinePhoneSuccess = '[Countries] Country General :: Update Country Hotline Phone Success',
  UpdateCountryHotlineEmail = '[Countries] Country General :: Update Country Hotline Email',
  UpdateCountryHotlineEmailError = '[Countries] Country General :: Update Country Hotline Email Error',
  UpdateCountryHotlineEmailSuccess = '[Countries] Country General :: Update Country Hotline Email Success',

  UpdateContactUsCountryCode = '[Countries] Country Contact Us :: Update Country Code',
  UpdateContactUsCountryCodeError = '[Countries] Country Contact Us :: Update Country Code Error',
  UpdateContactUsCountryCodeSuccess = '[Countries] Country Contact Us :: Update Country Code Success',

  UpdateContactUsDefaultPriority = '[Countries] Country Contact Us :: Update Default Priority',
  UpdateContactUsDefaultPriorityError = '[Countries] Country Contact Us :: Update Default Priority Error',
  UpdateContactUsDefaultPrioritySuccess = '[Countries] Country Contact Us :: Update Default Priority Success',

  UpdateContactUsDefaultType = '[Countries] Country Contact Us :: Update Default Type',
  UpdateContactUsDefaultTypeError = '[Countries] Country Contact Us :: Update Default Type Error',
  UpdateContactUsDefaultTypeSuccess = '[Countries] Country Contact Us :: Update Default Type Success',

  UpdateContactUsDefaultClassification = '[Countries] Country Contact Us :: Update Default Classification',
  UpdateContactUsDefaultClassificationError = '[Countries] Country Contact Us :: Update Default Classification Error',
  UpdateContactUsDefaultClassificationSuccess = '[Countries] Country Contact Us :: Update Default Classification Success',

  UpdateContactUsEnquiryType = '[Countries] Country Contact Us :: Update Enquiry type',
  UpdateContactUsEnquiryTypeError = '[Countries] Country Contact Us :: Update Enquiry type Error',
  UpdateContactUsEnquiryTypeSuccess = '[Countries] Country Contact Us :: Update Enquiry type Success',

  AddContactUsEnquiryType = '[Countries] Country Contact Us :: Add Enquiry type',
  AddContactUsEnquiryTypeError = '[Countries] Country Contact Us :: Add Enquiry type Error',
  AddContactUsEnquiryTypeSuccess = '[Countries] Country Contact Us :: Add Enquiry type Success',

  DeleteContactUsEnquiryType = '[Countries] Country Contact Us :: Delete Enquiry type',
  DeleteContactUsEnquiryTypeError = '[Countries] Country Contact Us :: Delete Enquiry type Error',
  DeleteContactUsEnquiryTypeSuccess = '[Countries] Country Contact Us :: Delete Enquiry type Success',

  UpdateContactUsHeaderTexts = '[Countries] Country Contact Us :: Update Header Texts',
  UpdateContactUsHeaderTextsError = '[Countries] Country Contact Us :: Update Header Texts Error',
  UpdateContactUsHeaderTextsSuccess = '[Countries] Country Contact Us :: Update Header Texts Success',

  LoadVinThreshold = '[Countries] Country Load VIN Threshold',
  LoadVinThresholdSuccess = '[Countries] Country Load VIN Threshold Success',
  LoadVinThresholdError = '[Countries] Country Load VIN Threshold Error',

  UpdateVinThreshold = '[Countries] Country Update VIN Threshold',
  UpdateVinThresholdSuccess = '[Countries] Country Update VIN Threshold Success',
  UpdateVinThresholdError = '[Countries] Country Update VIN Threshold Error',

  LoadExperianCountrySettings = '[Countries] Load Experian Country Settings',
  LoadExperianCountrySettingsSuccess = '[Countries] Load Experian Country Settings Success',
  LoadExperianCountrySettingsError = '[Countries] Load Experian Country Settings Error',

  UpdateExperianHostUrl = '[Countries] Update Experian Country Host Url',
  UpdateExperianHostUrlSuccess = '[Countries] Update Experian Country Host Url Success',
  UpdateExperianHostUrlError = '[Countries] Update Experian Country Host Url Error',

  UpdateExperianTermsAndConditionsUrl = '[Countries] Update Experian Country Terms And Conditions Url',
  UpdateExperianTermsAndConditionsUrlSuccess = '[Countries] Update Experian Country Host Terms And Conditions Success',
  UpdateExperianTermsAndConditionsUrlError = '[Countries] Update Experian Country Host Terms And Conditions Error',

  LoadCountryGdv = '[Countries] Country Gdv :: Load Country Gdv',
  LoadCountryGdvError = '[Countries] Country Gdv :: Load Country Gdv Error',
  LoadCountryGdvSuccess = '[Countries] Country Gdv :: Load Country Gdv Success',

  UpdateCountryGdvUsername = '[Countries] Country Gdv :: Update Country Gdv Username',
  UpdateCountryGdvUsernameError = '[Countries] Country Gdv :: Update Country Gdv Username Error',
  UpdateCountryGdvUsernameSuccess = '[Countries] Country Gdv :: Update Country Gdv Username Success',

  UpdateCountryGdvPassword = '[Countries] Country Gdv :: Update Country Gdv Password',
  UpdateCountryGdvPasswordError = '[Countries] Country Gdv :: Update Country Gdv Password Error',
  UpdateCountryGdvPasswordSuccess = '[Countries] Country Gdv :: Update Country Gdv Password Success',
}

export class LoadCountries implements Action {
  readonly type = CountriesActionTypes.LoadCountries;
}

export class CountriesLoadError implements Action {
  readonly type = CountriesActionTypes.CountriesLoadError;
}

export class CountriesLoaded implements Action {
  readonly type = CountriesActionTypes.CountriesLoaded;
  constructor(public payload: Entity[]) {}
}

export class LoadCountryDetails implements Action {
  readonly type = CountriesActionTypes.LoadCountryDetails;
  constructor(public payload: string) {}
}

export class CountryDetailsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryDetailsLoaded;
  constructor(public payload: CountryDto) {}
}

export class CountryDetailsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryDetailsLoadError;
}

export class UpdateCountryDefaultLanguage implements Action {
  readonly type = CountriesActionTypes.UpdateCountryDefaultLanguage;
  constructor(public payload: UpdateCountryDefaultLanguageCommand) {}
}

export class CountryDefaultLanguageUpdated implements Action {
  readonly type = CountriesActionTypes.CountryDefaultLanguageUpdated;
  constructor(public payload: LanguageEnum) {}
}

export class CountryDefaultLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryDefaultLanguageError;
}

export class UpdateCountryDefaultContentLanguage implements Action {
  readonly type = CountriesActionTypes.UpdateCountryDefaultContentLanguage;
  constructor(public payload: UpdateCountryDefaultContentLanguageCommand) {}
}

export class CountryDefaultContentLanguageUpdated implements Action {
  readonly type = CountriesActionTypes.CountryDefaultContentLanguageUpdated;
  constructor(public payload: LanguageEnum) {}
}

export class CountryDefaultContentLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryDefaultContentLanguageError;
}

export class AddCountryAlternativeLanguage implements Action {
  readonly type = CountriesActionTypes.AddCountryAlternativeLanguage;
  constructor(public payload: AddCountryAlternativeLanguageCommand) {}
}

export class RemoveCountryAlternativeLanguage implements Action {
  readonly type = CountriesActionTypes.RemoveCountryAlternativeLanguage;
  constructor(public payload: DeleteCountryAlternativeLanguageCommand) {}
}

export class CountryAlternativeLanguageAdded implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeLanguageAdded;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeLanguageRemoved implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeLanguageRemoved;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeLanguageError;
}

export class AddCountryAlternativeContentLanguage implements Action {
  readonly type = CountriesActionTypes.AddCountryAlternativeContentLanguage;
  constructor(public payload: AddCountryAlternativeContentLanguageCommand) {}
}

export class RemoveCountryAlternativeContentLanguage implements Action {
  readonly type = CountriesActionTypes.RemoveCountryAlternativeContentLanguage;
  constructor(public payload: DeleteCountryAlternativeContentLanguageCommand) {}
}

export class CountryAlternativeContentLanguageAdded implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeContentLanguageAdded;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeContentLanguageRemoved implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeContentLanguageRemoved;
  constructor(public payload: LanguageEnum) {}
}

export class CountryAlternativeContentLanguageError implements Action {
  readonly type = CountriesActionTypes.CountryAlternativeContentLanguageError;
}

export class UpdateCountryTimezone implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTimezone;
  constructor(public payload: UpdateCountryTimezoneCommand) {}
}

export class CountryTimezoneUpdated implements Action {
  readonly type = CountriesActionTypes.CountryTimezoneUpdated;
  constructor(public payload: string) {}
}

export class CountryTimezoneError implements Action {
  readonly type = CountriesActionTypes.CountryTimezoneError;
}

export class UpdateCountryLocale implements Action {
  readonly type = CountriesActionTypes.UpdateCountryLocale;
  constructor(public payload: UpdateCountryLocaleCommand) {}
}

export class CountryLocaleUpdated implements Action {
  readonly type = CountriesActionTypes.CountryLocaleUpdated;
  constructor(public payload: string) {}
}

export class CountryLocaleError implements Action {
  readonly type = CountriesActionTypes.CountryLocaleError;
}

export class CountryValuationListInitialSetOfFieldsUpdate implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdate;
  constructor(public payload: { countryId: string; command: UpdateTableConfigurationCommand }) {}
}

export class CountryValuationListInitialSetOfFieldsUpdated implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdated;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryValuationListInitialSetOfFieldsUpdateError implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdateError;
}

export class CountryValuationListInitialSetOfFieldsLoad implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoad;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryValuationListInitialSetOfFieldsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoaded;
  constructor(public payload: TableConfigurationDto) {}
}

export class CountryValuationListInitialSetOfFieldsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoadError;
}

export class CountryStockListInitialSetOfFieldsUpdate implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdate;
  constructor(public payload: { countryId: string; command: UpdateTableConfigurationCommand }) {}
}

export class CountryStockListInitialSetOfFieldsUpdated implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdated;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryStockListInitialSetOfFieldsUpdateError implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdateError;
}

export class CountryStockListInitialSetOfFieldsLoad implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsLoad;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryStockListInitialSetOfFieldsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsLoaded;
  constructor(public payload: TableConfigurationDto) {}
}

export class CountryStockListInitialSetOfFieldsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryStockListInitialSetOfFieldsLoadError;
}

export class CountryCustomersListInitialSetOfFieldsUpdate implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdate;
  constructor(public payload: { countryId: string; command: UpdateTableConfigurationCommand }) {}
}

export class CountryCustomersListInitialSetOfFieldsUpdated implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdated;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryCustomersListInitialSetOfFieldsUpdateError implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdateError;
}

export class CountryCustomersListInitialSetOfFieldsLoad implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoad;
  constructor(public payload: { countryId: string; tableName: string }) {}
}

export class CountryCustomersListInitialSetOfFieldsLoaded implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoaded;
  constructor(public payload: TableConfigurationDto) {}
}

export class CountryCustomersListInitialSetOfFieldsLoadError implements Action {
  readonly type = CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoadError;
}

export class UpdateCountryCurrency implements Action {
  readonly type = CountriesActionTypes.UpdateCountryCurrency;
  constructor(public payload: UpdateCountryCurrencyCommand) {}
}

export class CountryCurrencyUpdated implements Action {
  readonly type = CountriesActionTypes.CountryCurrencyUpdated;
  constructor(public payload: string) {}
}

export class CountryCurrencyError implements Action {
  readonly type = CountriesActionTypes.CountryCurrencyError;
}

export class UpdateCountryUrl implements Action {
  readonly type = CountriesActionTypes.UpdateCountryUrl;
  constructor(public payload: UpdateCountryUrlCommand) {}
}

export class UpdateCountryUrlError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryUrlError;
}

export class UpdateCountryUrlSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryUrlSuccess;
  constructor(public payload: { countryId: string; countryNamesWithSameUrl: string[] }) {}
}

export class UpdateUCNumberSourceType implements Action {
  readonly type = CountriesActionTypes.UpdateUCNumberSourceType;
  constructor(public payload: UpdateCountryUcNumberSourceCommand) {}
}

export class UpdateUCNumberSourceTypeError implements Action {
  readonly type = CountriesActionTypes.UpdateUCNumberSourceTypeError;
}

export class UpdateUCNumberSourceTypeSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateUCNumberSourceTypeSuccess;
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
}

export class LoadCountryTaxType implements Action {
  readonly type = CountriesActionTypes.LoadCountryTaxType;
  constructor(public payload: string) {}
}

export class LoadCountryTaxTypeError implements Action {
  readonly type = CountriesActionTypes.LoadCountryTaxTypeError;
}

export class LoadCountryTaxTypeSuccess implements Action {
  readonly type = CountriesActionTypes.LoadCountryTaxTypeSuccess;
  constructor(public payload: CountryTaxDto) {}
}

export class LoadBroadcastList implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastList;
  constructor(public payload: string) {}
}

export class LoadBroadcastListError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastListError;
}

export class LoadBroadcastListSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastListSuccess;
  constructor(public payload: any) {}
}

export class LoadBroadcastCountryMappingList implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCountryMappingList;
  constructor(public payload: string) {}
}

export class LoadBroadcastCountryMappingListError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCountryMappingListError;
}

export class LoadBroadcastCountryMappingListSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCountryMappingListSuccess;
  constructor(public payload: CountryBroadcastConnectionConfigurationMappingsListItemDto[]) {}
}

export class LoadBroadcastDetails implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastDetails;
  constructor(public payload: { broadcastId: string; countryId: string }) {}
}

export class LoadBroadcastDetailsError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastDetailsError;
}

export class LoadBroadcastDetailsSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastDetailsSuccess;
  constructor(public payload: any) {}
}

export class UpdateBroadcastHostname implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastHostname;
  constructor(public payload: UpdateCountryBroadcastConnectionConfigurationHostnameCommand) {}
}

export class UpdateBroadcastHostnameError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastHostnameError;
}

export class UpdateBroadcastHostnameSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastHostnameSuccess;
  constructor(public payload: any) {}
}

export class UpdateBroadcastIsActive implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastIsActive;
  constructor(public payload: UpdateCountryBroadcastConnectionConfigurationIsActiveCommand) {}
}

export class UpdateBroadcastIsActiveError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastIsActiveError;
}

export class UpdateBroadcastIsActiveSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastIsActiveSuccess;
  constructor(public payload: BroadcastCommandHandlerResponseSuccess) {}
}

export class UpdateBroadcastName implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastName;
  constructor(public payload: UpdateCountryBroadcastConnectionConfigurationNameCommand) {}
}

export class UpdateBroadcastNameError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastNameError;
}

export class UpdateBroadcastNameSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastNameSuccess;
  constructor(public payload: BroadcastCommandHandlerResponseSuccess) {}
}

export class UpdateBroadcastPassword implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastPassword;
  constructor(public payload: UpdateCountryBroadcastConnectionConfigurationPasswordCommand) {}
}

export class UpdateBroadcastPasswordError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastPasswordError;
}

export class UpdateBroadcastPasswordSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastPasswordSuccess;
  constructor(public payload: { broadcastId: string; countryId: string }) {}
}

export class UpdateBroadcastUsername implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastUsername;
  constructor(public payload: UpdateCountryBroadcastConnectionConfigurationUsernameCommand) {}
}

export class UpdateBroadcastUsernameError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastUsernameError;
}

export class UpdateBroadcastUsernameSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastUsernameSuccess;
  constructor(public payload: BroadcastCommandHandlerResponseSuccess) {}
}

export class UpdateBroadcastLogo implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastLogo;
  constructor(public payload: { aggregateRootId: string; file: Blob }) {}
}

export class UpdateBroadcastLogoError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastLogoError;
}

export class UpdateBroadcastLogoSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastLogoSuccess;
  constructor(public payload: BroadcastCommandHandlerResponseSuccess) {}
}

export class LoadBroadcastLogo implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastLogo;
  constructor(public payload: string) {}
}

export class LoadBroadcastLogoError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastLogoError;
}

export class LoadBroadcastLogoSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastLogoSuccess;
  constructor(public payload: string) {}
}

export class UpdateBroadcastCertificate implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastCertificate;
  constructor(public payload: { aggregateRootId: string; countryId: string; file: Blob }) {}
}

export class UpdateBroadcastCertificateError implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastCertificateError;
}

export class UpdateBroadcastCertificateSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateBroadcastCertificateSuccess;
  constructor(public payload: BroadcastCommandHandlerResponseSuccess) {}
}

export class LoadBroadcastCertificate implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCertificate;
  constructor(public payload: string) {}
}

export class LoadBroadcastCertificateError implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCertificateError;
}

export class LoadBroadcastCertificateSuccess implements Action {
  readonly type = CountriesActionTypes.LoadBroadcastCertificateSuccess;
  constructor(public payload: string) {}
}

export class CreateFtpExport implements Action {
  readonly type = CountriesActionTypes.CreateFtpExport;
  constructor(public payload: { ftpConfig: CreateCountryBroadcastConnectionFtpConfigurationCommand; file: any }) {}
}

export class CreateFtpExportError implements Action {
  readonly type = CountriesActionTypes.CreateFtpExportError;
}

export class CreateFtpExportSuccess implements Action {
  readonly type = CountriesActionTypes.CreateFtpExportSuccess;
  constructor(public payload: { res: CommandHandlerResponse; file: any }) {}
}

export class EditFtpExport implements Action {
  readonly type = CountriesActionTypes.EditFtpExport;
  constructor(
    public payload: {
      ftpConfig: UpdateCountryBroadcastConnectionFtpConfigurationCommand;
      file?: any;
    }
  ) {}
}

export class EditFtpExportError implements Action {
  readonly type = CountriesActionTypes.EditFtpExportError;
}

export class EditFtpExportSuccess implements Action {
  readonly type = CountriesActionTypes.EditFtpExportSuccess;
  constructor(public payload: { res: CommandHandlerResponse; file?: any }) {}
}

export class UpdateCountryTaxType implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTaxType;
  constructor(public payload: UpdateCountryTaxTypeCommand) {}
}

export class UpdateCountryTaxTypeError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTaxTypeError;
}

export class UpdateCountryTaxTypeSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryTaxTypeSuccess;
}

export class LogoUpload implements Action {
  readonly type = CountriesActionTypes.LogoUpload;
  constructor(public payload: LogoUploadCommand) {}
}

export class LogoUploaded implements Action {
  readonly type = CountriesActionTypes.LogoUploaded;
  constructor(public payload: string) {}
}

export class LogoUploadError implements Action {
  readonly type = CountriesActionTypes.LogoUploadError;
}

export class LogoLoad implements Action {
  readonly type = CountriesActionTypes.LogoLoad;
  constructor(public payload: string) {}
}

export class LogoLoaded implements Action {
  readonly type = CountriesActionTypes.LogoLoaded;
  constructor(public payload: string) {}
}

export class LogoLoadError implements Action {
  readonly type = CountriesActionTypes.LogoLoadError;
}

export class UpdateCountryHotlinePhone implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlinePhone;
  constructor(public payload: UpdateCountryHotlinePhoneCommand) {}
}

export class UpdateCountryHotlinePhoneError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlinePhoneError;
}

export class UpdateCountryHotlinePhoneSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateCountryHotlinePhoneSuccess;
}

export class UpdateCountryHotlineEmail implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlineEmail;
  constructor(public payload: UpdateCountryHotlineEmailCommand) {}
}

export class UpdateCountryHotlineEmailError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryHotlineEmailError;
}

export class UpdateCountryHotlineEmailSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateCountryHotlineEmailSuccess;
}

export class UpdateContactUsCountryCode implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsCountryCode;
  constructor(public payload: UpdateContactUsSalesforceCountryCodeCommand) {}
}

export class UpdateContactUsCountryCodeError implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsCountryCodeError;
}

export class UpdateContactUsCountryCodeSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateContactUsCountryCodeSuccess;
}

export class UpdateContactUsDefaultPriority implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsDefaultPriority;
  constructor(public payload: UpdateContactUsDefaultPriorityCommand) {}
}

export class UpdateContactUsDefaultPriorityError implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsDefaultPriorityError;
}

export class UpdateContactUsDefaultPrioritySuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateContactUsDefaultPrioritySuccess;
}

export class UpdateContactUsDefaultType implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsDefaultType;
  constructor(public payload: UpdateContactUsDefaultTypeCommand) {}
}

export class UpdateContactUsDefaultTypeError implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsDefaultTypeError;
}

export class UpdateContactUsDefaultTypeSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateContactUsDefaultTypeSuccess;
}

export class UpdateContactUsDefaultClassification implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsDefaultClassification;
  constructor(public payload: UpdateContactUsDefaultClassificationCommand) {}
}

export class UpdateContactUsDefaultClassificationError implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsDefaultClassificationError;
}

export class UpdateContactUsDefaultClassificationSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateContactUsDefaultClassificationSuccess;
}

export class UpdateContactUsEnquiryType implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsEnquiryType;
  constructor(public payload: UpdateContactUsSalesforceEnquiryTypeCommand) {}
}

export class UpdateContactUsEnquiryTypeError implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsEnquiryTypeError;
}

export class UpdateContactUsEnquiryTypeSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateContactUsEnquiryTypeSuccess;
}

export class AddContactUsEnquiryType implements Action {
  readonly type = CountriesActionTypes.AddContactUsEnquiryType;
  constructor(public payload: AddContactUsSalesforceEnquiryTypeCommand) {}
}

export class AddContactUsEnquiryTypeError implements Action {
  readonly type = CountriesActionTypes.AddContactUsEnquiryTypeError;
}

export class AddContactUsEnquiryTypeSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.AddContactUsEnquiryTypeSuccess;
}

export class DeleteContactUsEnquiryType implements Action {
  readonly type = CountriesActionTypes.DeleteContactUsEnquiryType;
  constructor(public payload: { countryId: string; enquiryTypeId: number }) {}
}

export class DeleteContactUsEnquiryTypeError implements Action {
  readonly type = CountriesActionTypes.DeleteContactUsEnquiryTypeError;
}

export class DeleteContactUsEnquiryTypeSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.DeleteContactUsEnquiryTypeSuccess;
}

export class UpdateContactUsHeaderTexts implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsHeaderTexts;
  constructor(public payload: UpdateContactUsHeaderTextsCommand) {}
}

export class UpdateContactUsHeaderTextsError implements Action {
  readonly type = CountriesActionTypes.UpdateContactUsHeaderTextsError;
}

export class UpdateContactUsHeaderTextsSuccess implements Action {
  constructor(public payload: AdminCommandHandlerResponseSuccess) {}
  readonly type = CountriesActionTypes.UpdateContactUsHeaderTextsSuccess;
}

export class LoadVinThreshold implements Action {
  readonly type = CountriesActionTypes.LoadVinThreshold;
  constructor(public payload: string) {}
}

export class LoadVinThresholdSuccess implements Action {
  readonly type = CountriesActionTypes.LoadVinThresholdSuccess;
  constructor(public payload: CountryVinThresholdDto) {}
}

export class LoadVinThresholdError implements Action {
  readonly type = CountriesActionTypes.LoadVinThresholdError;
}

export class UpdateVinThreshold implements Action {
  readonly type = CountriesActionTypes.UpdateVinThreshold;
  constructor(public payload: UpdateCountryVinThresholdCommand) {}
}

export class UpdateVinThresholdSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateVinThresholdSuccess;
  constructor(public payload: string) {}
}

export class UpdateVinThresholdError implements Action {
  readonly type = CountriesActionTypes.UpdateVinThresholdError;
}

export class LoadExperianCountrySettings implements Action {
  readonly type = CountriesActionTypes.LoadExperianCountrySettings;
  constructor(public payload: string) {}
}

export class LoadExperianCountrySettingsSuccess implements Action {
  readonly type = CountriesActionTypes.LoadExperianCountrySettingsSuccess;
  constructor(public payload: ExperianCountrySettingsDto) {}
}

export class LoadExperianCountrySettingsError implements Action {
  readonly type = CountriesActionTypes.LoadExperianCountrySettingsError;
}

export class UpdateExperianHostUrl implements Action {
  readonly type = CountriesActionTypes.UpdateExperianHostUrl;
  constructor(public payload: { countryId: string; command: SetExperianHostUrlCommand }) {}
}

export class UpdateExperianHostUrlSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateExperianHostUrlSuccess;
  constructor(public payload: string) {}
}

export class UpdateExperianHostUrlError implements Action {
  readonly type = CountriesActionTypes.UpdateExperianHostUrlError;
}

export class UpdateExperianTermsAndConditionsUrl implements Action {
  readonly type = CountriesActionTypes.UpdateExperianTermsAndConditionsUrl;
  constructor(public payload: { countryId: string; command: UpdateExperianTermsAndConditionsUrlCommand }) {}
}

export class UpdateExperianTermsAndConditionsUrlSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateExperianTermsAndConditionsUrlSuccess;
  constructor(public payload: string) {}
}

export class UpdateExperianTermsAndConditionsUrlError implements Action {
  readonly type = CountriesActionTypes.UpdateExperianTermsAndConditionsUrlError;
}

export class LoadCountryGdv implements Action {
  readonly type = CountriesActionTypes.LoadCountryGdv;
  constructor(public payload: string) {}
}

export class LoadCountryGdvError implements Action {
  readonly type = CountriesActionTypes.LoadCountryGdvError;
}

export class LoadCountryGdvSuccess implements Action {
  readonly type = CountriesActionTypes.LoadCountryGdvSuccess;
  constructor(public payload: GdvInsuranceClaimingConfigurationDto) {}
}

export class UpdateCountryGdvUsername implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvUsername;
  constructor(public payload: { countryId: string; command: UpdateGdvInsuranceClaimingConfigurationUsernameCommand }) {}
}

export class UpdateCountryGdvUsernameError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvUsernameError;
}

export class UpdateCountryGdvUsernameSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvUsernameSuccess;
  constructor(public payload: string) {}
}

export class UpdateCountryGdvPassword implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvPassword;
  constructor(public payload: { countryId: string; command: UpdateGdvInsuranceClaimingConfigurationPasswordCommand }) {}
}

export class UpdateCountryGdvPasswordError implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvPasswordError;
}

export class UpdateCountryGdvPasswordSuccess implements Action {
  readonly type = CountriesActionTypes.UpdateCountryGdvPasswordSuccess;
  constructor(public payload: string) {}
}

export type CountriesAction =
  | LoadCountries
  | CountriesLoaded
  | CountriesLoadError
  | LoadCountryDetails
  | CountryDetailsLoaded
  | CountryDetailsLoadError
  | UpdateCountryDefaultLanguage
  | CountryDefaultLanguageUpdated
  | CountryDefaultLanguageError
  | UpdateCountryDefaultContentLanguage
  | CountryDefaultContentLanguageUpdated
  | CountryDefaultContentLanguageError
  | AddCountryAlternativeLanguage
  | RemoveCountryAlternativeLanguage
  | CountryAlternativeLanguageAdded
  | CountryAlternativeLanguageRemoved
  | CountryAlternativeLanguageError
  | AddCountryAlternativeContentLanguage
  | RemoveCountryAlternativeContentLanguage
  | CountryAlternativeContentLanguageAdded
  | CountryAlternativeContentLanguageRemoved
  | CountryAlternativeContentLanguageError
  | UpdateCountryLocale
  | CountryLocaleUpdated
  | CountryLocaleError
  | UpdateCountryCurrency
  | CountryCurrencyUpdated
  | CountryCurrencyError
  | CountryTimezoneError
  | CountryTimezoneUpdated
  | CountryValuationListInitialSetOfFieldsUpdate
  | CountryValuationListInitialSetOfFieldsUpdated
  | CountryValuationListInitialSetOfFieldsUpdateError
  | CountryValuationListInitialSetOfFieldsLoad
  | CountryValuationListInitialSetOfFieldsLoaded
  | CountryValuationListInitialSetOfFieldsLoadError
  | CountryStockListInitialSetOfFieldsUpdate
  | CountryStockListInitialSetOfFieldsUpdated
  | CountryStockListInitialSetOfFieldsUpdateError
  | CountryStockListInitialSetOfFieldsLoad
  | CountryStockListInitialSetOfFieldsLoaded
  | CountryStockListInitialSetOfFieldsLoadError
  | CountryCustomersListInitialSetOfFieldsUpdate
  | CountryCustomersListInitialSetOfFieldsUpdated
  | CountryCustomersListInitialSetOfFieldsUpdateError
  | CountryCustomersListInitialSetOfFieldsLoad
  | CountryCustomersListInitialSetOfFieldsLoaded
  | CountryCustomersListInitialSetOfFieldsLoadError
  | UpdateCountryTimezone
  | LoadCountryTaxType
  | LoadCountryTaxTypeError
  | LoadCountryTaxTypeSuccess
  | UpdateCountryTaxType
  | UpdateCountryTaxTypeError
  | UpdateCountryTaxTypeSuccess
  | UpdateUCNumberSourceType
  | UpdateUCNumberSourceTypeSuccess
  | UpdateUCNumberSourceTypeError
  | LogoUpload
  | LogoUploaded
  | LogoUploadError
  | LogoLoad
  | LogoLoaded
  | LogoLoadError
  | UpdateCountryUrl
  | UpdateCountryUrlError
  | UpdateCountryUrlSuccess
  | UpdateCountryHotlinePhone
  | UpdateCountryHotlinePhoneError
  | UpdateCountryHotlinePhoneSuccess
  | UpdateCountryHotlineEmail
  | UpdateCountryHotlineEmailError
  | UpdateCountryHotlineEmailSuccess
  | LoadBroadcastList
  | LoadBroadcastListError
  | LoadBroadcastListSuccess
  | LoadBroadcastCountryMappingList
  | LoadBroadcastCountryMappingListError
  | LoadBroadcastCountryMappingListSuccess
  | LoadBroadcastDetails
  | LoadBroadcastDetailsError
  | LoadBroadcastDetailsSuccess
  | UpdateBroadcastHostname
  | UpdateBroadcastHostnameError
  | UpdateBroadcastHostnameSuccess
  | UpdateBroadcastIsActive
  | UpdateBroadcastIsActiveError
  | UpdateBroadcastIsActiveSuccess
  | UpdateBroadcastName
  | UpdateBroadcastNameError
  | UpdateBroadcastNameSuccess
  | UpdateBroadcastPassword
  | UpdateBroadcastPasswordError
  | UpdateBroadcastPasswordSuccess
  | UpdateBroadcastUsername
  | UpdateBroadcastUsernameError
  | UpdateBroadcastUsernameSuccess
  | UpdateBroadcastLogo
  | UpdateBroadcastLogoError
  | UpdateBroadcastLogoSuccess
  | UpdateBroadcastCertificate
  | UpdateBroadcastCertificateError
  | UpdateBroadcastCertificateSuccess
  | LoadBroadcastLogo
  | LoadBroadcastLogoError
  | LoadBroadcastLogoSuccess
  | LoadBroadcastCertificate
  | LoadBroadcastCertificateError
  | LoadBroadcastCertificateSuccess
  | CreateFtpExport
  | CreateFtpExportError
  | CreateFtpExportSuccess
  | EditFtpExport
  | EditFtpExportError
  | EditFtpExportSuccess
  | UpdateContactUsCountryCode
  | UpdateContactUsCountryCodeError
  | UpdateContactUsCountryCodeSuccess
  | UpdateContactUsDefaultPriority
  | UpdateContactUsDefaultPriorityError
  | UpdateContactUsDefaultPrioritySuccess
  | UpdateContactUsDefaultType
  | UpdateContactUsDefaultTypeError
  | UpdateContactUsDefaultTypeSuccess
  | UpdateContactUsDefaultClassification
  | UpdateContactUsDefaultClassificationError
  | UpdateContactUsDefaultClassificationSuccess
  | UpdateContactUsEnquiryType
  | UpdateContactUsEnquiryTypeError
  | UpdateContactUsEnquiryTypeSuccess
  | AddContactUsEnquiryType
  | AddContactUsEnquiryTypeError
  | AddContactUsEnquiryTypeSuccess
  | DeleteContactUsEnquiryType
  | DeleteContactUsEnquiryTypeError
  | DeleteContactUsEnquiryTypeSuccess
  | UpdateContactUsHeaderTexts
  | UpdateContactUsHeaderTextsError
  | UpdateContactUsHeaderTextsSuccess
  | LoadVinThreshold
  | LoadVinThresholdSuccess
  | LoadVinThresholdError
  | UpdateVinThreshold
  | UpdateVinThresholdSuccess
  | UpdateVinThresholdError
  | LoadExperianCountrySettings
  | LoadExperianCountrySettingsSuccess
  | LoadExperianCountrySettingsError
  | UpdateExperianHostUrl
  | UpdateExperianHostUrlSuccess
  | UpdateExperianHostUrlError
  | UpdateExperianTermsAndConditionsUrl
  | UpdateExperianTermsAndConditionsUrlSuccess
  | UpdateExperianTermsAndConditionsUrlError
  | LoadCountryGdv
  | LoadCountryGdvError
  | LoadCountryGdvSuccess
  | UpdateCountryGdvUsername
  | UpdateCountryGdvUsernameError
  | UpdateCountryGdvUsernameSuccess
  | UpdateCountryGdvPassword
  | UpdateCountryGdvPasswordError
  | UpdateCountryGdvPasswordSuccess;

export const fromCountriesActions = {
  LoadCountries,
  CountriesLoaded,
  CountriesLoadError,
  LoadCountryDetails,
  CountryDetailsLoaded,
  CountryDetailsLoadError,
  UpdateCountryDefaultLanguage,
  CountryDefaultLanguageUpdated,
  CountryDefaultLanguageError,
  UpdateCountryDefaultContentLanguage,
  CountryDefaultContentLanguageUpdated,
  CountryDefaultContentLanguageError,
  AddCountryAlternativeLanguage,
  RemoveCountryAlternativeLanguage,
  CountryAlternativeLanguageAdded,
  CountryAlternativeLanguageRemoved,
  CountryAlternativeLanguageError,
  AddCountryAlternativeContentLanguage,
  RemoveCountryAlternativeContentLanguage,
  CountryAlternativeContentLanguageAdded,
  CountryAlternativeContentLanguageRemoved,
  CountryAlternativeContentLanguageError,
  UpdateCountryLocale,
  CountryLocaleUpdated,
  CountryLocaleError,
  CountryValuationListInitialSetOfFieldsUpdate,
  CountryValuationListInitialSetOfFieldsUpdated,
  CountryValuationListInitialSetOfFieldsUpdateError,
  CountryValuationListInitialSetOfFieldsLoad,
  CountryValuationListInitialSetOfFieldsLoaded,
  CountryValuationListInitialSetOfFieldsLoadError,
  CountryStockListInitialSetOfFieldsUpdate,
  CountryStockListInitialSetOfFieldsUpdated,
  CountryStockListInitialSetOfFieldsUpdateError,
  CountryStockListInitialSetOfFieldsLoad,
  CountryStockListInitialSetOfFieldsLoaded,
  CountryStockListInitialSetOfFieldsLoadError,
  CountryCustomersListInitialSetOfFieldsUpdate,
  CountryCustomersListInitialSetOfFieldsUpdated,
  CountryCustomersListInitialSetOfFieldsUpdateError,
  CountryCustomersListInitialSetOfFieldsLoad,
  CountryCustomersListInitialSetOfFieldsLoaded,
  CountryCustomersListInitialSetOfFieldsLoadError,
  UpdateCountryCurrency,
  CountryCurrencyUpdated,
  CountryCurrencyError,
  UpdateCountryTimezone,
  CountryTimezoneUpdated,
  CountryTimezoneError,
  LoadCountryTaxType,
  LoadCountryTaxTypeError,
  LoadCountryTaxTypeSuccess,
  UpdateCountryTaxType,
  UpdateCountryTaxTypeError,
  UpdateCountryTaxTypeSuccess,
  UpdateUCNumberSourceType,
  UpdateUCNumberSourceTypeSuccess,
  UpdateUCNumberSourceTypeError,
  LogoUpload,
  LogoUploaded,
  LogoUploadError,
  LogoLoad,
  LogoLoaded,
  LogoLoadError,
  UpdateCountryUrl,
  UpdateCountryUrlError,
  UpdateCountryUrlSuccess,
  UpdateCountryHotlinePhone,
  UpdateCountryHotlinePhoneError,
  UpdateCountryHotlinePhoneSuccess,
  UpdateCountryHotlineEmail,
  UpdateCountryHotlineEmailError,
  UpdateCountryHotlineEmailSuccess,
  LoadBroadcastList,
  LoadBroadcastListError,
  LoadBroadcastListSuccess,
  LoadBroadcastCountryMappingList,
  LoadBroadcastCountryMappingListError,
  LoadBroadcastCountryMappingListSuccess,
  LoadBroadcastDetails,
  LoadBroadcastDetailsError,
  LoadBroadcastDetailsSuccess,
  UpdateBroadcastHostname,
  UpdateBroadcastHostnameError,
  UpdateBroadcastHostnameSuccess,
  UpdateBroadcastIsActive,
  UpdateBroadcastIsActiveError,
  UpdateBroadcastIsActiveSuccess,
  UpdateBroadcastName,
  UpdateBroadcastNameSuccess,
  UpdateBroadcastNameError,
  UpdateBroadcastPassword,
  UpdateBroadcastPasswordError,
  UpdateBroadcastPasswordSuccess,
  UpdateBroadcastUsername,
  UpdateBroadcastUsernameError,
  UpdateBroadcastUsernameSuccess,
  UpdateBroadcastLogo,
  UpdateBroadcastLogoError,
  UpdateBroadcastLogoSuccess,
  UpdateBroadcastCertificate,
  UpdateBroadcastCertificateError,
  UpdateBroadcastCertificateSuccess,
  LoadBroadcastLogo,
  LoadBroadcastLogoError,
  LoadBroadcastLogoSuccess,
  LoadBroadcastCertificate,
  LoadBroadcastCertificateError,
  LoadBroadcastCertificateSuccess,
  CreateFtpExport,
  CreateFtpExportError,
  CreateFtpExportSuccess,
  EditFtpExport,
  EditFtpExportError,
  EditFtpExportSuccess,
  UpdateContactUsCountryCode,
  UpdateContactUsCountryCodeError,
  UpdateContactUsCountryCodeSuccess,
  UpdateContactUsDefaultPriority,
  UpdateContactUsDefaultPriorityError,
  UpdateContactUsDefaultPrioritySuccess,
  UpdateContactUsDefaultType,
  UpdateContactUsDefaultTypeError,
  UpdateContactUsDefaultTypeSuccess,
  UpdateContactUsDefaultClassification,
  UpdateContactUsDefaultClassificationError,
  UpdateContactUsDefaultClassificationSuccess,
  UpdateContactUsEnquiryType,
  UpdateContactUsEnquiryTypeError,
  UpdateContactUsEnquiryTypeSuccess,
  AddContactUsEnquiryType,
  AddContactUsEnquiryTypeError,
  AddContactUsEnquiryTypeSuccess,
  DeleteContactUsEnquiryType,
  DeleteContactUsEnquiryTypeError,
  DeleteContactUsEnquiryTypeSuccess,
  UpdateContactUsHeaderTexts,
  UpdateContactUsHeaderTextsError,
  UpdateContactUsHeaderTextsSuccess,
  LoadVinThreshold,
  LoadVinThresholdSuccess,
  LoadVinThresholdError,
  UpdateVinThreshold,
  UpdateVinThresholdSuccess,
  UpdateVinThresholdError,
  LoadExperianCountrySettings,
  LoadExperianCountrySettingsSuccess,
  LoadExperianCountrySettingsError,
  UpdateExperianHostUrl,
  UpdateExperianHostUrlSuccess,
  UpdateExperianHostUrlError,
  UpdateExperianTermsAndConditionsUrl,
  UpdateExperianTermsAndConditionsUrlSuccess,
  UpdateExperianTermsAndConditionsUrlError,
  LoadCountryGdv,
  LoadCountryGdvError,
  LoadCountryGdvSuccess,
  UpdateCountryGdvUsername,
  UpdateCountryGdvUsernameError,
  UpdateCountryGdvUsernameSuccess,
  UpdateCountryGdvPassword,
  UpdateCountryGdvPasswordError,
  UpdateCountryGdvPasswordSuccess,
};
