import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  BranchViewService,
  BusinessViewService,
  UserGroupViewService,
  UserService,
  UserViewService,
} from '@vpfa/rest-api/admin';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import {
  AddUser,
  AddUserCompleted,
  EditUser,
  EditUserCompleted,
  fromUsersActions,
  LoadCreateUserBranches,
  LoadCreateUserBusiness,
  LoadCreateUserUserGroups,
  LoadEditUserBranches,
  LoadEditUserUserGroups,
  LoadUserDetails,
  LoadUserDetailsCompleted,
  LoadUsersByBusiness,
  LoadUsersByBusinessSuccess,
  LoadUsersByCountry,
  LoadUsersGlobal,
  ResetUserPassword,
  ResetUserPasswordCompleted,
  UsersActionTypes,
  UpdateUserActiveStatus,
  UpdateUserActiveStatusCompleted,
  LoadUsersByUsersBusiness,
  ResetUserPasswordByAdmin,
} from './users.actions';
import { ProfileFacade } from '@vpfa/profile/data';
import { Store } from '@ngrx/store';
import { UsersPartialState } from '@vpfa/admin/users-data';
import { usersQuery } from './users.selectors';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private notificationService: BasicNotificationsService,
    private userViewService: UserViewService,
    private userService: UserService,
    private businessService: BusinessViewService,
    private branchService: BranchViewService,
    private userGroupService: UserGroupViewService,
    private profileFacade: ProfileFacade,
    private store: Store<UsersPartialState>
  ) {}

   loadUsersGlobal$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersGlobal>(UsersActionTypes.LoadUsersGlobal),
    switchMap(() =>
      this.userViewService.getList().pipe(
        map(res => new fromUsersActions.LoadUsersGlobalSuccess(res)),
        catchError(err => of(new fromUsersActions.LoadUsersGlobalError()))
      )
    )
  ));

   loadUsersByCountry$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersByCountry>(UsersActionTypes.LoadUsersByCountry),
    switchMap(action =>
      this.userViewService.getListByCountry(action.payload).pipe(
        map(res => new fromUsersActions.LoadUsersByCountrySuccess(res)),
        catchError(err => of(new fromUsersActions.LoadUsersByCountryError()))
      )
    )
  ));

   loadUsersByBusiness$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersByBusiness>(UsersActionTypes.LoadUsersByBusiness),
    switchMap(action =>
      this.userViewService.getListByBusiness(action.payload).pipe(
        map(res => new fromUsersActions.LoadUsersByBusinessSuccess({ businessId: action.payload, users: res })),
        catchError(err => of(new fromUsersActions.LoadUsersByBusinessError()))
      )
    )
  ));

   loadUsersByUsersBusiness$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersByUsersBusiness>(UsersActionTypes.LoadUsersByUsersBusiness),
    switchMap(action =>
      this.userViewService.getListByUsersBusiness().pipe(
        map(res => new fromUsersActions.LoadUsersByUsersBusinessSucess({ users: res })),
        catchError(err => of(new fromUsersActions.LoadUsersByUsersBusinessError()))
      )
    )
  ));

   loadUsersByBusinessSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersByBusinessSuccess>(UsersActionTypes.LoadUsersByBusinessSuccess),
    switchMap(action => {
      return [
        new fromUsersActions.LoadCreateUserBusiness(action.payload.businessId),
        new fromUsersActions.LoadCreateUserBranches(action.payload.businessId),
        new fromUsersActions.LoadCreateUserUserGroups(action.payload.businessId),
      ];
    })
  ));

   loadUserDetails$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserDetails>(UsersActionTypes.LoadUserDetails),
    switchMap(action => {
      return this.userViewService.get(action.payload).pipe(
        map(user => new fromUsersActions.LoadUserDetailsCompleted(user)),
        catchError(err => of(new fromUsersActions.LoadUserDetailsError()))
      );
    })
  ));

   loadUserDetailsCompleted$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserDetailsCompleted>(UsersActionTypes.LoadUserDetailsCompleted),
    switchMap(action => {
      return [
        new fromUsersActions.LoadEditUserBranches(action.payload.businessId),
        new fromUsersActions.LoadEditUserUserGroups(action.payload.businessId),
      ];
    })
  ));

   loadCreateUserBusiness$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCreateUserBusiness>(UsersActionTypes.LoadCreateUserBusiness),
    switchMap(action =>
      this.businessService.get(action.payload).pipe(
        map(res => new fromUsersActions.LoadCreateUserBusinessCompleted(res)),
        catchError(err => of(new fromUsersActions.LoadCreateUserBusinessError()))
      )
    )
  ));

   loadCreateUserBranches$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCreateUserBranches>(UsersActionTypes.LoadCreateUserBranches),
    switchMap(action =>
      this.branchService.getBranchesListForDealerNetwork(action.payload).pipe(
        map(res => new fromUsersActions.LoadCreateUserBranchesCompleted(res)),
        catchError(err => of(new fromUsersActions.LoadCreateUserBranchesError()))
      )
    )
  ));

   loadCreateUserUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCreateUserUserGroups>(UsersActionTypes.LoadCreateUserUserGroups),
    switchMap(action =>
      this.userGroupService.getList(action.payload).pipe(
        map(res => new fromUsersActions.LoadCreateUserUserGroupsCompleted(res)),
        catchError(err => of(new fromUsersActions.LoadCreateUserUserGroupsError()))
      )
    )
  ));

   loadEditUserBranches$ = createEffect(() => this.actions$.pipe(
    ofType<LoadEditUserBranches>(UsersActionTypes.LoadEditUserBranches),
    switchMap(action => {
      if (action.payload) {
        return this.branchService.getBranchesListForDealerNetwork(action.payload).pipe(
          map(res => new fromUsersActions.LoadEditUserBranchesCompleted(res)),
          catchError(err => of(new fromUsersActions.LoadEditUserBranchesError()))
        );
      } else {
        return of();
      }
    })
  ));

   loadEditUserUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType<LoadEditUserUserGroups>(UsersActionTypes.LoadEditUserUserGroups),
    switchMap(action => {
      if (action.payload) {
        return this.userGroupService.getList(action.payload).pipe(
          map(res => new fromUsersActions.LoadEditUserUserGroupsCompleted(res)),
          catchError(err => of(new fromUsersActions.LoadEditUserUserGroupsError()))
        );
      } else {
        return of();
      }
    })
  ));

   addUser$ = createEffect(() => this.actions$.pipe(
    ofType<AddUser>(UsersActionTypes.AddUser),
    switchMap(action => {
      return this.userService.create(action.payload.createUserCommand).pipe(
        map(() => new fromUsersActions.AddUserCompleted(action.payload.businessId)),
        catchError(err => of(new fromUsersActions.AddUserError()))
      );
    })
  ));

   addUserCompleted$ = createEffect(() => this.actions$.pipe(
    ofType<AddUserCompleted>(UsersActionTypes.AddUserCompleted),
    tap(() => {
      this.notificationService.success('users.success.userCreated');
    }),
    map(action => new fromUsersActions.LoadUsersByBusiness(action.payload))
  ));

   editUser$ = createEffect(() => this.actions$.pipe(
    ofType<EditUser>(UsersActionTypes.EditUser),
    switchMap(action => {
      return this.userService.edit(action.payload).pipe(
        map(res => {
          return new fromUsersActions.EditUserCompleted(res.aggregateId);
        }),
        catchError(err => of(new fromUsersActions.EditUserError()))
      );
    })
  ));

   editUserCompleted$ = createEffect(() => this.actions$.pipe(
    ofType<EditUserCompleted>(UsersActionTypes.EditUserCompleted),
    withLatestFrom(this.store.select(usersQuery.getUserDetailsSubjectId)),
    tap(() => {
      this.notificationService.success('users.success.userEdited');
    }),
    map(([action, userId]) => new fromUsersActions.LoadUserDetails(userId))
  ));

   updateUserActiveStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserActiveStatus>(UsersActionTypes.UpdateUserActiveStatus),
    switchMap(action => {
      return this.userService.updateUserActiveStatus(action.payload).pipe(
        map(response => new fromUsersActions.UpdateUserActiveStatusCompleted(action.payload)),
        catchError(err => of(new fromUsersActions.UpdateUserActiveStatusError()))
      );
    })
  ));

   updateUserActiveStatusCompleted$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserActiveStatusCompleted>(UsersActionTypes.UpdateUserActiveStatusCompleted),
    tap(action => {
      this.notificationService.success(`users.success.user${action.payload.isActive ? 'Activated' : 'Deactivated'}`);
    })
  ), { dispatch: false });

   resetUserPassword$ = createEffect(() => this.actions$.pipe(
    ofType<ResetUserPassword>(UsersActionTypes.ResetUserPassword),
    switchMap(action => {
      return this.userService.resetPassword(action.payload.branchId, action.payload.subjectId).pipe(
        map(() => new fromUsersActions.ResetUserPasswordCompleted()),
        catchError(() => of(new fromUsersActions.ResetUserPasswordError()))
      );
    })
  ));

   resetUserPasswordByAdmin$ = createEffect(() => this.actions$.pipe(
    ofType<ResetUserPasswordByAdmin>(UsersActionTypes.ResetUserPasswordByAdmin),
    switchMap(action => {
      return this.userService.resetPassword_1(action.payload.subjectId).pipe(
        map(() => new fromUsersActions.ResetUserPasswordByAdminCompleted()),
        catchError(() => of(new fromUsersActions.ResetUserPasswordByAdminError()))
      );
    })
  ));

   resetUserPasswordCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActionTypes.ResetUserPasswordCompleted, UsersActionTypes.ResetUserPasswordByAdminCompleted),
    tap(() => {
      this.notificationService.success('users.success.passwordResetComplete');
    })
  ), { dispatch: false });
}
