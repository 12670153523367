import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';
import { AmsSyncState, UserGroupType } from '@vpfa/rest-api/admin';

// Lookup the 'Users' feature state managed by NgRx
const getUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

// create user
const getCreateUser = createSelector(getUsersState, (state: UsersState) => state.createUser);

const getCreateUserCreating = createSelector(getCreateUser, state => state.creating);
const getCreateUserAddUserModalOpen = createSelector(getCreateUser, state => state.addUserModalOpen);

const getCreateUserBusiness = createSelector(getCreateUser, state => state.business);
const getCreateUserBusinessEntity = createSelector(getCreateUserBusiness, state => state.entity);
const getCreateUserBusinessName = createSelector(getCreateUserBusinessEntity, state =>
  state ? state.businessName : null
);

const getCreateUserBranches = createSelector(getCreateUser, state => state.branches);
const getCreateUserBranchesEntities = createSelector(getCreateUserBranches, state => state.entities);
const getCreateUserBranchesEntitiesWithOkAmsStatus = createSelector(getCreateUserBranchesEntities, state =>
  state
    ? state.filter(branch => branch.amsState === AmsSyncState.Ok || branch.amsState === AmsSyncState.Modified)
    : null
);
const getCreateUserBranchesSelectOptions = createSelector(getCreateUserBranchesEntitiesWithOkAmsStatus, state =>
  state
    ? state.map(branch => {
        return {
          name: branch.branchName,
          value: branch.branchId,
        };
      })
    : null
);

const getCreateUserUserGroups = createSelector(getCreateUser, state => state.userGroups);
const getCreateUserUserGroupsEntities = createSelector(getCreateUserUserGroups, state => state.entities);
const getCreateUserUserGroupsSelectOptions = createSelector(getCreateUserUserGroupsEntities, state =>
  state
    ? [...state]
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map(userGroup => {
          return {
            name: userGroup.name,
            value: userGroup.id,
          };
        })
    : null
);
const getCreateUserDefaultUserGroupSelectOption = createSelector(getCreateUserUserGroupsEntities, state => {
  const defaultUserGroup = state ? state.find(userGroup => userGroup.type === UserGroupType.Default) : null;
  return defaultUserGroup
    ? {
        name: defaultUserGroup.name,
        value: defaultUserGroup.id,
      }
    : null;
});

// users list
const getUsers = createSelector(getUsersState, (state: UsersState) => state.list);
const getUsersEntities = createSelector(getUsers, state => state.entities);
const getUsersLoaded = createSelector(getUsers, state => state.loaded);
const getUsersLoading = createSelector(getUsers, state => state.loading);
const getUsersError = createSelector(getUsers, state => state.error);
const getAllUsers = createSelector(getUsersEntities, getUsersLoaded, (entities, areUsersLoaded) => {
  return areUsersLoaded ? entities : [];
});
const getSelectedId = createSelector(getUsersState, (state: UsersState) => state.selectedId);
const getSelectedUsers = createSelector(getAllUsers, getSelectedId, (users, id) => {
  const result = users.find(it => it['id'] === id);
  return result ? Object.assign({}, result) : undefined;
});

// selected user
const getUserDetails = createSelector(getUsersState, (state: UsersState) => state.userDetails);
const getUserDetailsEntity = createSelector(getUserDetails, state => state.entity);
const getUserDetailsSubjectId = createSelector(getUserDetailsEntity, state => (state ? state.subjectId : null));
const getUserDetailsBusinessId = createSelector(getUserDetailsEntity, state => (state ? state.businessId : null));
const getUserDetailsLoading = createSelector(getUserDetails, state => state.isLoadingUser);
const getUserDetailsEditing = createSelector(getUserDetails, state => state.editing);
const getUserDetailsEditUserModalOpen = createSelector(getUserDetails, state => state.editUserModalOpen);

// selected user edit user
const getUserDetailsEditUser = createSelector(getUserDetails, state => state.editUser);

const getUserDetailsEditUserBranches = createSelector(getUserDetailsEditUser, state => state.branches);
const getUserDetailsEditUserBranchesEntities = createSelector(getUserDetailsEditUserBranches, state => state.entities);
const getUserDetailsEditUserBranchesEntitiesWithOkAmsStatus = createSelector(
  getUserDetailsEditUserBranchesEntities,
  state =>
    state
      ? state.filter(branch => branch.amsState === AmsSyncState.Ok || branch.amsState === AmsSyncState.Modified)
      : null
);
const getUserDetailsEditUserBranchesSelectOptions = createSelector(
  getUserDetailsEditUserBranchesEntitiesWithOkAmsStatus,
  state =>
    state
      ? state.map(branch => {
          return {
            name: branch.branchName,
            value: branch.branchId,
          };
        })
      : null
);

const getUserDetailsEditUserUserGroups = createSelector(getUserDetailsEditUser, state => state.userGroups);
const getUserDetailsEditUserUserGroupsEntities = createSelector(
  getUserDetailsEditUserUserGroups,
  state => state.entities
);
const getUserDetailsEditUserUserGroupsSelectOptions = createSelector(getUserDetailsEditUserUserGroupsEntities, state =>
  state
    ? [...state]
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map(userGroup => {
          return {
            name: userGroup.name,
            value: userGroup.id,
          };
        })
    : null
);

const isResettingPassword = createSelector(getUserDetails, state => state.resettingPassword);

const getUpdatingActiveStatus = createSelector(getUsersState, state => state.updatingActiveStatus);

export const usersQuery = {
  getCreateUserCreating,
  getCreateUserAddUserModalOpen,
  getCreateUserBusinessName,
  getCreateUserBranchesSelectOptions,
  getCreateUserUserGroupsSelectOptions,
  getCreateUserDefaultUserGroupSelectOption,
  getUsersLoaded,
  getUsersLoading,
  getUsersError,
  getAllUsers,
  getSelectedUsers,
  getUserDetailsEntity,
  getUserDetailsSubjectId,
  getUserDetailsBusinessId,
  getUserDetailsLoading,
  getUpdatingActiveStatus,
  getUserDetailsEditing,
  getUserDetailsEditUserModalOpen,
  getUserDetailsEditUserBranchesSelectOptions,
  getUserDetailsEditUserUserGroupsSelectOptions,
  isResettingPassword,
};
