import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerFormModel } from '../../models/customer-form.model';
import { getCustomerDataFormDefinition } from '../../definitions/customerDataFormDefinition';
import { SelectOption, TextListFieldComponent } from '@vpfa/ui-kit';
import { TranslateService } from '@ngx-translate/core';
import { isEqual, isNil } from 'lodash';
import { EMAIL_MAX_LENGTH, emailValidator, TEXT_AREA_MAX_LENGTH, TEXT_LINE_MAX_LENGTH } from '@vpfa/shared/validators';
import { UserDto } from '@vpfa/rest-api/admin';
import { notLaterThanFromToday } from '@vpfa/ui-kit/uikit-validation';
import { LocaleFacade } from '@vpfa/locale';

@Component({
  template: '',
})
export class CustomerDataComponent implements AfterViewInit, OnChanges {
  @Input() modalTitleCode = 'customerDataModal.customer';
  @Input() set show(visible: boolean) {
    this.visible = visible;
  }
  @Input() isProcessing: boolean;

  @Input() set formsOfAddress(forms: any) {
    if (forms) {
      this._formsOfAddress = forms.map(
        (form: string) =>
          <SelectOption>{
            name: form,
            value: form,
          },
      );
    }
  }
  get formsOfAddress() {
    return this._formsOfAddress;
  }

  private _formsOfAddress: SelectOption[];

  @Input() set owners(owners: UserDto[]) {
    if (owners) {
      this.ownersOptions = owners.map(
        user =>
          <SelectOption>{
            name: `${user.firstName} ${user.lastName}`,
            value: user.subjectId,
          },
      );
      this.setOwnerInitValue();
    }
  }
  @Input() set customerData(customer: CustomerFormModel) {
    this.customer = customer;
  }
  get customerData() {
    return this.customer;
  }
  @Input() isFormsOfAddressLoading = false;
  @Input() isOwnersLoading = false;
  @Input() initialOwnerId: string = null;

  @Output() customerDataChange = new EventEmitter<CustomerFormModel>();
  @Output() closeAction = new EventEmitter();

  @ViewChild('phoneNumberComponent')
  phoneNumberComponent: TextListFieldComponent;

  @ViewChild('emailsComponent')
  emailsComponent: TextListFieldComponent;

  customerForm: UntypedFormGroup;
  textListFieldsForm: UntypedFormGroup;
  formOfAddressInitValue: SelectOption;
  visible = false;
  emailValidator = emailValidator;
  maxLengthValidator = Validators.maxLength(TEXT_LINE_MAX_LENGTH);
  emailMaxLengthValidator = Validators.maxLength(EMAIL_MAX_LENGTH);
  textareaMaxLength = TEXT_AREA_MAX_LENGTH;
  private customer: CustomerFormModel;
  ownersOptions: SelectOption[];
  ownerInitValue: SelectOption;

  maxLengthErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: TEXT_LINE_MAX_LENGTH }),
    },
  ];

  lastNameFieldErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    ...this.maxLengthErrorMsgList,
    {
      error: 'required',
      errorMsg: this.translateService.instant('customerDataModal.lastNameIsRequired'),
    },
  ];

  emailFieldErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: EMAIL_MAX_LENGTH }),
    },
    {
      error: 'email',
      errorMsg: this.translateService.instant('users.error.wrongEmailFormat'),
    },
  ];

  timezone$ = this.localeFacade.timezone$;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private localeFacade: LocaleFacade,
  ) {
    this.customerForm = this.fb.group(getCustomerDataFormDefinition());
    this.textListFieldsForm = this.fb.group({});
  }

  ngAfterViewInit() {
    if (!isNil(this.phoneNumberComponent)) {
      this.textListFieldsForm.addControl('phoneNumberInput', this.phoneNumberComponent.formControl);
    }

    if (!isNil(this.emailsComponent)) {
      this.textListFieldsForm.addControl('emailInput', this.emailsComponent.formControl);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ownersOptions'] && this.ownersOptions) {
      this.setOwnerInitValue();
      this.customerForm.patchValue({ ownerId: this.ownerInitValue });
    }

    this.initializeForm();

    if (!this.customerData || !this.visible) {
      this.cleanupForm();
    }
  }

  private cleanupForm() {
    this.textListFieldsForm.reset();
    this.customerForm.reset();
  }

  get saveCustomerDisabled() {
    return (
      this.customerForm.invalid ||
      this.textListFieldsForm.invalid ||
      (this.customerForm.pristine && this.textListFieldsForm.pristine)
    );
  }

  close() {
    this.cleanupForm();
    this.closeAction.emit();
  }

  saveCustomer() {
    if (this.customerForm.valid && this.textListFieldsForm.valid) {
      this.appendListInputs();
      const form = {
        ...this.customerForm.value,
        formOfAddress: this.getValue(this.customerForm.get('formOfAddress').value),
        ownerId: this.getValue(this.customerForm.get('ownerId').value),
      };
      this.textListFieldsForm.reset();
      this.customerDataChange.emit(form);
    }
  }

  appendListInputs() {
    this.updateCustomerFormValue(this.phoneNumberComponent.formControl, 'phoneNumbers');
    this.updateCustomerFormValue(this.emailsComponent.formControl, 'emails');
  }

  updateCustomerFormValue(textListFormControl: UntypedFormControl, customerFormControlName: string): void {
    const textListInputValue = textListFormControl.value;
    if (textListInputValue && textListInputValue !== '') {
      const customerFormControlValue = this.customerForm.get(customerFormControlName).value;
      const resultControlValue = customerFormControlValue
        ? [...customerFormControlValue, textListInputValue]
        : [textListInputValue];
      this.customerForm.get(customerFormControlName).patchValue(resultControlValue);
    }
  }

  disableNewerThanToday = timezone => checkedData => {
    return notLaterThanFromToday(checkedData, timezone);
  };

  initializeForm() {
    if (this.customer && this.formsOfAddress) {
      this.setFormOfAddressInitValue();
      this.customerForm.reset({
        ...this.customer,
        formOfAddress: this.formOfAddressInitValue,
        ownerId: this.ownerInitValue,
      });
      this.customerForm.markAsPristine();
      this.setOwnerInitValue();
    }
  }

  private setFormOfAddressInitValue() {
    this.formOfAddressInitValue = this.formsOfAddress.find(s => s.name === this.customer.formOfAddress);
  }

  private setOwnerInitValue() {
    if (this.initialOwnerId) {
      this.ownerInitValue = this.ownersOptions.find(s => s.value === this.initialOwnerId);
    }
    if (this.customer) {
      this.ownerInitValue = this.ownersOptions.find(s => s.value === this.customer.ownerId);
    }
  }

  private getValue(option: SelectOption) {
    if (!isNil(option)) {
      return option.value;
    }
  }
}
