import { Action } from '@ngrx/store';
import {
  CreateCustomerCommand,
  CustomerDto,
  Filter,
  Order,
  OwnerVehicleCaseDto,
  OwnerVehicleRecentOfferDto,
  UpdateCustomerCommand,
} from '@vpfa/rest-api/valuation';
import { Entity } from './customers.reducer';
import { AfterCustomerCreatedActionSuccessHook } from '../hooks/AfterCustomerCreatedActionSuccessHook';
import { PagedApiResponse } from '@vpfa/shared/data-table-backend-pagination';
import { CustomerListItem } from '../interfaces';

export enum CustomersActionTypes {
  CreateCustomer = '[Customers] Customers :: Create Customer',
  CustomerCreated = '[Customers] Customers :: Customer Created',
  CustomerCreateError = '[Customers] Customers :: Customer Create Error',
  OpenCreateCustomerModal = '[Customers] Customers :: Open Customer Create Modal',
  CloseCreateCustomerModal = '[Customers] Customers :: Close Customer Create Modal',

  UpdateCustomer = '[Customers] Customers :: Update Customer',
  CustomerUpdated = '[Customers] Customers :: Customer Updated',
  CustomerUpdateError = '[Customers] Customers :: Customer Updated Error',
  OpenUpdateCustomerModal = '[Customers] Customers :: Open Customer Update Modal',
  CloseUpdateCustomerModal = '[Customers] Customers :: Close Customer Update Modal',

  OpenCreateCaseOwnerModal = '[Customers] Customers :: Open Create Case Owner Modal',
  CloseCreateCaseOwnerModal = '[Customers] Customers :: Close Create Case Owner Modal',
  OpenCreateCasePreviousOwnerModal = '[Customers] Customers :: Open Create Case Previous Owner Modal',
  CloseCreateCasePreviousOwnerModal = '[Customers] Customers :: Close Create Case Previous Owner Modal',
  OpenCreateCaseProspectCustomerModal = '[Customers] Customers :: Open Create Case Prospect Customer Modal',
  CloseCreateCaseProspectCustomerModal = '[Customers] Customers :: Close Create Case Prospect Customer Modal',

  LoadCustomerList = '[CustomerList] Load CustomerList',
  CustomerListLoaded = '[CustomerList] CustomerList Loaded',
  CustomerListLoadError = '[CustomerList] CustomerList Load Error',

  LoadPagedCustomerList = '[CustomerList] Load Paged CustomerList',
  PagedCustomerListLoaded = '[CustomerList] Paged CustomerList Loaded',
  PagedCustomerListLoadError = '[CustomerList] Paged CustomerList Load Error',

  CustomerListChangeSelections = '[CustomerList] CustomerList Change Selections',

  LoadCustomerDetails = '[Customers] Customer Details :: Load Customer Details',
  CustomerDetailsLoaded = '[Customers] Customer Details :: Customer Details Loaded',
  CustomerDetailsLoadError = '[Customers] Customer Details :: Customer Details Load Error',

  LoadSelectedCustomerDetailsForSellModal = '[Customers] Customer Details :: Load Selected Customer Details For Sell Modal',
  SelectedCustomerDetailsForSellModalLoaded = '[Customers] Customer Details :: Selected Customer Details For Sell Modal Loaded',
  SelectedCustomerDetailsForSellModalLoadError = '[Customers] Customer Details :: Selected Customer Details For Sell Modal Load Error',

  LoadHaveRelatedCaseForSelectCustomers = '[CustomerList] Load HaveRelatedCase For Select Customers',

  LoadHaveRelatedCase = '[CustomerList] Load HaveRelatedCase',
  HaveRelatedCaseLoaded = '[CustomerList] HaveRelatedCase Loaded',
  HaveRelatedCaseError = '[CustomerList] HaveRelatedCase Error',

  DeleteSelectedCustomersFromList = '[Customers] Delete Selected Customers From List',
  SelectedCustomersDeleted = '[Customers] Selected Customers Deleted',
  SelectedCustomersDeleteError = '[Customers] Selected Customers Delete Error',

  DeleteCustomer = '[Customers] Delete Customer',
  CustomerDeleted = '[Customers] Customer Deleted',
  CustomerDeleteError = '[Customers] Customer Delete Error',

  LoadCustomerFormsOfAddress = '[Customer] Load Customer Forms Of Address',
  CustomerFormsOfAddressLoaded = '[Customer] Customer Forms Of Address Loaded',
  CustomerFormsOfAddressLoadError = '[Customer] Customer Forms Of Address Load Error',

  LoadCustomerLatestQuoteList = '[Customer] Load Customer Latest Quote List',
  CustomerLatestQuoteListLoaded = '[Customer] Customer Latest Quote List Loaded',
  CustomerLatestQuoteListLoadError = '[Customer] Customer Latest Quote List Load Error',

  LoadCustomerVehicleList = '[Customers] Customer Details :: Load Customer Vehicle List',
  CustomerVehicleListLoaded = '[Customers] Customer Details :: Customer Vehicle List Loaded',
  CustomerVehicleListLoadError = '[Customers] Customer Details :: Customer Vehicle List Load Error',
  ReloadCustomerDetails = '[Customers] Customer Details :: Reload Customer Details',

  DeleteSelectedCustomers = '[Customers] Delete selected Customer',
  DeleteCustomers = '[Customers] Delete Customer',
  CustomersDeleted = '[Customers] Customer Deleted',
  CustomersDeleteError = '[Customers] Customer Delete Error',
}

export class CreateCustomer<ContextType = unknown> implements Action {
  readonly type = CustomersActionTypes.CreateCustomer;
  constructor(
    public payload: CreateCustomerCommand,
    public meta?: {
      afterSuccess: keyof AfterCustomerCreatedActionSuccessHook<ContextType>;
    }
  ) {}
}

/**
 *  payload = new customer id
 *  after success = forwarded hook from CreateCustomer action
 */
export class CustomerCreated implements Action {
  readonly type = CustomersActionTypes.CustomerCreated;
  constructor(public payload: string, public afterSuccess?: keyof AfterCustomerCreatedActionSuccessHook<undefined>) {}
}

export class CustomerCreateError implements Action {
  readonly type = CustomersActionTypes.CustomerCreateError;
  constructor() {}
}

export class OpenCreateCustomerModal implements Action {
  readonly type = CustomersActionTypes.OpenCreateCustomerModal;
}

export class CloseCreateCustomerModal implements Action {
  readonly type = CustomersActionTypes.CloseCreateCustomerModal;
}

export class UpdateCustomer implements Action {
  readonly type = CustomersActionTypes.UpdateCustomer;
  constructor(public payload: UpdateCustomerCommand) {}
}

export class CustomerUpdated implements Action {
  readonly type = CustomersActionTypes.CustomerUpdated;
  constructor(public id: string, public command: UpdateCustomerCommand) {}
}

export class CustomerUpdateError implements Action {
  readonly type = CustomersActionTypes.CustomerUpdateError;
  constructor() {}
}

export class OpenUpdateCustomerModal implements Action {
  readonly type = CustomersActionTypes.OpenUpdateCustomerModal;
}

export class CloseUpdateCustomerModal implements Action {
  readonly type = CustomersActionTypes.CloseUpdateCustomerModal;
}

export class OpenCreateCaseOwnerModal implements Action {
  readonly type = CustomersActionTypes.OpenCreateCaseOwnerModal;
}

export class CloseCreateCaseOwnerModal implements Action {
  readonly type = CustomersActionTypes.CloseCreateCaseOwnerModal;
}

export class OpenCreateCasePreviousOwnerModal implements Action {
  readonly type = CustomersActionTypes.OpenCreateCasePreviousOwnerModal;
}

export class CloseCreateCasePreviousOwnerModal implements Action {
  readonly type = CustomersActionTypes.CloseCreateCasePreviousOwnerModal;
}

export class OpenCreateCaseProspectCustomerModal implements Action {
  readonly type = CustomersActionTypes.OpenCreateCaseProspectCustomerModal;
}

export class CloseCreateCaseProspectCustomerModal implements Action {
  readonly type = CustomersActionTypes.CloseCreateCaseProspectCustomerModal;
}

export class LoadCustomerList implements Action {
  readonly type = CustomersActionTypes.LoadCustomerList;
}

export class CustomerListLoadError implements Action {
  readonly type = CustomersActionTypes.CustomerListLoadError;
  constructor(public payload: any) {}
}

export class CustomerListLoaded implements Action {
  readonly type = CustomersActionTypes.CustomerListLoaded;
  constructor(public payload: Entity[]) {}
}

export class LoadPagedCustomerList implements Action {
  readonly type = CustomersActionTypes.LoadPagedCustomerList;
  constructor(
    public payload?: {
      pageIndex?: number;
      pageSize?: number;
      filters?: Array<Filter>;
      order?: Array<Order>;
    }
  ) {}
}

export class PagedCustomerListLoaded implements Action {
  readonly type = CustomersActionTypes.PagedCustomerListLoaded;
  constructor(public payload: PagedApiResponse<CustomerListItem>) {}
}

export class PagedCustomerListLoadError implements Action {
  readonly type = CustomersActionTypes.PagedCustomerListLoadError;
  constructor(public payload: any) {}
}

export class CustomerListChangeSelections implements Action {
  readonly type = CustomersActionTypes.CustomerListChangeSelections;
  constructor(public payload: string[]) {}
}

export class LoadCustomerDetails implements Action {
  readonly type = CustomersActionTypes.LoadCustomerDetails;
  constructor(public payload: string) {}
}

export class CustomerDetailsLoaded implements Action {
  readonly type = CustomersActionTypes.CustomerDetailsLoaded;
  constructor(public payload: CustomerDto) {}
}

export class CustomerDetailsLoadError implements Action {
  readonly type = CustomersActionTypes.CustomerDetailsLoadError;
  constructor(public payload: any) {}
}

export class LoadSelectedCustomerDetailsForSellModal implements Action {
  readonly type = CustomersActionTypes.LoadSelectedCustomerDetailsForSellModal;
  constructor(public payload: string) {}
}

export class SelectedCustomerDetailsForSellModalLoaded implements Action {
  readonly type = CustomersActionTypes.SelectedCustomerDetailsForSellModalLoaded;
  constructor(public payload: CustomerDto) {}
}

export class SelectedCustomerDetailsForSellModalLoadError implements Action {
  readonly type = CustomersActionTypes.SelectedCustomerDetailsForSellModalLoadError;
}

export class LoadHaveRelatedCaseForSelectedCustomers implements Action {
  readonly type = CustomersActionTypes.LoadHaveRelatedCaseForSelectCustomers;
  constructor() {}
}

export class LoadHaveRelatedCase implements Action {
  readonly type = CustomersActionTypes.LoadHaveRelatedCase;
  constructor(public payload: string[]) {}
}

export class HaveRelatedCaseLoaded implements Action {
  readonly type = CustomersActionTypes.HaveRelatedCaseLoaded;
  constructor(public payload: boolean) {}
}

export class HaveRelatedCaseError implements Action {
  readonly type = CustomersActionTypes.HaveRelatedCaseError;
  constructor(public payload: any) {}
}

export class DeleteSelectedCustomersFromList implements Action {
  readonly type = CustomersActionTypes.DeleteSelectedCustomersFromList;
}

export class SelectedCustomersDeleted implements Action {
  readonly type = CustomersActionTypes.SelectedCustomersDeleted;
}

export class SelectedCustomersDeleteError implements Action {
  readonly type = CustomersActionTypes.SelectedCustomersDeleteError;
}

export class DeleteCustomer implements Action {
  readonly type = CustomersActionTypes.DeleteCustomer;
  constructor(public payload: string) {}
}

export class CustomerDeleted implements Action {
  readonly type = CustomersActionTypes.CustomerDeleted;
}

export class CustomerDeleteError implements Action {
  readonly type = CustomersActionTypes.CustomerDeleteError;
}

export class LoadCustomerFormsOfAddress implements Action {
  readonly type = CustomersActionTypes.LoadCustomerFormsOfAddress;
  constructor(public payload: string) {}
}

export class CustomerFormsOfAddressLoaded implements Action {
  readonly type = CustomersActionTypes.CustomerFormsOfAddressLoaded;
  constructor(public payload: string[]) {}
}

export class CustomerFormsOfAddressLoadError implements Action {
  readonly type = CustomersActionTypes.CustomerFormsOfAddressLoadError;
}

export class LoadCustomerLatestQuoteList implements Action {
  readonly type = CustomersActionTypes.LoadCustomerLatestQuoteList;
  constructor(public payload: string) {}
}

export class CustomerLatestQuoteListLoaded implements Action {
  readonly type = CustomersActionTypes.CustomerLatestQuoteListLoaded;
  constructor(public payload: Array<OwnerVehicleRecentOfferDto>) {}
}

export class CustomerLatestQuoteListLoadError implements Action {
  readonly type = CustomersActionTypes.CustomerLatestQuoteListLoadError;
  constructor(public payload: any) {}
}

export class ReloadCustomerDetails implements Action {
  readonly type = CustomersActionTypes.ReloadCustomerDetails;
  constructor(public payload: string) {}
}

export class DeleteSelectedCustomers implements Action {
  readonly type = CustomersActionTypes.DeleteSelectedCustomers;
}

export class DeleteCustomers implements Action {
  readonly type = CustomersActionTypes.DeleteCustomers;
  constructor(public payload: string[]) {}
}

export class CustomersDeleted implements Action {
  readonly type = CustomersActionTypes.CustomersDeleted;
  constructor(public payload: { isMultiple: boolean }) {}
}

export class CustomersDeleteError implements Action {
  readonly type = CustomersActionTypes.CustomersDeleteError;
  constructor(public payload: any) {}
}

export class LoadCustomerVehicleList implements Action {
  readonly type = CustomersActionTypes.LoadCustomerVehicleList;
  constructor(public payload: string) {}
}

export class CustomerVehicleListLoadError implements Action {
  readonly type = CustomersActionTypes.CustomerVehicleListLoadError;
  constructor(public payload: any) {}
}

export class CustomerVehicleListLoaded implements Action {
  readonly type = CustomersActionTypes.CustomerVehicleListLoaded;
  constructor(public payload: OwnerVehicleCaseDto[]) {}
}

export type CustomersAction =
  | CreateCustomer
  | CustomerCreated
  | CustomerCreateError
  | OpenCreateCustomerModal
  | CloseCreateCustomerModal
  | UpdateCustomer
  | CustomerUpdated
  | CustomerUpdateError
  | OpenUpdateCustomerModal
  | CloseUpdateCustomerModal
  | OpenCreateCaseOwnerModal
  | CloseCreateCaseOwnerModal
  | OpenCreateCasePreviousOwnerModal
  | CloseCreateCasePreviousOwnerModal
  | OpenCreateCaseProspectCustomerModal
  | CloseCreateCaseProspectCustomerModal
  | LoadCustomerList
  | CustomerListLoaded
  | CustomerListLoadError
  | CustomerListChangeSelections
  | LoadCustomerDetails
  | CustomerDetailsLoaded
  | CustomerDetailsLoadError
  | LoadSelectedCustomerDetailsForSellModal
  | SelectedCustomerDetailsForSellModalLoaded
  | SelectedCustomerDetailsForSellModalLoadError
  | LoadHaveRelatedCase
  | HaveRelatedCaseLoaded
  | HaveRelatedCaseError
  | DeleteSelectedCustomersFromList
  | SelectedCustomersDeleted
  | SelectedCustomersDeleteError
  | DeleteCustomer
  | CustomerDeleted
  | CustomerDeleteError
  | LoadHaveRelatedCaseForSelectedCustomers
  | LoadCustomerFormsOfAddress
  | CustomerFormsOfAddressLoaded
  | CustomerFormsOfAddressLoadError
  | LoadCustomerLatestQuoteList
  | CustomerLatestQuoteListLoaded
  | CustomerLatestQuoteListLoadError
  | LoadPagedCustomerList
  | PagedCustomerListLoaded
  | PagedCustomerListLoadError
  | ReloadCustomerDetails
  | LoadCustomerVehicleList
  | CustomerVehicleListLoaded
  | CustomerVehicleListLoadError;

export const fromCustomersActions = {
  CreateCustomer,
  CustomerCreated,
  CustomerCreateError,
  OpenCreateCustomerModal,
  CloseCreateCustomerModal,
  UpdateCustomer,
  CustomerUpdated,
  CustomerUpdateError,
  OpenUpdateCustomerModal,
  CloseUpdateCustomerModal,
  OpenCreateCaseOwnerModal,
  CloseCreateCaseOwnerModal,
  OpenCreateCasePreviousOwnerModal,
  CloseCreateCasePreviousOwnerModal,
  OpenCreateCaseProspectCustomerModal,
  CloseCreateCaseProspectCustomerModal,
  LoadCustomerList,
  CustomerListLoaded,
  CustomerListLoadError,
  CustomerListChangeSelections,
  LoadCustomerDetails,
  CustomerDetailsLoaded,
  CustomerDetailsLoadError,
  LoadSelectedCustomerDetailsForSellModal,
  SelectedCustomerDetailsForSellModalLoaded,
  SelectedCustomerDetailsForSellModalLoadError,
  LoadHaveRelatedCaseForSelectedCustomers,
  LoadHaveRelatedCase,
  HaveRelatedCaseLoaded,
  HaveRelatedCaseError,
  DeleteSelectedCustomersFromList,
  SelectedCustomersDeleted,
  SelectedCustomersDeleteError,
  DeleteCustomer,
  CustomerDeleted,
  CustomerDeleteError,
  LoadCustomerFormsOfAddress,
  CustomerFormsOfAddressLoaded,
  CustomerFormsOfAddressLoadError,
  LoadCustomerLatestQuoteList,
  CustomerLatestQuoteListLoaded,
  CustomerLatestQuoteListLoadError,
  LoadPagedCustomerList,
  PagedCustomerListLoaded,
  PagedCustomerListLoadError,
  ReloadCustomerDetails,
  DeleteSelectedCustomers,
  DeleteCustomers,
  CustomersDeleted,
  CustomersDeleteError,
  LoadCustomerVehicleList,
  CustomerVehicleListLoaded,
  CustomerVehicleListLoadError,
};
