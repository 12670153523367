import { Action } from '@ngrx/store';

export enum AppActionTypes {
  LoadApp = '[App] Load App',
  AppLoaded = '[App] App Loaded',
  AppLoadError = '[App] App Load Error',
  SetIsDealerAppValue = '[App] Set Is Dealer App Value',
  SetIsAdminAppValue = '[App] Set Is Admin App Value',
  SetIsNavigationInProgressValue = '[App] Set Is Navigation In Progress Value',
}

export class LoadApp implements Action {
  readonly type = AppActionTypes.LoadApp;
}

export class AppLoadError implements Action {
  readonly type = AppActionTypes.AppLoadError;
  constructor(public payload: any) {}
}

export class AppLoaded implements Action {
  readonly type = AppActionTypes.AppLoaded;
  constructor(public payload: any) {}
}

export class SetIsDealerAppValue implements Action {
  readonly type = AppActionTypes.SetIsDealerAppValue;
  constructor(public payload: boolean) {}
}

export class SetIsAdminAppValue implements Action {
  readonly type = AppActionTypes.SetIsAdminAppValue;
  constructor(public payload: boolean) {}
}

export class SetIsNavigationInProgressValue implements Action {
  readonly type = AppActionTypes.SetIsNavigationInProgressValue;
  constructor(public payload: boolean) {}
}

export type AppAction =
  | LoadApp
  | AppLoaded
  | AppLoadError
  | SetIsDealerAppValue
  | SetIsAdminAppValue
  | SetIsNavigationInProgressValue;

export const fromAppActions = {
  LoadApp,
  AppLoaded,
  AppLoadError,
  SetIsDealerAppValue,
  SetIsAdminAppValue,
  SetIsNavigationInProgressValue,
};
