import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMapTo, tap } from 'rxjs/operators';
import { authQuery } from '../../+state/auth.selectors';
import { UnauthorizedUrl } from '../../+state/actions/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard  {
  constructor(private store: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const loadedFromCacheState$ = this.store.pipe(
      select(authQuery.getLoadedFromCacheState),
      filter(loaded => loaded)
    );

    const authStatus$ = this.store.pipe(select(authQuery.getIsAuthenticated));
    return loadedFromCacheState$.pipe(
      switchMapTo(authStatus$),
      tap(authenticated => {
        if (!authenticated) {
          this.store.dispatch(new UnauthorizedUrl(state.url));
        }
      })
    );
  }
}
