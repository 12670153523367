import { Component, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { VersionService } from '@vpfa/rest-api/versions';
import { DynamicComponentsStackService } from '@vpfa/shared/dynamic-components-stack';
import { MobileCopyrightComponent } from '../../components/mobile-copyright/mobile-copyright.component';
import { EnvironmentsService } from '@vpfa/environments/data';
import { ValuationKernelDto, ValuationKernelViewService } from '@vpfa/rest-api/valuation';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthFacade } from '@vpfa/auth';
import { ProfileFacade } from '@vpfa/profile/data';

declare const BRANCH: string;
declare const COMMITHASH: string;

@Component({
  selector: 'vpfa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  get frontendCommitUrl() {
    return `https://bitbucket.org/eurotaxglass1/dealerchannelfa/commits/${this.FRONTEND_COMMITHASH}`;
  }
  get backendCommitUrl() {
    return `https://bitbucket.org/eurotaxglass1/dealerchannel/commits/`;
  }

  FRONTEND_BRANCH = BRANCH;
  FRONTEND_COMMITHASH = COMMITHASH;

  BACKEND_COMMITHASH: {
    service: string;
    branch: string;
    commitId: string;
  }[] = null;

  valuationKernel$: Observable<ValuationKernelDto>;
  // INFO: Kernel data should be available for dealer admins, but not for country and system admins
  isNonAdminUserLoggedIn$: Observable<boolean> = combineLatest([
    this.authFacade.isAuthenticated$,
    this.profileFacade.isCountryAdmin$,
    this.profileFacade.isSystemAdmin$,
  ]).pipe(
    map(
      ([isAuthenticated, isCountryAdmin, isSystemAdmin]) =>
        isAuthenticated === true && isCountryAdmin === false && isSystemAdmin === false
    )
  );
  showKernelData$ = combineLatest([this.isNonAdminUserLoggedIn$, this.authFacade.loading$]).pipe(
    map(([isNonAdminUserLoggedIn, isLoginProcessing]) => isNonAdminUserLoggedIn === true && isLoginProcessing === false)
  );

  isGitInformationVisible = this.getIsGitInformationVisible();
  isVisible = false;
  mobileCopyright: ComponentRef<MobileCopyrightComponent>;

  groupSiteUrl = this.environmentsService.config.groupSiteUrl;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private versionService: VersionService,
    private valuationKernelViewService: ValuationKernelViewService,
    private dynamicComponentsStackService: DynamicComponentsStackService,
    private environmentsService: EnvironmentsService,
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade
  ) {}

  ngOnInit(): void {
    this.versionService.getCommit().subscribe(value => {
      this.BACKEND_COMMITHASH = Object.entries(value).map(([service, value]) => {
        return {
          service: service,
          branch: value.Branch ?? value.branch,
          commitId: value.CommitId ?? value.commitId,
        };
      });
    });

    this.isNonAdminUserLoggedIn$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        isNonAdminUserLoggedIn =>
          (this.valuationKernel$ = isNonAdminUserLoggedIn
            ? this.valuationKernelViewService.getValuationKernelVersion()
            : of(null))
      );
  }

  private getIsGitInformationVisible(): boolean {
    let showFooter = false;

    const showInHostNames: string[] = ['autovistagroup.com', 'autovistacloud.net.int', 'localhost'];

    showInHostNames.forEach(hostNameWithFooter => {
      if (location.hostname.indexOf(hostNameWithFooter) !== -1) {
        showFooter = true;
        return false;
      }
    });

    return showFooter;
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  onCopyrightClick() {
    this.isVisible = true;
    if (!this.mobileCopyright) {
      this.mobileCopyright = this.dynamicComponentsStackService.add(MobileCopyrightComponent);
      const clearMobileCopyright = () => {
        this.mobileCopyright = null;
        this.isVisible = false;
      };

      this.mobileCopyright.onDestroy(() => {
        clearMobileCopyright();
      });

      this.mobileCopyright.instance.close.subscribe(() => {
        clearMobileCopyright();
      });
    }
  }

  closeCopyright() {
    this.isVisible = false;
    if (this.mobileCopyright) {
      this.dynamicComponentsStackService.removeTop();
      this.mobileCopyright = null;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
