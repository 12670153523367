const serverHost = location.host;
const protocol = location.protocol;

export const environment = {
  production: true,
  apiGateway: `${protocol}//api.${serverHost}`,
  mixPanelEnabled: true,
  datadogEnabled: true,
  deploymentVersion: {
    checkIntervalInSeconds: 300, // 5 minutes
    filename: 'deployment-version.json',
  },
};
