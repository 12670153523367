import { CountriesAction, CountriesActionTypes } from './countries.actions';
import { produce } from 'immer';
import {
  CountryDto,
  CountryListItemDto,
  CountryVinThresholdDto,
  ExperianCountrySettingsDto,
} from '@vpfa/rest-api/admin';
import { CountryTaxDto } from '@vpfa/rest-api/valuation';
import {
  CountryBroadcastConnectionConfigurationDto,
  CountryBroadcastConnectionConfigurationListItemDto,
} from '@vpfa/rest-api/ad-broadcast';
import { CountryBroadcastConnectionConfigurationMappingsListItemDto } from '@vpfa/rest-api/ad-broadcast';

export const COUNTRIES_FEATURE_KEY = 'countries';

/**
 * Interface for the 'Countries' data used in
 *  - CountriesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity extends CountryListItemDto {}

export interface CountryGdvStateData {
  id: string;
  username: string;
  isPasswordSet: boolean;
}

export interface CountryGdvState {
  isLoading: boolean;
  isLoadingUsername: boolean;
  isLoadingSetPassword: boolean;
  data: CountryGdvStateData;
}

export interface CountriesListState {
  items: Entity[];
  loaded: boolean;
  isLoading: boolean;
}

export interface CountryLogoState {
  uploading: boolean;
  logo: string;
  loading: boolean;
}

export interface CountryGeneralState {
  countryTax: CountryTaxDto;
  isProcessingCountryTax: boolean;
  isProcessCountryUCNumberSource: boolean;
  isProcessingCountryUrl: boolean;
  isProcessingHotlinePhone: boolean;
  isProcessingHotlineEmail: boolean;
}

export interface BroadcastingItemLogoState {
  isUploading: boolean;
  item?: string;
  isLoading: boolean;
  hasError: boolean;
}

export interface BroadcastingItemCertificateState {
  isUploading: boolean;
  item: string;
  isLoading: boolean;
  hasError: boolean;
}

interface BroadcastingState {
  isProcessing: boolean;
  countryId: string;
  listForMapping: {
    isLoading: boolean;
    items: Array<CountryBroadcastConnectionConfigurationMappingsListItemDto>;
  };
  list: {
    items: Array<CountryBroadcastConnectionConfigurationListItemDto>;
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
  };
  details: {
    logo: BroadcastingItemLogoState;
    certificate: BroadcastingItemCertificateState;
    item: CountryBroadcastConnectionConfigurationDto;
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
  };
  ftp: {
    created: boolean;
    updated: boolean;
  };
}

export interface CountryDetailsState {
  country: CountryDto;
  isLoading: boolean;
  isDefaultLanguageUpdating: boolean;
  isDefaultContentLanguageUpdating: boolean;
  isAlternativeLanguageUpdating: boolean;
  isAlternativeContentLanguageUpdating: boolean;
  isLocaleUpdating: boolean;
  isCurrencyUpdating: boolean;
  isTimezoneUpdating: boolean;
}

export interface ContactUsState {
  countryCodeUpdating: boolean;
  defaultPriorityUpdating: boolean;
  defaultTypeUpdating: boolean;
  defaultClassificationUpdating: boolean;
  enquiryTypeUpdating: boolean;
  headerTextsUpdating: boolean;
}

export interface VinThresholdState {
  threshold: CountryVinThresholdDto;
  processing: boolean;
}

export interface ExperianCountrySettingsState {
  countrySettings: ExperianCountrySettingsDto;
  loading: boolean;
  processing: boolean;
}

export interface CountriesState {
  list: CountriesListState;
  details: CountryDetailsState;
  general: CountryGeneralState;
  valuationListInitialSetOfFields: ValuationListInitialSetOfFields;
  stockListInitialSetOfFields: StockListInitialSetOfFields;
  customersListInitialSetOfFields: CustomersListInitialSetOfFields;
  logo: CountryLogoState;
  broadcasting: BroadcastingState;
  contactUs: ContactUsState;
  vinThreshold: VinThresholdState;
  experianCountrySettings: ExperianCountrySettingsState;
  gdv: CountryGdvState;
}

export interface ValuationListInitialSetOfFields {
  loading: boolean;
  data: any;
}

export interface StockListInitialSetOfFields {
  loading: boolean;
  data: any;
}

export interface CustomersListInitialSetOfFields {
  loading: boolean;
  data: any;
}

export interface CountriesPartialState {
  readonly [COUNTRIES_FEATURE_KEY]: CountriesState;
}

export const initialState: CountriesState = {
  list: {
    items: [],
    loaded: false,
    isLoading: false,
  },
  valuationListInitialSetOfFields: {
    loading: false,
    data: null,
  },
  stockListInitialSetOfFields: {
    loading: false,
    data: null,
  },
  customersListInitialSetOfFields: {
    loading: false,
    data: null,
  },
  details: {
    country: null,
    isLoading: false,
    isDefaultLanguageUpdating: false,
    isDefaultContentLanguageUpdating: false,
    isAlternativeLanguageUpdating: false,
    isAlternativeContentLanguageUpdating: false,
    isLocaleUpdating: false,
    isCurrencyUpdating: false,
    isTimezoneUpdating: false,
  },
  general: {
    countryTax: null,
    isProcessingCountryTax: false,
    isProcessCountryUCNumberSource: false,
    isProcessingCountryUrl: false,
    isProcessingHotlinePhone: false,
    isProcessingHotlineEmail: false,
  },
  logo: {
    uploading: false,
    logo: null,
    loading: false,
  },
  broadcasting: {
    isProcessing: false,
    countryId: null,
    list: { items: [], isLoaded: false, isLoading: false, hasError: false },
    details: {
      isLoaded: false,
      isLoading: false,
      item: null,
      hasError: false,
      logo: { item: null, hasError: null, isLoading: false, isUploading: false },
      certificate: { hasError: false, isLoading: false, isUploading: false, item: null },
    },
    ftp: {
      created: false,
      updated: false,
    },
    listForMapping: {
      isLoading: false,
      items: [],
    },
  },
  contactUs: {
    countryCodeUpdating: false,
    defaultPriorityUpdating: false,
    defaultTypeUpdating: false,
    defaultClassificationUpdating: false,
    enquiryTypeUpdating: false,
    headerTextsUpdating: false,
  },
  vinThreshold: {
    processing: false,
    threshold: {
      threshold: null,
    },
  },
  experianCountrySettings: {
    countrySettings: null,
    loading: false,
    processing: false,
  },
  gdv: {
    isLoading: false,
    isLoadingUsername: false,
    isLoadingSetPassword: false,
    data: {
      id: null,
      username: null,
      isPasswordSet: false,
    },
  },
};

export function reducer(state: CountriesState = initialState, action: CountriesAction): CountriesState {
  switch (action.type) {
    case CountriesActionTypes.CountriesLoaded:
      return produce<CountriesState>(state, newState => {
        newState.list.items = action.payload;
        newState.list.loaded = true;
        newState.list.isLoading = false;
      });
    case CountriesActionTypes.LoadCountries:
      return produce<CountriesState>(state, newState => {
        newState.list.isLoading = true;
      });
    case CountriesActionTypes.LoadCountryDetails:
      return produce<CountriesState>(state, newState => {
        newState.details.country = null;
        newState.details.isLoading = true;
      });
    case CountriesActionTypes.CountryDetailsLoaded:
      return produce<CountriesState>(state, newState => {
        newState.details.country = action.payload;
        newState.details.isLoading = false;
      });
    case CountriesActionTypes.CountryDetailsLoadError:
      return produce<CountriesState>(state, newState => {
        newState.details.isLoading = false;
      });
    case CountriesActionTypes.UpdateCountryDefaultLanguage:
      return produce<CountriesState>(state, newState => {
        newState.details.isDefaultLanguageUpdating = true;
      });
    case CountriesActionTypes.CountryDefaultLanguageUpdated:
      return produce<CountriesState>(state, newState => {
        newState.details.country.defaultLanguage = action.payload;
        newState.details.country.alternativeLanguages = state.details.country.alternativeLanguages.filter(
          lang => lang !== action.payload
        );
        newState.details.isDefaultLanguageUpdating = false;
      });
    case CountriesActionTypes.CountryDefaultLanguageError:
      return produce<CountriesState>(state, newState => {
        newState.details.isDefaultLanguageUpdating = false;
      });
    case CountriesActionTypes.UpdateCountryDefaultContentLanguage:
      return produce<CountriesState>(state, newState => {
        newState.details.isDefaultContentLanguageUpdating = true;
      });
    case CountriesActionTypes.CountryDefaultContentLanguageUpdated:
      return produce<CountriesState>(state, newState => {
        newState.details.country.defaultContentLanguage = action.payload;
        newState.details.country.alternativeContentLanguages = state.details.country.alternativeContentLanguages.filter(
          lang => lang !== action.payload
        );
        newState.details.isDefaultContentLanguageUpdating = false;
      });
    case CountriesActionTypes.CountryDefaultContentLanguageError:
      return produce<CountriesState>(state, newState => {
        newState.details.isDefaultContentLanguageUpdating = false;
      });
    case CountriesActionTypes.AddCountryAlternativeLanguage:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeLanguageUpdating = true;
      });
    case CountriesActionTypes.RemoveCountryAlternativeLanguage:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeLanguageUpdating = true;
      });
    case CountriesActionTypes.CountryAlternativeLanguageAdded:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeLanguageUpdating = false;
        newState.details.country.alternativeLanguages = [...state.details.country.alternativeLanguages, action.payload];
      });
    case CountriesActionTypes.CountryAlternativeLanguageRemoved:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeLanguageUpdating = false;
        newState.details.country.alternativeLanguages = state.details.country.alternativeLanguages.filter(
          lang => lang !== action.payload
        );
      });
    case CountriesActionTypes.CountryAlternativeLanguageError:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeLanguageUpdating = false;
      });
    case CountriesActionTypes.AddCountryAlternativeContentLanguage:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeContentLanguageUpdating = true;
      });
    case CountriesActionTypes.RemoveCountryAlternativeContentLanguage:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeContentLanguageUpdating = true;
      });
    case CountriesActionTypes.CountryAlternativeContentLanguageAdded:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeContentLanguageUpdating = false;
        newState.details.country.alternativeContentLanguages = [
          ...state.details.country.alternativeContentLanguages,
          <string>action.payload,
        ];
      });
    case CountriesActionTypes.CountryAlternativeContentLanguageRemoved:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeContentLanguageUpdating = false;
        newState.details.country.alternativeContentLanguages = state.details.country.alternativeContentLanguages.filter(
          lang => lang !== action.payload
        );
      });
    case CountriesActionTypes.CountryAlternativeContentLanguageError:
      return produce<CountriesState>(state, newState => {
        newState.details.isAlternativeContentLanguageUpdating = false;
      });
    case CountriesActionTypes.UpdateCountryLocale:
      return produce<CountriesState>(state, newState => {
        newState.details.isLocaleUpdating = true;
      });
    case CountriesActionTypes.CountryLocaleUpdated:
      return produce<CountriesState>(state, newState => {
        newState.details.country.locale = action.payload;
        newState.details.isLocaleUpdating = false;
      });
    case CountriesActionTypes.CountryLocaleError:
      return produce<CountriesState>(state, newState => {
        newState.details.isLocaleUpdating = false;
      });
    case CountriesActionTypes.UpdateCountryTimezone:
      return produce<CountriesState>(state, newState => {
        newState.details.isTimezoneUpdating = true;
      });
    case CountriesActionTypes.CountryTimezoneUpdated:
      return produce<CountriesState>(state, newState => {
        newState.details.country.timezone = action.payload;
        newState.details.isTimezoneUpdating = false;
      });
    case CountriesActionTypes.CountryTimezoneError:
      return produce<CountriesState>(state, newState => {
        newState.details.isTimezoneUpdating = false;
      });
    case CountriesActionTypes.UpdateCountryCurrency:
      return produce<CountriesState>(state, newState => {
        newState.details.isCurrencyUpdating = true;
      });
    case CountriesActionTypes.CountryCurrencyUpdated:
      return produce<CountriesState>(state, newState => {
        newState.details.country.currency = action.payload;
        newState.details.isCurrencyUpdating = false;
      });
    case CountriesActionTypes.CountryCurrencyError:
      return produce<CountriesState>(state, newState => {
        newState.details.isCurrencyUpdating = false;
      });
    case CountriesActionTypes.UpdateCountryTaxType:
    case CountriesActionTypes.LoadCountryTaxType:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingCountryTax = true;
      });

    case CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdate:
      return produce<CountriesState>(state, newState => {
        newState.valuationListInitialSetOfFields.loading = true;
      });
    case CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdated:
    case CountriesActionTypes.CountryValuationListInitialSetOfFieldsUpdateError:
      return produce<CountriesState>(state, newState => {
        newState.valuationListInitialSetOfFields.loading = false;
      });

    case CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoad:
      return produce<CountriesState>(state, newState => {
        newState.valuationListInitialSetOfFields.loading = true;
      });
    case CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoaded:
      return produce<CountriesState>(state, newState => {
        newState.valuationListInitialSetOfFields.data = action.payload;
        newState.valuationListInitialSetOfFields.loading = false;
      });
    case CountriesActionTypes.CountryValuationListInitialSetOfFieldsLoadError:
      return produce<CountriesState>(state, newState => {
        newState.valuationListInitialSetOfFields.loading = false;
      });

    case CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdate:
      return produce<CountriesState>(state, newState => {
        newState.stockListInitialSetOfFields.loading = true;
      });
    case CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdated:
    case CountriesActionTypes.CountryStockListInitialSetOfFieldsUpdateError:
      return produce<CountriesState>(state, newState => {
        newState.stockListInitialSetOfFields.loading = false;
      });

    case CountriesActionTypes.CountryStockListInitialSetOfFieldsLoad:
      return produce<CountriesState>(state, newState => {
        newState.stockListInitialSetOfFields.loading = true;
      });
    case CountriesActionTypes.CountryStockListInitialSetOfFieldsLoaded:
      return produce<CountriesState>(state, newState => {
        newState.stockListInitialSetOfFields.data = action.payload;
        newState.stockListInitialSetOfFields.loading = false;
      });
    case CountriesActionTypes.CountryStockListInitialSetOfFieldsLoadError:
      return produce<CountriesState>(state, newState => {
        newState.stockListInitialSetOfFields.loading = false;
      });

    case CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdate:
      return produce<CountriesState>(state, newState => {
        newState.customersListInitialSetOfFields.loading = true;
      });
    case CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdated:
    case CountriesActionTypes.CountryCustomersListInitialSetOfFieldsUpdateError:
      return produce<CountriesState>(state, newState => {
        newState.customersListInitialSetOfFields.loading = false;
      });

    case CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoad:
      return produce<CountriesState>(state, newState => {
        newState.customersListInitialSetOfFields.loading = true;
      });
    case CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoaded:
      return produce<CountriesState>(state, newState => {
        newState.customersListInitialSetOfFields.data = action.payload;
        newState.customersListInitialSetOfFields.loading = false;
      });
    case CountriesActionTypes.CountryCustomersListInitialSetOfFieldsLoadError:
      return produce<CountriesState>(state, newState => {
        newState.customersListInitialSetOfFields.loading = false;
      });

    case CountriesActionTypes.UpdateCountryTaxTypeError:
    case CountriesActionTypes.LoadCountryTaxTypeError:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingCountryTax = false;
      });
    case CountriesActionTypes.UpdateCountryTaxTypeSuccess:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingCountryTax = false;
      });
    case CountriesActionTypes.LoadCountryTaxTypeSuccess:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingCountryTax = false;
        newState.general.countryTax = action.payload;
      });

    case CountriesActionTypes.UpdateUCNumberSourceType:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessCountryUCNumberSource = true;
      });

    case CountriesActionTypes.UpdateUCNumberSourceTypeSuccess:
    case CountriesActionTypes.UpdateUCNumberSourceTypeError:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessCountryUCNumberSource = false;
      });
    case CountriesActionTypes.LogoUpload:
      return produce(state, newState => {
        newState.logo.uploading = true;
      });
    case CountriesActionTypes.LogoUploaded:
    case CountriesActionTypes.LogoUploadError:
      return produce(state, newState => {
        newState.logo.uploading = false;
      });
    case CountriesActionTypes.LogoLoad:
      return produce(state, newState => {
        newState.logo.loading = true;
      });
    case CountriesActionTypes.LogoLoaded:
      return produce(state, newState => {
        newState.logo.loading = false;
        newState.logo.logo = action.payload;
      });
    case CountriesActionTypes.LogoLoadError:
      return produce(state, newState => {
        newState.logo.loading = false;
      });
    case CountriesActionTypes.UpdateCountryUrl:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingCountryUrl = true;
      });

    case CountriesActionTypes.UpdateCountryUrlSuccess:
    case CountriesActionTypes.UpdateCountryUrlError:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingCountryUrl = false;
      });
    case CountriesActionTypes.UpdateCountryHotlinePhone:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingHotlinePhone = true;
      });

    case CountriesActionTypes.UpdateCountryHotlinePhoneSuccess:
    case CountriesActionTypes.UpdateCountryHotlinePhoneError:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingHotlinePhone = false;
      });
    case CountriesActionTypes.UpdateCountryHotlineEmail:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingHotlineEmail = true;
      });

    case CountriesActionTypes.UpdateCountryHotlineEmailSuccess:
    case CountriesActionTypes.UpdateCountryHotlineEmailError:
      return produce<CountriesState>(state, newState => {
        newState.general.isProcessingHotlineEmail = false;
      });

    case CountriesActionTypes.LoadBroadcastList:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.list.isLoaded = false;
        newState.broadcasting.list.isLoading = true;
        newState.broadcasting.countryId = action.payload;
        newState.broadcasting.ftp.created = false;
        newState.broadcasting.ftp.updated = false;
        newState.broadcasting.details.isLoaded = false;
        newState.broadcasting.details.isLoading = false;
        newState.broadcasting.details.item = null;
        newState.broadcasting.details.hasError = false;
        newState.broadcasting.details.logo.item = null;
        newState.broadcasting.details.logo.hasError = null;
        newState.broadcasting.details.logo.isLoading = false;
        newState.broadcasting.details.logo.isUploading = false;
        newState.broadcasting.details.certificate.item = null;
        newState.broadcasting.details.certificate.hasError = null;
        newState.broadcasting.details.certificate.isLoading = false;
        newState.broadcasting.details.certificate.isUploading = false;
      });

    case CountriesActionTypes.LoadBroadcastListSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.list.items = action.payload;
        newState.broadcasting.list.isLoaded = true;
        newState.broadcasting.list.isLoading = false;
      });

    case CountriesActionTypes.LoadBroadcastListError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.list.isLoaded = false;
        newState.broadcasting.list.isLoading = false;
        newState.broadcasting.list.hasError = true;
      });

    case CountriesActionTypes.LoadBroadcastDetails:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.isLoaded = false;
        newState.broadcasting.details.isLoading = true;
        newState.broadcasting.countryId = action.payload.countryId;
      });

    case CountriesActionTypes.LoadBroadcastDetailsSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.item = action.payload;
        newState.broadcasting.details.isLoaded = true;
        newState.broadcasting.details.isLoading = false;
      });

    case CountriesActionTypes.LoadBroadcastDetailsError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.isLoaded = false;
        newState.broadcasting.details.isLoading = false;
        newState.broadcasting.details.hasError = true;
      });

    case CountriesActionTypes.LoadBroadcastLogo:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.logo.isLoading = true;
      });

    case CountriesActionTypes.LoadBroadcastLogoError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.logo.isLoading = false;
        newState.broadcasting.details.logo.hasError = true;
      });
    case CountriesActionTypes.LoadBroadcastLogoSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.logo.isLoading = false;
        newState.broadcasting.details.logo.item = action.payload;
      });

    case CountriesActionTypes.LoadBroadcastCertificate:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.certificate.isLoading = true;
        newState.broadcasting.details.certificate.hasError = false;
        newState.broadcasting.isProcessing = true;
      });

    case CountriesActionTypes.LoadBroadcastCertificateError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.certificate.isLoading = false;
        newState.broadcasting.details.certificate.hasError = true;
        newState.broadcasting.isProcessing = false;
      });
    case CountriesActionTypes.LoadBroadcastCertificateSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.certificate.isLoading = false;
        newState.broadcasting.details.certificate.item = action.payload;
        newState.broadcasting.isProcessing = false;
      });

    case CountriesActionTypes.UpdateBroadcastLogo:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.logo.isUploading = true;
        newState.broadcasting.isProcessing = true;
      });

    case CountriesActionTypes.UpdateBroadcastLogoError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.logo.isUploading = false;
        newState.broadcasting.details.logo.hasError = true;
        newState.broadcasting.isProcessing = false;
      });
    case CountriesActionTypes.UpdateBroadcastLogoSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.logo.isUploading = false;
        newState.broadcasting.isProcessing = false;
      });

    case CountriesActionTypes.UpdateBroadcastCertificate:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.certificate.isUploading = true;
        newState.broadcasting.isProcessing = true;
      });

    case CountriesActionTypes.UpdateBroadcastCertificateError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.certificate.isUploading = false;
        newState.broadcasting.details.certificate.hasError = true;
        newState.broadcasting.isProcessing = false;
      });
    case CountriesActionTypes.UpdateBroadcastCertificateSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.details.certificate.isUploading = false;
        newState.broadcasting.isProcessing = false;
      });

    case CountriesActionTypes.UpdateBroadcastName:
    case CountriesActionTypes.UpdateBroadcastUsername:
    case CountriesActionTypes.UpdateBroadcastHostname:
    case CountriesActionTypes.UpdateBroadcastPassword:
    case CountriesActionTypes.UpdateBroadcastIsActive:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.isProcessing = true;
        newState.broadcasting.details.isLoading = true;
        newState.broadcasting.details.hasError = false;
      });

    case CountriesActionTypes.UpdateBroadcastNameError:
    case CountriesActionTypes.UpdateBroadcastUsernameError:
    case CountriesActionTypes.UpdateBroadcastHostnameError:
    case CountriesActionTypes.UpdateBroadcastPasswordError:
    case CountriesActionTypes.UpdateBroadcastIsActiveError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.isProcessing = false;
        newState.broadcasting.details.isLoading = false;
        newState.broadcasting.details.hasError = true;
      });

    case CountriesActionTypes.UpdateBroadcastNameSuccess:
    case CountriesActionTypes.UpdateBroadcastUsernameSuccess:
    case CountriesActionTypes.UpdateBroadcastHostnameSuccess:
    case CountriesActionTypes.UpdateBroadcastPasswordSuccess:
    case CountriesActionTypes.UpdateBroadcastIsActiveSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.isProcessing = false;
        newState.broadcasting.details.isLoading = false;
        newState.broadcasting.details.hasError = false;
      });

    case CountriesActionTypes.CreateFtpExportSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.ftp.created = true;
      });

    case CountriesActionTypes.EditFtpExportSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.ftp.updated = true;
      });

    case CountriesActionTypes.UpdateContactUsCountryCode:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.countryCodeUpdating = true;
      });

    case CountriesActionTypes.UpdateContactUsCountryCodeError:
    case CountriesActionTypes.UpdateContactUsCountryCodeSuccess:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.countryCodeUpdating = false;
      });

    case CountriesActionTypes.UpdateContactUsDefaultPriority:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.defaultPriorityUpdating = true;
      });

    case CountriesActionTypes.UpdateContactUsDefaultPriorityError:
    case CountriesActionTypes.UpdateContactUsDefaultPrioritySuccess:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.defaultPriorityUpdating = false;
      });

    case CountriesActionTypes.UpdateContactUsDefaultType:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.defaultTypeUpdating = true;
      });

    case CountriesActionTypes.UpdateContactUsDefaultTypeError:
    case CountriesActionTypes.UpdateContactUsDefaultTypeSuccess:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.defaultTypeUpdating = false;
      });

    case CountriesActionTypes.UpdateContactUsDefaultClassification:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.defaultClassificationUpdating = true;
      });

    case CountriesActionTypes.UpdateContactUsDefaultClassificationError:
    case CountriesActionTypes.UpdateContactUsDefaultClassificationSuccess:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.defaultClassificationUpdating = false;
      });

    case CountriesActionTypes.UpdateContactUsHeaderTexts:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.headerTextsUpdating = true;
      });

    case CountriesActionTypes.UpdateContactUsHeaderTextsError:
    case CountriesActionTypes.UpdateContactUsHeaderTextsSuccess:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.headerTextsUpdating = false;
      });

    case CountriesActionTypes.AddContactUsEnquiryType:
    case CountriesActionTypes.UpdateContactUsEnquiryType:
    case CountriesActionTypes.DeleteContactUsEnquiryType:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.enquiryTypeUpdating = true;
      });

    case CountriesActionTypes.AddContactUsEnquiryTypeError:
    case CountriesActionTypes.AddContactUsEnquiryTypeSuccess:
    case CountriesActionTypes.UpdateContactUsEnquiryTypeError:
    case CountriesActionTypes.UpdateContactUsEnquiryTypeSuccess:
    case CountriesActionTypes.DeleteContactUsEnquiryTypeError:
    case CountriesActionTypes.DeleteContactUsEnquiryTypeSuccess:
      return produce<CountriesState>(state, newState => {
        newState.contactUs.enquiryTypeUpdating = false;
      });

    case CountriesActionTypes.LoadVinThreshold:
      return produce<CountriesState>(state, newState => {
        newState.vinThreshold.processing = true;
      });
    case CountriesActionTypes.LoadVinThresholdSuccess:
      return produce<CountriesState>(state, newState => {
        newState.vinThreshold.processing = false;
        newState.vinThreshold.threshold = action.payload;
      });
    case CountriesActionTypes.LoadVinThresholdError:
      return produce<CountriesState>(state, newState => {
        newState.vinThreshold.processing = false;
      });
    case CountriesActionTypes.UpdateVinThreshold:
      return produce<CountriesState>(state, newState => {
        newState.vinThreshold.processing = true;
      });
    case CountriesActionTypes.UpdateVinThresholdSuccess:
    case CountriesActionTypes.UpdateVinThresholdError:
      return produce<CountriesState>(state, newState => {
        newState.vinThreshold.processing = false;
      });

    case CountriesActionTypes.LoadExperianCountrySettings:
      return produce<CountriesState>(state, newState => {
        newState.experianCountrySettings.loading = true;
      });
    case CountriesActionTypes.LoadExperianCountrySettingsSuccess:
      return produce<CountriesState>(state, newState => {
        newState.experianCountrySettings.loading = false;
        newState.experianCountrySettings.countrySettings = action.payload;
      });
    case CountriesActionTypes.LoadExperianCountrySettingsError:
      return produce<CountriesState>(state, newState => {
        newState.experianCountrySettings.loading = false;
      });
    case CountriesActionTypes.UpdateExperianHostUrl:
    case CountriesActionTypes.UpdateExperianTermsAndConditionsUrl:
      return produce<CountriesState>(state, newState => {
        newState.experianCountrySettings.processing = true;
      });
    case CountriesActionTypes.UpdateExperianHostUrlSuccess:
    case CountriesActionTypes.UpdateExperianHostUrlError:
    case CountriesActionTypes.UpdateExperianTermsAndConditionsUrlSuccess:
    case CountriesActionTypes.UpdateExperianTermsAndConditionsUrlError:
      return produce<CountriesState>(state, newState => {
        newState.experianCountrySettings.processing = false;
      });

    case CountriesActionTypes.LoadCountryGdv:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoading = true;
      });
    case CountriesActionTypes.UpdateCountryGdvUsername:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoadingUsername = true;
      });
    case CountriesActionTypes.UpdateCountryGdvPassword:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoadingSetPassword = true;
      });
    case CountriesActionTypes.LoadCountryGdvError:
    case CountriesActionTypes.UpdateCountryGdvUsernameError:
    case CountriesActionTypes.UpdateCountryGdvPasswordError:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoading = false;
        newState.gdv.isLoadingUsername = false;
        newState.gdv.isLoadingSetPassword = false;
      });
    case CountriesActionTypes.UpdateCountryGdvUsernameSuccess:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoadingUsername = false;
        newState.gdv.data.username = action.payload;
      });
    case CountriesActionTypes.UpdateCountryGdvPasswordSuccess:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoadingSetPassword = false;
      });
    case CountriesActionTypes.LoadCountryGdvSuccess:
      return produce<CountriesState>(state, newState => {
        newState.gdv.isLoading = false;
        newState.gdv.data = action.payload;
      });
      
    case CountriesActionTypes.LoadBroadcastCountryMappingList:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.listForMapping.isLoading = true;
        newState.broadcasting.listForMapping.items = [];
      });
    case CountriesActionTypes.LoadBroadcastCountryMappingListSuccess:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.listForMapping.isLoading = false;
        newState.broadcasting.listForMapping.items = action.payload;
      });
    case CountriesActionTypes.LoadBroadcastCountryMappingListError:
      return produce<CountriesState>(state, newState => {
        newState.broadcasting.listForMapping.isLoading = false;
        newState.broadcasting.listForMapping.items = [];
      });
  }

  return state;
}
