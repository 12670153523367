import { Component } from '@angular/core';
import { ProfileFacade } from '@vpfa/profile/data';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import { map } from 'rxjs/operators';
import { getLiveRetailTaxType } from '@vpfa/utils';

@Component({
  selector: 'vpfa-currency-renderer',
  templateUrl: './currency-renderer.component.html',
  styleUrls: ['./currency-renderer.component.scss'],
})
export class CurrencyRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  priceInformation: boolean;

  countryCode$ = this.profileFacade.userContext$.pipe(map(x => x.countryCode));

  priceInformation$ = this.countryCode$.pipe(
    map(countryCode => {
      return getLiveRetailTaxType(this.params.data.vehicleType, countryCode);
    }),
  );

  constructor(private profileFacade: ProfileFacade) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.priceInformation = params?.colDef?.cellRendererParams?.priceInformation;
  }

  isPriceInformationVisible() {
    return !isNil(this.params?.value) && !isNil(this.priceInformation);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
