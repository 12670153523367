import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CreateCustomerCommand, CustomerDto, Filter, Order, UpdateCustomerCommand } from '@vpfa/rest-api/valuation';
import { filter, map, take } from 'rxjs/operators';
import {
  CustomerCreated,
  CustomerDetailsLoaded,
  CustomerDetailsLoadError,
  CustomersActionTypes,
  fromCustomersActions,
  LoadPagedCustomerList,
  PagedCustomerListLoaded,
  SelectedCustomersDeleted,
} from './customers.actions';
import { CustomersPartialState } from './customers.reducer';
import { customersQuery } from './customers.selectors';
import { merge } from 'rxjs';

@Injectable()
export class CustomersFacade {
  loadedList$ = this.store.pipe(select(customersQuery.getIsListLoaded));
  loadingList$ = this.store.pipe(select(customersQuery.getIsListLoading));
  allCustomerList$ = this.store.pipe(select(customersQuery.getAllCustomerList));
  selectedCustomersId$ = this.store.pipe(select(customersQuery.getPagedSelectedCustomersId));

  // TODO: remove
  selectedCustomers$ = this.store.pipe(select(customersQuery.getSelectedCustomers));

  pagedCustomersList$ = this.store.pipe(select(customersQuery.getPagedCustomersList));
  isPagedCustomersListLoading$ = this.store.pipe(select(customersQuery.getIsPagedCustomersListLoading));
  isPagedCustomersListProcessing$ = this.store.pipe(select(customersQuery.getCustomerPagedListProcessing));
  isPagedCustomersListAllRowLoaded$ = this.store.pipe(select(customersQuery.getIsPagedCustomersListAllRowLoaded));
  pagedCustomersListTotalRows$ = this.store.pipe(select(customersQuery.getPagedCustomersListTotalRows));

  customerDetails$ = this.store.pipe(select(customersQuery.getCustomerDetails));
  detailsLoading$ = this.store.pipe(select(customersQuery.getCustomerDetailsLoading));
  detailsProcessing$ = this.store.pipe(select(customersQuery.getCustomerDetailsProcessing));
  detailsError$ = this.store.pipe(select(customersQuery.getCustomerDetailsError));

  createCustomerModalOpen$ = this.store.pipe(select(customersQuery.getCreateCustomerModalOpen));
  createCustomerModalProcessing$ = this.store.pipe(select(customersQuery.getCreateCustomerModalProcessing));
  updateCustomerModalOpen$ = this.store.pipe(select(customersQuery.getUpdateCustomerModalOpen));
  updateCustomerModalProcessing$ = this.store.pipe(select(customersQuery.getUpdateCustomerModalProcessing));

  createCaseOwnerModalOpen$ = this.store.pipe(select(customersQuery.getCreateCaseOwnerModalOpen));
  createCasePreviousOwnerModalOpen$ = this.store.pipe(select(customersQuery.getCreateCasePreviousOwnerModalOpen));
  createCaseProspectCustomerModalOpen$ = this.store.pipe(select(customersQuery.getCreateCaseProspectCustomerModalOpen));

  selectedCustomersForSellModalDetails$ = this.store.pipe(
    select(customersQuery.getSelectedCustomersForSellModalDetails),
  );
  selectedForSellModalDetailsLoaded$ = this.store.pipe(select(customersQuery.getSelectedForSellModalDetailsLoaded));

  haveRelatedCase$ = this.store.pipe(select(customersQuery.getHaveRelatedCase));
  haveRelatedCaseLoading$ = this.store.pipe(select(customersQuery.getHaveRelatedCaseLoading));

  customerFormsOfAddress$ = this.store.pipe(select(customersQuery.getCustomerFormsOfAddress));
  customerFormsOfAddressLoading$ = this.store.pipe(select(customersQuery.getCustomerFormsOfAddressLoading));
  isCustomerLatestQuoteListLoading$ = this.store.pipe(select(customersQuery.getIsCustomerLatestQuoteListLoading));
  customerLatestQuoteList$ = this.store.pipe(select(customersQuery.getCustomerLatestQuoteList));

  customerCreatedAction$ = this.actions$.pipe(ofType<CustomerCreated>(CustomersActionTypes.CustomerCreated));
  selectedCustomersDeletedAction$ = this.actions$.pipe(
    ofType<SelectedCustomersDeleted>(CustomersActionTypes.SelectedCustomersDeleted),
  );

  constructor(
    private store: Store<CustomersPartialState>,
    private actions$: Actions,
  ) {}

  loadPage(pageIndex: number, pageSize: number, filters: Filter[], order?: Order[]) {
    const firstPage = pageIndex === 0;

    this.isPagedCustomersListAllRowLoaded$
      .pipe(
        take(1),
        // do not get page if all results are already loaded
        filter(allRowLoaded => allRowLoaded === false || firstPage),
      )
      .subscribe(() => {
        this.store.dispatch(
          new LoadPagedCustomerList({
            pageIndex,
            pageSize,
            filters,
            order,
          }),
        );
      });

    return this.actions$.pipe(
      ofType<PagedCustomerListLoaded>(CustomersActionTypes.PagedCustomerListLoaded),
      map(x => x.payload),
    );
  }

  // TODO: still used in case details and others to load customers
  loadAll() {
    this.store.dispatch(new fromCustomersActions.LoadCustomerList());
  }

  createCustomer(payload: CreateCustomerCommand, hooks: { afterSuccess: any }) {
    this.store.dispatch(new fromCustomersActions.CreateCustomer<undefined>(payload, hooks));
  }

  updateCustomer(payload: UpdateCustomerCommand) {
    this.store.dispatch(new fromCustomersActions.UpdateCustomer(payload));
  }

  loadCustomerDetails(payload: string) {
    this.store.dispatch(new fromCustomersActions.LoadCustomerDetails(payload));

    return merge(
      this.actions$.pipe(ofType<CustomerDetailsLoaded>(CustomersActionTypes.CustomerDetailsLoaded)),
      this.actions$.pipe(ofType<CustomerDetailsLoadError>(CustomersActionTypes.CustomerDetailsLoadError)),
    );
  }

  loadedCustomerDetails(payload: CustomerDto) {
    this.store.dispatch(new fromCustomersActions.CustomerDetailsLoaded(payload));
  }

  loadSelectedCustomerDetailsForSellModal(payload: string) {
    this.store.dispatch(new fromCustomersActions.LoadSelectedCustomerDetailsForSellModal(payload));
  }

  changeListSelections(payload: string[]) {
    this.store.dispatch(new fromCustomersActions.CustomerListChangeSelections(payload));
  }

  loadHaveRelatedCaseForSelectedCustomers() {
    this.store.dispatch(new fromCustomersActions.LoadHaveRelatedCaseForSelectedCustomers());
  }

  loadHaveRelatedCase(payload: string[]) {
    this.store.dispatch(new fromCustomersActions.LoadHaveRelatedCase(payload));
  }

  // INFO: used in customers list
  deleteSelectedCustomer() {
    this.store.dispatch(new fromCustomersActions.DeleteSelectedCustomersFromList());
  }

  // INFO: used in customer details
  deleteCustomer(customerId: string) {
    this.store.dispatch(new fromCustomersActions.DeleteCustomer(customerId));
  }

  loadFormsOfAddress(payload: string) {
    this.store.dispatch(new fromCustomersActions.LoadCustomerFormsOfAddress(payload));
  }

  loadLatestQuoteList(payload: string) {
    this.store.dispatch(new fromCustomersActions.LoadCustomerLatestQuoteList(payload));
  }

  openCreateCustomerModal() {
    this.store.dispatch(new fromCustomersActions.OpenCreateCustomerModal());
  }

  closeCreateCustomerModal() {
    this.store.dispatch(new fromCustomersActions.CloseCreateCustomerModal());
  }

  openUpdateCustomerModal() {
    this.store.dispatch(new fromCustomersActions.OpenUpdateCustomerModal());
  }

  closeUpdateCustomerModal() {
    this.store.dispatch(new fromCustomersActions.CloseUpdateCustomerModal());
  }

  openCreateCaseOwnerModal() {
    this.store.dispatch(new fromCustomersActions.OpenCreateCaseOwnerModal());
  }

  closeCreateCaseOwnerModal() {
    this.store.dispatch(new fromCustomersActions.CloseCreateCaseOwnerModal());
  }

  openCreateCasePreviousOwnerModal() {
    this.store.dispatch(new fromCustomersActions.OpenCreateCasePreviousOwnerModal());
  }

  closeCreateCasePreviousOwnerModal() {
    this.store.dispatch(new fromCustomersActions.CloseCreateCasePreviousOwnerModal());
  }

  openCreateCaseProspectCustomerModal() {
    this.store.dispatch(new fromCustomersActions.OpenCreateCaseProspectCustomerModal());
  }

  closeCreateCaseProspectCustomerModal() {
    this.store.dispatch(new fromCustomersActions.CloseCreateCaseProspectCustomerModal());
  }
}
