import { PdfTemplateListItemDto } from '@vpfa/rest-api/valuation';

export function getTemplateName(
  countryCode: string,
  userLangCode: string,
  countryDefaultLangCode: string,
  template: PdfTemplateListItemDto,
): string {
  if (isNotEmpty(template.name[countryCode + userLangCode])) {
    return template.name[countryCode + userLangCode];
  }

  if (isNotEmpty(template.name[countryCode + countryDefaultLangCode])) {
    return template.name[countryCode + countryDefaultLangCode];
  }

  return Object.values(template.name).find(x => isNotEmpty(x));
}

function isNotEmpty(input?: string) {
  return input !== null && input !== undefined && input !== '';
}
