import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from '@vpfa/auth';
import { DealerAppGuard } from './guards/dealer-app-guard/dealer-app-guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('@vpfa/admin/app').then(module => module.AdminAppModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('@vpfa/login-page').then(module => module.LoginPageModule),
  },
  {
    path: 'oauth',
    loadChildren: () => import('@vpfa/oauth/details').then(module => module.OAuthDetailsModule),
  },
  {
    path: 'public',
    loadChildren: () => import('@vpfa/public/offer/app').then(module => module.PublicOfferAppModule),
  },
  {
    path: '',
    loadChildren: () => import('@vpfa/dealer/app').then(module => module.DealerAppModule),
    canActivate: [AuthenticatedGuard, DealerAppGuard],
  },
  {
    path: 'notify',
    children: [
      {
        path: 'repair',
        loadChildren: () =>
          import('@vpfa/dealer/repair-estimate/notify').then(module => module.DealerRepairEstimateNotifyModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'template',
        loadChildren: () => import('@vpfa/print-templates/notify').then(module => module.PrintTemplatesNotifyModule),
      },
    ],
  },
  {
    path: 'resellers',
    loadChildren: () => import('@vpfa/public/resellers/app').then(module => module.PublicResellersAppModule),
  },
  {
    path: 'error',
    loadChildren: () => import('@vpfa/error').then(module => module.ErrorModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
