import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class RouteHistoryService {

  private localHistory : string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { 

    let existingHistory = window.sessionStorage.getItem("RouteHistoryService");
    if (existingHistory) {
      this.localHistory = JSON.parse(existingHistory);      
    }

    this.router.events.subscribe( event => {

      if (event instanceof NavigationEnd)
      {
        if (this.localHistory.length > 10) {
          this.localHistory = this.localHistory.slice(1);
        }
        this.localHistory.push(event.url);
        window.sessionStorage.setItem("RouteHistoryService", JSON.stringify(this.localHistory));
      }

    });

  }

  hasHistory(): boolean {
    return this.localHistory.length > 1 && this.router.url !== this.localHistory[0];
  }

}
