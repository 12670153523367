import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitModalsModule } from '@vpfa/ui-kit/modals';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './containers/footer/footer.component';
import { MobileCopyrightComponent } from './components/mobile-copyright/mobile-copyright.component';
import { UiKitMobileModule } from '@vpfa/ui-kit-mobile';
import { UiKitModule } from '@vpfa/ui-kit';
import { ModalsModule } from '@vpfa/modals';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '@vpfa/shared/pipes';

@NgModule({
  declarations: [FooterComponent, MobileCopyrightComponent],
  imports: [
    CommonModule,
    ModalsModule,
    RouterModule,
    UiKitModalsModule,
    TranslateModule,
    SharedPipesModule,
    UiKitMobileModule,
    UiKitModule,
    SharedMobileViewModule,
    FlexModule,
    NzToolTipModule,
  ],
  exports: [FooterComponent],
})
export class SharedFooterModule {}
