import { AppAction, AppActionTypes } from './app.actions';
import { produce } from 'immer';

export const APP_FEATURE_KEY = 'app';

export interface AppState {
  isDealerApp: boolean;
  isAdminApp: boolean;
  loaded: boolean; // has the App list been loaded
  error?: any; // last none error (if any)
  isNavigationInProgress: boolean;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const initialState: AppState = {
  isDealerApp: null,
  isAdminApp: null,
  loaded: false,
  error: null,
  isNavigationInProgress: null,
};

export function reducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case AppActionTypes.SetIsDealerAppValue:
      return produce<AppState>(state, newState => {
        newState.isDealerApp = action.payload;
      });
    case AppActionTypes.SetIsAdminAppValue:
      return produce<AppState>(state, newState => {
        newState.isAdminApp = action.payload;
      });

    case AppActionTypes.SetIsNavigationInProgressValue:
      return produce<AppState>(state, newState => {
        newState.isNavigationInProgress = action.payload;
      });
  }

  return state;
}
