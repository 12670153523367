import { VehicleDto } from '@vpfa/rest-api/identification';
import {
  DataTableColDef,
  DataTableValueGetterParams,
  DATA_TABLE_COMPONENTS,
  IconActionParams,
  SpecificationColumnName,
  unitValueComparator,
} from '@vpfa/ui-kit/data-table';
import { EngineCapacityCellRendererComponent } from '../../../cell-renderers/engine-capacity-cell-renderer/engine-capacity-cell-renderer.component';
import { isNil } from 'lodash';
import { SearchFeatures } from '@vpfa/shared/interfaces';

export const ShowSpecAction = 'showSpec';

export const specIconParams: IconActionParams = {
  iconName: 'file-text',
  actionName: ShowSpecAction,
};

export const vehicleListColDef = (
  prodDateFormatter: (data) => string,
  disableFn: (data) => boolean,
  errorInfo: (data) => string,
  noValuePlaceholder: string,
  searchFeatures: SearchFeatures = null
): DataTableColDef<any>[] => {
  const colDef: DataTableColDef<any>[] = [];

  colDef.push(
    ...[
      {
        headerName: 'searchResultTable.make',
        field: 'make',
      },
      {
        headerName: 'searchResultTable.model',
        field: 'model',
      },
      {
        headerName: 'searchResultTable.edition',
        field: 'edition',
      },
      {
        headerName: 'searchResultTable.prodStart',
        valueGetter: (params: DataTableValueGetterParams<VehicleDto>) => params.data?.technicalData?.prodbegin,
        valueFormatter: prodDateFormatter,
      },
      {
        headerName: 'searchResultTable.prodEnd',
        valueGetter: (params: DataTableValueGetterParams<VehicleDto>) => params.data?.technicalData?.prodend,
        valueFormatter: prodDateFormatter,
      },
      {
        headerName: 'searchResultTable.bodyTypeDoors',
        valueGetter: (params: DataTableValueGetterParams<VehicleDto>) => {
          return !isNil(params.data.numberDoors)
            ? `${params.data.bodyType}/${params.data.numberDoors}`
            : `${params.data.bodyType}`;
        },
      },
      {
        headerName: 'vehicleFullTechnicalData.engineInformation.engineCapacity',
        field: 'engineCapacity',
        cellRenderer: EngineCapacityCellRendererComponent,
        comparator: unitValueComparator,
      },
      {
        headerName: 'vehicleFullTechnicalData.engineInformation.fuelType',
        valueGetter: (params: DataTableValueGetterParams<VehicleDto>) =>
          params?.data?.technicalData?.propulsion?.fuelType?.texts,
      },
      {
        headerName: 'vehicleFullTechnicalData.engineInformation.power',
        valueGetter: (params: DataTableValueGetterParams<VehicleDto>) => {
          let powerInfoArray = [];

          if (params.data.powerInKW) {
            powerInfoArray.push(`${params.data.powerInKW?.value} ${params.data.powerInKW?.unit.toUpperCase()}`);
          }

          if (params.data.powerInHP) {
            powerInfoArray.push(`${params.data.powerInHP?.value} ${params.data.powerInHP?.unit.toUpperCase()}`);
          }

          return powerInfoArray.length ? powerInfoArray.join('/') : noValuePlaceholder;
        },
      },
    ]
  );

  if (searchFeatures?.showGVW) {
    colDef.push({
      headerName: 'vehicleFullTechnicalData.weights.grossVehicleWeight',
      valueGetter: (params: DataTableValueGetterParams<VehicleDto>) => params.data?.grossVehicleWeight?.value,
    });
  }

  if (searchFeatures?.showWheelbase) {
    colDef.push({
      headerName: 'vehicleFullTechnicalData.dimensions.wheelbase',
      valueGetter: (params: DataTableValueGetterParams<VehicleDto>) => params.data?.wheelbase?.value,
    });
  }

  colDef.push(
    ...[
      {
        colId: SpecificationColumnName,
        cellRenderer: DATA_TABLE_COMPONENTS.ICON_ACTION_RENDERER,
        cellRendererParams: { ...specIconParams, isDisabled: disableFn, errorInfo: errorInfo },
        width: 40,
        pinned: 'right' as 'right', // FIXME: why `as` is required?
        sortable: false,
      },
    ]
  );

  return colDef;
};
