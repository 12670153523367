<vpfa-modal
  width="650px"
  modalName="Rename Template"
  [showBackdrop]="true"
  [isVisible]="visible"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
>
  <vpfa-modal-content>
    <vpfa-action-button-with-confirmation
      class="ant-modal-close"
      icon="close-circle"
      [showPopconfirm]="!templateForm.pristine"
      (onConfirm)="close()"
    ></vpfa-action-button-with-confirmation>

    <form (ngSubmit)="saveTemplate()" [formGroup]="templateForm">
      <nz-spin [nzSpinning]="loading">
        <h1>{{ modalTitle | translate }}</h1>
        <div>
          <div class="template-form-box">
            <vpfa-setting-box [divider]="false" [forceRequired]="true">
              <ng-container label>
                <vpfa-label>
                  {{ 'admin.printTemplates.modal.name' | translate }}
                  <small>{{ 'admin.printTemplates.modal.atLeastOneNameIsRequired' | translate }}</small>
                </vpfa-label>
              </ng-container>
              <ng-container content>
                <table>
                  <tr *ngFor="let lang of langs">
                    <td>
                      <vpfa-label>
                        {{ lang.name | translate }}&nbsp;<small *ngIf="lang.additional?.isDefaultLanguage">
                          {{ 'admin.printTemplates.modal.defaultLanguage' | translate }}
                        </small>
                      </vpfa-label>
                    </td>
                    <td>
                      <vpfa-text-field
                        class="nameByLanguageField"
                        fieldWidth="100%"
                        [parentFormGroup]="templateForm"
                        [fcName]="countryCode + lang.value"
                        errorMsgVertPosition="inside"
                        [errorMessages]="nameValidationErrors"
                      ></vpfa-text-field>
                    </td>
                  </tr>
                </table>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'admin.printTemplates.modal.description' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="templateForm"
                  fcName="description"
                  errorMsgVertPosition="inside"
                  [errorMessages]="descriptionValidationErrors"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
          </div>
          <div class="customer-button-box mt-3">
            <vpfa-action-button buttonType="submit" [disabled]="templateForm.invalid">
              {{ 'admin.printTemplates.modal.saveTemplate' | translate }}
            </vpfa-action-button>
            <vpfa-action-button-with-confirmation
              [showPopconfirm]="!templateForm.pristine"
              (onConfirm)="close()"
            ></vpfa-action-button-with-confirmation>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
