export class FixSafariCdkScroll {
  private debug: false = false;
  private appContainer: HTMLElement;
  private cdkMutationObserver: MutationObserver;

  constructor() {
    this.appContainer = document.querySelector<HTMLElement>('.app-container');
    this.watchForCdk();
  }

  /**
   * Observer changes in body to find if `cdk-overlay-container` was created or removed.
   */
  private watchForCdk() {
    const cdkOverlayClass = 'cdk-overlay-container';

    const bodyObserver = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        const createdCdkContainer = Array.from(mutation.addedNodes).find((x: HTMLElement) =>
          x?.classList?.contains(cdkOverlayClass)
        );

        if (createdCdkContainer !== undefined) {
          if (this.debug) console.log('CDK Container created');
          this.cdkMutationObserver?.disconnect();
          this.cdkMutationObserver = this.createCdkMutationObserver(createdCdkContainer as HTMLElement);
        } else {
          const cdkContainerRemoved =
            Array.from(mutation.removedNodes).find((x: HTMLElement) => x?.classList?.contains(cdkOverlayClass)) !==
            undefined;

          if (cdkContainerRemoved) {
            if (this.debug) console.log('CDK Container removed');
            this.cdkMutationObserver?.disconnect();
          }
        }
      }
    });

    bodyObserver.observe(document.body, { childList: true });
  }

  /**
   * Disable `pointerEvents` to prevent scroll on Safari
   */
  private disableAppContainerScroll() {
    this.appContainer.style.pointerEvents = 'none';
    if (this.debug) console.log('Disabled pointer events on .app-container');
  }

  private enableAppContainerScroll() {
    this.appContainer.style.pointerEvents = '';
    if (this.debug) console.log('Enabled pointer events on .app-container');
  }

  /**
   * Observe `cdk-overlay-container` children if `cdk-overlay-backdrop` was created or deleted.
   */
  private createCdkMutationObserver(cdkContainer: HTMLElement) {
    const cdkContainerObserver = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        const isOverlayBackdropAdded =
          Array.from(mutation.addedNodes).find((x: HTMLElement) => x.classList.contains('cdk-overlay-backdrop')) !==
          undefined;

        if (isOverlayBackdropAdded) {
          if (this.debug) console.log('CDK Overlay created');
          this.disableAppContainerScroll();
        } else {
          const isOverlayBackdropRemoved =
            Array.from(mutation.removedNodes).find((x: HTMLElement) => x.classList.contains('cdk-overlay-backdrop')) !==
            undefined;

          if (isOverlayBackdropRemoved) {
            if (this.debug) console.log('CDK Overlay removed');
            this.enableAppContainerScroll();
          }
        }
      }
    });

    cdkContainerObserver.observe(cdkContainer, { childList: true });
    return cdkContainerObserver;
  }
}
