import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import {
  AuthTemporaryActionTypes,
  CheckPasswordCompatibility,
  CheckPasswordCompatibilitySuccess,
  CheckPasswordCompatibilityError,
  ChangeTemporaryPassword,
  ChangeTemporaryPasswordSuccess,
  ChangeTemporaryPasswordError,
} from '../actions/temporary.actions';
import { Router } from '@angular/router';
import { UserService, ChangeUserTemporaryPasswordCommand, AuthService } from '@vpfa/rest-api/admin';
import { AuthFacade } from '../auth.facade';

@Injectable()
export class TemporaryEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private authFacade: AuthFacade
  ) {}

  
  temporaryPassword$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthTemporaryActionTypes.TemporaryPassword),
    tap(() => {
      this.router.navigate(['auth/change-password']);
    })
  ), { dispatch: false });

  
  checkPasswordCompatibility$ = createEffect(() => this.actions$.pipe(
    ofType<CheckPasswordCompatibility>(AuthTemporaryActionTypes.CheckPasswordCompatibility),
    switchMap(action =>
      this.authService.checkPasswordAsync({ password: action.payload }).pipe(
        map(result => new CheckPasswordCompatibilitySuccess(result?.checkPasswordResult?.compatible)),
        catchError(() => of(new CheckPasswordCompatibilityError()))
      )
    )
  ));

  
  changeTemporaryPassword$ = createEffect(() => this.actions$.pipe(
    ofType<ChangeTemporaryPassword>(AuthTemporaryActionTypes.ChangeTemporaryPassword),
    withLatestFrom(this.authFacade.temporaryLoginData$),
    map(([action, data]) => {
      const command: ChangeUserTemporaryPasswordCommand = {
        login: data.username,
        temporaryPassword: data.password,
        newPassword: action.payload,
        confirmNewPassword: action.payload,
      };
      return command;
    }),
    switchMap(command =>
      this.userService.resetTemporaryPassword(command).pipe(
        map(() => new ChangeTemporaryPasswordSuccess()),
        catchError(() => of(new ChangeTemporaryPasswordError()))
      )
    )
  ));
}
