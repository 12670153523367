import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CaseUpdateRegistrationDateBody, DateRangeThresholdDto } from '@vpfa/rest-api/valuation';
import { MILEAGE_MAX, MILEAGE_MIN } from '@vpfa/shared/validators';
import { firstRegistrationRange } from '@vpfa/ui-kit/uikit-validation';

@Component({
  selector: 'vpfa-register-new-modal',
  templateUrl: './register-new-modal.component.html',
  styleUrl: './register-new-modal.component.scss',
})
export class RegisterNewModalComponent implements OnInit, OnDestroy {
  @Input() isVisible = false;
  @Input() isLoading = false;
  @Input() caseId: string;
  @Input() prodBegin: string;
  @Input() prodEnd: string;
  @Input() countryDateRangeThreshold: DateRangeThresholdDto;
  @Input() timezone: string;
  @Output() close = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<CaseUpdateRegistrationDateBody>();

  priceErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'required',
      errorMsg: this.translateService.instant('purchaseVehicleModal.priceIsRequired'),
    },
    {
      error: 'min',
      errorMsg: this.translateService.instant('common.forms.minimumValue', { minValue: MILEAGE_MIN }),
    },
    {
      error: 'max',
      errorMsg: this.translateService.instant('common.forms.maximumValue', { maxValue: MILEAGE_MAX }),
    },
  ];

  form = new FormGroup({
    registrationDate: new FormControl<string | null>(new Date().toISOString(), [Validators.required]),
    mileage: new FormControl<string | null>(null, [
      Validators.required,
      Validators.min(MILEAGE_MIN),
      Validators.max(MILEAGE_MAX),
    ]),
  });

  constructor(private translateService: TranslateService) {}

  disabledDate = (date: string) =>
    firstRegistrationRange(date, this.prodBegin, this.prodEnd, this.countryDateRangeThreshold, this.timezone);

  ngOnInit() {}

  ngOnDestroy() {}

  onClose() {
    this.form.reset();
    this.close.emit();
  }

  saveChanges() {
    if (this.form.invalid) {
      return;
    }

    const formData = this.form.value;

    this.onSave.emit({
      newVehicle: true,
      aggregateRootId: this.caseId,
      mileage: formData.mileage ? Number(formData.mileage) : 0,
      registrationDate: formData.registrationDate,
    });
  }
}
