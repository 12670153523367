export const X_AVH_REALM_HEADER_KEY = 'x-avh-realm';
export const X_AVH_REALM_HEADER_VALUE = 'dc';

export const CONTENT_TYPE_HEADER_KEY = 'Content-Type';
export const CONTENT_TYPE_HEADER_VALUE = 'application/json';

export const ACCEPT_HEADER_KEY = 'Accept';
export const ACCEPT_HEADER_VALUE = 'application/json';

export const AUTHORIZATION_HEADER_KEY = 'Authorization';
export const AUTHORIZATION_HEADER_BEARER = 'Bearer';
