import { Action } from '@ngrx/store';
import { AuthTokenActions, SetTokenBase, ClearTokenBase } from './token.actions';
import { AuthLoginActions } from './login.actions';
import { AuthLocaleActions } from './locale.actions';

export enum AuthActionTypes {
  InitAuthEffects = '[Init Auth Effects] Init Auth Effects',
  LoadTokenFromCacheSuccess = '[Init Auth Effects] Load Token From Cache Success',
  LoadTokenFromCacheSetToken = '[Init Auth Effects] Set Token',
  LoadTokenFromCacheFailure = '[Init Auth Effects] Load Token From Cache Failure',
  LoadTokenFromCacheClearToken = '[Init Auth Effects] Clear Token',
  TokenIsExpired = '[Init Auth Effects] Token Is Expired',
  RefreshToken = '[Init Auth Effects] Refresh Token',
  UnauthorizedUrl = '[Auth Guard] Unauthorized Url'
}

export class InitAuthEffects implements Action {
  readonly type = AuthActionTypes.InitAuthEffects;
}

export class LoadTokenFromCacheSuccess implements Action {
  readonly type = AuthActionTypes.LoadTokenFromCacheSuccess;
}

export class LoadTokenFromCacheSetToken extends SetTokenBase {
  readonly type = AuthActionTypes.LoadTokenFromCacheSetToken;
}

export class LoadTokenFromCacheFailure implements Action {
  readonly type = AuthActionTypes.LoadTokenFromCacheFailure;
}

export class LoadTokenFromCacheClearToken extends ClearTokenBase {
  readonly type = AuthActionTypes.LoadTokenFromCacheClearToken;
}

export class TokenIsExpired implements Action {
  readonly type = AuthActionTypes.TokenIsExpired;
}

export class UnauthorizedUrl implements Action {
  readonly type = AuthActionTypes.UnauthorizedUrl;
  constructor(public payload: string) {}
}

export type AuthActions =
  | InitAuthEffects
  | LoadTokenFromCacheSuccess
  | LoadTokenFromCacheSetToken
  | LoadTokenFromCacheFailure
  | LoadTokenFromCacheClearToken
  | AuthTokenActions
  | AuthLoginActions
  | AuthLocaleActions
  | TokenIsExpired
  | UnauthorizedUrl;
