import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CountriesFacade } from './+state/countries.facade';
import { CountriesEffects } from './+state/countries.effects';
import * as fromCountries from './+state/countries.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCountries.COUNTRIES_FEATURE_KEY, fromCountries.reducer),
    EffectsModule.forFeature([CountriesEffects]),
  ],
  providers: [CountriesFacade],
})
export class AdminCountriesDataModule {}
