import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isNil } from 'lodash';
import { debounceTime, Observable, Subject } from 'rxjs';

/**
 * Requires updateValueAndValidity (via updateValidity$) that needs to be performed outside the validator (to avoid
 * infinite loop of validation). See other parts of application like TemplateBaseModal.generateNamesFields()
 * for usage example.
 *
 * @param controlNames array of control names that will be used in validation
 * @param className used for debugging
 * @returns
 *  `atLeastOneRequiredValidator` - validator to use in FormControl.
 *
 *  `updateValidity$` - observable, to run `updateValueAndValidity` on the controls to updates their validity. It needs
 *   to be done outside of validator to avoid infinite loop of validation.
 */
export function createAtLeastOneRequiredValidator(
  controlNames: string[],
  className: string,
): {
  atLeastOneRequiredValidator: (control: AbstractControl) => ValidationErrors | null;
  updateValidity$: Observable<void>;
} {
  let numberOfControlsChecked = false;
  let prevValidity: boolean | null = null;

  const updateValidity$ = new Subject<void>();

  const atLeastOneRequiredValidator = function atLeastOneFieldRequiredValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    // not initialized fully yet
    if (!control?.parent?.controls || !control) {
      return null;
    }

    const allControls = Object.entries(control.parent.controls);

    if (allControls.length === 0) {
      console.error(`[${className}] Form Group does not have controls`);
      return null;
    }

    const controlsToProcess = allControls.filter(x => controlNames?.includes(x[0]));

    if (!numberOfControlsChecked && controlsToProcess?.some(x => x[1].dirty)) {
      numberOfControlsChecked = true;
      validateNumberOfControls(controlsToProcess, controlNames, className);
    }

    const someHasValue = controlsToProcess.some(ctrl => {
      if (isNil(ctrl[1].value) || String(ctrl[1].value).trim() === '') {
        return false;
      }
      return true;
    });

    // fire updateValidity only when when validation result is different for better performance
    if (prevValidity !== null && prevValidity != someHasValue) {
      updateValidity$.next();
    }
    prevValidity = someHasValue;

    return someHasValue ? null : { atLeastOneFieldRequired: true };
  };

  return {
    atLeastOneRequiredValidator,
    updateValidity$: updateValidity$.pipe(
      // debounce is required to avoid infinite loop of validation
      debounceTime(100),
    ),
  };
}

function validateNumberOfControls(
  processedControls: [string, AbstractControl<any, any>][],
  controlNames: string[],
  className: string,
) {
  if (processedControls?.length != controlNames?.length) {
    console.error(
      `[${className}] Number of control differs, expected ${controlNames?.length}, found ${processedControls?.length}`,
      {
        expected: controlNames,
        found: processedControls,
      },
    );
  }
}
