import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

export const notInListValidatorKey = 'notInList';

export function notInListValidator(list$: Observable<string[]>) {
  const list = new BehaviorSubject([]);
  list$.subscribe(list);
  return (control: AbstractControl) => {
    if (!control?.value) {
      return null;
    }

    if (control.value === '') {
      return null;
    }

    if (!!control.value && !list.getValue().includes(control.value.toLowerCase())) {
      return null;
    }
    return {
      [notInListValidatorKey]: true,
    };
  };
}
