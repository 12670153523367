import { TranslateService } from '@ngx-translate/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CreateTemplateModel } from '../models/create-template.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, first, takeUntil, withLatestFrom } from 'rxjs/operators';
import { createAtLeastOneRequiredValidator, notInListValidator, notInListValidatorKey } from '@vpfa/shared/validators';
import { pdfTemplateModules } from './pdf-template-modules';
import {
  pdfReProNewTemplate,
  pdfReProNewTemplateValue,
  pdfTemplateNamePattern,
  templateNameMaxLength,
  templateDescriptionMaxLength,
} from '../constants';
import { LocaleFacade } from '@vpfa/locale';
import { CountriesFacade } from '@vpfa/admin/countries/data';
import { SelectOption } from '@vpfa/ui-kit';
import { isEmpty } from 'lodash';

@Directive()
export abstract class TemplateBaseModal implements OnDestroy, OnInit {
  @Input() set show(visible: boolean) {
    if (!visible) {
      this.resetForm();
    }
    this.visible = visible;
  }

  @Input() loading = false;
  private usedNames$ = new BehaviorSubject<string[]>([]);
  @Input() set usedNames(usedNames: string[] | null) {
    this.usedNames$.next(usedNames ?? []);
  }
  @Input() area: 'admin' | 'dealer' = 'dealer';
  @Output() closeAction = new EventEmitter();
  @Output() templateDataChange = new EventEmitter<CreateTemplateModel>();

  templateForm: UntypedFormGroup;
  nameControl: AbstractControl;
  descriptionControl: AbstractControl;
  templateModuleArray: UntypedFormArray;
  visible = false;
  pattern = pdfTemplateNamePattern;
  templateNameMaxLength = templateNameMaxLength;
  templateDescriptionMaxLength = templateDescriptionMaxLength;
  nameValidationErrors: { error: string; errorMsg: string }[];
  descriptionValidationErrors: { error: string; errorMsg: string }[];
  langs: SelectOption<string>[] = [];
  countryCode: string | null = null;

  protected onDestroy$ = new Subject<void>();

  constructor(
    protected fb: UntypedFormBuilder,
    private translateService: TranslateService,
    protected localeFacade: LocaleFacade,
    protected countriesFacade: CountriesFacade,
  ) {
    this.templateForm = this.fb.group({
      description: [null],
      pdfTemplateModules: this.fb.array([]),
    });

    this.descriptionControl = this.templateForm.get('description');
    this.templateModuleArray = this.templateForm.controls.pdfTemplateModules as UntypedFormArray;

    this.addModules();
    this.setUpValidation();
    this.setNameValidationErrorsList();

    this.descriptionControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      const trimmed = value ? value.trimStart() : value;

      if (trimmed !== value) {
        this.descriptionControl.setValue(trimmed);
      }
    });
  }

  ngOnInit(): void {
    this.initializeNamesFields();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  close() {
    this.closeAction.emit();
  }

  saveTemplate() {
    if (this.templateForm.invalid) {
      return;
    }

    const formValue = this.templateForm.value;

    // BE expects to have "name" property as a Record type so we need to convert it
    const names = {};
    for (const lang of this.langs) {
      const controlName = this.countryCode + lang.value;
      const value = formValue[controlName]?.trim();

      if (!isEmpty(value)) {
        names[controlName] = value;
      }
    }
    formValue['name'] = names;

    localStorage.setItem(pdfReProNewTemplate, pdfReProNewTemplateValue);
    this.templateDataChange.emit(formValue);
  }

  protected addModules() {
    pdfTemplateModules.forEach(templateModule =>
      this.templateModuleArray.push(this.fb.group({ templateModuleEnum: [templateModule], available: false })),
    );
  }

  protected resetModules() {
    this.templateModuleArray.controls.forEach((templateModule, index) => {
      templateModule.patchValue({ templateModuleEnum: pdfTemplateModules[index], available: false });
    });
  }

  protected setNameValidationErrorsList() {
    this.nameValidationErrors = [
      { error: 'required', errorMsg: this.translateService.instant('admin.printTemplates.modal.nameIsRequired') },
      {
        error: 'maxlength',
        errorMsg: this.translateService.instant('admin.printTemplates.modal.nameMaxLengthExceeded', {
          maxLength: this.templateNameMaxLength,
        }),
      },
      { error: 'pattern', errorMsg: this.translateService.instant('admin.printTemplates.modal.allowedCharacters') },
      {
        error: 'atLeastOneFieldRequired',
        errorMsg: this.translateService.instant('admin.printTemplates.modal.atLeastOneNameIsRequiredValidationError'),
      },
      {
        error: notInListValidatorKey,
        errorMsg: this.translateService.instant('admin.printTemplates.modal.nameIsAlreadyUsed'),
      },
    ];
    this.descriptionValidationErrors = [
      {
        error: 'maxlength',
        errorMsg: this.translateService.instant('admin.printTemplates.modal.descriptionMaxLengthExceeded'),
      },
      { error: 'pattern', errorMsg: this.translateService.instant('admin.printTemplates.modal.allowedCharacters') },
    ];
  }

  protected setUpValidation() {
    this.descriptionControl.setValidators([
      Validators.maxLength(this.templateDescriptionMaxLength),
      Validators.pattern(this.pattern),
    ]);
  }

  /**
   * Country data from Admin and dealer areas are kept in different store, this method
   * is taking care of selecting correct store and initialize name fields by available languages
   * in the country.
   */
  protected initializeNamesFields() {
    switch (this.area) {
      case 'admin':
        if (!this.countriesFacade) {
          console.error('countriesFacade not provided');
          return;
        }

        this.countriesFacade.countryLanguagesOptions$
          .pipe(withLatestFrom(this.countriesFacade.countryDetails$), first())
          .subscribe(([langOptions, country]) => {
            this.langs = langOptions;
            this.countryCode = country.countryCode;
            this.setDefaultLangAsFirst();
            this.generateNamesFields(langOptions);
          });
        break;

      case 'dealer':
        this.localeFacade.uiLanguageOptions$
          .pipe(withLatestFrom(this.localeFacade.country$), first())
          .subscribe(([langOptions, country]) => {
            this.langs = langOptions;
            this.countryCode = country.countryCode;
            this.setDefaultLangAsFirst();
            this.generateNamesFields(langOptions);
          });
        break;

      default:
        console.error('Unknown language source: ' + this.area);
        return;
    }
  }

  private setDefaultLangAsFirst() {
    this.langs.sort((a, b) => Number(b.additional?.isDefaultLanguage) - Number(a.additional?.isDefaultLanguage));
  }

  private generateNamesFields(langs: SelectOption<string>[]) {
    if (!langs?.length) {
      console.error('No languages provided');
      return;
    }

    const allLangsFields = langs.map(x => this.countryCode + x.value);

    const { atLeastOneRequiredValidator, updateValidity$ } = createAtLeastOneRequiredValidator(
      allLangsFields,
      this.constructor.name,
    );

    const validators = [
      atLeastOneRequiredValidator,
      Validators.maxLength(this.templateNameMaxLength),
      Validators.pattern(this.pattern),
      notInListValidator(this.usedNames$),
    ];

    for (const lang of allLangsFields) {
      this.templateForm.addControl(lang, new FormControl('', validators));
    }

    const allControls = allLangsFields.map(f => this.templateForm.controls[f]);

    updateValidity$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      allControls.forEach(c => c.updateValueAndValidity());
    });
  }

  abstract resetForm();
}
