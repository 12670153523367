import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // uncomment to enable change detection profiler
  // .then(module => {
  //   const appRef = module.injector.get(ApplicationRef);
  //   const appComponent = appRef.components[0];
  //   enableDebugTools(appComponent);
  // })
  .catch(err => console.error(err));
