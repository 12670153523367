import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { AuthLogoutActionTypes, Logout } from '../actions/logout.actions';
import { ClearToken } from '../actions/token.actions';
import { MixpanelService, MixpanelEvent, DataDogService } from '@vpfa/utils';
import { map, switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class LogoutEffects {
  constructor(
    private actions$: Actions,
    private mixpanel: MixpanelService,
    private httpClient: HttpClient,
    private dataDobService: DataDogService
  ) {}

  
  logout$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<Logout>(AuthLogoutActionTypes.Logout),
    switchMap(action =>
      this.httpClient.post(`${environment.apiGateway}/api/auth/logout`, {}).pipe(
        map(result => action),
        catchError(error => of(action))
      )
    ),
    map((action: Logout) => {
      this.mixpanel.excludeCloseNavigationTab();
      this.mixpanel.track(MixpanelEvent.Logout);
      this.dataDobService.userLoggedOut();

      return new ClearToken({ afterRedirectTo: action.redirectAddress ?? '/auth/login' });
    })
  ));
}
