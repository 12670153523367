import { Component, Input, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@vpfa/ui-kit';
import { isNil } from 'lodash';
import { TemplateBaseModal } from '../../utils/template-base-modal';
import { LocaleFacade } from '@vpfa/locale';
import { CountriesFacade } from '@vpfa/admin/countries/data';

@Component({
  selector: 'vpfa-create-template-modal',
  templateUrl: './create-template-modal.component.html',
  styleUrls: ['./create-template-modal.component.scss'],
})
export class CreateTemplateModalComponent extends TemplateBaseModal {
  @Input() modalTitle = 'admin.printTemplates.modal.createTemplate';
  @Input() selectName = 'select.selectValue';
  @Input() duplicate: boolean;
  @Input() set initValue(val: string) {
    this.initVal = val;
    this.setInitValue();
  }

  @Input() set selectOptions(options: SelectOption[]) {
    if (options) {
      this.options = options;
      this.setInitValue();
    }
  }

  init: SelectOption;
  options: SelectOption[];

  private initVal: string;

  constructor(
    fb: UntypedFormBuilder,
    translateService: TranslateService,
    localeFacade: LocaleFacade,
    @Optional() countriesFacade: CountriesFacade,
  ) {
    super(fb, translateService, localeFacade, countriesFacade);
    this.templateForm.addControl('selectValue', new UntypedFormControl(null, [Validators.required]));
  }

  resetForm() {
    this.templateForm.reset({
      selectValue: this.init,
    });

    this.resetModules();
  }

  private setInitValue() {
    if (this.options) {
      if (!isNil(this.initVal)) {
        const foundOption = this.options.find(opt => opt.value === this.initVal);
        this.init = foundOption ? foundOption : this.options[0];
      } else {
        this.init = this.options[0];
      }
      this.templateForm.get('selectValue').setValue(this.init);
    }
  }
}
