import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { IToken } from '../../models/token';
import { AuthActionTypes } from '../actions/auth.actions';
import { AuthLoginActionTypes } from '../actions/login.actions';
import {
  AuthTokenActionTypes,
  SetToken,
  SetTokenBase,
  SetTokenFromDtoBase,
  ClearTokenCompleted,
  ClearTokenBase,
} from '../actions/token.actions';
import { TokenDto } from '@vpfa/rest-api/admin';
import { ManageStorageService } from '../../utils/manage-storage.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenEffects {
  constructor(
    private actions$: Actions,
    private jwtHelperService: JwtHelperService,
    private manageStorageService: ManageStorageService,
    private router: Router
  ) {}

  
  SetTokenFromDto: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthTokenActionTypes.SET_TOKEN_FROM_DTO, AuthLoginActionTypes.LoginSetTokenFromDTO),
    map((action: SetTokenFromDtoBase) => {
      return new SetToken(this.convertToken(action.payload));
    })
  ));

  
  SetToken: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthTokenActionTypes.SET_TOKEN, AuthActionTypes.LoadTokenFromCacheSetToken),
    tap((action: SetTokenBase) => this.manageStorageService.setToken(action.payload))
  ), { dispatch: false });

  
  ClearToken: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(
      AuthTokenActionTypes.CLEAR_TOKEN,
      AuthLoginActionTypes.LoginClearToken,
      AuthActionTypes.LoadTokenFromCacheClearToken
    ),
    switchMap((action: ClearTokenBase) => {
      return from(this.manageStorageService.clearToken()).pipe(map(() => action));
    }),
    map(() => {
      return new ClearTokenCompleted();
    })
  ));

   redirectAfterTokenCleared: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AuthTokenActionTypes.CLEAR_TOKEN_COMPLETED),
    tap(() => {
      this.router.navigateByUrl('/auth/login');
    })
  ), { dispatch: false });

  private convertToken(tokenDto: TokenDto): IToken {
    return {
      token: tokenDto.token,
      decodedToken: this.jwtHelperService.decodeToken(tokenDto.token),
    };
  }
}
