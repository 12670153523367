import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ContactUsState,
  COUNTRIES_FEATURE_KEY,
  CountriesListState,
  CountriesState,
  CountryDetailsState,
  CountryGdvState,
  CountryGdvStateData,
  CountryLogoState,
  CustomersListInitialSetOfFields,
  ExperianCountrySettingsState,
  VinThresholdState,
} from './countries.reducer';
import { CountryDto, CountryListItemDto, ExperianCountrySettingsDto } from '@vpfa/rest-api/admin';
import { SelectOption } from '@vpfa/ui-kit';
import { isEmpty, isNil } from 'lodash';
import {
  CountryBroadcastConnectionConfigurationListItemDto,
  CountryBroadcastConnectionConfigurationMappingsListItemDto
} from '@vpfa/rest-api/ad-broadcast';

// Lookup the 'Countries' feature state managed by NgRx
const getCountriesState = createFeatureSelector<CountriesState>(COUNTRIES_FEATURE_KEY);

const getCountriesListState = createSelector(getCountriesState, (state: CountriesState) => state.list);

const getListLoaded = createSelector(getCountriesListState, (state: CountriesListState) => state.loaded);

const getCountriesList = createSelector(getCountriesListState, (state: CountriesListState) => {
  return state.items;
});

const getCountriesListOptions = createSelector(getCountriesListState, (state: CountriesListState) => {
  return !(isNil(state.items) || isEmpty(state.items))
    ? state.items.map(
        (item: CountryListItemDto) =>
          <SelectOption>{
            name: item.countryName,
            value: item,
          }
      )
    : [];
});

const getListLoading = createSelector(getCountriesListState, (state: CountriesListState) => state.isLoading);

const getCountryDetailsState = createSelector(getCountriesState, (state: CountriesState) => state.details);

const getCountryDetails = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.country;
});

const getCountryCode = createSelector(getCountryDetails, (state: CountryDto) => {
  return state ? state.countryCode : null;
});

const getCountryUCNumberSource = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.country ? state.country.ucNumberSource : null;
});

const getCountryHotlinePhone = createSelector(getCountryDetails, (state: CountryDto) => {
  return state ? state.hotlinePhone : null;
});

const getCountryHotlineEmail = createSelector(getCountryDetails, (state: CountryDto) => {
  return state ? state.hotlineEmail : null;
});

const getCountryCurrency = createSelector(getCountryDetails, (state: CountryDto) => {
  return state?.currency;
});

const getCountryDetailsLoading = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.isLoading;
});

const getCountryDefaultLanguageUpdating = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.isDefaultLanguageUpdating;
});

const getCountryAlternativeLanguageUpdating = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.isAlternativeLanguageUpdating;
});

const getCountryDefaultContentLanguageUpdating = createSelector(
  getCountryDetailsState,
  (state: CountryDetailsState) => {
    return state.isDefaultContentLanguageUpdating;
  }
);

const getCountryAlternativeContentLanguageUpdating = createSelector(
  getCountryDetailsState,
  (state: CountryDetailsState) => {
    return state.isAlternativeContentLanguageUpdating;
  }
);

const getCustomersListInitialSetOfFields = createSelector(getCountriesState, (state: CountriesState) => {
  return state.customersListInitialSetOfFields;
});

const getIsLoadingValuationListInitialSetOfFields = createSelector(getCountriesState, (state: CountriesState) => {
  return state.valuationListInitialSetOfFields.loading;
});

const getValuationListInitialSetOfFieldsData = createSelector(getCountriesState, (state: CountriesState) => {
  return state.valuationListInitialSetOfFields.data;
});

const getIsLoadingStockListInitialSetOfFields = createSelector(getCountriesState, (state: CountriesState) => {
  return state.stockListInitialSetOfFields.loading;
});

const getStockListInitialSetOfFieldsData = createSelector(getCountriesState, (state: CountriesState) => {
  return state.stockListInitialSetOfFields.data;
});

const getIsLoadingCustomersListInitialSetOfFields = createSelector(
  getCustomersListInitialSetOfFields,
  (state: CustomersListInitialSetOfFields) => {
    return state.loading;
  }
);

const getCustomersListInitialSetOfFieldsData = createSelector(
  getCustomersListInitialSetOfFields,
  (state: CustomersListInitialSetOfFields) => {
    return state.data;
  }
);

const getCountryLocaleUpdating = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.isLocaleUpdating;
});

const getCountryTimezoneUpdating = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.isTimezoneUpdating;
});

const getCountryCurrencyUpdating = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.isCurrencyUpdating;
});

const getCountryGeneralState = createSelector(getCountriesState, (state: CountriesState) => state.general);

const getCountryTaxType = createSelector(getCountryGeneralState, state => {
  return state.countryTax;
});

const getIsProcessingCountryTax = createSelector(getCountryGeneralState, state => {
  return state.isProcessingCountryTax;
});

const getisProcessCountryUCNumberSource = createSelector(getCountryGeneralState, state => {
  return state.isProcessCountryUCNumberSource;
});

const getCountryLogoState = createSelector(getCountriesState, (state: CountriesState) => state.logo);

const isCountryLogoLoading = createSelector(getCountryLogoState, (state: CountryLogoState) => state.loading);

const isCountryLogoUploading = createSelector(getCountryLogoState, (state: CountryLogoState) => state.uploading);

const getCountryLogo = createSelector(getCountryLogoState, (state: CountryLogoState) => state.logo);

const getCountryUrl = createSelector(getCountryDetailsState, (state: CountryDetailsState) => {
  return state.country ? state.country.url : null;
});

const getIsProcessingCountryUrl = createSelector(getCountryGeneralState, state => {
  return state.isProcessingCountryUrl;
});

const getIsProcessingCountryHotlinePhone = createSelector(getCountryGeneralState, state => {
  return state.isProcessingHotlinePhone;
});

const getIsProcessingCountryHotlineEmail = createSelector(getCountryGeneralState, state => {
  return state.isProcessingHotlineEmail;
});

const getBroadcasting = createSelector(getCountriesState, state => {
  return state.broadcasting;
});

const getBroadcastProcessing = createSelector(getBroadcasting, state => {
  return state.isProcessing;
});

const getBroadcastListState = createSelector(getBroadcasting, state => {
  return state.list;
});

const getBroadcastList = createSelector(getBroadcastListState, state => {
  return state.items;
});

const getBroadcastListOptions = createSelector(getBroadcastList, state => {
  return state.map(
    (item: CountryBroadcastConnectionConfigurationListItemDto) =>
      <SelectOption>{ name: item.name, value: item.transformMappingThirdParty }
  );
});

const getBroadcastListLoading = createSelector(getBroadcastListState, state => {
  return state.isLoading;
});

const getBroadcastCountryId = createSelector(getBroadcasting, state => {
  return state.countryId;
});

const getBroadcastDetailsState = createSelector(getBroadcasting, state => {
  return state.details;
});

const getBroadcastDetails = createSelector(getBroadcastDetailsState, state => {
  return state.item;
});

const getBroadcastDetailsLoading = createSelector(getBroadcastDetailsState, state => {
  return state.isLoading;
});

const getBroadcastLogo = createSelector(getBroadcastDetailsState, state => {
  return state.logo;
});

const getBroadcastCertificate = createSelector(getBroadcastDetailsState, state => {
  return state.certificate;
});

const getBroadcastFtpState = createSelector(getBroadcasting, state => {
  return state.ftp;
});

const getFtpCreated = createSelector(getBroadcastFtpState, state => {
  return state.created;
});

const getFtpUpdated = createSelector(getBroadcastFtpState, state => {
  return state.updated;
});

const getVinThresholdState = createSelector(getCountriesState, (state: CountriesState) => state.vinThreshold);

const getVinThreshold = createSelector(getVinThresholdState, (state: VinThresholdState) => state.threshold);

const isVinThresholdProcessing = createSelector(getVinThresholdState, (state: VinThresholdState) => state.processing);

const getExperianCountriesSettingsState = createSelector(
  getCountriesState,
  (state: CountriesState) => state.experianCountrySettings
);

const getExperianCountrySettings = createSelector(
  getExperianCountriesSettingsState,
  (state: ExperianCountrySettingsState) => state.countrySettings
);

const getExperianCountryHostUrl = createSelector(
  getExperianCountrySettings,
  (state: ExperianCountrySettingsDto) => state?.hostUrl
);

const getExperianCountryTermsAndConditionsUrl = createSelector(
  getExperianCountrySettings,
  (state: ExperianCountrySettingsDto) => state?.termsAndConditionsUrl
);

const isExperianCountrySettingsLoading = createSelector(
  getExperianCountriesSettingsState,
  (state: ExperianCountrySettingsState) => state.loading
);

const isExperianCountrySettingsProcessing = createSelector(
  getExperianCountriesSettingsState,
  (state: ExperianCountrySettingsState) => state.processing
);

const getCountryGdv = createSelector(getCountriesState, (state: CountriesState) => {
  return state.gdv;
});

const isCountryGdvLoading = createSelector(getCountryGdv, (state: CountryGdvState) => {
  return state.isLoading;
});

const isCountryGdvUsernameLoading = createSelector(getCountryGdv, (state: CountryGdvState) => {
  return state.isLoadingUsername;
});

const isCountryGdvSetPasswordLoading = createSelector(getCountryGdv, (state: CountryGdvState) => {
  return state.isLoadingSetPassword;
});

const getCountryGdvConfig = createSelector(getCountryGdv, (state: CountryGdvState) => {
  return state.data;
});

const getCountryGdvConfigId = createSelector(getCountryGdvConfig, (state: CountryGdvStateData) => {
  return state.id;
});

const getCountryGdvConfigUsername = createSelector(getCountryGdvConfig, (state: CountryGdvStateData) => {
  return state.username;
});

const getIsCountryGdvConfigPasswordSet = createSelector(getCountryGdvConfig, (state: CountryGdvStateData) => {
  return state.isPasswordSet;
});

const getCountriesContactUsState = createSelector(getCountriesState, (state: CountriesState) => state.contactUs);

const getIsCountryContactUsCountryCodeUpdating = createSelector(getCountriesContactUsState, (state: ContactUsState) => {
  return state.countryCodeUpdating;
});

const getIsCountryContactUsDefaultPriorityUpdating = createSelector(
  getCountriesContactUsState,
  (state: ContactUsState) => {
    return state.defaultPriorityUpdating;
  }
);

const getIsCountryContactUsDefaultTypeUpdating = createSelector(getCountriesContactUsState, (state: ContactUsState) => {
  return state.defaultTypeUpdating;
});

const getIsCountryContactUsDefaultClassificationUpdating = createSelector(
  getCountriesContactUsState,
  (state: ContactUsState) => {
    return state.defaultClassificationUpdating;
  }
);

const getIsCountryContactUsEnquiryTypeUpdating = createSelector(getCountriesContactUsState, (state: ContactUsState) => {
  return state.enquiryTypeUpdating;
});

const getIsCountryContactUsHeaderTextsUpdating = createSelector(getCountriesContactUsState, (state: ContactUsState) => {
  return state.headerTextsUpdating;
});

const getBroadcastListForMappingItems = createSelector(getBroadcasting, state => {
  return state.listForMapping?.items?.map(
    (item: CountryBroadcastConnectionConfigurationMappingsListItemDto) =>
      <SelectOption>{ name: item.name, value: item.transformMappingThirdParty }
  );
});

const getBroadcastListForMappingLoading = createSelector(getBroadcasting, state => {
  return state.listForMapping.isLoading;
});

export const countriesQuery = {
  getListLoaded,
  getCountriesList,
  getCountriesListOptions,
  getListLoading,
  getCountryDetails,
  getCountryCode,
  getCountryHotlinePhone,
  getCountryHotlineEmail,
  getCountryDetailsLoading,
  getCountryDefaultLanguageUpdating,
  getCountryDefaultContentLanguageUpdating,
  getCountryAlternativeLanguageUpdating,
  getCountryAlternativeContentLanguageUpdating,
  getCountryLocaleUpdating,
  getCountryCurrencyUpdating,
  getCountryTimezoneUpdating,
  getCountryTaxType,
  getIsProcessingCountryTax,
  getIsLoadingValuationListInitialSetOfFields,
  getValuationListInitialSetOfFieldsData,
  getIsLoadingStockListInitialSetOfFields,
  getStockListInitialSetOfFieldsData,
  getIsLoadingCustomersListInitialSetOfFields,
  getCustomersListInitialSetOfFieldsData,
  getCountryUCNumberSource,
  getisProcessCountryUCNumberSource,
  isCountryLogoLoading,
  isCountryLogoUploading,
  getCountryLogo,
  getCountryUrl,
  getIsProcessingCountryUrl,
  getIsProcessingCountryHotlinePhone,
  getIsProcessingCountryHotlineEmail,
  getCountryCurrency,
  getBroadcastList,
  getBroadcastListOptions,
  getBroadcastListLoading,
  getBroadcastCountryId,
  getBroadcastDetails,
  getBroadcastDetailsLoading,
  getBroadcastLogo,
  getBroadcastCertificate,
  getBroadcastProcessing,
  getFtpCreated,
  getFtpUpdated,
  getVinThreshold,
  isVinThresholdProcessing,
  getExperianCountrySettings,
  getExperianCountryHostUrl,
  getExperianCountryTermsAndConditionsUrl,
  isExperianCountrySettingsProcessing,
  isExperianCountrySettingsLoading,
  isCountryGdvLoading,
  isCountryGdvUsernameLoading,
  isCountryGdvSetPasswordLoading,
  getCountryGdvConfigUsername,
  getIsCountryGdvConfigPasswordSet,
  getCountryGdvConfigId,
  getCountryGdvConfig,
  getIsCountryContactUsCountryCodeUpdating,
  getIsCountryContactUsDefaultPriorityUpdating,
  getIsCountryContactUsDefaultTypeUpdating,
  getIsCountryContactUsDefaultClassificationUpdating,
  getIsCountryContactUsEnquiryTypeUpdating,
  getIsCountryContactUsHeaderTextsUpdating,
  getBroadcastListForMappingItems,
  getBroadcastListForMappingLoading,
};
