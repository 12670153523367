import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MixpanelService, MixpanelEvent } from '@vpfa/utils';
import { isNil } from 'lodash';

@Injectable()
export class MixPanelInterceptor implements HttpInterceptor {
  constructor(private mixpanel: MixpanelService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.mixpanel.isEnabled) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        tap(response => {
          if (response instanceof HttpResponse) {
            this.mixpanel.track(MixpanelEvent.Request, { request: request, response: response.status });
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.mixpanel.track(MixpanelEvent.RequestError, { request: request, error: err.status });
          return throwError(err);
        })
      );
    }
  }
}
