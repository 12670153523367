import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import {
  DataTableColDef,
  DataTableActionEvent,
  DataTableAction,
  DataTableValueGetterParams,
  DataTableActionListItem,
} from '@vpfa/ui-kit/data-table';
import { getTemplateColumnsDefinitions } from './get-template-columns-definitions';
import { defaultActionGetter } from './default-action-getter';
import { SelectOption } from '@vpfa/ui-kit';
import { TranslateService } from '@ngx-translate/core';
import { PdfTemplateListItem } from '@vpfa/shared/interfaces';

@Component({
  selector: 'vpfa-print-templates-list',
  templateUrl: './print-templates-list.component.html',
  styleUrls: ['./print-templates-list.component.scss'],
})
export class PrintTemplatesListComponent implements OnInit, OnChanges {
  @Input() templates: PdfTemplateListItem[];
  @Input() languages: SelectOption<string>[];
  @Input() loading = false;
  @Input() area: 'admin' | 'dealer' = 'dealer';
  @Input() actionGetter: (
    params: DataTableValueGetterParams<PdfTemplateListItem>,
  ) => DataTableActionListItem[];

  colDefs: DataTableColDef<PdfTemplateListItem>[] = [];
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    pagination: true,
    getRowHeight: () => {
      //HACK: gridOptions.rowHeight not working
      return 40;
    },
  };

  defaultColDef: DataTableColDef<any> = {
    floatingFilter: false,
    sortable: true,
    resizable: true,
    autoHeight: true,
    suppressMenu: true,
    filter: false,
    suppressMovable: true,
  };

  @Output() edit: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();
  @Output() duplicate: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();
  @Output() copyTo: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();
  @Output() delete: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();
  @Output() rename: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();
  @Output() modules: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();
  @Output() visible: EventEmitter<PdfTemplateListItem> =
    new EventEmitter<PdfTemplateListItem>();

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.area === 'dealer') {
      this.colDefs = getTemplateColumnsDefinitions(
        this.translate,
        null,
        this.actionGetter ? this.actionGetter : defaultActionGetter,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.area === 'admin' && this.languages?.length > 0) {
      this.colDefs = getTemplateColumnsDefinitions(
        this.translate,
        this.languages,
        this.actionGetter ? this.actionGetter : defaultActionGetter,
      );
    }
  }

  onActionClicked(actionEvent: DataTableActionEvent<PdfTemplateListItem>) {
    switch (actionEvent.action) {
      case DataTableAction.EDIT:
        this.edit.emit(actionEvent.data);
        break;
      case DataTableAction.DUPLICATE:
        this.duplicate.emit(actionEvent.data);
        break;
      case DataTableAction.COPY_TO:
        this.copyTo.emit(actionEvent.data);
        break;
      case DataTableAction.DELETE:
        this.delete.emit(actionEvent.data);
        break;
      case DataTableAction.RENAME:
        this.rename.emit(actionEvent.data);
        break;
      case DataTableAction.OTHER:
        this.visible.emit(actionEvent.data);
        break;
      case DataTableAction.MODULES:
        this.modules.emit(actionEvent.data);
        break;
    }
  }
}
