<ul class="list-unstyled" fxLayout.lt-mv="column" fxLayoutGap fxLayoutGap.lt-mv="6px">
  <li
    *vpfaResponsiveIf="'mobile'"
    fxLayout
    fxLayoutAlign="start center"
    ngClass="mobile labels-container"
    ngClass.lt-mv="mobile"
  >
    <ng-container *ngTemplateOutlet="resellerReplies"></ng-container>
    <ng-container *ngTemplateOutlet="caseStatusBadgeTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="promotion"></ng-container>
  </li>
  <li *vpfaResponsiveIf="'web'; else mobileIdentification">
    <span
      *ngIf="identification?.peakPowerkW?.value || identification?.peakPowerHP?.value"
      id="baseVehInfoPeakPowerKwHp"
      [attr.data-peak-power-kw]="identification?.peakPowerkW | json"
      [attr.data-peak-power-hp]="identification?.peakPowerHP | json"
    >
      {{ [identification?.peakPowerkW, identification?.peakPowerHP] | getTwoValue }},
    </span>
    <span *ngIf="identification.transmissionTypeName" id="baseVehInfoTransmissionTypeName">
      {{ identification.transmissionTypeName }},
    </span>
    <span *ngIf="identification.fuelTypeName" id="baseVehInfoFuelTypeName">
      {{ identification.fuelTypeName }}
    </span>
    <span *ngIf="identification.fuelSubtypeName" id="baseVehInfoFuelSubtypeName">
      ({{ identification.fuelSubtypeName }})
    </span>
  </li>
  <li *vpfaResponsiveIf="'web'" fxLayout="row wrap">
    <ng-container *ngTemplateOutlet="caseStatusBadgeTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="promotion"></ng-container>
  </li>
  <ng-template #mobileIdentification>
    <li [fxLayout]="'row'" [fxLayoutGap]="'10px'">
      <div
        id="baseVehInfoMakeAndEdition"
        [attr.data-identification-make]="identification.make"
        [attr.data-identification-edition]="identification.edition"
        fxLayout="row wrap"
        class="car-type-name"
      >
        {{ identification.make }} {{ identification.edition }}
      </div>
      <vpfa-market-badge
        id="marketBadge"
        *ngIf="showOriginMarketLabel"
        [market]="vehicleData?.deliveryCountry"
      ></vpfa-market-badge>
    </li>
    <li fxLayout="column">
      <span
        *ngIf="identification?.peakPowerkW?.value || identification?.peakPowerHP?.value"
        id="baseVehInfoPeakPowerKwHp"
        [attr.data-peak-power-kw]="identification?.peakPowerkW | json"
        [attr.data-peak-power-hp]="identification?.peakPowerHP | json"
      >
        {{ [identification?.peakPowerkW, identification?.peakPowerHP] | getTwoValue }}
      </span>
      <span *ngIf="identification.transmissionTypeName" id="baseVehInfoTransmissionTypeName">
        {{ identification.transmissionTypeName }}
      </span>
      <span *ngIf="identification.fuelTypeName" id="baseVehInfoFuelTypeName">
        {{ identification.fuelTypeName }}
        <span *ngIf="identification.fuelSubtypeName" id="baseVehInfoFuelSubtypeName">
          ({{ identification.fuelSubtypeName }})
        </span>
      </span>
    </li>
  </ng-template>
</ul>
<vpfa-vehicle-basic-info
  #vehicleBasicInfoComponent
  *ngIf="vehicleData"
  [displayHorizontal]="false"
  [vehicleData]="vehicleData"
  [isLoadingCaseData]="isLoading"
  [isUpdatingPlateNo]="isUpdatingPlateNo"
  [isUpdatingPlateNoError]="isUpdatingPlateNoError"
  [isUpdatingVin]="isUpdatingVin"
  [isUpdatingVinError]="isUpdatingVinError"
  [isUpdatingMatriculationNumber]="isUpdatingMatriculationNumber"
  [isUpdatingMatriculationNumberError]="isUpdatingMatriculationNumberError"
  [isMatriculationNumberEditable]="isMatriculationNumberEditable"
  [countryMatriculationNumberRegexPattern]="countryMatriculationNumberRegexPattern$ | async"
  (matriculationNumberChange)="matriculationNumberChange.emit($event)"
  (vinChange)="vinChange.emit($event)"
  (kbaChange)="kbaChange.emit($event)"
  [isVinEditable]="isVinEditable"
  [isKbaEditable]="isKbaEditable"
  [allowKbaClear]="allowKbaClear"
  [isProcessingKba]="isProcessingKba"
  [plateNo]="plateNo"
  (plateNoChange)="plateNoChange.emit($event)"
  [isPlateNoEditable]="isPlateNoEditable"
  [countryVrmRegexPattern]="countryVrmRegexPattern$ | async"
  [noVinWarning]="noVinWarning"
  [noPlateWarning]="noPlateWarning"
  [showCheckedSymbolOnVin]="identifiedByVin"
  [showBlueBackgroundOnVin]="isAllVinEquipmentsSelected"
></vpfa-vehicle-basic-info>

<ng-template #promotion>
  <div
    id="promoBadge"
    ngClass.lt-mv="promo-mobile"
    [className]="identification.resellerPromo || identification.isPublicSiteEnable ? 'promo' : 'promo-deactivated'"
  >
    <i class="zmdi zmdi-hc-5x zmdi-alert-polygon"></i>
    <span class="promo-label" ngClass.lt-mv="mobile">
      {{ 'vehicleBasicInfo.resellerPromo' | translate | uppercase }}
    </span>
  </div>
</ng-template>

<ng-template #resellerReplies>
  <vpfa-inline-loader *ngIf="!resellerResponsesLoaded"></vpfa-inline-loader>
  <div *ngIf="resellerResponsesLoaded" id="baseVehInfoResellerReplies">
    <div
      [ngClass]="{ disabled: !identification.wasPromoEvenEnabled }"
      class="filter-box"
      fxLayout="row"
      fxFlexAlign="center center"
    >
      <button nz-button class="unread-count-box" (click)="unreadRepliesChosen.emit()">
        <span>
          <i class="zmdi zmdi-mail-reply"></i>
          <span id="resellerRepliesUnreadCount">{{ unreadResellerResponses }}</span>
        </span>
      </button>
      <button nz-button class="flagged-count-box" (click)="flaggedRepliesChosen.emit()">
        <span id="resellerRepliesFlaggedCount">
          {{ flaggedResellerResponses }} <span nz-icon nzType="flag" nzTheme="fill"></span>
        </span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #caseStatusBadgeTemplate>
  <ng-content select="[caseStatusBadge]"></ng-content>
</ng-template>
