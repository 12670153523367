import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppPartialState } from './app.reducer';
import { appQuery } from './app.selectors';
import { fromAppActions, SetIsAdminAppValue, SetIsDealerAppValue, SetIsNavigationInProgressValue } from './app.actions';

@Injectable()
export class AppFacade {
  loaded$ = this.store.pipe(select(appQuery.getLoaded));
  isDealerApp$ = this.store.pipe(select(appQuery.getIsDealerApp));
  isAdminApp$ = this.store.pipe(select(appQuery.getIsAdminApp));
  isNavigationInProgress$ = this.store.pipe(select(appQuery.getIsNavigationInProgress));

  constructor(private store: Store<AppPartialState>) {}

  loadAll() {
    this.store.dispatch(new fromAppActions.LoadApp());
  }

  setIsDealerAppValue(value: boolean) {
    this.store.dispatch(new SetIsDealerAppValue(value));
  }

  setIsAdminAppValue(value: boolean) {
    this.store.dispatch(new SetIsAdminAppValue(value));
  }

  setIsNavigationInProgressValue(value: boolean) {
    this.store.dispatch(new SetIsNavigationInProgressValue(value));
  }
}
