import { Injectable } from '@angular/core';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { X_AVH_REALM_HEADER_KEY, X_AVH_REALM_HEADER_VALUE } from '../constants/request-headers';

@Injectable()
export class FixJwtInterceptor extends JwtInterceptor {
  handleInterception(token: string | null, request: HttpRequest<any>, next: HttpHandler) {
    let tokenIsExpired = false;

    if (!token && this.throwNoTokenError) {
      throw new Error('Could not establish session.');
    }

    if (this.skipWhenExpired) {
      tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;
    }

    if (token && tokenIsExpired && this.skipWhenExpired) {
      request = request.clone();
    } else if (token) {
      request = request.clone({
        setHeaders: {
          [this.headerName]: `${this.authScheme}${token}`,
          [X_AVH_REALM_HEADER_KEY]: X_AVH_REALM_HEADER_VALUE,
        },
      });
    }
    return next.handle(request);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenGetter();
    if (!this.isAllowedDomain(request) || this.isDisallowedRoute(request)) {
      return next.handle(request);
    }
    if (token instanceof Promise) {
      return from(token).pipe(
        mergeMap((asyncToken: string | null) => {
          return this.handleInterception(asyncToken, request, next);
        })
      );
    } else {
      return this.handleInterception(token, request, next);
    }
  }
}
