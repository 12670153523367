import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class ApiUnavailableInterceptor implements HttpInterceptor {
  static chunkReloadKey = 'api-unavailable-error-reload-count';
  readonly maxReloadCount = 3;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (request.url.startsWith(environment.apiGateway)) {
          if (navigator.onLine == true && (err.status === 503 || err.status === 0)) {
            // Reloads are limited to prevent infinite reloading of the page when API is used from QA and
            // maintenance mode is enabled in there
            if (environment.production === false) {
              const reloadCount = Number(window.sessionStorage.getItem(ApiUnavailableInterceptor.chunkReloadKey)) ?? 0;
              if (reloadCount > this.maxReloadCount) {
                console.error('ApiUnavailable: Max reload count reached');
                return;
              }

              window.sessionStorage.setItem(ApiUnavailableInterceptor.chunkReloadKey, (reloadCount + 1).toString());
            }

            // When BE is unavailable, we refresh the page to get index.html, in the response
            // browser should receive HTTP 302 and redirect to maintenance page.
            window.location.reload();
          }
        }

        return throwError(err);
      })
    );
  }
}
