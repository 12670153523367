import { Component, Input, Output, EventEmitter, ViewChild, ContentChild, TemplateRef } from '@angular/core';
import { IdentificationDataModel } from '../../models/identification.model';
import { IdentificationMethod, VehicleDto } from '@vpfa/rest-api/valuation';
import { CountryCodes, ProfileFacade } from '@vpfa/profile/data';

@Component({
  selector: 'vpfa-base-vehicle-information',
  templateUrl: './base-vehicle-information.component.html',
  styleUrls: ['./base-vehicle-information.component.scss'],
})
export class BaseVehicleInformationComponent {
  @ViewChild('vehicleBasicInfoComponent') vehicleBasicInfoComponent: any;

  countryCodes = CountryCodes;
  identifiedByVin = false;

  private _isLoading = true;
  private _identificationData: IdentificationDataModel = new IdentificationDataModel();
  @Input() vehicleData: VehicleDto;
  @Input() isVinEditable = true;
  @Input() isKbaEditable = false;
  @Input() isMatriculationNumberEditable = false;
  @Input() allowKbaClear = true;
  @Input() isProcessingKba = false;
  @Input() unreadResellerResponses;
  @Input() resellerResponsesLoaded;
  @Input() flaggedResellerResponses;
  @Input() noPlateWarning = false;
  @Input() noVinWarning = false;
  @Input() showOriginMarketLabel: boolean;
  @Input() plateNo: string;
  @Input() isUpdatingPlateNo = false;
  @Input() isUpdatingPlateNoError = false;
  @Input() isPlateNoEditable = false;
  @Input() isUpdatingVin = false;
  @Input() isUpdatingVinError = false;
  @Input() isUpdatingMatriculationNumber = false;
  @Input() isUpdatingMatriculationNumberError = false;
  @Input() isAllVinEquipmentsSelected = false;

  @Output() vinChange = new EventEmitter<string>();
  @Output() kbaChange = new EventEmitter<string>();
  @Output() plateNoChange = new EventEmitter<string>();
  @Output() matriculationNumberChange = new EventEmitter<string>();
  @Output() unreadRepliesChosen = new EventEmitter();
  @Output() flaggedRepliesChosen = new EventEmitter();

  @Input()
  set identification(identificationModel: IdentificationDataModel) {
    if (identificationModel) {
      this._identificationData = identificationModel;
      this.identifiedByVin = identificationModel.identificationMethod === IdentificationMethod.VIN;
    }
  }

  get identification() {
    return this._identificationData;
  }

  @Input()
  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  get isLoading() {
    return this._isLoading;
  }

  countryVrmRegexPattern$ = this.profileFacade.countryVrmRegexPattern$;
  countryMatriculationNumberRegexPattern$ = this.profileFacade.countryMatriculationNumberRegexPattern$;

  constructor(private profileFacade: ProfileFacade) {}
}
